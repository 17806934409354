/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';
import { ReviewOperation, ReviewMetadataChangeDiffStatus } from '../../../types/reviews';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';

interface OwnProps extends TranslationContext {
    changeDiffStatus: ReviewMetadataChangeDiffStatus;
    onAccept(): void;
    onReject(): void;
    reviewOperation: ReviewOperation | null;
}

const ReviewActionButtons: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t, changeDiffStatus, onAccept, onReject, reviewOperation,
    } = props;

    if (changeDiffStatus !== ReviewMetadataChangeDiffStatus.New && changeDiffStatus !== ReviewMetadataChangeDiffStatus.Changed) return null;

    return (
        <div className="review-buttons">
            <button
                type="button"
                onClick={onReject}
                data-testid="button-reject-action"
                className={reviewOperation === ReviewOperation.Reject ? 'reject-selected' : ''}
            >
                {changeDiffStatus === ReviewMetadataChangeDiffStatus.New ? t('workReview.rejectNew') : t('workReview.keepExisting')}
            </button>
            <button
                type="button"
                onClick={onAccept}
                data-testid="button-accept-action"
                className={reviewOperation === ReviewOperation.Accept ? 'accept-selected' : ''}
            >
                {changeDiffStatus === ReviewMetadataChangeDiffStatus.New ? t('workReview.acceptNew') : t('workReview.keepNew')}
            </button>
        </div>
    );
};

export default withTranslationContext(ReviewActionButtons);
