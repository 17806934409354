/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useEffect, useState } from 'react';
import {
    AddRounded, CheckRounded, CloseRounded, Edit,
} from '@mui/icons-material';
import {
    IconButton,
    MenuItem,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import {
    ControlledVocabulariesContext,
    withControlledVocabulariesContext,
} from '../../controllers/controlled_vocabularies/ControlledVocabulariesContext';
import { WorkThirdPartyIDs } from '../../../types/works';
import { ThirdPartyNameCV } from '../../../types/controlled_vocabularies';
import { ControlledVocabularyName } from '../../../services/controlled_vocabularies';
import { getRandomInt } from '../../../utils/misc';
import Loader from '../Loader';

enum WorkIdentifierFieldsName {
    IdValue = 'id_value',
    ThirdPartyName = 'third_party_name',
}

interface OwnProps extends TranslationContext, ControlledVocabulariesContext {
    thirdPartyNames: Array<WorkThirdPartyIDs>;
    onAdd?(newThirdPartyName: WorkThirdPartyIDs): void;
    onEdited?(thirdPartyName: WorkThirdPartyIDs): void;
    onRemove?(id: number): void;
}

const WorkFormIdentifiersTable: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t, getCV, thirdPartyNames, onAdd, onRemove, onEdited,
    } = props;

    const [cvThirdPartyNamesOptions, setCvThirdPartyNamesOptions] = useState<Array<ThirdPartyNameCV>>([]);
    const [thirdPartyNameFields, setThirdPartyNameFields] = useState<WorkThirdPartyIDs | null>(null);
    const [isEditing, setIsEditing] = useState(false);
    const [isFetching, setIsFetching] = useState(true);

    useEffect(() => {
        const requestThirdPartyNames = async () => {
            const results = await getCV<ThirdPartyNameCV>(ControlledVocabularyName.ThirdPartyNames);

            if (results) {
                setCvThirdPartyNamesOptions(results.data);
            }
            setIsFetching(false);
        };
        requestThirdPartyNames();
    }, []);

    useEffect(() => {
        if (thirdPartyNames.length === 0) {
            setNewThirdPartyNameData();
        } else {
            setThirdPartyNameFields(null);
        }
    }, [thirdPartyNames]);

    const onAccept = () => {
        if (thirdPartyNameFields) {
            if (isEditing) {
                onEdited?.(thirdPartyNameFields);
            } else {
                onAdd?.(thirdPartyNameFields);
            }
        }
        setThirdPartyNameFields(null);
        setIsEditing(false);
    };

    const onAddNewIdentifier = () => {
        setIsEditing(false);
        setNewThirdPartyNameData();
    };

    const setNewThirdPartyNameData = () => {
        setThirdPartyNameFields({
            id: getRandomInt(-100000, 0),
            fiaf_work_id: -1,
            id_value: '',
            third_party_name: '',
        });
    };

    const onSelectChange = (event: SelectChangeEvent) => {
        const {
            target: { value },
        } = event;

        if (thirdPartyNameFields) {
            setThirdPartyNameFields({
                ...thirdPartyNameFields,
                third_party_name: value,
            });
        }
    };

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {
            target: { value },
        } = event;

        if (thirdPartyNameFields) {
            setThirdPartyNameFields({
                ...thirdPartyNameFields,
                id_value: value,
            });
        }
    };

    const onEditIdentifier = (identifier: WorkThirdPartyIDs) => {
        setIsEditing(true);
        setThirdPartyNameFields({ ...identifier });
    };

    if (isFetching) {
        return <Loader />;
    }

    const thirdNamesOptions = cvThirdPartyNamesOptions
        .filter((c) => c.name === thirdPartyNameFields?.third_party_name || !thirdPartyNames.find((v) => v.third_party_name === c.name));

    const renderFormFields = () => {
        if (!thirdPartyNameFields) return null;

        return (
            <TableRow key={thirdPartyNameFields.id}>
                <TableCell className="input-cell">
                    <TextField
                        fullWidth
                        inputProps={{ 'data-testid': 'work-third-party-input', placeholder: t('work.form.idValue') }}
                        variant="standard"
                        name={WorkIdentifierFieldsName.IdValue}
                        value={thirdPartyNameFields[WorkIdentifierFieldsName.IdValue]}
                        onChange={onInputChange}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && thirdPartyNameFields[WorkIdentifierFieldsName.IdValue] && thirdPartyNameFields.third_party_name) {
                                onAccept();
                            }
                        }}
                    />
                </TableCell>
                <TableCell>
                    <Select
                        fullWidth
                        MenuProps={{ classes: { paper: 'select' } }}
                        data-testid="new-identifier-third-party-name"
                        className="simple-select dense-select"
                        name={WorkIdentifierFieldsName.ThirdPartyName}
                        value={thirdPartyNameFields[WorkIdentifierFieldsName.ThirdPartyName]}
                        onChange={onSelectChange}
                        displayEmpty
                    >
                        <MenuItem dense value="">
                            <em>{t('general.select')}</em>
                        </MenuItem>
                        {thirdNamesOptions.map((value) => (
                            <MenuItem data-testid="new-identifier-third-party-name-item" dense key={value.name} value={value.name}>{value.name}</MenuItem>
                        ))}
                    </Select>
                </TableCell>
                <TableCell align="right">
                    <IconButton
                        size="small"
                        data-testid="new-identifier-confirm-btn"
                        onClick={onAccept}
                        className="action-btn"
                        disabled={!thirdPartyNameFields.third_party_name || !thirdPartyNameFields.id_value}
                    >
                        <CheckRounded />
                    </IconButton>
                </TableCell>
            </TableRow>
        );
    };

    if (thirdPartyNames.length === 0 && !onAdd) {
        return null;
    }

    return (
        <Table size="small" data-testid="identifiers-table">
            <TableHead>
                <TableRow>
                    <TableCell>{t('work.form.id')}</TableCell>
                    <TableCell>{t('work.form.issuer')}</TableCell>
                    {onAdd && (
                        <TableCell className="add-btn-cell">
                            <IconButton data-testid="add-new-identifier-btn" className="add-small-btn" onClick={onAddNewIdentifier}>
                                <AddRounded />
                            </IconButton>
                        </TableCell>
                    )}
                </TableRow>
            </TableHead>
            <TableBody>
                {thirdPartyNames.map((name) => {
                    if (thirdPartyNameFields && isEditing && thirdPartyNameFields.id === name.id) return renderFormFields();

                    return (
                        <TableRow key={name.id}>
                            <TableCell data-testid="existing-identifier-id">{name.id_value}</TableCell>
                            <TableCell data-testid="existing-identifier-issuer">{name.third_party_name}</TableCell>
                            {(onEdited || onRemove) && (
                                <TableCell align="right">
                                    {onEdited && (
                                        <IconButton
                                            size="small"
                                            data-testid="edit-identifier-delete-btn"
                                            onClick={() => onEditIdentifier(name)}
                                            className="action-btn"
                                        >
                                            <Edit fontSize="small" />
                                        </IconButton>
                                    )}
                                    {onRemove && (
                                        <IconButton
                                            size="small"
                                            data-testid="new-identifier-delete-btn"
                                            onClick={() => onRemove(name.id)}
                                            className="action-btn"
                                        >
                                            <CloseRounded />
                                        </IconButton>
                                    )}
                                </TableCell>
                            )}
                        </TableRow>
                    );
                })}
                {thirdPartyNameFields && !isEditing && renderFormFields()}
            </TableBody>
        </Table>
    );
};

WorkFormIdentifiersTable.defaultProps = {
    onAdd: undefined,
    onEdited: undefined,
    onRemove: undefined,
};

export default withTranslationContext(withControlledVocabulariesContext(WorkFormIdentifiersTable));
