/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';

import { ConnectedArchivesController } from './ArchivesController';
import { Archive, ArchivePayload } from '../../../types/archives';
import { KeyedObject } from '../../../types/general';
import { GenericFunction, ListResponse } from '../../../types/misc';

export interface ArchivesContext {
    getArchives(filters?: KeyedObject): Promise<ListResponse<Archive> | null>;
    getArchive(code: string): Promise<Archive | null>;
    deleteArchive(code: string, onSuccess: () => void, onFailure: () => void): Promise<void>;
    getArchivesCities(): Promise<string[] | null>;
    createArchive(payload: ArchivePayload, onSuccess: GenericFunction, onFailure: GenericFunction): void;
    editArchive(id: string, payload: ArchivePayload, onSuccess: GenericFunction, onFailure: GenericFunction): void;
}

export const archivesContextDefaultValue = {
    getArchives: async (): Promise<ListResponse<Archive> | null> => null,
    getArchive: async (): Promise<Archive | null> => null,
    deleteArchive: async (): Promise<void> => {},
    getArchivesCities: async (): Promise<string[] | null> => null,
    createArchive: (): void => {},
    editArchive: (): void => {},
};

const ArchivesContextInstance = createContext<ArchivesContext | null>(archivesContextDefaultValue);

export const ArchivesContextProvider = ArchivesContextInstance.Provider;
export const ArchivesContextConsumer = ArchivesContextInstance.Consumer;

export const withArchivesContext = <P extends unknown>(Component: ComponentType<P>): FC<Omit<P, keyof ArchivesContext>> => (props) => (
    <ConnectedArchivesController>
        <ArchivesContextConsumer>
            {(ctx) => <Component {...props as P} {...ctx} />}
        </ArchivesContextConsumer>
    </ConnectedArchivesController>
);
