/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

export const TOKEN_SEARCH_PARAM_KEY = 't';

export const USER_EMAIL_SEARCH_PARAM_KEY = 'email';

export const USER_ID_SEARCH_PARAM_KEY = 'user_id';

export const FIAF_TREASURES_EMAIL = 'treasures@fiafnet.org';

export const MIN_WORK_YEAR = 1850;

export const inputMasks = {
    duration: [
        /[0-9]/,
        /[0-9]/,
        ':',
        /[0-5]/,
        /[0-9]/,
        ':',
        /[0-5]/,
        /[0-9]/,
    ],
};
