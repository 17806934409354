/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';
import { KeyedObject } from '../types/general';
import { objectToParams } from '../utils/misc';

export const citiesArchivesUrl = (): string => {
    return `${API_URL}/archives/cities`;
};

export const archivesUrl = (filters?: KeyedObject): string => {
    return `${API_URL}/archives${objectToParams(filters)}`;
};

export const archiveUrl = (code: string): string => {
    return `${API_URL}/archives/${code}`;
};
