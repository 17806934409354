/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { KeyedObject } from '../types/general';
import { API_URL } from '../settings';
import { objectToParams } from '../utils/misc';

export const agentsUrl = (filters?: KeyedObject): string => {
    return `${API_URL}/agents${objectToParams(filters)}`;
};

export const agentUrl = (id: number): string => {
    return `${API_URL}/agents/${id}`;
};
