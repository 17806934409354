/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { Container, Grid } from '@mui/material';

import { withWorksContext, WorksContext } from '../controllers/works/WorksContext';
import { Work } from '../../types/works';
import Loader from '../elements/Loader';
import { AppRoute } from '../../constants/routes';
import WorkScreenTop from '../elements/work/WorkScreenTop';
import WorkScreenContentLeft from '../elements/work/WorkScreenContentLeft';
import WorkScreenContentRight from '../elements/work/WorkScreenContentRight';

type RouteParams = {
    id: string;
};

type OwnProps = WorksContext;

const WorkScreen: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const { getWork } = props;

    const { id: paramId } = useParams<RouteParams>();
    const [work, setWork] = useState<Work | null>(null);
    const [isFetching, setIsFetching] = useState(true);

    const prepare = async () => {
        setIsFetching(true);
        const workData = await getWork(Number(paramId));
        if (workData) setWork(workData);
        setIsFetching(false);
    };

    useEffect(() => {
        prepare();
    }, []);

    if (isFetching) {
        return (
            <div className="work-screen">
                <Loader />
            </div>
        );
    }

    if (!work) {
        return <Navigate to={AppRoute.Index} />;
    }

    return (
        <Container maxWidth="md">
            <div className="work-screen">
                <WorkScreenTop work={work} onRefresh={prepare} />
                <div className="work-screen__bottom">
                    <Grid container spacing={2}>
                        <Grid item xs={4} height="100%">
                            <WorkScreenContentLeft work={work} />
                        </Grid>
                        <Grid item xs={8} height="100%">
                            <WorkScreenContentRight work={work} onRefresh={prepare} />
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Container>
    );
};

export default withWorksContext(WorkScreen);
