/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { connect } from 'react-redux';
import React, { Component, ReactNode } from 'react';
import axios, { AxiosError } from 'axios';

import { AgentsContextProvider } from './AgentsContext';
import { KeyedObject } from '../../../types/general';
import { GenericFunction, ListResponse } from '../../../types/misc';
import { agentsUrl, agentUrl } from '../../../services/agents';
import { Agent, AgentPayload } from '../../../types/agents';

interface OwnProps {
    children: ReactNode;
}

type Props = OwnProps;

export class AgentsController extends Component<Props> {
    getAgents = async (filters?: KeyedObject): Promise<ListResponse<Agent> | null> => {
        try {
            const { data } = await axios.get(agentsUrl(filters));
            return data;
        } catch {
            return null;
        }
    };

    getAgent = async (id: number): Promise<Agent | null> => {
        try {
            const { data } = await axios.get(agentUrl(id));
            return data.data;
        } catch {
            return null;
        }
    };

    createAgent = async (payload: AgentPayload, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void> => {
        try {
            await axios.post(agentsUrl(), payload);
            onSuccess();
        } catch (error) {
            const err = error as AxiosError;
            onFailure(err.response?.data);
        }
    }

    editAgent = async (agentId: number, payload: AgentPayload, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void> => {
        try {
            const { data } = await axios.put(agentUrl(agentId), payload);
            onSuccess(data.data);
        } catch (error) {
            const err = error as AxiosError;
            onFailure(err.response?.data);
        }
    }

    deleteAgent = async (agentId: number, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void> => {
        try {
            await axios.delete(agentUrl(agentId));
            onSuccess();
        } catch (error) {
            const err = error as AxiosError;
            onFailure(err.response?.data);
        }
    }

    render(): ReactNode {
        const {
            children,
        } = this.props;

        return (
            <AgentsContextProvider
                value={{
                    getAgents: this.getAgents,
                    getAgent: this.getAgent,
                    createAgent: this.createAgent,
                    editAgent: this.editAgent,
                    deleteAgent: this.deleteAgent,
                }}
            >
                {children}
            </AgentsContextProvider>
        );
    }
}

export const ConnectedAgentsController = connect()(AgentsController);
