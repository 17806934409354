/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import {
    Button,
    Dialog,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Tooltip,
} from '@mui/material';
import { Close, DeleteOutline, EditOutlined } from '@mui/icons-material';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import { Archive, ArchiveFormFields, ArchivePayload } from '../../../types/archives';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import {
    ControlledVocabulariesContext,
    withControlledVocabulariesContext,
} from '../../controllers/controlled_vocabularies/ControlledVocabulariesContext';
import { ControlledVocabulary, CountryCV } from '../../../types/controlled_vocabularies';
import Loader from '../Loader';
import Confirm from '../Confirm';
import { ArchivesContext, withArchivesContext } from '../../controllers/archives/ArchivesContext';
import { ControlledVocabularyName } from '../../../services/controlled_vocabularies';
import IconCircleArchive from '../../assets/IconCircleArchive';
import { AuthorizationContext, withAuthorizationContext } from '../../controllers/authorization/AuthorizationContext';
import { ApiError } from '../../../types/errors';
import { Permission } from '../../../types/authorization';

interface OwnProps extends TranslationContext, ArchivesContext, ControlledVocabulariesContext, AuthorizationContext {
    archiveCode?: string;
    onClose(updateList: boolean): void;
}

enum FieldName {
    Name = 'name',
    Code = 'code',
    Country = 'country_ISO_code',
    City = 'city',
}

const initialFieldValues: ArchiveFormFields = {
    code: '',
    id: '',
    name: '',
    city: '',
    country_ISO_code: '',
};

const ArchiveFormDialog: FunctionComponent<OwnProps> = (props): React.ReactElement | null => {
    const {
        t,
        getCV,
        onClose,
        archiveCode,
        getArchive,
        deleteArchive,
        createArchive,
        editArchive,
        checkPermission,
    } = props;

    const isEdit = !!archiveCode;

    const [fieldValues, setFieldValues] = useState<ArchiveFormFields>(initialFieldValues);
    const [countriesList, setCountriesList] = useState<ControlledVocabulary<CountryCV>>({ data: [] });
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [archive, setArchive] = useState<Archive | null>(null);
    const [isViewingDetails, setIsViewingDetails] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        prepare();
    }, []);

    const onCreateSuccess = () => {
        onClose(true);
        enqueueSnackbar(t('archivesScreen.successfullyCreated'), { variant: 'success' });
    };

    const onEditSuccess = () => {
        onClose(true);
        enqueueSnackbar(t('archivesScreen.successfullyEdited'), { variant: 'success' });
    };

    const onDeleteSuccessMessage = () => {
        enqueueSnackbar(t('archivesScreen.successfullyDeleted'), { variant: 'success' });
    };

    const onRequestFailureMessage = (error?: ApiError) => {
        if (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
        } else {
            enqueueSnackbar(t('general.requestGeneralError'), { variant: 'error' });
        }
    };
    
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setFieldValues({
            ...fieldValues,
            [name]: value,
        });
    };

    const handleSelectChange = (event: SelectChangeEvent) => {
        const {
            target: { value, name },
        } = event;

        setFieldValues({
            ...fieldValues,
            [name]: value as string,
        });
    };

    const handleDelete = () => {
        if (archiveCode) {
            setShowConfirm(false);
            deleteArchive(
                archiveCode,
                () => {
                    onDeleteSuccessMessage();
                    onClose(true);
                },
                onRequestFailureMessage,
            );
        }
    };

    const handleSave = () => {
        const payload: ArchivePayload = {
            code: fieldValues.code,
            name: fieldValues.name,
            city: fieldValues.city,
            country_ISO_code: fieldValues.country_ISO_code,
        };

        if (isEdit && archive && archive.code) {
            editArchive(archive.code, payload, onEditSuccess, onRequestFailureMessage);
            return;
        }

        createArchive(payload, onCreateSuccess, onRequestFailureMessage);
    };

    const prepare = async () => {
        const data = await getCV<CountryCV>(ControlledVocabularyName.Countries);
        if (data) setCountriesList(data);
        if (archiveCode) {
            try {
                const archiveData = await getArchive(archiveCode);
                if (archiveData) {
                    setIsViewingDetails(true);
                    setArchive(archiveData);
                    setFieldValues({
                        code: archiveData.code,
                        id: archiveData.id || '',
                        name: archiveData.name,
                        city: archiveData.city,
                        country_ISO_code: archiveData.country?.ISO_code || '',
                    });
                }
            } catch (error) {
                const err = error as AxiosError;
                onRequestFailureMessage(err.response?.data);
            }
        }
        setIsFetching(false);
    };

    let title = t('archiveFormDialog.archiveDetails');
    if (!isViewingDetails && isEdit) {
        title = t('archiveFormDialog.editTitle');
    }
    if (!isEdit) {
        title = t('archiveFormDialog.createTitle');
    }

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open
            onClose={() => onClose(false)}
            className="main-dialog archive-dialog"
        >
            {isFetching && <Loader />}
            {archive && (
                <Confirm
                    show={showConfirm}
                    title={t('archiveDeleteConfirm.title')}
                    message={[t('archiveDeleteConfirm.message1'), t('archiveDeleteConfirm.message2')]}
                    okButton={t('archiveDeleteConfirm.okButton')}
                    onClose={() => setShowConfirm(false)}
                    onConfirm={handleDelete}
                >
                    <div className="delete-confirm delete-archive">
                        <div className="icon">
                            <IconCircleArchive />
                        </div>
                        <p>{archive.name}</p>
                    </div>
                </Confirm>
            )}
            <div className="main-dialog__top">
                <div className="main-dialog__top__left">
                    <h2 className="secondary">{title}</h2>
                </div>
                <div className="main-dialog__top__right">
                    {!isViewingDetails && (
                        <Button
                            color="primary"
                            data-testid="archive-save-button"
                            variant="contained"
                            disableElevation
                            onClick={handleSave}
                        >
                            {t('archiveFormDialog.save')}
                        </Button>
                    )}
                    {isEdit && isViewingDetails && checkPermission([Permission.UPDATE_ARCHIVE]) && (
                        <Tooltip title={t('archiveFormDialog.editToolTip')}>
                            <IconButton
                                className="square-button"
                                data-testid="archive-edit-button"
                                onClick={() => setIsViewingDetails(false)}
                            >
                                <EditOutlined />
                            </IconButton>
                        </Tooltip>
                    )}
                    {isEdit && checkPermission([Permission.DELETE_ARCHIVE]) && (
                        <Tooltip title={t('archiveFormDialog.deleteTooltip')}>
                            <IconButton
                                className="square-button"
                                data-testid="archive-delete-button"
                                onClick={() => setShowConfirm(true)}
                            >
                                <DeleteOutline />
                            </IconButton>
                        </Tooltip>
                    )}
                    <IconButton
                        data-testid="archive-dialog-close-button"
                        aria-label="close"
                        onClick={() => onClose(false)}
                    >
                        <Close color="primary" />
                    </IconButton>
                </div>
            </div>
            <div className="main-dialog__content">
                <Grid container spacing={2}>
                    <Grid item xs={12} className="centered">
                        <IconCircleArchive />
                    </Grid>
                    <Grid item xs={8}>
                        <FormControl fullWidth>
                            <InputLabel>{t('archiveFormDialog.nameLabel')}</InputLabel>
                            <TextField
                                fullWidth
                                data-testid="archive-dialog-input-name"
                                name={FieldName.Name}
                                value={fieldValues[FieldName.Name]}
                                onChange={handleInputChange}
                                disabled={isViewingDetails}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <InputLabel>{t('archiveFormDialog.codeLabel')}</InputLabel>
                            <TextField
                                fullWidth
                                data-testid="archive-dialog-input-code"
                                name={FieldName.Code}
                                value={fieldValues[FieldName.Code]}
                                onChange={handleInputChange}
                                disabled={isViewingDetails}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel>{t('archiveFormDialog.countryLabel')}</InputLabel>
                            <Select
                                fullWidth
                                MenuProps={{ classes: { paper: 'select' } }}
                                data-testid="archive-dialog-country-input"
                                name={FieldName.Country}
                                value={fieldValues[FieldName.Country]}
                                onChange={handleSelectChange}
                                disabled={isViewingDetails}
                            >
                                <MenuItem value=""><em>{t('archiveFormDialog.none')}</em></MenuItem>
                                {countriesList?.data.map((country) => (
                                    <MenuItem
                                        data-testid="archive-dialog-country-input-item"
                                        key={country.ISO_code}
                                        value={country.ISO_code}
                                    >
                                        {country.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel>{t('archiveFormDialog.cityLabel')}</InputLabel>
                            <TextField
                                fullWidth
                                data-testid="archive-dialog-input-city"
                                name={FieldName.City}
                                value={fieldValues[FieldName.City]}
                                onChange={handleInputChange}
                                disabled={isViewingDetails}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </div>
        </Dialog>
    );
};

ArchiveFormDialog.defaultProps = {
    archiveCode: undefined,
};

export default withTranslationContext(
    withAuthorizationContext(
        withControlledVocabulariesContext(
            withArchivesContext(
                ArchiveFormDialog,
            ),
        ),
    ),
);
