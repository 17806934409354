/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Tooltip } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation } from 'react-router';
import { useSnackbar } from 'notistack';

import FiafTreasuresLogo from '../../assets/image/fiaf-treasures-logo.png';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { AppRoute } from '../../constants/routes';
import UserMenu from './UserMenu';
import IconCircleUser from '../assets/IconCircleUser';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import { SearchParamsContext, withSearchParamsContext } from '../controllers/searchParams/SearchParamsContext';
import { ApiError } from '../../types/errors';
import UserFormDialog from './user/UserFormDialog';

enum ButtonVisibility {
    Visible = 'visible',
    Hidden = 'hidden',
}

interface OwnProps extends TranslationContext, AuthenticationContext, SearchParamsContext {}

type Props = OwnProps;

const TopBar: FunctionComponent<Props> = (props: Props) => {
    const {
        t,
        submitLogout,
        authenticatedUser,
        resetSearchParams,
    } = props;

    const [visibility, setVisibility] = useState(ButtonVisibility.Visible);
    const [showUserProfile, setShowUserProfile] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const renderBackButton = () => {
        if (location.pathname === AppRoute.Index) {
            setVisibility(ButtonVisibility.Hidden);
        } else {
            setVisibility(ButtonVisibility.Visible);
        }
    };

    useEffect(() => {
        renderBackButton();
    }, [location.pathname]);

    return (
        <div className="top-bar">
            {authenticatedUser && showUserProfile && (
                <UserFormDialog
                    userId={authenticatedUser.id}
                    onClose={() => setShowUserProfile(false)}
                    isProfile
                />
            )}
            <div className="top-bar__page-back-wrapper">
                <Button
                    variant="text"
                    size="small"
                    color="secondary"
                    startIcon={<ArrowBackIcon fontSize="small" />}
                    onClick={() => navigate(-1)}
                    sx={{ visibility: `${visibility}` }}
                    data-testid="topbar-back-button"
                >
                    <p>{t('topBar.back')}</p>
                </Button>
            </div>
            <div className="top-bar__logo-wrapper">
                <img
                    src={FiafTreasuresLogo}
                    alt={t('general.fiafAndTreasuresLogo')}
                    data-testid="topbar-logo"
                    onClick={() => {
                        resetSearchParams();
                        navigate(AppRoute.Index);
                    }}
                />
            </div>
            <div className="top-bar__profile">
                <div className="top-bar__profile__username-wrapper">
                    <p>{`${authenticatedUser?.first_name || ''} ${authenticatedUser?.last_name || ''}`}</p>
                    <Button
                        variant="text"
                        size="small"
                        color="secondary"
                        onClick={() => {
                            submitLogout((error: ApiError) => {
                                if (error.message) enqueueSnackbar(error.message, { variant: 'error' });
                            });
                            resetSearchParams();
                        }}
                    >
                        {t('topBar.logout')}
                    </Button>
                </div>
                <Tooltip title={t('topBar.profileTooltip')}>
                    <button
                        type="button"
                        className="top-bar__profile__avatar"
                        data-testid="profile-btn"
                        onClick={() => setShowUserProfile(true)}
                    >
                        <IconCircleUser />
                    </button>
                </Tooltip>
                <div className="top-bar__profile__user-menu">
                    <UserMenu />
                </div>
            </div>
        </div>
    );
};

export default withTranslationContext(
    withAuthenticationContext(
        withSearchParamsContext(
            TopBar,
        ),
    ),
);
