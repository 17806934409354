/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { Agent } from '../types/agents';
import { WorkAgent } from '../types/works';

export const getAgentName = (agent: WorkAgent | Agent, withComma = false): string => {
    let name = `${agent.first_name} ${agent.last_name}`;
    if (agent.name_inverted && !agent.company) name = `${agent.last_name}${withComma && agent.last_name ? ',' : ''} ${agent.first_name}`;

    if (agent.extra_name_info) {
        name += ` - ${agent.extra_name_info}`;
    }

    return name.trim();
};

export const getAgentNameForAgentScreen = (agent: WorkAgent | Agent): string => {
    return getAgentName({ ...agent, name_inverted: true }, true);
};
