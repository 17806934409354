/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { AnyAction } from 'redux';
import { REHYDRATE } from 'redux-persist';
import { SearchParamsState } from '../types/store';
import { SearchParamsAction } from '../actions/searchParams_types';

export const searchParamsInitialState: SearchParamsState = {
    simpleSearchParams: '',
    advancedSearchParams: null,
    isAdvancedSearch: false,
    currentPage: 1,
    simpleSearchInputDisplay: '',
};

function searchParams(state = searchParamsInitialState, action: AnyAction): SearchParamsState {
    switch (action.type) {
        case REHYDRATE:
            if (action.payload && action.payload.search) {
                return {
                    ...action.payload.search,
                };
            }
            return state;
        case SearchParamsAction.SET_SIMPLE_SEARCH_PARAMS:
            return {
                ...state,
                simpleSearchParams: action.payload,
            };
        case SearchParamsAction.SET_ADVANCED_SEARCH_PARAMS:
            return {
                ...state,
                advancedSearchParams: action.payload,
            };
        case SearchParamsAction.SET_IS_ADVANCED_SEARCH:
            return {
                ...state,
                isAdvancedSearch: action.payload,
            };
        case SearchParamsAction.SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload,
            };
        case SearchParamsAction.SET_SIMPLE_SEARCH_INPUT_DISPLAY:
            return {
                ...state,
                simpleSearchInputDisplay: action.payload,
            };
        case SearchParamsAction.RESET_SEARCH_PARAMS:
            return searchParamsInitialState;
        default:
            return state;
    }
}

export default searchParams;
