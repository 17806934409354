/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

export enum AppRoute {
    Index = '/',
    Login = '/login',
    ForgotPassword = '/forgot-password',
    Profile = '/profile',
    WorkDetails = '/work/:id',
    WorkNew = '/work/new',
    WorkEdit = '/work-edit/:id',
    WorkReview = '/work-review/:id',
    WorkReviewChanges = '/work-review-changes/:id',
    Users = '/users',
    Agents = '/agents',
    Archives = '/archives',
    Vocabulary = '/vocabulary',
    Data = '/data',
    AcceptInvite = '/acceptinvite',
    ConfirmEmailChange = '/newemail',
    ResetPassword = '/newpass',
}
