/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, ReactNode } from 'react';
import axios, { AxiosError } from 'axios';

import { ImportContextProvider } from './ImportContext';
import { GenericFunction } from '../../../types/misc';
import { importsUrl } from '../../../services/import';

interface OwnProps {
    children: ReactNode;
}

type Props = OwnProps;

class ImportController extends Component<Props> {
    sendImportFile = async (formData: FormData, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void> => {
        try {
            const { data } = await axios.post(importsUrl(), formData);

            onSuccess(data.data);
        } catch (e) {
            const err = e as AxiosError;
            onFailure(err.response?.data);
        }
    }

    downloadFileAPI = async (typeUrl: string, onFailure: GenericFunction): Promise<{ file: Blob, fileName: string } | null> => {
        try {
            const { data, headers } = await axios.get(typeUrl, {
                responseType: 'blob',
            });
            const fileName = headers['content-disposition'].split('filename=')[1];
            return { file: data, fileName };
        } catch (e) {
            const err = e as AxiosError;
            onFailure(err.response?.data);
            return null;
        }
    }

    render(): ReactNode {
        const {
            children,
        } = this.props;

        return (
            <ImportContextProvider
                value={{
                    sendImportFile: this.sendImportFile,
                    downloadFileAPI: this.downloadFileAPI,
                }}
            >
                {children}
            </ImportContextProvider>
        );
    }
}

export default ImportController;
