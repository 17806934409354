/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { Permission } from '../types/authorization';

export const groupedPermissions: { [key: string]: Array<Permission> } = {
    User: [
        Permission.LIST_AGENTS,
        Permission.LIST_ARCHIVES,
    ],
    Contributor: [
        Permission.CREATE_ITEM,
        Permission.UPDATE_ITEM,
        Permission.DELETE_ITEM,
        Permission.USE_IMPORT,
        Permission.LIST_AGENTS,
        Permission.LIST_ARCHIVES,
        Permission.LIST_VOCABULARY,
    ],
    Editor: [
        ...Object.values(Permission),
    ],
};
