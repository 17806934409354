/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

export enum DateType {
    Release = 'Release',
    Production = 'Production',
}

export enum DateAccuracyType {
    Estimated = 'Estimated',
    Accurate = 'Accurate',
}
