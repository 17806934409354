/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useEffect, useState } from 'react';
import {
    Button, Container, Grid, Pagination,
} from '@mui/material';
import { useNavigate } from 'react-router';

import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import Loader from '../elements/Loader';
import IconCircleUser from '../assets/IconCircleUser';
import { ReviewsContext, withReviewsContext } from '../controllers/reviews/ReviewsContext';
import {
    Review, ReviewStatusParams, ReviewsSearchParams, ReviewStatusReturns,
} from '../../types/reviews';
import { ListResponseMeta } from '../../types/misc';
import IconCirclePen from '../assets/IconCirclePen';
import { buildRoute } from '../../utils/misc';
import { AppRoute } from '../../constants/routes';

type Props = TranslationContext & ReviewsContext;

const DataListScreen: FunctionComponent<Props> = (props: Props) => {
    const { t, getReviews } = props;

    const reviewsSearchParams: ReviewsSearchParams = {
        status: ReviewStatusParams.RequiredOrCompleted,
        page: 1,
    };

    const navigate = useNavigate();

    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [reviews, setReviews] = useState<Review[]>([]);
    const [paginationParams, setPaginationParams] = useState<ListResponseMeta | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);

    useEffect(() => {
        const prepare = async () => {
            setIsFetching(true);
            const reviewsData = await getReviews(reviewsSearchParams);
            if (reviewsData) {
                setReviews(reviewsData.data);
                setPaginationParams(reviewsData.meta);
            }
            setIsFetching(false);
        };

        prepare();
    }, []);

    useEffect(() => {
        fetchOnPageChange();
    }, [currentPage]);

    const onPagination = async (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
    };

    const fetchOnPageChange = async () => {
        setIsFetching(true);

        const reviewsData = await getReviews({ ...reviewsSearchParams, page: currentPage });
        if (reviewsData) {
            setReviews(reviewsData.data);
            setPaginationParams(reviewsData.meta);
        }
        setIsFetching(false);
    };

    const onDateDisplay = (date: string) => {
        const dateObject = new Date(date);
        return `${dateObject.getMonth() + 1}/${dateObject.getDate()}/${dateObject.getFullYear()}`;
    };

    const onReviseClick = (review: Review) => {
        if (review.fiaf_work_id_exact) {
            navigate(buildRoute(AppRoute.WorkReviewChanges, { id: review.id }));
        } else {
            navigate(buildRoute(AppRoute.WorkReview, { id: review.id }));
        }
    };

    const renderStatus = (review: Review) => {
        switch (review.status) {
            case ReviewStatusReturns.Required:
                return <Button onClick={() => onReviseClick(review)}>{t('reviewDataScreen.revise')}</Button>;
            case ReviewStatusReturns.CompleteRevised:
                return <span className="information">{t('reviewDataScreen.reviewed')}</span>;
            case ReviewStatusReturns.CompleteDeclined:
                return <span className="information">{t('reviewDataScreen.declined')}</span>;
            case ReviewStatusReturns.CompleteApproved:
                return <span className="information">{t('reviewDataScreen.approved')}</span>;
            case ReviewStatusReturns.NoRequired:
                return <span className="information">{t('reviewDataScreen.noReviewRequired')}</span>;
            default:
                return null;
        }
    };

    return (
        <Container maxWidth="md">
            {isFetching && <Loader />}
            <div className="management-screen">
                <div className="management-screen__top">
                    <div className="management-screen__top__left">
                        <div className="management-screen__top__left__image">
                            <IconCirclePen />
                        </div>
                        <div className="management-screen__top__left__title">
                            <h3>{t('reviewDataScreen.title')}</h3>
                            <p>{t('reviewDataScreen.subtitle')}</p>
                        </div>
                    </div>
                </div>
                <div className="management-screen__bottom">
                    <div className="management-screen__bottom__list">
                        {reviews.map((review) => {
                            return (
                                <div
                                    className="review__item"
                                    key={review.id}
                                >
                                    <div className="review__item__left-side">
                                        <div className="review__item__image">
                                            <IconCircleUser />
                                        </div>
                                        <div className="review__item__description">
                                            <h3>{review.userCreated.first_name} {review.userCreated.last_name}</h3>
                                            <p>
                                                <span className="bold">{review.archive_code}</span>
                                                , {t('reviewDataScreen.madeChangesTo')}
                                                <span className="bold"> {review.fiaf_work_preferred_title || review.preferred_work_title_original}</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="review__item__right-side">
                                        <Grid
                                            container
                                            justifyContent="space-between"
                                            alignItems="center"
                                            spacing={1}
                                        >
                                            <Grid item xs={6}>
                                                <p>{onDateDisplay(review.created_at)}</p>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <div className="center">
                                                    {renderStatus(review)}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="management-screen__bottom__pagination">
                        {paginationParams && (
                            <Pagination
                                count={paginationParams.last_page}
                                page={paginationParams.current_page}
                                onChange={onPagination}
                            />
                        )}
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default withReviewsContext(withTranslationContext(DataListScreen));
