/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import axios, { AxiosError } from 'axios';
import { connect } from 'react-redux';
import React, { Component, ReactNode } from 'react';

import { ArchivesContextProvider } from './ArchivesContext';
import { archivesUrl, archiveUrl, citiesArchivesUrl } from '../../../services/archives';
import { Archive, ArchivePayload } from '../../../types/archives';
import { KeyedObject } from '../../../types/general';
import { GenericFunction, ListResponse } from '../../../types/misc';

interface OwnProps {
    children: ReactNode;
}

type Props = OwnProps;

export class ArchivesController extends Component<Props> {
    getArchives = async (filters?: KeyedObject): Promise<ListResponse<Archive> | null> => {
        try {
            const { data } = await axios.get(archivesUrl(filters));
            return data;
        } catch {
            return null;
        }
    };

    getArchive = async (code: string): Promise<Archive | null> => {
        try {
            const { data } = await axios.get(archiveUrl(encodeURIComponent(code)));
            return data.data;
        } catch {
            return null;
        }
    };

    createArchive = async (payload: ArchivePayload, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void> => {
        try {
            await axios.post(archivesUrl(), payload);
            onSuccess();
        } catch (error) {
            const err = error as AxiosError;
            onFailure(err.response?.data);
        }
    };

    editArchive = async (id: string, payload: ArchivePayload, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void> => {
        try {
            await axios.put(archiveUrl(encodeURIComponent(id)), payload);
            onSuccess();
        } catch (error) {
            const err = error as AxiosError;
            onFailure(err.response?.data);
        }
    };

    deleteArchive = async (code: string, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void> => {
        try {
            await axios.delete(archiveUrl(encodeURIComponent(code)));
            onSuccess();
        } catch (error) {
            const err = error as AxiosError;
            onFailure(err.response?.data);
        }
    };

    getArchivesCities = async (): Promise<Array<string> | null> => {
        try {
            const { data } = await axios.get(citiesArchivesUrl());
            return data;
        } catch {
            return null;
        }
    };

    render(): ReactNode {
        const {
            children,
        } = this.props;

        return (
            <ArchivesContextProvider
                value={{
                    getArchives: this.getArchives,
                    getArchive: this.getArchive,
                    deleteArchive: this.deleteArchive,
                    getArchivesCities: this.getArchivesCities,
                    createArchive: this.createArchive,
                    editArchive: this.editArchive,
                }}
            >
                {children}
            </ArchivesContextProvider>
        );
    }
}

export const ConnectedArchivesController = connect()(ArchivesController);
