/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useEffect, useState } from 'react';
import {
    IconButton,
    MenuItem,
    Select, SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';
import { AddRounded, CheckRounded, CloseRounded } from '@mui/icons-material';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { ControlledVocabulariesContext, withControlledVocabulariesContext } from '../../controllers/controlled_vocabularies/ControlledVocabulariesContext';
import { WorkCountry } from '../../../types/works';
import { CountryCV } from '../../../types/controlled_vocabularies';
import { ControlledVocabularyName } from '../../../services/controlled_vocabularies';
import Loader from '../Loader';
import { getRandomInt } from '../../../utils/misc';

interface OwnProps extends TranslationContext, ControlledVocabulariesContext {
    countries: Array<WorkCountry>;
    onAdd?(workCountry: WorkCountry): void;
    onRemove?(countryIsoCode: string): void;
}

enum FieldName {
    CountryISOCode = 'country_ISO_code',
    CountryProbable = 'country_probable',
    Name = 'name',
}

const workCountryInitialValues: WorkCountry = {
    id: -1,
    name: '',
    country_ISO_code: '',
    country_probable: false,
};

const WorkFormCountriesTable: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t, onAdd, onRemove, getCV, countries,
    } = props;

    const [countriesOptions, setCountryOptions] = useState<Array<CountryCV>>([]);
    const [newCountry, setNewCountry] = useState<WorkCountry | null>(null);
    const [isFetching, setIsFetching] = useState(true);

    useEffect(() => {
        const fetchCountriesCV = async () => {
            const results = await getCV<CountryCV>(ControlledVocabularyName.Countries);
            if (results) {
                setCountryOptions(results.data);
            }

            setIsFetching(false);
        };

        fetchCountriesCV();
    }, []);

    useEffect(() => {
        if (countries.length === 0) {
            onAddNewCountry();
        } else {
            setNewCountry(null);
        }
    }, [countries]);

    const onAddNewCountry = () => {
        setNewCountry({ ...workCountryInitialValues, id: getRandomInt(-100000, 0) });
    };

    const onSelectChange = (event: SelectChangeEvent) => {
        const {
            target: { value },
        } = event;

        const country = countriesOptions.find((c) => c.ISO_code === value);
        if (country && newCountry) {
            setNewCountry({
                ...newCountry,
                [FieldName.Name]: country.name,
                [FieldName.CountryISOCode]: country.ISO_code,
            });
        }
    };

    const onChangeAccuracy = () => {
        if (newCountry) {
            setNewCountry({
                ...newCountry,
                country_probable: !newCountry.country_probable,
            });
        }
    };

    const onAccept = () => {
        if (newCountry) {
            onAdd?.(newCountry);
            setNewCountry(null);
        }
    };

    const getCountryNameFromCode = (isoCode: string) => {
        const country = countriesOptions.find((c) => c.ISO_code === isoCode);
        return country?.name || '';
    };

    if (isFetching) {
        return <Loader />;
    }
    
    if (countries.length === 0 && !onAdd) {
        return null;
    }

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>{t('work.form.country')}</TableCell>
                    <TableCell>{t('work.form.accuracy')}</TableCell>
                    {onAdd && (
                        <TableCell className="add-btn-cell">
                            <IconButton data-testid="add-new-country-btn" className="add-small-btn" onClick={onAddNewCountry}>
                                <AddRounded />
                            </IconButton>
                        </TableCell>
                    )}
                </TableRow>
            </TableHead>
            <TableBody>
                {countries.map((workCountry) => (
                    <TableRow key={workCountry.id}>
                        <TableCell>{workCountry.name || getCountryNameFromCode(workCountry.country_ISO_code)}</TableCell>
                        <TableCell>{workCountry.country_probable ? t('work.form.estimated') : t('work.form.accurate')}</TableCell>
                        {onRemove && (
                            <TableCell align="right">
                                <IconButton
                                    size="small"
                                    data-testid="work-country-delete-btn"
                                    onClick={() => onRemove(workCountry.country_ISO_code)}
                                    className="action-btn"
                                >
                                    <CloseRounded />
                                </IconButton>
                            </TableCell>
                        )}
                    </TableRow>
                ))}
                {newCountry && (
                    <TableRow>
                        <TableCell>
                            <Select
                                fullWidth
                                MenuProps={{ classes: { paper: 'select' } }}
                                data-testid="country-select"
                                className="simple-select dense-select"
                                name={FieldName.CountryISOCode}
                                value={newCountry[FieldName.CountryISOCode]}
                                onChange={onSelectChange}
                                displayEmpty
                            >
                                <MenuItem dense value="">
                                    <em>{t('general.select')}</em>
                                </MenuItem>
                                {countriesOptions.filter((opt) => !countries.find((c) => c.country_ISO_code === opt.ISO_code)).map((value) => (
                                    <MenuItem data-testid="country-select-item" dense key={value.name} value={value.ISO_code}>{value.name}</MenuItem>
                                ))}
                            </Select>
                        </TableCell>
                        <TableCell>
                            <Select
                                fullWidth
                                MenuProps={{ classes: { paper: 'select' } }}
                                data-testid="country-accuracy-select"
                                className="simple-select dense-select"
                                name={FieldName.CountryProbable}
                                value={String(newCountry[FieldName.CountryProbable])}
                                onChange={onChangeAccuracy}
                            >
                                <MenuItem dense value="false">{t('work.form.accurate')}</MenuItem>
                                <MenuItem dense value="true">{t('work.form.estimated')}</MenuItem>
                            </Select>
                        </TableCell>
                        <TableCell align="right">
                            <IconButton
                                size="small"
                                data-testid="work-country-confirm-btn"
                                onClick={onAccept}
                                className="action-btn"
                                disabled={!newCountry.country_ISO_code}
                            >
                                <CheckRounded />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
};

WorkFormCountriesTable.defaultProps = {
    onAdd: undefined,
    onRemove: undefined,
};

export default withTranslationContext(withControlledVocabulariesContext(WorkFormCountriesTable));
