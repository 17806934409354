/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { Permission, PermissionOperator } from '../types/authorization';

export const checkPermission = (permissions: Array<Permission>, actions: Array<Permission>, operator: PermissionOperator = PermissionOperator.OR): boolean => {
    if (operator === PermissionOperator.OR) {
        return actions.some((action) => permissions.some((perm) => perm === action));
    }

    return actions.every((action) => permissions.includes(action));
};
