/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */
 
import { Archive } from './archives';

export enum UserType {
    User = 'user',
    Editor = 'editor',
    Contributor = 'contributor',
}

export enum UserStatus {
    Active = 'active',
    Inactive = 'inactive',
    InviteSent = 'invite_sent',
}

export interface User {
    id: number;
    name: string;
    first_name: string;
    last_name: string;
    email: string;
    user_type: UserType;
    archive_code: string | null;
    archive: Archive | null;
    status: UserStatus;
    new_email_requested: string | null;
}

export interface UserFormFields {
    email: string;
    first_name: string;
    last_name: string;
    user_type: string;
    archive_code: string;
    password: string;
    current_password: string;
    password_confirmation: string;
}

export interface UserPayload {
    email: string;
    first_name: string;
    last_name: string;
    user_type: string;
    archive_code: string | null;
}

export interface SetPasswordFields {
    password: string;
    password_confirmation: string;
}

export interface UserSetPasswordPayload {
    token: string;
    email: string;
    password: string;
    password_confirmation: string;
}

export interface ConfirmEmailChangePayload {
    user_id: number;
    token: string;
    email: string;
}

export interface ChangePasswordPayload {
    current_password: string;
    password: string;
    password_confirmation: string;
}
