/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
    Button, Collapse, Grid, InputLabel, List, ListItem, ListItemText, Radio,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import WarningIcon from '@mui/icons-material/Warning';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { ReviewsContext, withReviewsContext } from '../../controllers/reviews/ReviewsContext';
import { Review, ReviewMetadataChange, ReviewWorkSuggestions } from '../../../types/reviews';
import WorkFormIdentifiers from '../work/WorkFormIdentifiersTable';
import { buildRoute, getRandomInt } from '../../../utils/misc';
import WorkFormTitlesTable from '../work/WorkFormTitlesTable';
import ChipsInput from '../ChipsInput';
import WorkFormCountriesTable from '../work/WorkFormCountriesTable';
import WorkFormDatesTable from '../work/WorkFormDatesTable';
import WorkFormAgentsTable from '../work/WorkFormAgentsTable';
import { Work } from '../../../types/works';
import { AppRoute } from '../../../constants/routes';
import WorkMatchesDialog from '../work/WorkMatchesDialog';
import { ArchiveWork } from '../../../types/archive_works';
import WorkFormItems from '../work/WorkFormItems';

interface OwnProps extends TranslationContext, ReviewsContext {
    review: Review;
    metadataChanges: ReviewMetadataChange | null;
    suggestions: ReviewWorkSuggestions | null;
    workArchives: Array<ArchiveWork>;
}

const ReviewNewWorkData: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t, review, metadataChanges, suggestions, setReviewSelectedWork, workArchives,
    } = props;

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [showSuggestions, setShowSuggestions] = useState(true);
    const [selectedWork, setSelectedWork] = useState<Work | null>(null);
    const [totalWorkSuggestions, setTotalWorkSuggestions] = useState(0);
    const [findOtherMatches, setFindOtherMatches] = useState<boolean>(false);
    const [newWorkSuggestion, setNewWorkSuggestion] = useState<Work | null>(null);

    useEffect(() => {
        let totalSuggestions = 0;
        if (suggestions) {
            const {
                preferred_work_title_similar: preferredSimilar,
                preferred_work_title_equal: preferredEqual,
                third_party_work_id: thirdParty,
                series,
                alternative_titles,
            } = suggestions;
            totalSuggestions = preferredEqual.length + preferredSimilar.length + thirdParty.length + series.length + alternative_titles.length;
        }
        setTotalWorkSuggestions(totalSuggestions);
    }, [suggestions]);

    const onApproveSelectedWork = () => {
        if (!selectedWork) return;

        setReviewSelectedWork(review.id, selectedWork.fiaf_work_id, onApproveSelectedWorkSuccess, onApproveSelectedWorkFailure);
    };

    const onApproveSelectedWorkSuccess = () => {
        enqueueSnackbar(t('workReview.newWorkMatchApproveSuccess'), { variant: 'success' });
        navigate(buildRoute(AppRoute.WorkReviewChanges, { id: review.id }));
    };

    const onApproveSelectedWorkFailure = () => {
        enqueueSnackbar(t('workReview.newWorkMatchApproveFailure'), { variant: 'error' });
    };

    const onUpdateWithNewSuggestion = (data: Work) => {
        if (data) {
            setNewWorkSuggestion(data);
        }
    };

    const renderSuggestionRow = (work: Work, label: string) => {
        return (
            <ListItem
                key={work.fiaf_work_id}
                secondaryAction={(
                    <Radio
                        data-testid="radio-select-suggestion"
                        edge="end"
                        onChange={() => setSelectedWork(work)}
                        checked={work.fiaf_work_id === selectedWork?.fiaf_work_id}
                    />
                )}
            >
                <ListItemText
                    primary={work.preferred_title}
                    secondary={label}
                />
            </ListItem>
        );
    };

    const onCloseModal = () => {
        setFindOtherMatches(false);
    };

    const renderProductionSection = () => {
        if (metadataChanges) {
            if (metadataChanges.sound_production.valueRef
                || metadataChanges.notes.valueRef
                || metadataChanges.countries.length > 0
                || metadataChanges.content_description.valueRef) {
                return true;
            }
        }
        return false;
    };

    if (!metadataChanges) return null;
    return (
        <div className="work-form__inner work-form__inner--new-work-review" data-testid="div-review-new-work-data">
            <Collapse in={showSuggestions && !!(suggestions)} collapsedSize={0}>
                <div className="work-form__inner__suggestions">
                    <div className="work-form__inner__suggestions__top">
                        <div>
                            <WarningIcon />
                            <span>{t('workReview.possibleMatchesFound', { count: String(totalWorkSuggestions) })}</span>
                        </div>
                        <Button
                            variant="text"
                            onClick={() => setShowSuggestions(false)}
                        >
                            {t('workReview.ignoreMatches')}
                        </Button>
                    </div>
                    {suggestions && (
                        <List dense>
                            {suggestions.third_party_work_id.map((work) => renderSuggestionRow(work, t('workReview.hasSameThirdPartyId')))}
                            {suggestions.preferred_work_title_equal.map((work) => renderSuggestionRow(work, t('workReview.hasSamePreferredWorkTitle')))}
                            {suggestions.preferred_work_title_similar.map((work) => renderSuggestionRow(work, t('workReview.hasSimilarPreferredWorkTitle')))}
                            {suggestions.alternative_titles.map((work) => renderSuggestionRow(work, t('workReview.hasSameAlternativeTitle')))}
                            {suggestions.series.map((work) => renderSuggestionRow(work, t('workReview.hasSameSerieTitle')))}
                            {newWorkSuggestion?.preferred_title && renderSuggestionRow(newWorkSuggestion, t('workReview.matchManuallySelected'))}
                        </List>
                    )}
                    <div className="work-form__inner__suggestions__bottom">
                        <div className="work-form__inner__suggestions__bottom__buttons">
                            <Button
                                color="success"
                                variant="contained"
                                disableElevation
                                disabled={!selectedWork}
                                onClick={onApproveSelectedWork}
                            >
                                {t('workReview.approveSelected')}
                            </Button>
                            <Button
                                color="info"
                                variant="contained"
                                disableElevation
                                className="btn-find-match"
                                onClick={() => setFindOtherMatches(true)}
                            >
                                {t('workReview.findOtherMatches')}
                            </Button>
                        </div>
                        <p>{t('workReview.replaceWorkWithMatchInformation')}</p>
                    </div>
                </div>
            </Collapse>
            <div className="work-form__inner__section without-margin">
                <h3>{t('work.form.identifiers')}</h3>
                {metadataChanges.thirdPartyWorkIds.length > 0
                    ? (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <WorkFormIdentifiers
                                    thirdPartyNames={metadataChanges.thirdPartyWorkIds.map((changeItem) => ({
                                        fiaf_work_id: -1,
                                        id: getRandomInt(-10000, 0),
                                        id_value: changeItem?.valueRef?.id_value || '',
                                        third_party_name: changeItem?.valueRef?.third_party_name || '',
                                    }))}
                                />
                            </Grid>
                        </Grid>
                    ) : <p>{t('workReview.noDataImportedIntoThisSection')}</p>}
            </div>
            <div className="work-form__inner__section">
                <h3>{t('work.form.titles')}</h3>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className="text-data-wrapper">
                            <InputLabel>{t('work.form.preferredTitle')}</InputLabel>
                            <div>
                                <p>{metadataChanges.preferred_work_title.valueCompare}</p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <WorkFormTitlesTable
                            titles={metadataChanges.titles.map((changeItem) => ({
                                id: getRandomInt(-10000, 0),
                                title_type: changeItem?.valueRef?.title_type || '',
                                value: changeItem?.valueRef?.value || '',
                            }))}
                        />
                    </Grid>
                    {metadataChanges.series.length > 0 && (
                        <Grid item xs={6}>
                            <div className="work-form__inner__section__chips-wrapper">
                                <InputLabel>{t('work.form.series')}</InputLabel>
                                <ChipsInput
                                    onAdd={() => {}}
                                    onRemove={() => {}}
                                    hideInput
                                    values={metadataChanges.series.map((changeItem) => ({
                                        value: changeItem?.valueRef || '',
                                        removeDisabled: true,
                                    }))}
                                />
                            </div>
                        </Grid>
                    )}
                </Grid>
            </div>
            <div className="work-form__inner__section">
                <h3>{t('work.form.production')}</h3>
                { renderProductionSection() ? (
                    <>
                        <Grid container spacing={2}>
                            {metadataChanges.sound_production.valueRef
                                && (
                                    <Grid item xs={6}>
                                        <div className="text-data-wrapper">
                                            <InputLabel>{t('work.form.sound')}</InputLabel>
                                            <div>
                                                <p>{metadataChanges.sound_production.valueRef}</p>
                                            </div>
                                        </div>
                                    </Grid>
                                )}
                            <Grid item xs={12}>
                                <WorkFormCountriesTable
                                    countries={metadataChanges.countries.map((changeItem) => ({
                                        id: getRandomInt(-10000, 0),
                                        country_ISO_code: changeItem?.valueRef?.country_ISO_code || '',
                                        name: changeItem?.valueRef?.name || '',
                                        country_probable: changeItem?.valueRef?.country_probable || false,
                                    }))}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <WorkFormDatesTable
                                    dates={metadataChanges.dates.map((changeItem) => ({
                                        id: getRandomInt(-10000, 0),
                                        date_accuracy: changeItem?.valueRef?.date_accuracy || false,
                                        date_value: changeItem?.valueRef?.date_value || 0,
                                        year_from: changeItem?.valueRef?.year_from || null,
                                        year_until: changeItem?.valueRef?.year_until || null,
                                        date_string: changeItem?.valueRef?.date_string || '',
                                        date_type: changeItem?.valueRef?.date_type || '',
                                    }))}
                                />
                            </Grid>
                            {metadataChanges.notes.valueRef
                                && (
                                    <Grid item xs={6}>
                                        <div className="text-data-wrapper">
                                            <InputLabel>{t('work.form.notes')}</InputLabel>
                                            <div>
                                                <p>{metadataChanges.notes.valueRef || ''}</p>
                                            </div>
                                        </div>
                                    </Grid>
                                )}
                            <Grid item xs={6}>
                                <div className="text-data-wrapper">
                                    <InputLabel>{t('work.form.description')}</InputLabel>
                                    <div>
                                        <p>{metadataChanges.content_description.valueRef || ''}</p>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </>
                )
                    : <p>{t('workReview.noDataImportedIntoThisSection')}</p>}
            </div>
            <div className="work-form__inner__section">
                <h3>{t('work.form.agents')}</h3>
                {metadataChanges.agents.length > 0
                    ? (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <WorkFormAgentsTable
                                    workAgents={metadataChanges.agents.map((changeItem) => ({
                                        id: getRandomInt(-100000, 0),
                                        name: '',
                                        agent_id: changeItem?.valueRef?.agent_id || 0,
                                        agent_role: changeItem?.valueRef?.agent_role || '',
                                        company: changeItem?.valueRef?.agent_company || false,
                                        first_name: changeItem?.valueRef?.agent_first_name || '',
                                        last_name: changeItem?.valueRef?.agent_last_name || '',
                                        extra_name_info: changeItem?.valueRef?.agent_extra_name_info || '',
                                        name_inverted: changeItem?.valueRef?.agent_name_inverted || false,
                                        alternate_names: [],
                                    }))}
                                />
                            </Grid>
                        </Grid>
                    ) : <p>{t('workReview.noDataImportedIntoThisSection')}</p>}
            </div>
            {workArchives.length > 0 && (
                <div className="work-form__inner__section">
                    <h3>{t('work.form.items')}</h3>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <WorkFormItems
                                archiveWorks={workArchives}
                                importId={review.import_id}
                            />
                        </Grid>
                    </Grid>
                </div>
            )}
            {findOtherMatches && suggestions && <WorkMatchesDialog onNewSuggestion={onUpdateWithNewSuggestion} onClose={onCloseModal} />}
        </div>
    );
};

export default withTranslationContext(withReviewsContext(ReviewNewWorkData));
