/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';

import { Permission, PermissionOperator } from '../../types/authorization';
import { AuthorizationContext, withAuthorizationContext } from '../controllers/authorization/AuthorizationContext';

interface OwnProps extends AuthorizationContext {
    actions?: Array<Permission>;
    operator?: PermissionOperator;
    can?: boolean;
    yes?: () => React.ReactElement | null;
    no?: () => React.ReactElement | null;
}

type Props = OwnProps;

export const yesDefault = (): null => null;
export const noDefault = (): null => null;

const Can: React.FunctionComponent<Props> = (props) => {
    const {
        can,
        actions,
        operator,
        no = noDefault,
        checkPermission,
        yes = yesDefault,
    } = props;

    let isYes;
    if (actions && actions.length) {
        isYes = (checkPermission(actions, operator) && can !== false);
    } else {
        isYes = (can === true);
    }

    return isYes ? yes() : no();
};

export default withAuthorizationContext(Can);
