/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Button,
    Chip,
    IconButton,
    Tooltip,
} from '@mui/material';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

import { Work } from '../../../types/works';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { buildRoute } from '../../../utils/misc';
import { AppRoute } from '../../../constants/routes';
import Confirm from '../Confirm';
import { withWorksContext, WorksContext } from '../../controllers/works/WorksContext';
import IconCircleWork from '../../assets/IconCircleWork';
import iconSound from '../../../assets/image/icon-sound.svg';
import ItemFormDialog from '../item/ItemFormDialog';
import { ApiError } from '../../../types/errors';
import { Permission } from '../../../types/authorization';
import Can from '../../containers/Can';

interface OwnProps extends TranslationContext, WorksContext {
    work: Work;
    onRefresh(): void;
}

type Props = OwnProps;

const WorkScreenTop: FunctionComponent<Props> = (props: Props) => {
    const {
        t, work, deleteWork, onRefresh,
    } = props;
    
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [showCreateItem, setShowCreateItem] = useState<boolean>(false);

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const handleDelete = () => {
        setShowConfirm(false);
        deleteWork(
            work.fiaf_work_id,
            () => {
                enqueueSnackbar(t('work.delete.successfullyDeleted'), { variant: 'success' });
                navigate(AppRoute.Index);
            },
            onFailure,
        );
    };

    const handleEdit = () => {
        navigate(buildRoute(AppRoute.WorkEdit, { id: work.fiaf_work_id }));
    };

    const onFailure = (error?: ApiError) => {
        setShowConfirm(false);
        if (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
        } else {
            enqueueSnackbar(t('general.requestGeneralError'), { variant: 'error' });
        }
    };

    return (
        <div className="work-screen-top">
            {showCreateItem && (
                <ItemFormDialog
                    workId={work.fiaf_work_id}
                    onClose={() => {
                        onRefresh();
                        setShowCreateItem(false);
                    }}
                />
            )}
            <Confirm
                show={showConfirm}
                title={t('work.delete.confirm.title')}
                message={[t('work.delete.confirm.message1'), t('work.delete.confirm.message2')]}
                okButton={t('work.delete.confirm.okButton')}
                onClose={() => setShowConfirm(false)}
                onConfirm={handleDelete}
            >
                <div className="delete-confirm">
                    <IconCircleWork />
                    <p>{work.preferred_title}</p>
                </div>
            </Confirm>
            <div className="work-screen-top__img-container">
                <IconCircleWork />
            </div>
            <div className="work-screen-top__info">
                <div className="work-screen-top__info__tags">
                    <Chip label={work.fiaf_work_id} size="small" />
                </div>
                <h1>{work.preferred_title}</h1>
                {work.sound_production && <p><img src={iconSound} alt={t('work.top.audioSound')} /> {work.sound_production}</p>}
            </div>
            <div className="work-screen-top__actions">
                <Can
                    actions={[Permission.CREATE_ITEM]}
                    yes={() => (
                        <Button
                            color="primary"
                            variant="contained"
                            data-testid="create-item-button"
                            disableElevation
                            onClick={() => setShowCreateItem(true)}
                        >
                            {t('work.top.createItem')}
                        </Button>
                    )}
                />
                <Can
                    actions={[Permission.UPDATE_WORK]}
                    yes={() => (
                        <Tooltip title={t('work.top.editTooltip')}>
                            <IconButton
                                className="square-button"
                                data-testid="work-screen-top-edit-button"
                                onClick={handleEdit}
                            >
                                <EditOutlined />
                            </IconButton>
                        </Tooltip>
                    )}
                />
                <Can
                    actions={[Permission.DELETE_WORK]}
                    yes={() => (
                        <Tooltip title={t('work.top.deleteTooltip')}>
                            <IconButton
                                className="square-button"
                                data-testid="work-screen-top-delete-button"
                                onClick={() => setShowConfirm(true)}
                            >
                                <DeleteOutline />
                            </IconButton>
                        </Tooltip>
                    )}
                />
            </div>
        </div>
    );
};

export default withTranslationContext(withWorksContext(WorkScreenTop));
