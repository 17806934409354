/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';

const IconCircleCV: FunctionComponent = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
            <g transform="translate(6941 -16951)">
                <rect width="60" height="60" rx="30" transform="translate(-6941 16951)" fill="#cfcfcf" className="icon-bg" />
                <g fill="#707070" className="icon-icon">
                    <path d="M-6902.429 16972.429V16969H-6923v8.571h7.714V16993H-6899v-20.571Zm-18.857 3.428v-5.143h17.143v1.715h-11.143v3.428Zm20.572 15.429h-12.857v-17.143h12.857Z" />
                    <path d="M-6911.767 16976.777h1.533v1.533h-1.533Z" />
                    <path d="M-6907.434 16976.777h5.367v1.533h-5.367Z" />
                    <path d="M-6911.767 16981.961h1.533v1.533h-1.533Z" />
                    <path d="M-6907.434 16981.961h5.367v1.533h-5.367Z" />
                    <path d="M-6911.767 16987.146h1.533v1.533h-1.533Z" />
                    <path d="M-6907.434 16987.146h5.367v1.533h-5.367Z" />
                </g>
            </g>
        </svg>
    );
};

export default IconCircleCV;
