/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import axios, { AxiosError } from 'axios';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    itemUrl,
} from '../../../services/items';
import { ItemsContextProvider } from './ItemsContext';
import { Item, ItemPayload } from '../../../types/items';
import { GenericFunction } from '../../../types/misc';

interface OwnProps {
    children: React.ReactNode;
}

type Props = OwnProps;

export class ItemsController extends Component<Props> {
    getItem = async (id: number): Promise<Item | null> => {
        try {
            const { data: responseData } = await axios.get(itemUrl(id));
            return responseData.data;
        } catch {
            return null;
        }
    };

    deleteItem = async (id: number, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void> => {
        try {
            await axios.delete(itemUrl(id));
            onSuccess();
        } catch (error) {
            const err = error as AxiosError;
            onFailure(err.response?.data);
        }
    };

    updateItem = async (id: number, payload: ItemPayload, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<Item | null> => {
        try {
            const { data: responseData } = await axios.put(itemUrl(id), payload);
            onSuccess();
            return responseData.data;
        } catch (error) {
            const err = error as AxiosError;
            onFailure(err.response?.data);
            return null;
        }
    };

    createItem = async (payload: ItemPayload, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<Item | null> => {
        try {
            const { data: responseData } = await axios.post(itemUrl(), payload);
            onSuccess();
            return responseData.data;
        } catch (error) {
            const err = error as AxiosError;
            onFailure(err.response?.data);
            return null;
        }
    };

    render(): React.ReactNode {
        const { children } = this.props;
        return (
            <ItemsContextProvider value={{
                getItem: this.getItem,
                deleteItem: this.deleteItem,
                updateItem: this.updateItem,
                createItem: this.createItem,
            }}
            >
                {children}
            </ItemsContextProvider>
        );
    }
}

export const ConnectedItemsController = connect()(ItemsController);
