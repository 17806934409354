/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { AppLanguage } from '../types/preferences';
import { KeyedObject } from '../types/general';

/**
 * get the right translation string
 */
export const getString = (data: KeyedObject, key: string): string => {
    const keyParts = key.split('.');
    let currentPosition = data;

    for (let i = 0; i < keyParts.length; i++) {
        if (currentPosition) {
            currentPosition = currentPosition[keyParts[i]];
        }
    }

    if (currentPosition) return String(currentPosition);
    return key;
};

/**
 * interpolates a string from a translation
 */
const interpolateString = (text: string, params?: Record<string, unknown>): string => {
    if (!params) {
        return text;
    }

    const interpolatedStringChild = text.split(/({[^}]+})/g).map((child) => {
        const match = /{(.+)}/g.exec(child);
        if (match) {
            return String(params[match[1]]);
        }
        return child;
    });

    return interpolatedStringChild.some((child) => child && typeof child === 'object')
        ? text
        : interpolatedStringChild.join('');
};

/**
 * gets the right translation
 */
export const getTranslation = (translations: KeyedObject, language: AppLanguage, key: string, params?: KeyedObject): string => {
    let out = key;

    if (translations) {
        const languageStrings = translations[language];

        if (languageStrings) {
            const keyString = getString(languageStrings, key);

            if (keyString) {
                out = interpolateString(keyString, params);
            }
        }
    }

    return out;
};
