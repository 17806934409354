/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';

import { ConnectedAgentsController } from './AgentsController';
import { KeyedObject } from '../../../types/general';
import { GenericFunction, ListResponse } from '../../../types/misc';
import { Agent, AgentPayload } from '../../../types/agents';

export interface AgentsContext {
    getAgents(filters?: KeyedObject): Promise<ListResponse<Agent> | null>;
    getAgent(id: number): Promise<Agent | null>;
    createAgent(payload: AgentPayload, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void>;
    editAgent(agentId: number, payload: AgentPayload, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void>;
    deleteAgent(agentId: number, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void>;
}

export const agentsContextDefaultValue = {
    getAgents: async (): Promise<ListResponse<Agent> | null> => null,
    getAgent: async (): Promise<Agent | null> => null,
    createAgent: async (): Promise<void> => {},
    editAgent: async (): Promise<void> => {},
    deleteAgent: async (): Promise<void> => {},
};

const AgentsContextInstance = createContext<AgentsContext | null>(agentsContextDefaultValue);

export const AgentsContextProvider = AgentsContextInstance.Provider;
export const AgentsContextConsumer = AgentsContextInstance.Consumer;

export const withAgentsContext = <P extends unknown>(Component: ComponentType<P>): FC<Omit<P, keyof AgentsContext>> => (props) => (
    <ConnectedAgentsController>
        <AgentsContextConsumer>
            {(ctx) => <Component {...props as P} {...ctx} />}
        </AgentsContextConsumer>
    </ConnectedAgentsController>
);
