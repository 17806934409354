/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, {
    FunctionComponent,
    useCallback,
    useEffect,
    useState,
} from 'react';
import {
    Autocomplete,
    AutocompleteRenderInputParams,
    Box,
    IconButton,
    MenuItem,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import {
    AddRounded,
    CheckRounded,
    Close,
    CloseRounded,
} from '@mui/icons-material';
import { debounce } from 'lodash';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import {
    ControlledVocabulariesContext,
    withControlledVocabulariesContext,
} from '../../controllers/controlled_vocabularies/ControlledVocabulariesContext';
import { AgentRoleCV } from '../../../types/controlled_vocabularies';
import { ControlledVocabularyName } from '../../../services/controlled_vocabularies';
import { WorkAgent, WorkFormAgentFields } from '../../../types/works';
import { AgentsContext, withAgentsContext } from '../../controllers/agents/AgentsContext';
import { Agent } from '../../../types/agents';
import Loader from '../Loader';
import { getRandomInt } from '../../../utils/misc';
import { getAgentName } from '../../../utils/agents';

interface OwnProps extends TranslationContext, ControlledVocabulariesContext, AgentsContext {
    workAgents: Array<WorkAgent>;
    onAdd?(workAgent: WorkAgent): void;
    onRemove?(workAgentId: number): void;
    agentIdSelected?(id: number): void;
}

enum FieldsName {
    AgentRole = 'agent_role',
}

const newAgentInitialValues: WorkFormAgentFields = {
    agent: null,
    agent_role: '',
};

const WorkFormAgentsTable: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t, workAgents, getCV, getAgents, onAdd, onRemove, agentIdSelected,
    } = props;

    const throttledFetchAgents = useCallback(debounce((search: string) => fetchAgents(search), 500), []);

    const [isFetching, setIsFetching] = useState(true);
    const [rolesOptions, setRolesOptions] = useState<Array<AgentRoleCV>>([]);
    const [search, setSearch] = useState('');
    const [agentsOptions, setAgentsOptions] = useState<Array<Agent>>([]);
    const [newAgent, setNewAgent] = useState<WorkFormAgentFields | null>(null);

    useEffect(() => {
        fetchSelectOptions();
    }, []);

    useEffect(() => {
        if (workAgents.length === 0) {
            setNewAgent(newAgentInitialValues);
        } else {
            setNewAgent(null);
        }
    }, [workAgents]);

    const fetchSelectOptions = async () => {
        Promise.all([
            getCV<AgentRoleCV>(ControlledVocabularyName.AgentRoles),
            getAgents(),
        ]).then((results) => {
            if (results[0]) setRolesOptions(results[0].data);
            if (results[1]) setAgentsOptions(results[1].data);
        }).finally(() => setIsFetching(false));
    };

    const onAddNewAgent = () => {
        setNewAgent(newAgentInitialValues);
    };

    const onAccept = () => {
        if (newAgent && newAgent.agent) {
            onAdd?.({
                id: getRandomInt(-100000, 0),
                agent_id: newAgent.agent.id,
                agent_role: newAgent.agent_role,
                name: newAgent.agent.name,
                first_name: newAgent.agent.first_name,
                last_name: newAgent.agent.last_name,
                extra_name_info: newAgent.agent.extra_name_info,
                name_inverted: newAgent.agent.name_inverted,
                company: newAgent.agent.company,
                alternate_names: newAgent.agent.alternate_names,
            });

            setNewAgent(null);
        }
    };

    const onSearchChange = (searchValue: string, reason: string) => {
        setSearch(searchValue);

        if (reason === 'input') throttledFetchAgents(searchValue);
    };

    const onRoleChange = (event: SelectChangeEvent) => {
        const {
            target: { value },
        } = event;

        if (newAgent) {
            setNewAgent({
                ...newAgent,
                agent_role: value,
            });
        }
    };

    const fetchAgents = async (searchValue: string) => {
        const agentsResult = await getAgents({ search: searchValue });
        if (agentsResult) setAgentsOptions(agentsResult.data);
    };

    const onSelectAgent = (id: number) => {
        if (agentIdSelected) agentIdSelected(id);
    };

    if (isFetching) {
        return <Loader />;
    }

    if (workAgents.length === 0 && !onAdd) {
        return null;
    }

    return (
        <React.Fragment>
            <Table size="small" data-testid="new-agent-table">
                <TableHead>
                    <TableRow>
                        <TableCell>{t('work.form.name')}</TableCell>
                        <TableCell>{t('work.form.roles')}</TableCell>
                        {onAdd && (
                            <TableCell className="add-btn-cell">
                                <IconButton data-testid="add-new-agent-btn" className="add-small-btn" onClick={onAddNewAgent}>
                                    <AddRounded />
                                </IconButton>
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {workAgents.map((agent) => (
                        <TableRow key={agent.id}>
                            <TableCell
                                className={agentIdSelected ? 'clickable' : ''}
                                data-testid="existing-agent-name"
                                onClick={agentIdSelected ? () => onSelectAgent(agent.agent_id) : undefined}
                            >
                                {getAgentName(agent)}
                            </TableCell>
                            <TableCell data-testid="existing-agent-role">{agent.agent_role}</TableCell>
                            {onRemove && (
                                <TableCell align="right">
                                    <IconButton
                                        size="small"
                                        data-testid="new-agent-delete-button"
                                        onClick={() => onRemove(agent.id)}
                                        className="action-btn"
                                    >
                                        <CloseRounded />
                                    </IconButton>
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                    {newAgent && (
                        <TableRow>
                            <TableCell>
                                <div className="autocomplete-input">
                                    <Autocomplete
                                        data-testid="new-agent-search"
                                        getOptionLabel={(option) => { return option ? getAgentName(option) : ''; }}
                                        value={newAgent.agent}
                                        fullWidth
                                        clearIcon={<Close />}
                                        inputValue={search}
                                        options={agentsOptions}
                                        filterOptions={(options) => options}
                                        onChange={(event, newValue) => setNewAgent({ ...newAgent, agent: newValue })}
                                        onInputChange={(e, newValue, reason) => onSearchChange(newValue, reason)}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        renderOption={(prop, option) => (
                                            <Box data-testid="new-agent-search-item" component="li" {...prop} key={option.id}>
                                                {getAgentName(option)}
                                            </Box>
                                        )}
                                        renderInput={(params: AutocompleteRenderInputParams) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    disableUnderline: true,
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                            </TableCell>
                            <TableCell>
                                <Select
                                    fullWidth
                                    MenuProps={{ classes: { paper: 'select' } }}
                                    data-testid="new-agent-role"
                                    className="simple-select dense-select"
                                    name={FieldsName.AgentRole}
                                    value={newAgent[FieldsName.AgentRole]}
                                    onChange={onRoleChange}
                                    displayEmpty
                                    renderValue={
                                                newAgent[FieldsName.AgentRole] !== '' ? undefined : () => <div className="placeholder">{t('general.select')}</div>
                                            }
                                >
                                    {rolesOptions.map((value) => (
                                        <MenuItem data-testid="new-agent-role-item" dense key={value.name} value={value.name}>{value.name}</MenuItem>
                                    ))}
                                </Select>
                            </TableCell>
                            <TableCell align="right">
                                <IconButton
                                    size="small"
                                    data-testid="new-agent-confirm-button"
                                    onClick={onAccept}
                                    className="action-btn"
                                    disabled={!newAgent.agent}
                                >
                                    <CheckRounded />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </React.Fragment>
    );
};

WorkFormAgentsTable.defaultProps = {
    onAdd: undefined,
    onRemove: undefined,
    agentIdSelected: undefined,
};

export default withTranslationContext(withControlledVocabulariesContext(withAgentsContext(WorkFormAgentsTable)));
