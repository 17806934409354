/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { DateType } from '../types/dates';
import {
    Work,
    WorkAgent,
    WorkDate,
    WorkTitle,
    WorkTitleType,
} from '../types/works';
import { AgentType } from '../types/agents';

export const getWorkReleaseDate = (work: Work): WorkDate | undefined => {
    return work.dates.find((d) => d.date_type === DateType.Release);
};

export const getWorkAgent = (work: Work, type?: AgentType): Array<WorkAgent> => {
    if (type) {
        return work.agents ? work.agents.filter((agent) => agent.agent_role === type) : [];
    }
    return work.agents ? work.agents.filter((agent) => {
        let notFixedAgent = true;
        Object.values(AgentType).forEach((wat) => {
            if (agent.agent_role !== AgentType.Credit && agent.agent_role === wat) {
                notFixedAgent = false;
            }
        });
        return notFixedAgent;
    }) : [];
};

export const getWorkAlternativeTitles = (work: Work): Array<WorkTitle> => {
    return work.titles.filter((w) => w.title_type === WorkTitleType.Alternative);
};
