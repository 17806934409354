/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import axios, { AxiosError } from 'axios';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    workUrl,
    worksUrl,
    workSuggestionsUrl,
} from '../../../services/works';
import { KeyedObject } from '../../../types/general';
import { GenericFunction } from '../../../types/misc';
import {
    Work,
    WorkApiResponse,
    WorkPayload,
    WorkSuggestionApiResponse,
} from '../../../types/works';
import { WorksContextProvider } from './WorksContext';

interface OwnProps {
    children: React.ReactNode;
}

type Props = OwnProps;

export class WorksController extends Component<Props> {
    getWork = async (id: number): Promise<Work | null> => {
        try {
            const { data: responseData } = await axios.get(workUrl(id));
            return responseData.data;
        } catch {
            return null;
        }
    };

    deleteWork = async (id: number, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void> => {
        try {
            await axios.delete(workUrl(id));
            onSuccess();
        } catch (error) {
            const err = error as AxiosError;
            onFailure(err.response?.data);
        }
    };

    getWorks = async (filters?: KeyedObject): Promise<WorkApiResponse | null> => {
        try {
            const { data } = await axios.get(worksUrl(filters));
            return data;
        } catch {
            return null;
        }
    };

    getWorksSuggestions = async (filters?: KeyedObject): Promise<WorkSuggestionApiResponse | null> => {
        try {
            const { data } = await axios.get(workSuggestionsUrl(filters));
            return data;
        } catch {
            return null;
        }
    };

    createWork = async (payload: WorkPayload, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void> => {
        try {
            const { data } = await axios.post(worksUrl(), payload);

            onSuccess(data.data);
        } catch (error) {
            const err = error as AxiosError;
            onFailure(err.response?.data);
        }
    };

    updateWork = async (id: number, payload: WorkPayload, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void> => {
        try {
            await axios.put(workUrl(id), payload);
            onSuccess();
        } catch (error) {
            const err = error as AxiosError;
            onFailure(err.response?.data);
        }
    };

    getWorksFile = async (filters?: KeyedObject): Promise< {file: Blob, fileName: string} | null> => {
        try {
            const { data, headers } = await axios.get(worksUrl(filters), {
                responseType: 'blob',
            });
            const fileName = headers['content-disposition'].split('filename=')[1];

            return { file: data, fileName };
        } catch {
            return null;
        }
    };

    render(): React.ReactNode {
        const { children } = this.props;
        return (
            <WorksContextProvider value={{
                getWork: this.getWork,
                deleteWork: this.deleteWork,
                getWorks: this.getWorks,
                getWorksSuggestions: this.getWorksSuggestions,
                createWork: this.createWork,
                updateWork: this.updateWork,
                getWorksFile: this.getWorksFile,
            }}
            >
                {children}
            </WorksContextProvider>
        );
    }
}

export const ConnectedWorksController = connect()(WorksController);
