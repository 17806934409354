/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';

export const itemUrl = (id?: number): string => {
    if (id) return `${API_URL}/items/${id}`;
    return `${API_URL}/items`;
};
