/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useState } from 'react';
import { AddRounded, CheckRounded, CloseRounded } from '@mui/icons-material';
import {
    IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField,
} from '@mui/material';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { CountryCV } from '../../../types/controlled_vocabularies';

interface OwnProps extends TranslationContext {
    countries: Array<CountryCV>;
    onAdd(country: CountryCV): void;
    onRemove(countryIsoCode: string): void;
    viewOnly?: boolean;
}

enum FieldName {
    Name = 'name',
    FiafCode = 'FIAF_code',
    IsoCode = 'ISO_code',
}

const CVFormCountriesTable: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t, onAdd, countries, onRemove, viewOnly,
    } = props;

    const [newCvCountry, setNewCvCountry] = useState<CountryCV | null>(null);

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {
            target: { value, name },
        } = event;

        if (newCvCountry) {
            setNewCvCountry({
                ...newCvCountry,
                [name]: value,
            });
        }
    };

    const onAddNewCountry = () => {
        setNewCvCountry({
            name: '',
            FIAF_code: '',
            FIAF_name: '',
            ISO_code: '',
            deleted_country: false,
        });
    };

    const onAccept = () => {
        if (newCvCountry) onAdd(newCvCountry);
        setNewCvCountry(null);
    };

    return (
        <Table size="small" data-testid="cv-countries-table">
            <TableHead>
                <TableRow>
                    <TableCell>{t('controlledVocabularyForm.locations.country')}</TableCell>
                    <TableCell>{t('controlledVocabularyForm.locations.fiafCode')}</TableCell>
                    <TableCell>{t('controlledVocabularyForm.locations.isoCode')}</TableCell>
                    {!viewOnly && (
                        <TableCell className="add-btn-cell">
                            <IconButton data-testid="add-cv-country-btn" className="add-small-btn" onClick={onAddNewCountry}>
                                <AddRounded />
                            </IconButton>
                        </TableCell>
                    )}
                </TableRow>
            </TableHead>
            <TableBody>
                {countries.map((country) => (
                    <TableRow key={country.ISO_code}>
                        <TableCell>{country.name}</TableCell>
                        <TableCell>{country.FIAF_code}</TableCell>
                        <TableCell>{country.ISO_code}</TableCell>
                        {!viewOnly && (
                            <TableCell align="right">
                                <IconButton
                                    size="small"
                                    data-testid="cv-countries-delete-btn"
                                    onClick={() => onRemove(country.ISO_code)}
                                    className="action-btn"
                                >
                                    <CloseRounded />
                                </IconButton>
                            </TableCell>
                        )}
                    </TableRow>
                ))}
                {newCvCountry && (
                    <TableRow>
                        <TableCell>
                            <TextField
                                fullWidth
                                inputProps={{
                                    'data-testid': 'cv-country-name-input',
                                    placeholder: t('controlledVocabularyForm.locations.countryName'),
                                }}
                                name={FieldName.Name}
                                value={newCvCountry[FieldName.Name]}
                                onChange={onInputChange}
                                variant="standard"
                            />
                        </TableCell>
                        <TableCell>
                            <TextField
                                fullWidth
                                variant="standard"
                                inputProps={{
                                    'data-testid': 'cv-country-fiaf-code-input',
                                    placeholder: t('controlledVocabularyForm.locations.fiafCode'),
                                }}
                                name={FieldName.FiafCode}
                                value={newCvCountry[FieldName.FiafCode]}
                                onChange={onInputChange}
                            />
                        </TableCell>
                        <TableCell>
                            <TextField
                                fullWidth
                                variant="standard"
                                inputProps={{
                                    'data-testid': 'cv-country-iso-code-input',
                                    placeholder: t('controlledVocabularyForm.locations.isoCode'),
                                }}
                                name={FieldName.IsoCode}
                                value={newCvCountry[FieldName.IsoCode]}
                                onChange={onInputChange}
                            />
                        </TableCell>
                        <TableCell align="right">
                            <IconButton
                                size="small"
                                data-testid="cv-country-confirm-btn"
                                onClick={onAccept}
                                className="action-btn"
                                disabled={!newCvCountry.name || !newCvCountry.ISO_code}
                            >
                                <CheckRounded />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
};

CVFormCountriesTable.defaultProps = {
    viewOnly: false,
};

export default withTranslationContext(CVFormCountriesTable);
