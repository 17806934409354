/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, ReactNode } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

interface OwnProps {
    children: ReactNode;
}

type Props = OwnProps;

const themePalette = {
    Primary: '#e30e20',
    Secondary: '#4b4b4b',
    Success: '#72db9a',
};

export const screenTitleAnimateClass = 'animate__animated animate__fadeIn animate__fast';
export const screenContentAnimateClass = 'animate__animated animate__fadeIn animate__fast';

const Themed: FunctionComponent<Props> = (props) => {
    const {
        children,
    } = props;

    const muiTheme = createTheme({
        typography: {
            fontFamily: [
                'BrownStd-Regular',
                'Roboto',
                'Arial',
                'sans-serif',
            ].join(','),
        },
        components: {
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        position: 'relative',
                        transform: 'none',
                        marginBottom: '6px',
                        color: '#707070',
                        fontSize: '12px',
                    },
                },
            },
            MuiInput: {
                styleOverrides: {
                    root: {
                        fontSize: '14px',
                        backgroundColor: '#ffffff',
                        borderRadius: '8px',
                        '&.Mui-disabled': {
                            backgroundColor: '#F1F2F2',
                            border: '1px solid #DBDBDB',
                        },
                    },
                    underline: {
                        '&:before': {
                            borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
                        },
                        '&:after': {
                            display: 'none',
                        },
                        '&:hover:not(.Mui-disabled):before': {
                            borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        fontSize: '14px',
                        backgroundColor: '#ffffff',
                        borderRadius: '8px',
                        border: 'none',
                        '&.Mui-disabled': {
                            backgroundColor: '#F1F2F2',
                            border: '1px solid #DBDBDB',
                        },
                    },
                    input: {
                        padding: '10px 14px',
                    },
                    notchedOutline: {
                        border: 'none',
                    },
                },
            },
            MuiAccordion: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'transparent',
                        borderBottom: '1px solid rgba(35, 31, 32, .25)',
                        boxShadow: 'none',
                        '&.MuiAccordion-root:before': {
                            opacity: 0,
                        },
                    },
                },
            },
            MuiAccordionSummary: {
                styleOverrides: {
                    root: {
                        padding: '0',
                        minHeight: 'auto',
                        height: '40px',
                    },
                },
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        fontSize: '12px',
                    },
                },
            },
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        backgroundColor: '#231F20',
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                        borderRadius: '8px',
                        '&.Mui-disabled': {
                            backgroundColor: '#CBCBCB',
                        },
                    },
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        fontSize: '14px',
                        color: '#707070',
                    },
                },
            },
            MuiChip: {
                styleOverrides: {
                    root: {
                        '&.MuiChip-colorSuccess': {
                            '.MuiChip-label': {
                                color: 'white',
                            },
                        },
                    },
                },
            },
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 768,
                md: 1024,
                lg: 1280,
                xl: 1920,
            },
        },
        palette: {
            mode: 'light',
            primary: {
                main: themePalette.Primary,
            },
            secondary: {
                main: themePalette.Secondary,
            },
            success: {
                main: themePalette.Success,
            },
        },
    });

    return (
        <div className="theme-container">
            <ThemeProvider theme={muiTheme}>
                {children}
            </ThemeProvider>
        </div>
    );
};

export default Themed;
