/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useEffect, useState } from 'react';
import {
    Button,
    Dialog, IconButton, Pagination, TextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { Close } from '@mui/icons-material';

import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { ListResponseMeta, ListSearchParams } from '../../../types/misc';
import Loader from '../Loader';
import { AgentsContext, withAgentsContext } from '../../controllers/agents/AgentsContext';
import { Agent } from '../../../types/agents';
import IconCircleAgent from '../../assets/IconCircleAgent';
import AgentFormDialog from './AgentFormDialog';
import { getAgentNameForAgentScreen } from '../../../utils/agents';

interface OwnProps extends TranslationContext, AgentsContext {
    onFindMatch(data: Agent): void;
    onClose(): void;
}

type Props = OwnProps;

const AgentMatchesDialog: FunctionComponent<Props> = (props: Props) => {
    const {
        t, getAgents, onClose, onFindMatch,
    } = props;

    const agentsSearchDefaultParams: ListSearchParams = {
        search: '',
        page: 1,
    };

    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [searchParams, setSearchParams] = useState<ListSearchParams>(agentsSearchDefaultParams);
    const [agentsList, setAgentsList] = useState<Array<Agent>>([]);
    const [paginationParams, setPaginationParams] = useState<ListResponseMeta | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [agentIDSelected, setAgentIDSelected] = useState<number | null>(null);
    const [isAgentDetailsOpen, setIsAgentDetailsOpen] = useState<boolean>(false);

    const onSearch = async () => {
        const params = (searchParams.search === '') ? agentsSearchDefaultParams : { ...searchParams, page: 1 };
        const search = (searchParams.search === '') ? undefined : searchParams;

        setSearchParams(params);
        const agentsData = await getAgents(search);

        if (agentsData) {
            setAgentsList(agentsData.data);
            setPaginationParams(agentsData.meta);
            setCurrentPage(1);
        }
    };

    const fetchOnPageChange = async () => {
        const agentsData = await getAgents({ ...searchParams, page: currentPage });
        if (agentsData) {
            setAgentsList(agentsData.data);
            setPaginationParams(agentsData.meta);
        }
        setIsFetching(false);
    };

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target: { value } } = event;
        setSearchParams({ search: value });
    };

    const onPagination = async (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
    };

    const onSelectClick = (data: Agent) => {
        if (data) {
            onFindMatch(data);
            onClose();
        }
    };

    const onAgentClick = (n: number) => {
        setAgentIDSelected(n);
        setIsAgentDetailsOpen(true);
    };

    const onCloseAgentDetails = () => {
        setIsAgentDetailsOpen(false);
        setIsFetching(true);
        fetchOnPageChange();
    };

    useEffect(() => {
        fetchOnPageChange();
    }, [currentPage]);
    
    useEffect(() => {
        const prepare = async () => {
            const agentsData = await getAgents(agentsSearchDefaultParams);
            if (agentsData) {
                setAgentsList(agentsData.data);
                setPaginationParams(agentsData.meta);
            }
            setIsFetching(false);
        };
        prepare();
    }, []);

    if (isFetching) {
        return <Loader />;
    }

    if (isAgentDetailsOpen && agentIDSelected) {
        return <AgentFormDialog onClose={onCloseAgentDetails} agentId={agentIDSelected} />;
    }

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open
            className="matches-dialog"
            data-testid="work-match-dialog"
        >
            <div className="matches-dialog__top">
                <div className="matches-dialog__top__left">
                    <div>
                        <h2>{t('workReview.findMatchingAgent')}</h2>
                    </div>
                </div>
                <IconButton
                    data-testid="agent-match-close-button"
                    aria-label="close"
                    onClick={onClose}
                >
                    <Close color="primary" />
                </IconButton>
            </div>
            <div className="matches-dialog__search-area">
                <div className="matches-dialog__search-area__search-bar-wrapper">
                    <div className="matches-dialog__search-area__search-bar-wrapper__search-bar">
                        <TextField
                            fullWidth
                            data-testid="agent-match-search-bar"
                            variant="standard"
                            placeholder={t('workReview.agentSearchPlaceholder')}
                            value={searchParams.search}
                            onChange={onInputChange}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    onSearch();
                                }
                            }}
                            InputProps={{
                                startAdornment: <SearchIcon className="matches-dialog__search-area__search-bar-wrapper__search-icon" />,
                                disableUnderline: true,
                                endAdornment: (
                                    <>
                                        <IconButton onClick={() => setSearchParams({ search: '' })}>
                                            <CancelRoundedIcon />
                                        </IconButton>
                                        <IconButton
                                            className="matches-dialog__search-area__search-bar-wrapper__search-button"
                                            onClick={onSearch}
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </>
                                ),
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="matches-dialog__content">
                <div className="search-screen__works-container__works-list">
                    {agentsList?.map((agent) => {
                        return (
                            <div
                                data-testid="agent-card"
                                className="card"
                                key={agent.id}
                                onClick={() => onAgentClick(agent.id)}
                            >
                                <IconCircleAgent />
                                <div className="card__description">
                                    <div className="card__description__title">
                                        <h3 className="card__description-container__movie-title">{getAgentNameForAgentScreen(agent)}</h3>
                                    </div>
                                    <p className="card__description__information">
                                        {agent.agent_roles.map((ar) => ar.agent_role).join(', ')}
                                    </p>
                                </div>
                                <div className="card__select-button-wrapper">
                                    <Button data-testid="agent-card-select-button" onClick={() => onSelectClick(agent)}>{t('general.select')}</Button>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="matches-dialog__content__pagination">
                    {paginationParams && agentsList.length > 0 && (
                        <Pagination
                            count={paginationParams.last_page}
                            page={paginationParams.current_page}
                            onChange={onPagination}
                        />
                    )}
                </div>
            </div>
        </Dialog>
    );
};

export default withTranslationContext(withAgentsContext(AgentMatchesDialog));
