/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';

interface OwnProps extends TranslationContext {
    searchValue: string;
}

const ListNoDataMessage: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const { t, searchValue } = props;

    return (
        <p data-testid="no-results-message">
            { searchValue === '' ? (
                `${t('general.noResultsFound')} `
            ) : (
                <>
                    {t('general.noResultsFoundFor')}
                    <span className="txt-bold">{` "${searchValue}". `}</span>
                </>
            )}
            {`${t('general.pleaseTryDifferentKeywords')}`}
        </p>
    );
};

export default withTranslationContext(ListNoDataMessage);
