/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { ComponentType, createContext, FC } from 'react';

import { ConnectedItemsController } from './ItemsController';
import { Item, ItemPayload } from '../../../types/items';

export interface ItemsContext {
    getItem(id: number): Promise<Item | null>;
    deleteItem(id: number, onSuccess: () => void, onFailure: () => void): Promise<void>;
    updateItem(id: number, payload: ItemPayload, onSuccess: () => void, onFailure: () => void): Promise<Item | null>;
    createItem(payload: ItemPayload, onSuccess: () => void, onFailure: () => void): Promise<Item | null>;
}

export const itemsContextDefaultValue = {
    getItem: async (): Promise<Item | null> => null,
    deleteItem: async (): Promise<void> => {},
    updateItem: async (): Promise<Item | null> => null,
    createItem: async (): Promise<Item | null> => null,
};

const itemContextInstance = createContext<ItemsContext | null>(itemsContextDefaultValue);

export const ItemsContextProvider = itemContextInstance.Provider;
export const ItemsContextConsumer = itemContextInstance.Consumer;

export const withItemsContext = <P extends unknown>(Component: ComponentType<P>): FC<Omit<P, keyof ItemsContext>> => (props) => (
    <ConnectedItemsController>
        <ItemsContextConsumer>
            {(ctx) => <Component {...props as P} {...ctx} />}
        </ItemsContextConsumer>
    </ConnectedItemsController>
);
