/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, {
    FunctionComponent, useEffect, useState,
} from 'react';
import {
    Accordion, AccordionDetails, AccordionSummary, Container, Grid, IconButton, InputLabel, Tooltip,
} from '@mui/material';
import { ArrowDropDown, Close, EditOutlined } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import {
    ControlledVocabulariesContext,
    withControlledVocabulariesContext,
} from '../controllers/controlled_vocabularies/ControlledVocabulariesContext';
import IconCircleCV from '../assets/IconCircleCV';
import Loader from '../elements/Loader';
import {
    AgentRoleCV,
    AspectRatioCV,
    BroadcastStandardCV,
    CarrierCV,
    CodecCV,
    ControlledVocabulary,
    CountryCV,
    DateTypeCV,
    GeneralColorCharacteristicCV,
    GeneralMediaTypeCV,
    ItemBaseCV,
    ItemElementCV,
    LanguageCV,
    LanguageUsageCV,
    LengthMeasurementCV,
    SimpleCV,
    SoundProductionCV,
    SpecificColorTypeCV,
    SpecificMediaTypeCV,
    ThirdPartyNameCV,
    TitleTypeCV,
} from '../../types/controlled_vocabularies';
import { ControlledVocabularyName } from '../../services/controlled_vocabularies';
import ChipsInput from '../elements/ChipsInput';
import { WorkTitleType } from '../../types/works';
import { DateType } from '../../types/dates';
import { SoundProduction } from '../../types/sound';
import { AgentType } from '../../types/agents';
import CVFormCountriesTable from '../elements/controlledVocabulary/CVFormCountriesTable';
import CVFormLanguagesTable from '../elements/controlledVocabulary/CVFormLanguagesTable';
import { ApiError } from '../../types/errors';
import {
    ItemCarrierType, ItemElementType, ItemGeneralMediaType, ItemSpecificMediaType,
} from '../../types/items';
import Can from '../containers/Can';
import { Permission } from '../../types/authorization';

interface OwnProps extends TranslationContext, ControlledVocabulariesContext {}

enum CVSection {
    ThirdPartyIds = 'thirdPartyIds',
    Work = 'work',
    Date = 'date',
    Item = 'item',
    Agents = 'agents',
    Location = 'location',
    Languages = 'languages',
}

type SimpleCVInputs = Partial<Record<ControlledVocabularyName, ControlledVocabulary<SimpleCV>>>;

const ControlledVocabularyScreen: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t, getCV, createCVValue, deleteCVValue,
    } = props;

    const [isEditing, setIsEditing] = useState(false);
    const [isFetching, setIsFetching] = useState(true);
    const [simpleCVInputs, setSimpleCVInputs] = useState<SimpleCVInputs>({});
    const [countries, setCountries] = useState<Array<CountryCV>>([]);
    const [languages, setLanguages] = useState<Array<LanguageCV>>([]);
    const [expandedCVSection, setExpandedCVSection] = useState<Set<string>>(new Set<string>([]));

    useEffect(() => {
        prepare();
    }, []);

    const { enqueueSnackbar } = useSnackbar();

    const onExpanded = (section: CVSection) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        if (isExpanded) {
            setExpandedCVSection(new Set(expandedCVSection).add(section));
        } else {
            const newExpanded = new Set(expandedCVSection);
            newExpanded.delete(section);
            setExpandedCVSection(newExpanded);
        }
    };

    const onAddNewSimpleCVValue = (name: ControlledVocabularyName, value: string) => {
        if (simpleCVInputs[name]) {
            const simpleCvValue = simpleCVInputs[name];
            if (simpleCvValue) {
                setSimpleCVInputs((actualValues) => ({
                    ...actualValues,
                    [name]: { data: [...simpleCvValue.data, { name: value }] },
                }));
            }
        }

        requestCreateSimpleCV(name, value);
    };

    const onRemoveSimpleCVValue = (name: ControlledVocabularyName, value: string) => {
        requestDeleteCVValue(name, value);
    };

    const onRemoveSuccess = (name: ControlledVocabularyName, value: string) => {
        if (name === ControlledVocabularyName.Countries) {
            setCountries([...countries.filter((c) => c.ISO_code !== value)]);
            return;
        }

        if (name === ControlledVocabularyName.Languages) {
            setLanguages([...languages.filter((l) => l.code !== value)]);
            return;
        }

        if (simpleCVInputs[name]) {
            const simpleCvValue = simpleCVInputs[name];
            if (simpleCvValue) {
                setSimpleCVInputs((actualValues) => ({
                    ...actualValues,
                    [name]: { data: [...simpleCvValue.data.filter((n) => n.name !== value)] },
                }));
            }
        }
    };

    const onCreateSuccess = () => {
        enqueueSnackbar(t('controlledVocabularyForm.successfullyCreated'), { variant: 'success' });
    };

    const onDeleteSuccessMessage = () => {
        enqueueSnackbar(t('controlledVocabularyForm.successfullyDeleted'), { variant: 'success' });
    };

    const onRequestFailure = (error?: ApiError) => {
        if (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
        } else {
            enqueueSnackbar(t('general.requestGeneralError'), { variant: 'error' });
        }
    };

    const onAddNewCountry = (newCountry: CountryCV) => {
        createCVValue(ControlledVocabularyName.Countries, newCountry, onCreateSuccess, onRequestFailure);
    };

    const onAddNewLanguage = (newLanguage: LanguageCV) => {
        createCVValue(ControlledVocabularyName.Languages, newLanguage, onCreateSuccess, onRequestFailure);
    };

    const requestCreateSimpleCV = (cvName: ControlledVocabularyName, value: string) => {
        createCVValue(cvName, { name: value }, onCreateSuccess, onRequestFailure);
    };

    const requestDeleteCVValue = (cvName: ControlledVocabularyName, value: string) => {
        deleteCVValue(
            cvName,
            value,
            () => { onRemoveSuccess(cvName, value); onDeleteSuccessMessage(); },
            onRequestFailure,
        );
    };

    const orderCVByFixedValues = (fixedValues: Array<string>, cvValues: Array<SimpleCV>) => {
        return cvValues.sort((v) => {
            if (fixedValues.includes(v.name)) return -1;
            return 1;
        });
    };

    const prepare = async () => {
        setIsFetching(true);

        Promise.all([
            getCV<ThirdPartyNameCV>(ControlledVocabularyName.ThirdPartyNames),
            getCV<TitleTypeCV>(ControlledVocabularyName.TitleTypes),
            getCV<SoundProductionCV>(ControlledVocabularyName.SoundProductions),
            getCV<DateTypeCV>(ControlledVocabularyName.DateTypes),
            getCV<GeneralMediaTypeCV>(ControlledVocabularyName.GeneralMediaTypes),
            getCV<SpecificMediaTypeCV>(ControlledVocabularyName.SpecificMediaTypes),
            getCV<CarrierCV>(ControlledVocabularyName.Carriers),
            getCV<CodecCV>(ControlledVocabularyName.Codecs),
            getCV<ItemElementCV>(ControlledVocabularyName.ItemElements),
            getCV<ItemBaseCV>(ControlledVocabularyName.ItemBases),
            getCV<AspectRatioCV>(ControlledVocabularyName.AspectRatios),
            getCV<BroadcastStandardCV>(ControlledVocabularyName.BroadcastStandards),
            getCV<GeneralColorCharacteristicCV>(ControlledVocabularyName.GeneralColorCharacteristics),
            getCV<SpecificColorTypeCV>(ControlledVocabularyName.SpecificColorTypes),
            getCV<LengthMeasurementCV>(ControlledVocabularyName.LengthMeasurements),
            getCV<LanguageUsageCV>(ControlledVocabularyName.LanguageUsages),
            getCV<AgentRoleCV>(ControlledVocabularyName.AgentRoles),
            getCV<CountryCV>(ControlledVocabularyName.Countries),
            getCV<LanguageCV>(ControlledVocabularyName.Languages),
        ]).then((results) => {
            const [
                thirdPartyNames,
                titleTypes,
                sounds,
                dateTypes,
                generalMediaTypes,
                specificMediaType,
                carriers,
                codecs,
                itemElements,
                itemBases,
                aspectRatio,
                broadcastStandards,
                generalColorCharacteristics,
                specificColorTypes,
                lengthMeasurements,
                languageUsage,
                agentRoles,
                countriesResults,
                languagesResults,
            ] = results;

            setCountries(countriesResults ? [...countriesResults.data].sort((a, b) => a.name.localeCompare(b.name)) : []);
            setLanguages(languagesResults ? [...languagesResults.data].sort((a, b) => a.name.localeCompare(b.name)) : []);

            setSimpleCVInputs({
                [ControlledVocabularyName.ThirdPartyNames]: thirdPartyNames,
                [ControlledVocabularyName.SoundProductions]: sounds,
                [ControlledVocabularyName.GeneralMediaTypes]: generalMediaTypes ? { data: orderCVByFixedValues(Object.values(ItemGeneralMediaType), generalMediaTypes.data) } : { data: [] },
                [ControlledVocabularyName.SpecificMediaTypes]: specificMediaType ? { data: orderCVByFixedValues(Object.values(ItemSpecificMediaType), specificMediaType.data) } : { data: [] },
                [ControlledVocabularyName.Carriers]: carriers ? { data: orderCVByFixedValues(Object.values(ItemCarrierType), carriers.data) } : { data: [] },
                [ControlledVocabularyName.Codecs]: codecs,
                [ControlledVocabularyName.ItemElements]: itemElements ? { data: orderCVByFixedValues(Object.values(ItemElementType), itemElements.data) } : { data: [] },
                [ControlledVocabularyName.ItemBases]: itemBases,
                [ControlledVocabularyName.AspectRatios]: aspectRatio,
                [ControlledVocabularyName.BroadcastStandards]: broadcastStandards,
                [ControlledVocabularyName.GeneralColorCharacteristics]: generalColorCharacteristics,
                [ControlledVocabularyName.SpecificColorTypes]: specificColorTypes,
                [ControlledVocabularyName.LengthMeasurements]: lengthMeasurements,
                [ControlledVocabularyName.LanguageUsages]: languageUsage,
                [ControlledVocabularyName.AgentRoles]: agentRoles ? { data: orderCVByFixedValues(Object.values(AgentType), agentRoles.data) } : { data: [] },
                [ControlledVocabularyName.TitleTypes]: titleTypes ? { data: orderCVByFixedValues(Object.values(WorkTitleType), titleTypes.data) } : { data: [] },
                [ControlledVocabularyName.DateTypes]: dateTypes ? { data: orderCVByFixedValues(Object.values(DateType), dateTypes.data) } : { data: [] },
            });

            setIsFetching(false);
        });
    };

    const renderSimpleCVChipsInput = (cvName: ControlledVocabularyName, title: string, placeholder: string) => {
        const values = simpleCVInputs[cvName]?.data || [];

        return (
            <React.Fragment>
                <InputLabel>{title}</InputLabel>
                <ChipsInput
                    onAdd={(v) => onAddNewSimpleCVValue(cvName, v)}
                    onRemove={(v) => onRemoveSimpleCVValue(cvName, v)}
                    values={values.map((v) => ({ value: v.name })) || []}
                    placeholder={placeholder}
                    hideInput={!isEditing}
                    disabled={!isEditing}
                />
            </React.Fragment>
        );
    };

    const renderSimpleCVsWithFixedValues = (cvName: ControlledVocabularyName, title: string, placeholder: string, fixedValues: Array<string>) => {
        const values = simpleCVInputs[cvName]?.data || [];

        return (
            <React.Fragment>
                <InputLabel>{title}</InputLabel>
                <ChipsInput
                    onAdd={(v) => onAddNewSimpleCVValue(cvName, v)}
                    onRemove={(v) => onRemoveSimpleCVValue(cvName, v)}
                    values={values.map((v) => ({
                        value: v.name,
                        removeDisabled: fixedValues.includes(v.name),
                    })) || []}
                    placeholder={placeholder}
                    hideInput={!isEditing}
                    disabled={!isEditing}
                />
            </React.Fragment>
        );
    };

    if (isFetching) {
        return <Loader />;
    }

    return (
        <Container maxWidth="md">
            <div className="management-screen">
                <div className="management-screen__top">
                    <div className="management-screen__top__left">
                        <div className="management-screen__top__left__image">
                            <IconCircleCV />
                        </div>
                        {isEditing ? (
                            <div className="management-screen__top__left__title">
                                <h3>{t('controlledVocabularyForm.editTitle')}</h3>
                                <p>{t('controlledVocabularyForm.editSubtitle')}</p>
                            </div>
                        ) : (
                            <div className="management-screen__top__left__title">
                                <h3>{t('controlledVocabularyForm.viewTitle')}</h3>
                            </div>
                        )}
                    </div>
                    <Can
                        actions={[Permission.UPDATE_VOCABULARY]}
                        yes={() => (
                            <div className="management-screen__top__right">
                                {isEditing ? (
                                    <Tooltip title={t('controlledVocabularyForm.closeEditionTooltip')}>
                                        <IconButton
                                            className="square-button"
                                            data-testid="controlled-vocabulary-edit-button"
                                            onClick={() => setIsEditing(false)}
                                        >
                                            <Close />
                                        </IconButton>
                                    </Tooltip>
                                ) : (
                                    <Tooltip title={t('controlledVocabularyForm.editTooltip')}>
                                        <IconButton
                                            className="square-button"
                                            data-testid="controlled-vocabulary-edit-button"
                                            onClick={() => setIsEditing(true)}
                                        >
                                            <EditOutlined />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </div>
                        )}
                    />
                </div>
                <div className="cv-form">
                    <Accordion
                        expanded={expandedCVSection.has(CVSection.ThirdPartyIds)}
                        onChange={onExpanded(CVSection.ThirdPartyIds)}
                        disableGutters
                        className="cv-form__accordion-section"
                    >
                        <AccordionSummary
                            expandIcon={<ArrowDropDown color="primary" />}
                            className="accordion-header"
                        >
                            {t('controlledVocabularyForm.thirdPartyIds.title')}
                        </AccordionSummary>
                        <AccordionDetails>
                            {renderSimpleCVChipsInput(
                                ControlledVocabularyName.ThirdPartyNames,
                                t('controlledVocabularyForm.thirdPartyIds.label'),
                                t('controlledVocabularyForm.thirdPartyIds.addNew'),
                            )}
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expandedCVSection.has(CVSection.Work)}
                        onChange={onExpanded(CVSection.Work)}
                        disableGutters
                        className="cv-form__accordion-section"
                    >
                        <AccordionSummary
                            expandIcon={<ArrowDropDown color="primary" />}
                            className="accordion-header"
                        >
                            {t('controlledVocabularyForm.work.title')}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid spacing={2} container>
                                <Grid item xs={6}>
                                    {renderSimpleCVsWithFixedValues(
                                        ControlledVocabularyName.TitleTypes,
                                        t('controlledVocabularyForm.work.titleType'),
                                        t('controlledVocabularyForm.work.addNewTitleType'),
                                        Object.values(WorkTitleType),
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    {renderSimpleCVsWithFixedValues(
                                        ControlledVocabularyName.SoundProductions,
                                        t('controlledVocabularyForm.work.audioSound'),
                                        t('controlledVocabularyForm.work.addNewSoundAudio'),
                                        Object.values(SoundProduction),
                                    )}
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expandedCVSection.has(CVSection.Date)}
                        onChange={onExpanded(CVSection.Date)}
                        disableGutters
                        className="cv-form__accordion-section"
                    >
                        <AccordionSummary
                            expandIcon={<ArrowDropDown color="primary" />}
                            className="accordion-header"
                        >
                            {t('controlledVocabularyForm.date.title')}
                        </AccordionSummary>
                        <AccordionDetails>
                            {renderSimpleCVsWithFixedValues(
                                ControlledVocabularyName.DateTypes,
                                t('controlledVocabularyForm.date.type'),
                                t('controlledVocabularyForm.date.addNewType'),
                                Object.values(DateType),
                            )}
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expandedCVSection.has(CVSection.Location)}
                        onChange={onExpanded(CVSection.Location)}
                        disableGutters
                        className="cv-form__accordion-section"
                    >
                        <AccordionSummary
                            expandIcon={<ArrowDropDown color="primary" />}
                            className="accordion-header"
                        >
                            {t('controlledVocabularyForm.locations.title')}
                        </AccordionSummary>
                        <AccordionDetails>
                            <CVFormCountriesTable
                                onAdd={onAddNewCountry}
                                onRemove={(isoCode) => requestDeleteCVValue(ControlledVocabularyName.Countries, isoCode)}
                                countries={countries}
                                viewOnly={!isEditing}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expandedCVSection.has(CVSection.Languages)}
                        onChange={onExpanded(CVSection.Languages)}
                        disableGutters
                        className="cv-form__accordion-section"
                    >
                        <AccordionSummary
                            expandIcon={<ArrowDropDown color="primary" />}
                            className="accordion-header"
                        >
                            {t('controlledVocabularyForm.languages.title')}
                        </AccordionSummary>
                        <AccordionDetails>
                            <CVFormLanguagesTable
                                onAdd={onAddNewLanguage}
                                onRemove={(isoCode) => requestDeleteCVValue(ControlledVocabularyName.Languages, isoCode)}
                                languagesList={languages}
                                viewOnly={!isEditing}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expandedCVSection.has(CVSection.Item)}
                        onChange={onExpanded(CVSection.Item)}
                        disableGutters
                        className="cv-form__accordion-section"
                    >
                        <AccordionSummary
                            expandIcon={<ArrowDropDown color="primary" />}
                            className="accordion-header"
                        >
                            {t('controlledVocabularyForm.item.title')}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    {renderSimpleCVsWithFixedValues(
                                        ControlledVocabularyName.GeneralMediaTypes,
                                        t('controlledVocabularyForm.item.mediaType'),
                                        t('controlledVocabularyForm.item.addNewMediaType'),
                                        Object.values(ItemGeneralMediaType),
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    {renderSimpleCVsWithFixedValues(
                                        ControlledVocabularyName.SpecificMediaTypes,
                                        t('controlledVocabularyForm.item.specificMediaType'),
                                        t('controlledVocabularyForm.item.addNewSpecificMediaType'),
                                        Object.values(ItemSpecificMediaType),
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    {renderSimpleCVsWithFixedValues(
                                        ControlledVocabularyName.Carriers,
                                        t('controlledVocabularyForm.item.carrier'),
                                        t('controlledVocabularyForm.item.addNewCarrier'),
                                        Object.values(ItemCarrierType),
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    {renderSimpleCVChipsInput(
                                        ControlledVocabularyName.Codecs,
                                        t('controlledVocabularyForm.item.codec'),
                                        t('controlledVocabularyForm.item.addNewCodec'),
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    {renderSimpleCVsWithFixedValues(
                                        ControlledVocabularyName.ItemElements,
                                        t('controlledVocabularyForm.item.element'),
                                        t('controlledVocabularyForm.item.addNewElement'),
                                        Object.values(ItemElementType),
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    {renderSimpleCVChipsInput(
                                        ControlledVocabularyName.ItemBases,
                                        t('controlledVocabularyForm.item.base'),
                                        t('controlledVocabularyForm.item.addNewBase'),
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    {renderSimpleCVChipsInput(
                                        ControlledVocabularyName.AspectRatios,
                                        t('controlledVocabularyForm.item.aspectRatio'),
                                        t('controlledVocabularyForm.item.addNewAspectRatio'),
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    {renderSimpleCVChipsInput(
                                        ControlledVocabularyName.BroadcastStandards,
                                        t('controlledVocabularyForm.item.broadcastStandard'),
                                        t('controlledVocabularyForm.item.addNewBroadcastStandard'),
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    {renderSimpleCVChipsInput(
                                        ControlledVocabularyName.GeneralColorCharacteristics,
                                        t('controlledVocabularyForm.item.colorCharacteristics'),
                                        t('controlledVocabularyForm.item.addNewColorCharacteristic'),
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    {renderSimpleCVChipsInput(
                                        ControlledVocabularyName.SpecificColorTypes,
                                        t('controlledVocabularyForm.item.colorType'),
                                        t('controlledVocabularyForm.item.addNewColorType'),
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    {renderSimpleCVChipsInput(
                                        ControlledVocabularyName.LengthMeasurements,
                                        t('controlledVocabularyForm.item.lengthMeasurements'),
                                        t('controlledVocabularyForm.item.addNewLengthMeasurement'),
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    {renderSimpleCVChipsInput(
                                        ControlledVocabularyName.LanguageUsages,
                                        t('controlledVocabularyForm.item.languageUsage'),
                                        t('controlledVocabularyForm.item.addNewLanguageUsage'),
                                    )}
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expandedCVSection.has(CVSection.Agents)}
                        onChange={onExpanded(CVSection.Agents)}
                        disableGutters
                        className="cv-form__accordion-section"
                    >
                        <AccordionSummary
                            expandIcon={<ArrowDropDown color="primary" />}
                            className="accordion-header"
                        >
                            {t('controlledVocabularyForm.agents.title')}
                        </AccordionSummary>
                        <AccordionDetails>
                            {renderSimpleCVsWithFixedValues(
                                ControlledVocabularyName.AgentRoles,
                                t('controlledVocabularyForm.agents.role'),
                                t('controlledVocabularyForm.agents.addNewRole'),
                                Object.values(AgentType),
                            )}
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
        </Container>
    );
};

export default withTranslationContext(withControlledVocabulariesContext(ControlledVocabularyScreen));
