/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';

import { EmptyObject } from '../types/misc';
import { AppRoute } from '../constants/routes';
import BottomBar from './elements/BottomBar';
import Themed from './containers/Themed';
import LoginScreen from './screens/LoginScreen';
import RequireAuth from './containers/RequireAuth';
import ScreenBundler from './screens/ScreenBundler';
import ForgotPasswordScreen from './screens/ForgotPasswordScreen';
import SetPasswordScreen from './screens/SetPasswordScreen';
import ConfirmEmailChangeScreen from './elements/ConfirmEmailChangeScreen';

type Props = EmptyObject;

const AppRouter: FunctionComponent<Props> = (): ReactElement => {
    return (
        <Themed>
            <div className="app-router" data-testid="app-router">
                <Routes>
                    <Route path={AppRoute.Login} element={<LoginScreen />} />
                    <Route path={AppRoute.ForgotPassword} element={<ForgotPasswordScreen />} />
                    <Route path={AppRoute.AcceptInvite} element={<SetPasswordScreen />} />
                    <Route path={AppRoute.ResetPassword} element={<SetPasswordScreen />} />
                    <Route path={AppRoute.ConfirmEmailChange} element={<ConfirmEmailChangeScreen />} />
                    <Route
                        path="/*"
                        element={(
                            <RequireAuth>
                                <ScreenBundler />
                            </RequireAuth>
                        )}
                    />
                </Routes>
                <footer className="footer">
                    <BottomBar />
                </footer>
            </div>
        </Themed>
    );
};

export default AppRouter;
