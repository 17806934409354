/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, {
    FunctionComponent, ReactElement, useEffect, useState,
} from 'react';
import {
    TextField, IconButton, Pagination, Container,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';

import { CancelRounded } from '@mui/icons-material';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { ArchivesContext, withArchivesContext } from '../controllers/archives/ArchivesContext';
import { Archive } from '../../types/archives';
import Loader from '../elements/Loader';
import ArchiveFormDialog from '../elements/archive/ArchiveFormDialog';
import { ListResponseMeta, ListSearchParams } from '../../types/misc';
import IconCircleArchive from '../assets/IconCircleArchive';
import ListNoDataMessage from '../elements/ListNoDataMessage';
import Can from '../containers/Can';
import { Permission } from '../../types/authorization';

interface OwnProps extends TranslationContext, ArchivesContext {}

type Props = OwnProps;

const ArchivesScreen: FunctionComponent<Props> = (props): ReactElement | null => {
    const archiveSearchDefaultParams: ListSearchParams = {
        search: '',
        page: -1,
    };

    const { t, getArchives } = props;
    const [archivesList, setArchivesList] = useState<Array<Archive>>([]);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [selectedArchive, setSelectedArchive] = useState<Archive | null>(null);
    const [paginationParams, setPaginationParams] = useState<ListResponseMeta | null>(null);
    const [searchParams, setSearchParams] = useState<ListSearchParams>(archiveSearchDefaultParams);
    const [valueSearched, setValueSearched] = useState<string>('');
    const [showCreate, setShowCreate] = useState<boolean>(false);

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target: { value } } = event;
        setSearchParams({ search: value });
    };

    const handleSearch = async () => {
        setIsFetching(true);

        const params = (searchParams.search === '') ? archiveSearchDefaultParams : { ...searchParams, page: null };
        const search = (searchParams.search === '') ? undefined : searchParams;

        setSearchParams(params);
        const archivesData = await getArchives(search);
        if (archivesData) {
            setArchivesList(archivesData.data);
            setPaginationParams(archivesData.meta);
            setCurrentPage(1);
        }

        setValueSearched(searchParams.search);

        setIsFetching(false);
    };

    const handlePagination = async (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
    };

    const fetchOnPageChange = async () => {
        setIsFetching(true);

        const archivesData = await getArchives({ ...searchParams, page: currentPage });
        if (archivesData) {
            setArchivesList(archivesData.data);
            setPaginationParams(archivesData.meta);
        }

        setIsFetching(false);
    };

    const handleCloseDialog = (updateList: boolean) => {
        if (updateList) fetchOnPageChange();
        setSelectedArchive(null);
    };

    const handleArchiveSelected = (archive: Archive) => {
        setSelectedArchive(archive);
    };

    useEffect(() => {
        const prepare = async () => {
            const archivesData = await getArchives();

            if (archivesData) {
                setArchivesList(archivesData.data);
                setPaginationParams(archivesData.meta);
            }

            setIsFetching(false);
        };

        prepare();
    }, []);

    useEffect(() => {
        fetchOnPageChange();
    }, [currentPage]);

    return (
        <Container maxWidth="md">
            {showCreate && <ArchiveFormDialog onClose={() => setShowCreate(false)} />}
            <div className="management-screen archives-screen">
                {isFetching && <Loader />}
                <div className="management-screen__top">
                    <div className="management-screen__top__left">
                        <div className="management-screen__top__left__image">
                            <IconCircleArchive />
                        </div>
                        <div className="management-screen__top__left__title">
                            <h3>{t('archivesScreen.title')}</h3>
                        </div>
                    </div>
                    <div className="management-screen__top__right">
                        <div className="management-screen__top__right__search-bar-wrapper">
                            <div className="management-screen__top__right__search-bar-wrapper__search-bar">
                                <TextField
                                    fullWidth
                                    data-testid="archives-search-bar"
                                    variant="standard"
                                    placeholder={t('archivesScreen.searchPlaceholder')}
                                    value={searchParams.search}
                                    onChange={onInputChange}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleSearch();
                                        }
                                    }}
                                    InputProps={{
                                        disableUnderline: true,
                                        startAdornment: <SearchIcon className="search-icon" />,
                                        endAdornment: (
                                            <>
                                                <IconButton data-testid="clear-search-icon" onClick={() => setSearchParams({ search: '' })}>
                                                    <CancelRounded />
                                                </IconButton>
                                                <IconButton
                                                    className="search-button"
                                                    onClick={handleSearch}
                                                >
                                                    <SearchIcon />
                                                </IconButton>
                                            </>
                                        ),
                                    }}
                                />
                            </div>
                        </div>
                        <Can
                            actions={[Permission.CREATE_ARCHIVE]}
                            yes={() => (
                                <IconButton data-testid="new-archive-btn" className="add-button" onClick={() => setShowCreate(true)}>
                                    <AddIcon />
                                </IconButton>
                            )}
                        />
                    </div>
                </div>
                <div className="management-screen__bottom">
                    <div className="management-screen__bottom__list">
                        {archivesList.map((archive) => {
                            return (
                                <div
                                    data-testid="archives-list-item"
                                    className="management-screen__bottom__list__item"
                                    key={archive.code + archive.name}
                                    onClick={() => handleArchiveSelected(archive)}
                                >
                                    <div className="management-screen__bottom__list__item__image">
                                        <IconCircleArchive />
                                    </div>
                                    <div className="management-screen__bottom__list__item__description">
                                        <h3>{archive.name}</h3>
                                        <p>{archive.code}, {archive.city}-{archive.country_ISO_code}</p>
                                    </div>
                                </div>
                            );
                        })}
                        {selectedArchive && <ArchiveFormDialog onClose={handleCloseDialog} archiveCode={selectedArchive.code} />}
                        {archivesList.length === 0 && (
                            <div className="management-screen__bottom__list__no-data">
                                <ListNoDataMessage searchValue={valueSearched} />
                            </div>
                        )}
                    </div>
                    <div className="management-screen__bottom__pagination">
                        {paginationParams && (
                            <Pagination
                                count={paginationParams.last_page}
                                page={paginationParams.current_page}
                                onChange={handlePagination}
                            />
                        )}
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default withTranslationContext(withArchivesContext(ArchivesScreen));
