/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { ReviewMetadataChangeDiffStatus, ReviewOperation } from '../types/reviews';

export const getReviewValueRefClassName = (diffStatus: ReviewMetadataChangeDiffStatus, operation: ReviewOperation | null): string => {
    let valueRefClassName = 'light-row';
    if (diffStatus === ReviewMetadataChangeDiffStatus.Changed) {
        valueRefClassName += ' without-border';
    }
    if (operation === ReviewOperation.Accept && (diffStatus === ReviewMetadataChangeDiffStatus.Changed || diffStatus === ReviewMetadataChangeDiffStatus.New)) {
        valueRefClassName += ' line-through';
    }

    return valueRefClassName;
};

export const getReviewValueCompareClassName = (diffStatus: ReviewMetadataChangeDiffStatus, operation: ReviewOperation | null): string => {
    let valueCompareClassName = diffStatus === ReviewMetadataChangeDiffStatus.New ? 'bold' : '';
    if (operation === ReviewOperation.Reject && (diffStatus === ReviewMetadataChangeDiffStatus.Changed || diffStatus === ReviewMetadataChangeDiffStatus.New)) {
        valueCompareClassName += ' line-through';
    }

    return valueCompareClassName;
};
