/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { WorkDate } from '../../../types/works';
import { ReviewMetadataChangeDiffStatus, ReviewMetadataChangeItem } from '../../../types/reviews';
import ReviewActionButtons from './ReviewActionButtons';
import { getReviewValueCompareClassName, getReviewValueRefClassName } from '../../../utils/review';

interface OwnProps extends TranslationContext {
    dateChange: ReviewMetadataChangeItem<WorkDate>;
    onAccept(): void;
    onReject(): void;
}

const ReviewDateTableRow: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t, dateChange, onReject, onAccept,
    } = props;
    const {
        valueCompare, valueRef, reviewOperation, diffStatus,
    } = dateChange;

    if (diffStatus === ReviewMetadataChangeDiffStatus.Deleted) return null;

    return (
        <React.Fragment>
            {valueRef && (
                <TableRow className={getReviewValueRefClassName(diffStatus, reviewOperation)}>
                    <TableCell>
                        {valueRef.date_value ? valueRef.date_value : valueRef.year_from}
                    </TableCell>
                    <TableCell>
                        {valueRef.year_until || '-'}
                    </TableCell>
                    <TableCell>
                        {valueRef.date_type}
                    </TableCell>
                    <TableCell>
                        {valueRef.date_accuracy ? t('work.form.accurate') : t('work.form.estimated')}
                    </TableCell>
                    <TableCell align="right" data-testid="date-cell">
                        <ReviewActionButtons
                            reviewOperation={reviewOperation}
                            changeDiffStatus={diffStatus}
                            onAccept={onAccept}
                            onReject={onReject}
                        />
                    </TableCell>
                </TableRow>
            )}
            {diffStatus !== ReviewMetadataChangeDiffStatus.Unchanged && valueCompare && (
                <TableRow className={getReviewValueCompareClassName(diffStatus, reviewOperation)}>
                    <TableCell>
                        {valueCompare.date_value ? valueCompare.date_value : valueCompare.year_from}
                    </TableCell>
                    <TableCell>
                        {valueCompare.year_until || '-'}
                    </TableCell>
                    <TableCell>
                        {valueCompare.date_type}
                    </TableCell>
                    <TableCell>
                        {valueCompare.date_accuracy ? t('work.form.accurate') : t('work.form.estimated')}
                    </TableCell>
                    <TableCell align="right" data-testid="date-cell">
                        {!valueRef && (
                            <ReviewActionButtons
                                reviewOperation={reviewOperation}
                                changeDiffStatus={diffStatus}
                                onAccept={onAccept}
                                onReject={onReject}
                            />
                        )}
                    </TableCell>
                </TableRow>
            )}
        </React.Fragment>
    );
};

export default withTranslationContext(ReviewDateTableRow);
