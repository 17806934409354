/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, {
    FunctionComponent, useEffect, useState, useCallback,
} from 'react';
import {
    Autocomplete,
    AutocompleteRenderInputParams,
    Box,
    Button,
    Dialog,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Switch,
    TextField,
    Tooltip,
} from '@mui/material';
import { debounce } from 'lodash';
import { Close, DeleteOutline } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';

import {
    ItemFormFields,
    Item,
    ItemFormOptions,
    ItemPayload,
    ItemSound,
    ItemBase,
    ItemColor,
    ItemLanguage,
} from '../../../types/items';
import Loader from '../Loader';
import { GenericFunction } from '../../../types/misc';
import { ItemsContext, withItemsContext } from '../../controllers/items/ItemsContext';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import {
    ControlledVocabulariesContext,
    withControlledVocabulariesContext,
} from '../../controllers/controlled_vocabularies/ControlledVocabulariesContext';
import { ArchivesContext, withArchivesContext } from '../../controllers/archives/ArchivesContext';
import {
    AspectRatioCV,
    BroadcastStandardCV,
    CarrierCV,
    CodecCV,
    GeneralMediaTypeCV,
    ItemBaseCV,
    ItemElementCV,
    LengthMeasurementCV,
    SoundProductionCV,
    SpecificMediaTypeCV,
} from '../../../types/controlled_vocabularies';
import { ControlledVocabularyName } from '../../../services/controlled_vocabularies';
import Confirm from '../Confirm';
import { Archive } from '../../../types/archives';
import { getRandomInt } from '../../../utils/misc';
import ChipsInput from '../ChipsInput';
import ItemFormColorsTable from './ItemFormColorsTable';
import ItemFormLanguagesTable from './ItemFormLanguagesTable';
import IconCircleItem from '../../assets/IconCircleItem';
import { WorksContext, withWorksContext } from '../../controllers/works/WorksContext';
import { Work } from '../../../types/works';
import { ApiError } from '../../../types/errors';
import { AuthenticationContext, withAuthenticationContext } from '../../controllers/authentication/AuthenticationContext';
import { Permission } from '../../../types/authorization';
import { AuthorizationContext, withAuthorizationContext } from '../../controllers/authorization/AuthorizationContext';
import Can from '../../containers/Can';

enum FieldName {
    Accessible = 'accessible',
    ArchiveDBID = 'archive_item_db_id',
    ArchiveRef = 'archive_item_ref',
    GeneralMediaType = 'general_media_type',
    SpecificMediaType = 'specific_media_type',
    Carrier = 'carrier',
    Element = 'element',
    Bases = 'bases',
    Duration = 'duration',
    AspectRatio = 'aspect_ratio',
    BroadcastStandard = 'broadcast_standard',
    LengthValue = 'length_value',
    LengthMeasurement = 'length_measurement',
    Codec = 'codec',
    Colors = 'colors',
    SpecificColorType = 'specific_color_type',
    LanguageUsage = 'language_usage',
    Languages = 'languages',
    Condition = 'condition',
    Notes = 'notes',
    HasSound = 'has_sound',
}

const initialFieldValues: ItemFormFields = {
    accessible: true,
    archive_item_db_id: '',
    archive_item_ref: '',
    general_media_type: '',
    specific_media_type: '',
    carrier: '',
    element: '',
    duration: '',
    has_sound: '',
    aspect_ratio: '',
    broadcast_standard: '',
    length_value: '',
    length_measurement: '',
    codec: '',
    condition: '',
    notes: '',
};

interface OwnProps extends TranslationContext,
ItemsContext,
ArchivesContext,
WorksContext,
ControlledVocabulariesContext,
AuthorizationContext,
AuthenticationContext {
    itemId?: number;
    workId?: number;
    onClose: GenericFunction;
}

type Props = OwnProps;

const ItemFormDialog: FunctionComponent<Props> = (props: Props) => {
    const {
        t,
        getCV,
        workId,
        itemId,
        onClose,
        getItem,
        getWork,
        getWorks,
        deleteItem,
        updateItem,
        createItem,
        getArchives,
        authenticatedUser,
        checkPermission,
    } = props;

    const [isEdit, setIsEdit] = useState<boolean>(!!itemId);
    const [editItemId, setEditItemId] = useState<number | undefined>(itemId);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [selectOptions, setSelectOptions] = useState<ItemFormOptions>({});
    const [fieldValues, setFieldValues] = useState<ItemFormFields>(initialFieldValues);
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [archiveSearch, setArchiveSearch] = useState<string>('');
    const [archiveSelected, setArchiveSelected] = useState<Archive | null>(null);
    const [workSearch, setWorkSearch] = useState<string>('');
    const [workSelected, setWorkSelected] = useState<Work | null>(null);
    const [workResults, setWorkResults] = useState<Array<Work>>([]);
    const [basesSelected, setBasesSelected] = useState<Array<Partial<ItemBase>>>([]);
    const [colorsList, setColorsList] = useState<Array<Partial<ItemColor>>>([]);
    const [languagesList, setLanguagesList] = useState<Array<Partial<ItemLanguage>>>([]);
    const [item, setItem] = useState<Item | null>(null);

    useEffect(() => {
        prepare();
    }, []);

    useEffect(() => {
        fetchWorks(workSearch);
    }, [workSearch]);

    const { enqueueSnackbar } = useSnackbar();

    const onCreateSuccessMessage = () => {
        enqueueSnackbar(t('itemFormDialog.successfullyCreated'), { variant: 'success' });
    };

    const onEditSuccessMessage = () => {
        enqueueSnackbar(t('itemFormDialog.successfullyEdited'), { variant: 'success' });
    };

    const onDeleteSuccessMessage = () => {
        enqueueSnackbar(t('itemFormDialog.successfullyDeleted'), { variant: 'success' });
    };

    const onRequestFailureMessage = (error?: ApiError) => {
        if (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
        } else {
            enqueueSnackbar(t('general.requestGeneralError'), { variant: 'error' });
        }
    };

    const onAddBase = (value: string) => {
        let base: Partial<ItemBase> = { id: getRandomInt(-10000, 0), base: value };
        const existentBase = item?.bases.find((b) => b.base === value);
        if (existentBase) base = existentBase;

        setBasesSelected([...basesSelected, base]);
    };

    const onAddColor = (newColor: Partial<ItemColor>) => {
        let color = { ...newColor };
        const existentColor = item?.colors.find((c: ItemColor) => c.general_color_characteristic === newColor.general_color_characteristic
            && c.specific_color_type === newColor.specific_color_type);
        if (existentColor) color = existentColor;

        setColorsList([...colorsList, color]);
    };

    const onRemoveColor = (itemColorId?: number) => {
        setColorsList([...colorsList.filter((c) => c.id !== itemColorId)]);
    };

    const onAddLanguage = (newLanguage: Partial<ItemLanguage>) => {
        let language = { ...newLanguage };
        const existentLanguage = item?.languages.find((l: ItemLanguage) => l.language === newLanguage.language && l.language_usage === newLanguage.language_usage);
        if (existentLanguage) language = existentLanguage;

        setLanguagesList([...languagesList, language]);
    };

    const onRemoveLanguage = (languageId: number) => {
        setLanguagesList([...languagesList.filter((l) => l.id !== languageId)]);
    };

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        if (name === FieldName.LengthValue && value === '') {
            setFieldValues({
                ...fieldValues,
                [name]: value,
                [FieldName.LengthMeasurement]: '',
            });
        } else {
            setFieldValues({
                ...fieldValues,
                [name]: value,
            });
        }
    };

    const onSelectChange = (event: SelectChangeEvent) => {
        const {
            target: { value, name },
        } = event;

        setFieldValues({
            ...fieldValues,
            [name]: value,
        });
    };

    const onSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {
            target: { checked, name },
        } = event;

        setFieldValues({
            ...fieldValues,
            [name]: checked,
        });
    };

    const handleDelete = () => {
        if (editItemId) {
            setShowConfirm(false);
            deleteItem(
                editItemId,
                () => {
                    onDeleteSuccessMessage();
                    onClose();
                },
                onRequestFailureMessage,
            );
        }
    };

    const handleClose = (btnName: string) => {
        if (btnName === 'btn-save-close') {
            onClose();
        }
    };

    const onFormSubmit = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const basesMap = new Map<string, number>(item?.bases?.map(({ base, id }) => [base, id]));
        
        const filteredBases = basesSelected.map((currentItem) => {
            if (currentItem?.base && basesMap.has(currentItem.base)) {
                return { base: currentItem.base, id: basesMap.get(currentItem.base) };
            }
            return currentItem;
        });

        let payload: ItemPayload = {
            accessible: fieldValues[FieldName.Accessible],
            archive_item_db_id: fieldValues[FieldName.ArchiveDBID],
            archive_item_ref: fieldValues[FieldName.ArchiveRef],
            aspect_ratio: fieldValues[FieldName.AspectRatio],
            bases: filteredBases,
            broadcast_standard: fieldValues[FieldName.BroadcastStandard],
            carrier: fieldValues[FieldName.Carrier],
            codec: fieldValues[FieldName.Codec],
            colors: colorsList,
            condition: fieldValues[FieldName.Condition],
            duration: Number(fieldValues[FieldName.Duration]),
            element: fieldValues[FieldName.Element],
            general_media_type: fieldValues[FieldName.GeneralMediaType],
            languages: languagesList,
            length_measurement: fieldValues[FieldName.LengthMeasurement],
            length_value: fieldValues[FieldName.LengthValue] ? Number(fieldValues[FieldName.LengthValue]) : null,
            notes: fieldValues[FieldName.Notes],
            specific_media_type: fieldValues[FieldName.SpecificMediaType],
            has_sound: null,
        };

        const btnName: string = event.currentTarget.name;
        if (isEdit && !!editItemId) {
            const updatedItem = await updateItem(
                editItemId,
                payload,
                () => {
                    onEditSuccessMessage();
                    handleClose(btnName);
                },
                onRequestFailureMessage,
            );

            if (updatedItem) {
                setItem(updatedItem);
                setBasesSelected(updatedItem.bases);
            }
        } else if (workSelected) {
            if (checkPermission([Permission.MANAGE_ALL_ARCHIVES])) {
                payload = {
                    ...payload,
                    archive_code: archiveSelected?.code,
                    fiaf_work_id: workSelected.fiaf_work_id,
                };
            } else if (authenticatedUser?.archive_code) {
                payload = {
                    ...payload,
                    archive_code: authenticatedUser.archive_code,
                    fiaf_work_id: workSelected.fiaf_work_id,
                };
            }
            
            const newItem = await createItem(
                payload,
                () => {
                    onCreateSuccessMessage();
                    handleClose(btnName);
                },
                onRequestFailureMessage,
            );

            if (btnName === 'btn-save' && newItem) {
                setItem(newItem);
                setEditItemId(newItem.fiaf_item_id);
                setBasesSelected(newItem.bases);
                setIsEdit(true);
            }
        } else {
            return enqueueSnackbar(t('general.fillRequiredFields'), { variant: 'error' });
        }
    };

    const fetchSelectOptions = () => {
        Promise.all([
            getCV<GeneralMediaTypeCV>(ControlledVocabularyName.GeneralMediaTypes),
            getCV<SpecificMediaTypeCV>(ControlledVocabularyName.SpecificMediaTypes),
            getCV<CarrierCV>(ControlledVocabularyName.Carriers),
            getCV<ItemElementCV>(ControlledVocabularyName.ItemElements),
            getCV<ItemBaseCV>(ControlledVocabularyName.ItemBases),
            getCV<SoundProductionCV>(ControlledVocabularyName.SoundProductions),
            getCV<AspectRatioCV>(ControlledVocabularyName.AspectRatios),
            getCV<BroadcastStandardCV>(ControlledVocabularyName.BroadcastStandards),
            getCV<LengthMeasurementCV>(ControlledVocabularyName.LengthMeasurements),
            getCV<CodecCV>(ControlledVocabularyName.Codecs),
            getArchives({ page: -1 }),
        ]).then((results) => {
            setSelectOptions({
                generalMediaTypes: results[0],
                specificMediaTypes: results[1],
                carriers: results[2],
                itemElements: results[3],
                itemBases: results[4],
                soundProductions: results[5],
                aspectRatios: results[6],
                broadcastStandards: results[7],
                lengthMeasurements: results[8],
                codecs: results[9],
                archives: results[10],
            });

            setIsFetching(false);
        });
    };

    const prepare = async () => {
        if (workId) {
            try {
                const workData = await getWork(workId);
                if (workData) setWorkSelected(workData);
            } catch (error) {
                const err = error as AxiosError;
                onRequestFailureMessage(err.response?.data);
            }
        }
        
        if (editItemId) {
            try {
                const itemData = await getItem(Number(editItemId));
                if (itemData) {
                    setItem(itemData);
                    setFieldValues({
                        archive_item_ref: itemData.archive_item_ref || '',
                        archive_item_db_id: itemData.archive_item_db_id || '',
                        accessible: itemData.accessible,
                        general_media_type: itemData.general_media_type || '',
                        specific_media_type: itemData.specific_media_type || '',
                        carrier: itemData.carrier || '',
                        codec: itemData.codec || '',
                        element: itemData.element || '',
                        duration: itemData.duration || '',
                        length_value: itemData.length_value || '',
                        length_measurement: itemData.length_measurement || '',
                        has_sound: itemData.has_sound ? t(`itemFormDialog.${ItemSound.HasSound}`) : t(`itemFormDialog.${ItemSound.NoSound}`),
                        aspect_ratio: itemData.aspect_ratio || '',
                        broadcast_standard: itemData.broadcast_standard || '',
                        condition: itemData.condition || '',
                        notes: itemData.notes || '',
                    });
                    setBasesSelected(itemData.bases);
                    setColorsList(itemData.colors);
                    setLanguagesList(itemData.languages);
                    setArchiveSelected(itemData.archive_work?.archive || null);
                }
            } catch (error) {
                const err = error as AxiosError;
                onRequestFailureMessage(err.response?.data);
            }
        }
        fetchSelectOptions();
    };

    const fetchWorks = useCallback(debounce(async (searchValue: string) => {
        if (searchValue !== '') {
            const res = await getWorks({ search: searchValue });

            if (res) setWorkResults(res.data);
            else setWorkResults([]);
        }
    }, 500), []);

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open
            onClose={onClose}
            className="main-dialog"
        >
            {isFetching && <Loader />}
            {item && (
                <Confirm
                    show={showConfirm}
                    title={t('itemDeleteConfirm.title')}
                    message={[t('itemDeleteConfirm.message1'), t('itemDeleteConfirm.message2')]}
                    okButton={t('itemDeleteConfirm.okButton')}
                    onClose={() => setShowConfirm(false)}
                    onConfirm={handleDelete}
                >
                    <div className="delete-confirm delete-item">
                        <IconCircleItem />
                        <p>{item?.specific_media_type}</p>
                        <p>{item?.general_media_type}</p>
                    </div>
                </Confirm>
            )}
            <div className="main-dialog__top">
                <div className="main-dialog__top__left">
                    <div>
                        <h2 className="secondary">{isEdit ? t('itemFormDialog.editTitle') : t('itemFormDialog.createTitle')}</h2>
                        {isEdit && <h2>{item?.fiaf_item_id}</h2>}
                    </div>
                </div>
                <div className="main-dialog__top__right">
                    <Button
                        color="primary"
                        variant="contained"
                        name="btn-save"
                        data-testid="item-save-changes-button"
                        disableElevation
                        onClick={(event) => onFormSubmit(event)}
                    >
                        {t('itemFormDialog.saveChanges')}
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        name="btn-save-close"
                        data-testid="item-save-close-button"
                        disableElevation
                        onClick={(event) => onFormSubmit(event)}
                    >
                        {t('itemFormDialog.saveClose')}
                    </Button>
                    {isEdit && (
                        <Tooltip title={t('itemFormDialog.deleteTooltip')}>
                            <IconButton
                                className="square-button"
                                data-testid="item-delete-button"
                                onClick={() => setShowConfirm(true)}
                            >
                                <DeleteOutline />
                            </IconButton>
                        </Tooltip>
                    )}
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        data-testid="item-close-button"
                    >
                        <Close color="primary" />
                    </IconButton>
                </div>
            </div>
            <div className="main-dialog__content main-form">
                <Grid container spacing={2}>
                    <Grid item xs={12} className="centered">
                        <IconCircleItem />
                    </Grid>
                    <Grid item xs={12} className="centered">
                        <FormControlLabel
                            control={(
                                <Switch
                                    name={FieldName.Accessible}
                                    checked={fieldValues[FieldName.Accessible]}
                                    data-testid="item-accessible-switch"
                                    onChange={onSwitchChange}
                                    size="small"
                                    color="success"
                                />
                            )}
                            label={t('itemFormDialog.currentlyAccessible')}
                            className="switch-input"
                        />
                    </Grid>
                </Grid>
                <h3>{t('itemFormDialog.work')}</h3>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel>{t('itemFormDialog.preferredTitleLabel')}</InputLabel>
                            <div className="autocomplete-input">
                                <Autocomplete
                                    data-testid="item-work-preferred-title"
                                    getOptionLabel={(option) => option.preferred_title || ''}
                                    value={workSelected}
                                    fullWidth
                                    clearIcon={<Close />}
                                    inputValue={workSearch}
                                    options={workResults}
                                    filterOptions={(options) => options}
                                    onChange={(_event, newValue) => setWorkSelected(newValue)}
                                    onInputChange={(_e, newValue) => setWorkSearch(newValue)}
                                    isOptionEqualToValue={(option, value) => option.fiaf_work_id === value.fiaf_work_id}
                                    renderOption={(prop, option) => (
                                        <Box component="li" {...prop} key={option.fiaf_work_id}>
                                            {option.preferred_title}
                                        </Box>
                                    )}
                                    renderInput={(params: AutocompleteRenderInputParams) => (
                                        <TextField
                                            {...params}
                                            data-testid="item-work-preferred-title-option"
                                            variant="standard"
                                            InputProps={{
                                                ...params.InputProps,
                                                disableUnderline: true,
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </FormControl>
                    </Grid>
                </Grid>
                <h3>{t('itemFormDialog.archive')}</h3>
                <Grid container spacing={2}>
                    <Can
                        actions={[Permission.MANAGE_ALL_ARCHIVES]}
                        yes={() => (
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel>{t('itemFormDialog.archiveNameLabel')}</InputLabel>
                                    <div className="autocomplete-input">
                                        <Autocomplete
                                            data-testid="item-archive-select"
                                            getOptionLabel={(option) => option.name || ''}
                                            value={archiveSelected}
                                            fullWidth
                                            clearIcon={<Close />}
                                            inputValue={archiveSearch}
                                            options={selectOptions.archives?.data || []}
                                            onChange={(event, newValue) => setArchiveSelected(newValue)}
                                            onInputChange={(e, newValue) => setArchiveSearch(newValue)}
                                            isOptionEqualToValue={(option, value) => option.code === value.code}
                                            renderOption={(prop, option) => (
                                                <Box data-testid="item-archive-option" component="li" {...prop} key={option.code}>
                                                    {option.name}
                                                </Box>
                                            )}
                                            renderInput={(params: AutocompleteRenderInputParams) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        disableUnderline: true,
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </FormControl>
                            </Grid>
                        )}
                        no={() => (
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel>{t('itemFormDialog.archiveNameLabel')}</InputLabel>
                                    <TextField
                                        fullWidth
                                        inputProps={{ 'data-testid': 'item-archive-name-static-field' }}
                                        name={FieldName.ArchiveDBID}
                                        defaultValue={authenticatedUser?.archive?.name}
                                        disabled
                                    />
                                </FormControl>
                            </Grid>
                        )}
                    />
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel>{t('itemFormDialog.archiveDBIDLabel')}</InputLabel>
                            <TextField
                                fullWidth
                                inputProps={{ 'data-testid': 'item-archive-db-id-input' }}
                                name={FieldName.ArchiveDBID}
                                value={fieldValues[FieldName.ArchiveDBID]}
                                onChange={onInputChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel>{t('itemFormDialog.archiveRefLabel')}</InputLabel>
                            <TextField
                                fullWidth
                                inputProps={{ 'data-testid': 'item-archive-ref-input' }}
                                name={FieldName.ArchiveRef}
                                value={fieldValues[FieldName.ArchiveRef]}
                                onChange={onInputChange}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <h3>{t('itemFormDialog.details')}</h3>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel>{t('itemFormDialog.generalMediaTypeLabel')}</InputLabel>
                            <Select
                                fullWidth
                                MenuProps={{ classes: { paper: 'select' } }}
                                name={FieldName.GeneralMediaType}
                                value={fieldValues[FieldName.GeneralMediaType]}
                                onChange={onSelectChange}
                            >
                                <MenuItem dense value="">
                                    <em>{t('itemFormDialog.none')}</em>
                                </MenuItem>
                                {selectOptions.generalMediaTypes?.data.map((type) => {
                                    return (
                                        <MenuItem
                                            dense
                                            data-testid="item-general-media-type-option"
                                            key={type.name}
                                            value={type.name}
                                        >
                                            {type.name}
                                        </MenuItem>
                                    );
                                }) || []}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel>{t('itemFormDialog.specificMediaTypeLabel')}</InputLabel>
                            <Select
                                fullWidth
                                MenuProps={{ classes: { paper: 'select' } }}
                                name={FieldName.SpecificMediaType}
                                value={fieldValues[FieldName.SpecificMediaType]}
                                onChange={onSelectChange}
                            >
                                <MenuItem dense value="" data-testid="item-specific-media-type-none">
                                    <em>{t('itemFormDialog.none')}</em>
                                </MenuItem>
                                {selectOptions.specificMediaTypes?.data.map((type) => (
                                    <MenuItem
                                        dense
                                        data-testid="item-specific-media-type-option"
                                        key={type.name}
                                        value={type.name}
                                    >
                                        {type.name}
                                    </MenuItem>
                                )) || []}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel>{t('itemFormDialog.carrierLabel')}</InputLabel>
                            <Select
                                fullWidth
                                MenuProps={{ classes: { paper: 'select' } }}
                                name={FieldName.Carrier}
                                value={fieldValues[FieldName.Carrier]}
                                onChange={onSelectChange}
                            >
                                <MenuItem dense value="">
                                    <em>{t('itemFormDialog.none')}</em>
                                </MenuItem>
                                {selectOptions.carriers?.data.map((carrier) => (
                                    <MenuItem
                                        dense
                                        data-testid="item-carrier-option"
                                        key={carrier.name}
                                        value={carrier.name}
                                    >
                                        {carrier.name}
                                    </MenuItem>
                                )) || []}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel>{t('itemFormDialog.elementLabel')}</InputLabel>
                            <Select
                                fullWidth
                                MenuProps={{ classes: { paper: 'select' } }}
                                name={FieldName.Element}
                                value={fieldValues[FieldName.Element]}
                                onChange={onSelectChange}
                            >
                                <MenuItem dense value="">
                                    <em>{t('itemFormDialog.none')}</em>
                                </MenuItem>
                                {selectOptions.itemElements?.data.map((element) => (
                                    <MenuItem
                                        dense
                                        data-testid="item-element-option"
                                        key={element.name}
                                        value={element.name}
                                    >
                                        {element.name}
                                    </MenuItem>
                                )) || []}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel>{t('itemFormDialog.basesLabel')}</InputLabel>
                            <ChipsInput
                                onAdd={onAddBase}
                                onRemove={(value) => setBasesSelected([...basesSelected.filter((b) => b.base !== value)])}
                                values={basesSelected.map((b) => ({ value: b.base || '' }))}
                                options={selectOptions.itemBases?.data.map((base) => base.name)}
                                placeholder={t('itemFormDialog.selectBase')}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel>{t('itemFormDialog.durationLabel')}</InputLabel>
                            <TextField
                                fullWidth
                                inputProps={{ 'data-testid': 'item-duration-input' }}
                                name={FieldName.Duration}
                                value={fieldValues[FieldName.Duration]}
                                onChange={onInputChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel>{t('itemFormDialog.aspectRatioLabel')}</InputLabel>
                            <Select
                                fullWidth
                                MenuProps={{ classes: { paper: 'select' } }}
                                name={FieldName.AspectRatio}
                                value={fieldValues[FieldName.AspectRatio]}
                                onChange={onSelectChange}
                            >
                                <MenuItem dense value="">
                                    <em>{t('itemFormDialog.none')}</em>
                                </MenuItem>
                                {selectOptions.aspectRatios?.data.map((ratio) => (
                                    <MenuItem
                                        dense
                                        data-testid="item-aspect-ratio-option"
                                        key={ratio.name}
                                        value={ratio.name}
                                    >
                                        {ratio.name}
                                    </MenuItem>
                                )) || []}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel>{t('itemFormDialog.broadcastStandardLabel')}</InputLabel>
                            <Select
                                fullWidth
                                MenuProps={{ classes: { paper: 'select' } }}
                                name={FieldName.BroadcastStandard}
                                value={fieldValues[FieldName.BroadcastStandard]}
                                onChange={onSelectChange}
                            >
                                <MenuItem dense value="">
                                    <em>{t('itemFormDialog.none')}</em>
                                </MenuItem>
                                {selectOptions.broadcastStandards?.data.map((standard) => (
                                    <MenuItem
                                        dense
                                        data-testid="item-broadcast-standard-option"
                                        key={standard.name}
                                        value={standard.name}
                                    >
                                        {standard.name}
                                    </MenuItem>
                                )) || []}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel>{t('itemFormDialog.lengthValueLabel')}</InputLabel>
                            <TextField
                                fullWidth
                                inputProps={{ 'data-testid': 'item-length-value-input' }}
                                name={FieldName.LengthValue}
                                value={fieldValues[FieldName.LengthValue]}
                                onChange={onInputChange}
                                type="number"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel>{t('itemFormDialog.units')}</InputLabel>
                            <Select
                                fullWidth
                                disabled={fieldValues[FieldName.LengthValue] === ''}
                                MenuProps={{ classes: { paper: 'select' } }}
                                name={FieldName.LengthMeasurement}
                                value={fieldValues[FieldName.LengthMeasurement]}
                                onChange={onSelectChange}
                            >
                                <MenuItem dense value="">
                                    <em>{t('itemFormDialog.none')}</em>
                                </MenuItem>
                                {selectOptions.lengthMeasurements?.data.map((uom) => (
                                    <MenuItem
                                        dense
                                        data-testid="item-length-measurement-option"
                                        key={uom.name}
                                        value={uom.name}
                                    >
                                        {uom.name}
                                    </MenuItem>
                                )) || []}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel>{t('itemFormDialog.codecLabel')}</InputLabel>
                            <Select
                                fullWidth
                                MenuProps={{ classes: { paper: 'select' } }}
                                name={FieldName.Codec}
                                value={fieldValues[FieldName.Codec]}
                                onChange={onSelectChange}
                            >
                                <MenuItem dense value="">
                                    <em>{t('itemFormDialog.none')}</em>
                                </MenuItem>
                                {selectOptions.codecs?.data.map((codec) => (
                                    <MenuItem
                                        dense
                                        data-testid="item-codec-option"
                                        key={codec.name}
                                        value={codec.name}
                                    >
                                        {codec.name}
                                    </MenuItem>
                                )) || []}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <ItemFormColorsTable
                            colors={colorsList}
                            onRemove={(colorId) => onRemoveColor(colorId)}
                            onAdd={onAddColor}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <ItemFormLanguagesTable
                            languagesList={languagesList}
                            onAdd={onAddLanguage}
                            onRemove={onRemoveLanguage}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel>{t('itemFormDialog.conditionLabel')}</InputLabel>
                            <TextField
                                multiline
                                fullWidth
                                inputProps={{ 'data-testid': 'item-condition-input' }}
                                name={FieldName.Condition}
                                value={fieldValues[FieldName.Condition]}
                                onChange={onInputChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel>{t('itemFormDialog.notesLabel')}</InputLabel>
                            <TextField
                                multiline
                                fullWidth
                                inputProps={{ 'data-testid': 'item-notes-input' }}
                                name={FieldName.Notes}
                                value={fieldValues[FieldName.Notes]}
                                onChange={onInputChange}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <div className="bottom-spacer" />
            </div>
        </Dialog>
    );
};

ItemFormDialog.defaultProps = {
    itemId: undefined,
    workId: undefined,
};

export default withTranslationContext(
    withItemsContext(
        withAuthenticationContext(
            withWorksContext(
                withAuthorizationContext(
                    withControlledVocabulariesContext(
                        withArchivesContext(
                            ItemFormDialog,
                        ),
                    ),
                ),
            ),
        ),
    ),
);
