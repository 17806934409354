/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { connect } from 'react-redux';
import React, { Component, ReactNode } from 'react';

import en from '../../../locale/en.json';
import { AppState } from '../../../types/store';
import { KeyedObject } from '../../../types/general';
import { AppLanguage } from '../../../types/preferences';
import { getTranslation } from '../../../utils/translation';
import { TranslationContextProvider } from './TranslationContext';

interface StateProps {
    language: AppLanguage;
    languages: Array<AppLanguage>;
    translations: Record<string, unknown> | null;
}

interface OwnProps {
    children: ReactNode;
}

type Props = StateProps & OwnProps;

export class TranslationController extends Component<Props> {
    private readonly defaultTranslations: Record<string, unknown> = { en };

    t = (literal: string, params?: KeyedObject): string => {
        const { translations, language } = this.props;
        const trans = translations || this.defaultTranslations;
        return getTranslation(trans, language, literal, params);
    };

    render(): ReactNode {
        const {
            language,
            languages,
            children,
        } = this.props;

        return (
            <TranslationContextProvider
                value={{
                    t: this.t,
                    languages,
                    language,
                }}
            >
                {children}
            </TranslationContextProvider>
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => {
    return {
        language: state.preferences.language,
        languages: state.preferences.languages,
        translations: state.preferences.translations,
    };
};

export const ConnectedTranslationController = connect(mapStateToProps)(TranslationController);
