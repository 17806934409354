/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useEffect, useState } from 'react';
import {
    IconButton, MenuItem, Select, SelectChangeEvent, Table, TableBody, TableCell, TableHead, TableRow, TextField,
} from '@mui/material';
import { AddRounded, CheckRounded, CloseRounded } from '@mui/icons-material';

import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import {
    ControlledVocabulariesContext,
    withControlledVocabulariesContext,
} from '../../controllers/controlled_vocabularies/ControlledVocabulariesContext';
import { WorkDate, WorkFormDateFields } from '../../../types/works';
import { DateTypeCV } from '../../../types/controlled_vocabularies';
import { ControlledVocabularyName } from '../../../services/controlled_vocabularies';
import { getRandomInt } from '../../../utils/misc';
import { DateAccuracyType } from '../../../types/dates';
import { MIN_WORK_YEAR } from '../../../constants/misc';
import Loader from '../Loader';

interface OwnProps extends TranslationContext, ControlledVocabulariesContext {
    dates: Array<WorkDate>;
    onAdd?(workDate: WorkDate): void;
    onRemove?(dateId: number): void;
}

enum FieldName {
    YearFrom = 'year_from',
    YearUntil = 'year_until',
    DateType = 'date_type',
    DateAccuracy = 'date_accuracy',
}

const workDateInitialValues: WorkFormDateFields = {
    id: -1,
    date_type: '',
    date_accuracy: false,
    year_from: '',
    year_until: '',
};

const WorkFormDatesTable: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t, getCV, dates, onAdd, onRemove,
    } = props;

    const [dateTypeOptions, setDateTypeOptions] = useState<Array<DateTypeCV>>([]);
    const [newDate, setNewDate] = useState<WorkFormDateFields | null>(null);
    const [isFetching, setIsFetching] = useState(true);

    useEffect(() => {
        const fetchDateTypes = async () => {
            const results = await getCV<DateTypeCV>(ControlledVocabularyName.DateTypes);
            if (results) {
                setDateTypeOptions(results.data);
            }
            setIsFetching(false);
        };

        fetchDateTypes();
    }, []);

    useEffect(() => {
        if (dates.length === 0) {
            onAddNewDate();
        } else {
            setNewDate(null);
        }
    }, [dates]);

    const onAddNewDate = () => {
        setNewDate({ ...workDateInitialValues, id: getRandomInt(-100000, 0) });
    };

    const onSelectChange = (event: SelectChangeEvent) => {
        const {
            target: { value, name },
        } = event;

        if (newDate) {
            setNewDate({
                ...newDate,
                [name]: value,
            });
        }
    };

    const onChangeAccuracy = () => {
        if (newDate) {
            setNewDate({
                ...newDate,
                date_accuracy: !newDate.date_accuracy,
            });
        }
    };

    const onYearInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {
            target: { value, name },
        } = event;

        if (newDate) {
            setNewDate({
                ...newDate,
                [name]: value,
            });
        }
    };

    const onAccept = () => {
        if (newDate) {
            const yearFrom = newDate.year_from.trim();
            const yearUntil = newDate.year_until.trim();

            let yearValue: number | null = null;
            let dateStr = yearFrom;
            if ((yearFrom && !yearUntil) || yearFrom === yearUntil) {
                yearValue = Number(yearFrom);
            }
            if (yearUntil) {
                dateStr += ` - ${yearUntil}`;
            }
            onAdd?.({
                id: newDate.id,
                date_type: newDate.date_type,
                date_accuracy: newDate.date_accuracy,
                year_from: yearValue ? null : Number(yearFrom),
                year_until: yearValue ? null : Number(yearUntil),
                date_string: dateStr,
                date_value: yearValue,
            });
        }

        setNewDate(null);
    };

    if (isFetching) {
        return <Loader />;
    }

    if (dates.length === 0 && !onAdd) {
        return null;
    }

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>{t('work.form.from')}</TableCell>
                    <TableCell>{t('work.form.until')}</TableCell>
                    <TableCell>{t('work.form.type')}</TableCell>
                    <TableCell>{t('work.form.accuracy')}</TableCell>
                    {onAdd && (
                        <TableCell className="add-btn-cell">
                            <IconButton data-testid="add-new-date-btn" className="add-small-btn" onClick={onAddNewDate}>
                                <AddRounded />
                            </IconButton>
                        </TableCell>
                    )}
                </TableRow>
            </TableHead>
            <TableBody>
                {dates.map((date) => (
                    <TableRow key={date.id}>
                        <TableCell data-testid="existing-date-from">
                            {date.date_value ? (
                                <p>{date.date_value}</p>
                            ) : (
                                <p>{date.year_from}</p>
                            )}
                        </TableCell>
                        <TableCell data-testid="existing-date-until">{date.year_until || '-'}</TableCell>
                        <TableCell data-testid="existing-date-type">{date.date_type}</TableCell>
                        <TableCell data-testid="existing-date-accuracy">{date.date_accuracy ? t('work.form.accurate') : t('work.form.estimated')}</TableCell>
                        {onRemove && (
                            <TableCell align="right">
                                <IconButton
                                    size="small"
                                    data-testid="date-delete-btn"
                                    onClick={() => onRemove(date.id)}
                                    className="action-btn"
                                >
                                    <CloseRounded />
                                </IconButton>
                            </TableCell>
                        )}
                    </TableRow>
                ))}
                {newDate && (
                    <TableRow>
                        <TableCell className="input-cell">
                            <TextField
                                fullWidth
                                variant="standard"
                                inputProps={{
                                    min: MIN_WORK_YEAR,
                                    max: new Date().getFullYear(),
                                    'data-testid': 'date-from-input',
                                    placeholder: t('work.form.from'),
                                }}
                                name={FieldName.YearFrom}
                                value={newDate[FieldName.YearFrom]}
                                onChange={onYearInputChange}
                                type="number"
                            />
                        </TableCell>
                        <TableCell className="input-cell">
                            <TextField
                                fullWidth
                                variant="standard"
                                inputProps={{
                                    min: MIN_WORK_YEAR,
                                    max: new Date().getFullYear(),
                                    'data-testid': 'date-until-input',
                                    placeholder: t('work.form.until'),
                                }}
                                name={FieldName.YearUntil}
                                value={newDate[FieldName.YearUntil]}
                                onChange={onYearInputChange}
                                type="number"
                            />
                        </TableCell>
                        <TableCell>
                            <Select
                                fullWidth
                                MenuProps={{ classes: { paper: 'select' } }}
                                data-testid="date-type-select"
                                className="simple-select dense-select"
                                name={FieldName.DateType}
                                value={newDate[FieldName.DateType]}
                                onChange={onSelectChange}
                                displayEmpty
                            >
                                <MenuItem dense value="">
                                    <em>{t('general.select')}</em>
                                </MenuItem>
                                {dateTypeOptions.map((value) => (
                                    <MenuItem data-testid="date-type-select-item" dense key={value.name} value={value.name}>{value.name}</MenuItem>
                                ))}
                            </Select>
                        </TableCell>
                        <TableCell>
                            <Select
                                fullWidth
                                MenuProps={{ classes: { paper: 'select' } }}
                                data-testid="date-type-accuracy-select"
                                className="simple-select dense-select"
                                name={FieldName.DateAccuracy}
                                value={String(newDate[FieldName.DateAccuracy])}
                                onChange={onChangeAccuracy}
                            >
                                <MenuItem dense value="false">{DateAccuracyType.Estimated}</MenuItem>
                                <MenuItem dense value="true">{DateAccuracyType.Accurate}</MenuItem>
                            </Select>
                        </TableCell>
                        <TableCell align="right">
                            <IconButton
                                size="small"
                                data-testid="date-confirm-btn"
                                onClick={onAccept}
                                className="action-btn"
                                disabled={!newDate.date_type}
                            >
                                <CheckRounded />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
};

WorkFormDatesTable.defaultProps = {
    onAdd: undefined,
    onRemove: undefined,
};

export default withTranslationContext(withControlledVocabulariesContext(WorkFormDatesTable));
