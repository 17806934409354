/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useEffect, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    AutocompleteRenderInputParams,
    Box,
    Button,
    Dialog,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Tooltip,
} from '@mui/material';
import {
    ArrowDropDown,
    Close,
    DeleteOutline,
    EditOutlined,
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { UsersContext, withUsersContext } from '../../controllers/users/UsersContext';
import IconCircleUser from '../../assets/IconCircleUser';
import { ArchivesContext, withArchivesContext } from '../../controllers/archives/ArchivesContext';
import { Archive } from '../../../types/archives';
import Loader from '../Loader';
import {
    ChangePasswordPayload,
    User,
    UserFormFields,
    UserPayload,
    UserType as UserTypeEnum,
} from '../../../types/users';
import { ApiError } from '../../../types/errors';
import Confirm from '../Confirm';
import IconCircleArchive from '../../assets/IconCircleArchive';
import {
    AuthenticationContext,
    withAuthenticationContext,
} from '../../controllers/authentication/AuthenticationContext';
import PasswordField from '../PasswordField';
import Can from '../../containers/Can';
import { Permission } from '../../../types/authorization';

enum FieldName {
    Email = 'email',
    FirstName = 'first_name',
    LastName = 'last_name',
    UserType = 'user_type',
    CurrentPassword = 'current_password',
    Password = 'password',
    PasswordConfirmation = 'password_confirmation',
}

const initialFieldValues: UserFormFields = {
    email: '',
    first_name: '',
    last_name: '',
    user_type: '',
    archive_code: '',
    password: '',
    password_confirmation: '',
    current_password: '',
};

interface OwnProps extends TranslationContext, UsersContext, ArchivesContext, AuthenticationContext {
    onClose: (updateList: boolean) => void;
    userId?: number;
    isProfile?: boolean;
}

const UserFormDialog: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t,
        userId,
        onClose,
        getArchives,
        createUser,
        getUser,
        editUser,
        deleteUser,
        isProfile,
        setAuthenticatedUser,
        changeUserPassword,
    } = props;

    const isEdit = !!userId;

    const { enqueueSnackbar } = useSnackbar();

    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [isViewingDetails, setIsViewingDetails] = useState<boolean>(false);
    const [user, setUser] = useState<User | null>(null);
    const [archiveSearch, setArchiveSearch] = useState<string>('');
    const [archives, setArchives] = useState<Array<Archive>>([]);
    const [fieldValues, setFieldValues] = useState<UserFormFields>(initialFieldValues);
    const [archiveSelected, setArchiveSelected] = useState<Archive | null>(null);
    const [changePasswordFieldsOpen, setChangePasswordFieldsOpen] = useState(false);

    useEffect(() => {
        const prepare = async () => {
            setIsFetching(true);
            const archivesData = await getArchives({ page: -1 });

            if (archivesData) {
                setArchives(archivesData.data);
            }

            setIsFetching(false);
        };

        prepare();
    }, []);

    useEffect(() => {
        getUserData();
    }, [userId]);

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setFieldValues({
            ...fieldValues,
            [name]: value,
        });
    };

    const onSelectChange = (event: SelectChangeEvent) => {
        const {
            target: { value, name },
        } = event;

        setFieldValues({
            ...fieldValues,
            [name]: value,
        });
    };

    const onSave = () => {
        const fields: UserFormFields = {
            ...fieldValues,
            [FieldName.UserType]: fieldValues[FieldName.UserType].toLowerCase(),
        };

        if (fields[FieldName.UserType] === UserTypeEnum.Contributor && !archiveSelected) {
            enqueueSnackbar(t('userFormDialog.contributorNeedsToHaveAnArchive'), { variant: 'error' });
            return;
        }

        const changePassword = changePasswordFieldsOpen && fields[FieldName.Password] && fields[FieldName.PasswordConfirmation] && fields[FieldName.Password];
        if (changePassword && fields[FieldName.PasswordConfirmation] !== fields[FieldName.Password]) {
            enqueueSnackbar(t('errors.passwordAndConfirmationDoNotMatch'), { variant: 'error' });
            return;
        }

        setIsFetching(true);

        const payload: UserPayload = {
            email: fields[FieldName.Email],
            first_name: fields[FieldName.FirstName],
            last_name: fields[FieldName.LastName],
            user_type: fields[FieldName.UserType],
            archive_code: archiveSelected ? archiveSelected.code : null,
        };

        if (changePassword && user) {
            const changePasswordPayload: ChangePasswordPayload = {
                current_password: fields[FieldName.CurrentPassword],
                password: fields[FieldName.Password],
                password_confirmation: fields[FieldName.PasswordConfirmation],
            };

            changeUserPassword(user.id, changePasswordPayload, () => onChangePasswordSuccess(payload), onFailure);
            return;
        }

        if (isEdit && user) {
            editUser(user.id, payload, onSuccess, onFailure);
        } else {
            createUser(payload, onSuccess, onFailure);
        }
    };

    const onChangePasswordSuccess = (payload: UserPayload) => {
        enqueueSnackbar(t('userFormDialog.passwordChangedSuccessfully'), { variant: 'success' });
        if (user) editUser(user.id, payload, onSuccess, onFailure);
    };

    const onSuccess = (editedUser: User) => {
        let msg = t('userFormDialog.successfullyCreated');
        if (isEdit) msg = t('userFormDialog.successfullyEdited');

        if (isProfile) {
            setAuthenticatedUser(editedUser);
        }

        enqueueSnackbar(msg, { variant: 'success' });
        onClose(true);
    };

    const onFailure = (error?: ApiError) => {
        setIsFetching(false);
        if (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
        } else {
            enqueueSnackbar(t('general.requestGeneralError'), { variant: 'error' });
        }
    };

    const onDeleteSuccess = () => {
        enqueueSnackbar(t('userDeleteConfirm.deleteSuccess'), { variant: 'success' });
        onClose(true);
    };

    const onDelete = () => {
        if (user) {
            setIsFetching(true);
            deleteUser(user.id, onDeleteSuccess, onFailure);
        }
    };

    const getUserData = async () => {
        if (userId) {
            setIsViewingDetails(true);
            setIsFetching(true);
            const userData = await getUser(userId);

            setUser(userData);
            if (userData) {
                setFieldValues({
                    ...fieldValues,
                    [FieldName.FirstName]: userData.first_name,
                    [FieldName.LastName]: userData.last_name,
                    [FieldName.Email]: userData.email,
                    [FieldName.UserType]: userData.user_type,
                });
                setArchiveSelected(userData.archive);
            }
            setIsFetching(false);
        }
    };

    let title = t('userFormDialog.createTitle');
    if (isEdit) {
        if (isViewingDetails) {
            title = t('userFormDialog.detailsTitle');
        } else {
            title = t('userFormDialog.editTitle');
        }
    }
    if (isProfile) {
        title = t('userFormDialog.profile');
    }

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open
            onClose={onClose}
            className="main-dialog archive-dialog"
        >
            {isFetching && <Loader />}
            {user && (
                <Confirm
                    show={showConfirm}
                    title={t('archiveDeleteConfirm.title')}
                    message={[t('archiveDeleteConfirm.message1'), t('archiveDeleteConfirm.message2')]}
                    okButton={t('archiveDeleteConfirm.okButton')}
                    onClose={() => setShowConfirm(false)}
                    onConfirm={onDelete}
                >
                    <div className="delete-confirm delete-archive">
                        <div className="icon">
                            <IconCircleArchive />
                        </div>
                        <p>{`${user.first_name} ${user.last_name}`}</p>
                    </div>
                </Confirm>
            )}
            <div className="main-dialog__top">
                <div className="main-dialog__top__left">
                    <h2 className="secondary">{title}</h2>
                </div>
                <div className="main-dialog__top__right">
                    {!isViewingDetails && (
                        <Button
                            data-testid="user-dialog-save-button"
                            color="primary"
                            variant="contained"
                            disableElevation
                            onClick={onSave}
                        >
                            {t('userFormDialog.save')}
                        </Button>
                    )}
                    {isEdit && isViewingDetails && (
                        <Tooltip title={t('userFormDialog.editTooltip')}>
                            <IconButton
                                className="square-button"
                                data-testid="user-edit-button"
                                onClick={() => setIsViewingDetails(false)}
                            >
                                <EditOutlined />
                            </IconButton>
                        </Tooltip>
                    )}
                    {isEdit && !isProfile && (
                        <Tooltip title={t('userFormDialog.deleteTooltip')}>
                            <IconButton
                                className="square-button"
                                data-testid="user-delete-button"
                                onClick={() => setShowConfirm(true)}
                            >
                                <DeleteOutline />
                            </IconButton>
                        </Tooltip>
                    )}
                    <IconButton
                        data-testid="user-dialog-close-button"
                        aria-label="close"
                        onClick={() => onClose(false)}
                    >
                        <Close color="primary" />
                    </IconButton>
                </div>
            </div>
            <div className="main-dialog__content">
                <Grid container spacing={2}>
                    <Grid item xs={12} className="centered">
                        <IconCircleUser />
                    </Grid>
                    <Can
                        actions={[Permission.UPDATE_ARCHIVE]}
                        yes={() => (
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>{t('userFormDialog.archiveLabel')}</InputLabel>
                                    <div className="autocomplete-input">
                                        <Autocomplete
                                            data-testid="user-archive-search-bar"
                                            getOptionLabel={(option) => option.name || ''}
                                            value={archiveSelected}
                                            fullWidth
                                            clearIcon={<Close />}
                                            inputValue={archiveSearch}
                                            options={archives}
                                            disabled={isViewingDetails}
                                            onChange={(event, newValue) => setArchiveSelected(newValue)}
                                            onInputChange={(e, newValue) => setArchiveSearch(newValue)}
                                            isOptionEqualToValue={(option, value) => option.code === value.code}
                                            renderOption={(prop, option) => (
                                                <Box
                                                    data-testid="user-archive-option"
                                                    component="li"
                                                    {...prop}
                                                    key={option.code}
                                                >
                                                    {option.name}
                                                </Box>
                                            )}
                                            renderInput={(params: AutocompleteRenderInputParams) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        disableUnderline: true,
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </FormControl>
                            </Grid>
                        )}
                        no={() => (archiveSelected && archiveSelected.name ? (
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>{t('userFormDialog.archiveLabel')}</InputLabel>
                                    <TextField
                                        fullWidth
                                        inputProps={{ 'data-testid': 'user-form-archive-static-field' }}
                                        defaultValue={archiveSelected.name}
                                        disabled
                                    />
                                </FormControl>
                            </Grid>
                        ) : null)}
                    />
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel>{t('userFormDialog.contactEmailLabel')}</InputLabel>
                            <TextField
                                fullWidth
                                inputProps={{ 'data-testid': 'user-dialog-input-email' }}
                                name={FieldName.Email}
                                value={fieldValues[FieldName.Email]}
                                onChange={onInputChange}
                                disabled={isViewingDetails}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel>{t('userFormDialog.firstNameLabel')}</InputLabel>
                            <TextField
                                fullWidth
                                inputProps={{ 'data-testid': 'user-dialog-input-first-name' }}
                                name={FieldName.FirstName}
                                value={fieldValues[FieldName.FirstName]}
                                onChange={onInputChange}
                                disabled={isViewingDetails}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel>{t('userFormDialog.lastNameLabel')}</InputLabel>
                            <TextField
                                fullWidth
                                inputProps={{ 'data-testid': 'user-dialog-input-last-name' }}
                                name={FieldName.LastName}
                                value={fieldValues[FieldName.LastName]}
                                onChange={onInputChange}
                                disabled={isViewingDetails}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel>{t('userFormDialog.userTypeLabel')}</InputLabel>
                            <Select
                                fullWidth
                                MenuProps={{ classes: { paper: 'select' } }}
                                data-testid="user-dialog-input-user-type"
                                name={FieldName.UserType}
                                value={fieldValues[FieldName.UserType]}
                                onChange={onSelectChange}
                                className="capitalize"
                                disabled={isViewingDetails || isProfile}
                            >
                                <MenuItem dense value="">
                                    <em>{t('userFormDialog.none')}</em>
                                </MenuItem>
                                {Object.values(UserTypeEnum).map((type) => (
                                    <MenuItem
                                        dense
                                        data-testid="user-type-option"
                                        key={type}
                                        value={type}
                                    >
                                        {type}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {!isViewingDetails && isProfile && (
                        <Grid item xs={12} className="main-dialog__content__password">
                            <Accordion
                                data-testid="user-password-accordion"
                                expanded={changePasswordFieldsOpen}
                                onChange={() => setChangePasswordFieldsOpen(!changePasswordFieldsOpen)}
                                disableGutters
                            >
                                <AccordionSummary
                                    expandIcon={<ArrowDropDown color="primary" />}
                                    className="accordion-header"
                                >
                                    {t('userFormDialog.changePassword')}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <PasswordField
                                        onChange={onInputChange}
                                        name={FieldName.CurrentPassword}
                                        value={fieldValues[FieldName.CurrentPassword]}
                                        label={t('userFormDialog.currentPassword')}
                                    />
                                    <PasswordField
                                        onChange={onInputChange}
                                        name={FieldName.Password}
                                        value={fieldValues[FieldName.Password]}
                                        label={t('userFormDialog.newPassword')}
                                    />
                                    <PasswordField
                                        onChange={onInputChange}
                                        name={FieldName.PasswordConfirmation}
                                        value={fieldValues[FieldName.PasswordConfirmation]}
                                        label={t('userFormDialog.repeatNewPassword')}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    )}
                </Grid>
                {!isViewingDetails && !isEdit && (
                    <div className="main-dialog__content__email-info">
                        <p>{t('userFormDialog.emailWillBeSent')}</p>
                        <p>{t('userFormDialog.youWillBeNotified')}</p>
                    </div>
                )}
            </div>
        </Dialog>
    );
};

UserFormDialog.defaultProps = {
    userId: undefined,
    isProfile: false,
};

export default withTranslationContext(withUsersContext(withArchivesContext(withAuthenticationContext(UserFormDialog))));
