/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useEffect, useState } from 'react';
import {
    Table, TableBody, TableCell, TableHead, TableRow,
} from '@mui/material';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { WorkCountry } from '../../../types/works';
import { ReviewMetadataChangeDiffStatus, ReviewMetadataChangeItem } from '../../../types/reviews';
import { CountryCV } from '../../../types/controlled_vocabularies';
import { ControlledVocabularyName } from '../../../services/controlled_vocabularies';
import {
    ControlledVocabulariesContext,
    withControlledVocabulariesContext,
} from '../../controllers/controlled_vocabularies/ControlledVocabulariesContext';
import ReviewActionButtons from './ReviewActionButtons';
import { getReviewValueCompareClassName, getReviewValueRefClassName } from '../../../utils/review';

interface OwnProps extends TranslationContext, ControlledVocabulariesContext {
    countriesChanges: ReviewMetadataChangeItem<WorkCountry>[];
    onAcceptChange(idx: number): void;
    onRejectChange(idx: number): void;
}

const ReviewCountriesTable: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t, countriesChanges, getCV, onAcceptChange, onRejectChange,
    } = props;

    const [countries, setCountries] = useState<CountryCV[]>([]);

    useEffect(() => {
        const fetchCountriesCV = async () => {
            const results = await getCV<CountryCV>(ControlledVocabularyName.Countries);
            if (results) {
                setCountries(results.data);
            }
        };

        fetchCountriesCV();
    }, []);

    const getCountryName = (isoCode?: string) => {
        const country = countries.find((c) => c.ISO_code === isoCode);
        return country ? country.name : isoCode;
    };

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>{t('work.form.country')}</TableCell>
                    <TableCell>{t('work.form.accuracy')}</TableCell>
                    <TableCell />
                </TableRow>
            </TableHead>
            <TableBody>
                {countriesChanges.map((change, idx) => {
                    const {
                        valueCompare, diffStatus, valueRef, reviewOperation, idRef, idCompare,
                    } = change;

                    return (
                        <React.Fragment key={idRef || idCompare}>
                            {valueRef && (
                                <TableRow className={getReviewValueRefClassName(diffStatus, reviewOperation)}>
                                    <TableCell>{getCountryName(valueRef.country_ISO_code)}</TableCell>
                                    <TableCell>{valueRef.country_probable ? t('work.form.accurate') : t('work.form.estimated')}</TableCell>
                                    <TableCell align="right" data-testid="country-cell">
                                        <ReviewActionButtons
                                            reviewOperation={reviewOperation}
                                            changeDiffStatus={diffStatus}
                                            onAccept={() => onAcceptChange(idx)}
                                            onReject={() => onRejectChange(idx)}
                                        />
                                    </TableCell>
                                </TableRow>
                            )}
                            {diffStatus !== ReviewMetadataChangeDiffStatus.Unchanged && valueCompare && (
                                <TableRow className={getReviewValueCompareClassName(diffStatus, reviewOperation)}>
                                    <TableCell>{valueCompare.country_ISO_code}</TableCell>
                                    <TableCell>{valueCompare.country_probable ? t('work.form.accurate') : t('work.form.estimated')}</TableCell>
                                    <TableCell align="right" data-testid="country-cell">
                                        {!valueRef && (
                                            <ReviewActionButtons
                                                reviewOperation={reviewOperation}
                                                changeDiffStatus={diffStatus}
                                                onAccept={() => onAcceptChange(idx)}
                                                onReject={() => onRejectChange(idx)}
                                            />
                                        )}
                                    </TableCell>
                                </TableRow>
                            )}
                        </React.Fragment>
                    );
                })}
            </TableBody>
        </Table>
    );
};

export default withTranslationContext(withControlledVocabulariesContext(ReviewCountriesTable));
