/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, ReactElement } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { AppRoute } from '../../constants/routes';
import { Permission, PermissionOperator } from '../../types/authorization';
import { AuthorizationContext, withAuthorizationContext } from '../controllers/authorization/AuthorizationContext';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';

interface OwnProps extends AuthenticationContext, AuthorizationContext {
    permissions?: Array<Permission>;
    permissionsOperator?: PermissionOperator;
}

type Props = OwnProps;

const RequireAuth: FunctionComponent<Props> = (props): ReactElement => {
    const {
        token,
        children,
        permissions,
        checkPermission,
        isAuthenticated,
        permissionsOperator,
    } = props;

    const location = useLocation();

    if (!isAuthenticated || !token) {
        return (
            <Navigate
                to={{
                    pathname: AppRoute.Login,
                }}
                state={{
                    from: location,
                }}
            />
        );
    }

    if (permissions && !checkPermission(permissions, permissionsOperator)) {
        return (
            <Navigate
                to={{
                    pathname: AppRoute.Index,
                }}
                state={{
                    from: location,
                }}
            />
        );
    }

    return <>{children}</>;
};

RequireAuth.defaultProps = {
    permissions: undefined,
    permissionsOperator: PermissionOperator.OR,
};

export default withAuthenticationContext(
    withAuthorizationContext(
        RequireAuth,
    ),
);
