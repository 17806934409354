/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { Fragment, FunctionComponent, ReactNode } from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';

import { GenericFunction } from '../../types/misc';
import FiafTreasuresLogo from '../../assets/image/fiaf-treasures-logo.png';

interface OwnProps {
    show: boolean;
    title: string;
    message: Array<string>;
    onClose: GenericFunction;
    onClosed?: GenericFunction;
    onConfirm: GenericFunction;
    cancelButton?: string;
    okButton?: string;
    okButtonClass?: string;
    children?: ReactNode;
}

type Props = OwnProps;

const Confirm: FunctionComponent<Props> = (props: Props) => {
    const {
        show,
        title,
        message,
        onClose,
        onClosed,
        okButton,
        children,
        onConfirm,
        cancelButton,
        okButtonClass,
    } = props;

    return (
        <Dialog
            open={show}
            onClose={() => onClose()}
            TransitionProps={{
                onExited: () => {
                    if (onClosed) onClosed();
                },
            }}
            className="confirm"
        >
            <DialogContent className="confirm__content">
                <div className="confirm__content__top">
                    <img src={FiafTreasuresLogo} alt="" />
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        data-testid="confirm-dialog-close-btn"
                    >
                        <Close color="primary" />
                    </IconButton>
                </div>
                <div className="confirm__content__bottom">
                    <div className="confirm__content__bottom__title">
                        {title}
                    </div>
                    {children}
                    <div className="confirm__content__bottom__message">
                        {message.map((msg) => {
                            return <Fragment key={msg}>{msg}<br /></Fragment>;
                        })}
                    </div>
                    <div className="confirm__content__bottom__buttons">
                        {cancelButton && (
                            <Button
                                data-testid="confirm-cancel-button"
                                onClick={() => onClose()}
                            >
                                {cancelButton}
                            </Button>
                        )}
                        {okButton && (
                            <Button
                                data-testid="confirm-ok-button"
                                className={okButtonClass || 'error'}
                                variant="contained"
                                autoFocus
                                onClick={() => onConfirm()}
                                disableElevation
                            >
                                {okButton}
                            </Button>
                        )}
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

Confirm.defaultProps = {
    onClosed: () => {},
    cancelButton: '',
    okButton: '',
    okButtonClass: '',
    children: undefined,
};

export default Confirm;
