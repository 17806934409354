/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { AdvancedSearchParams } from '../types/search';
import { SearchParamsAction, SearchParamsActionTypes } from './searchParams_types';

export const setSimpleSearchParamsActionCreator = (payload: string): SearchParamsActionTypes => {
    return {
        type: SearchParamsAction.SET_SIMPLE_SEARCH_PARAMS,
        payload,
    };
};

export const setAdvancedSearchParamsActionCreator = (payload: AdvancedSearchParams | null): SearchParamsActionTypes => {
    return {
        type: SearchParamsAction.SET_ADVANCED_SEARCH_PARAMS,
        payload,
    };
};

export const setIsAdvancedSearchActionCreator = (payload: boolean): SearchParamsActionTypes => {
    return {
        type: SearchParamsAction.SET_IS_ADVANCED_SEARCH,
        payload,
    };
};

export const setCurrentPageActionCreator = (payload: number): SearchParamsActionTypes => {
    return {
        type: SearchParamsAction.SET_CURRENT_PAGE,
        payload,
    };
};

export const setSimpleSearchInputDisplayActionCreator = (payload: string): SearchParamsActionTypes => {
    return {
        type: SearchParamsAction.SET_SIMPLE_SEARCH_INPUT_DISPLAY,
        payload,
    };
};

export const resetSearchParamsActionCreator = (): SearchParamsActionTypes => {
    return {
        type: SearchParamsAction.RESET_SEARCH_PARAMS,
    };
};
