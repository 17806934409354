/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, {
    FunctionComponent,
    ReactElement,
    useEffect,
    useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Chip } from '@mui/material';

import { Work } from '../../types/works';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { withWorksContext, WorksContext } from '../controllers/works/WorksContext';
import { buildRoute } from '../../utils/misc';
import { AppRoute } from '../../constants/routes';
import IconCircleWork from '../assets/IconCircleWork';

interface OwnProps extends TranslationContext, WorksContext {
    works: Array<Work>;
    isClickable?: boolean;
    onWorkSelected?(data: Work): void;
}

enum WorkGeneralInformationParam {
    Country = 'country',
    Director = 'director',
    Year = 'year',
    Series = 'series'
}

const WorksList: FunctionComponent<OwnProps> = (props): ReactElement | null => {
    const {
        t, works, isClickable, onWorkSelected,
    } = props;

    const navigate = useNavigate();
    const listRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        listRef.current?.scrollIntoView(true);
        window.scrollTo(0, 0);
    }, [works]);

    const workDescriptionToRender = (typeToRender: string, work: Work) => {
        if (work) {
            if (typeToRender === WorkGeneralInformationParam.Country) {
                if (work.countries && work.countries.length > 0) {
                    return (
                        <React.Fragment>
                            {work.countries.map((country) => country.country_ISO_code).join(', ')}
                        </React.Fragment>
                    );
                }
            }
            if (typeToRender === WorkGeneralInformationParam.Director) {
                if (work.directors && work.directors.length > 0) {
                    return <><span> - {t('works.work.directedBy')}</span> {work.directors.map((director) => director.name).join(', ')}</>;
                }
            }
            if (typeToRender === WorkGeneralInformationParam.Year) {
                if (work.dates && work.dates.length > 0) {
                    let yearText = 'works.work.year';

                    if (work.dates.length === 1) {
                        if (work.dates[0].year_from && work.dates[0].year_until) {
                            yearText = 'works.work.years';
                        }
                    } else {
                        yearText = 'works.work.years';
                    }

                    return <><span> - {t(yearText)}</span> {work.dates.map((date) => date.date_string).join(', ')}</>;
                }
            }
            if (typeToRender === WorkGeneralInformationParam.Series) {
                if (work.series && work.series.length > 0) {
                    return <><span> - {t('works.work.series')}</span> {work.series.map((serie) => serie.title).join(', ')}</>;
                }
            }
        }

        return null;
    };

    const onSelectClick = (data: Work) => {
        if (data && onWorkSelected) onWorkSelected(data);
    };

    return (
        <div ref={listRef}>
            {works?.map((work) => {
                return (
                    <div
                        data-testid="work-card"
                        className={`card ${isClickable ? '' : 'no-pointer'}`}
                        key={work.fiaf_work_id || work.metadata_id}
                        onClick={isClickable ? () => navigate(buildRoute(AppRoute.WorkDetails, { id: work.fiaf_work_id })) : undefined}
                    >
                        <IconCircleWork />
                        <div className="card__description">
                            <div className="card__description__title">
                                {work.fiaf_work_id && <Chip label={work.fiaf_work_id} size="small" />}
                                <h3 className="card__description-container__movie-title">{work.preferred_title}</h3>
                            </div>
                            <p className="card__description__information">
                                {workDescriptionToRender(WorkGeneralInformationParam.Country, work)}
                                {workDescriptionToRender(WorkGeneralInformationParam.Director, work)}
                                {workDescriptionToRender(WorkGeneralInformationParam.Year, work)}
                                {workDescriptionToRender(WorkGeneralInformationParam.Series, work)}
                            </p>
                        </div>
                        {!isClickable && (
                            <div className="card__select-button-wrapper">
                                <Button data-testid="work-card-select-button" onClick={() => onSelectClick(work)}>{t('general.select')}</Button>
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

WorksList.defaultProps = {
    isClickable: true,
    onWorkSelected: () => {},
};

export default withTranslationContext(withWorksContext(WorksList));
