/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';
import {
    Accordion, AccordionDetails, AccordionSummary, IconButton,
} from '@mui/material';
import { ArrowDropDown, MoreHoriz } from '@mui/icons-material';

import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { Item } from '../../../types/items';
import { ArchiveWork } from '../../../types/archive_works';
import Can from '../../containers/Can';

interface OwnProps extends TranslationContext {
    archiveWork: ArchiveWork;
    archiveWorkItems: Array<Item>;
    expanded: Set<string>;
    importId?: number;
    onExpanded(id: string, isExpanded: boolean): void;
    onSelectItem(id: number): void;
}

const WorkArchivesListItem: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t, archiveWork, expanded, onExpanded, archiveWorkItems, onSelectItem, importId,
    } = props;

    return (
        <Accordion
            data-testid="work-archive"
            key={archiveWork.archive_code}
            expanded={expanded.has(archiveWork.archive_code)}
            onChange={(e, isExpanded) => onExpanded(archiveWork.archive_code, isExpanded)}
            disableGutters
        >
            <AccordionSummary
                aria-controls={`${archiveWork.archive_code}-content`}
                id={`${archiveWork.archive_code}-header`}
                expandIcon={<ArrowDropDown color="primary" />}
            >
                <div className="accordion-header">
                    <div className="accordion-header__title">
                        {archiveWork.archive.name}
                    </div>
                    <div className="accordion-header__count">
                        {t(`work.archives.${(archiveWorkItems.length === 1) ? 'itemCount' : 'itemsCount'}`, { count: `${archiveWorkItems.length}` })}
                    </div>
                    <Can
                        can={false}
                        yes={() => (
                            <div className="accordion-header__actions">
                                <IconButton
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <MoreHoriz fontSize="small" color="secondary" />
                                </IconButton>
                            </div>
                        )}
                    />
                </div>
            </AccordionSummary>
            <AccordionDetails>
                {(archiveWorkItems.length > 0) ? (
                    <table className="item-list">
                        <thead className="item-list__header">
                            <tr>
                                <th className="media">{t('work.archives.items.table.specificMediaType')}</th>
                                <th className="media">{t('work.archives.items.table.carrier')}</th>
                                <th className="media">{t('work.archives.items.table.element')}</th>
                                <th className="access">{t('work.archives.items.table.access')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {archiveWorkItems.map((item: Item) => {
                                let extraClass = '';
                                if (importId) {
                                    if (item.created_by_import_operation === importId) extraClass = 'bold';
                                    else extraClass = 'light-row';
                                }
                                return (
                                    <tr
                                        data-testid="work-item"
                                        key={item.fiaf_item_id}
                                        className={`item-list__item ${extraClass}`}
                                        onClick={() => onSelectItem(item.fiaf_item_id)}
                                    >
                                        <td className="media">{item.specific_media_type}</td>
                                        <td className="media">{item.carrier}</td>
                                        <td className="media">{item.element}</td>
                                        <td className="access"><div className={item.accessible ? 'on' : ''} /></td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                ) : <div className="no-results">{t('work.archives.items.noResults')}</div>}
            </AccordionDetails>
        </Accordion>
    );
};

WorkArchivesListItem.defaultProps = {
    importId: undefined,
};

export default withTranslationContext(WorkArchivesListItem);
