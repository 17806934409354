/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import {
    IconButton, Menu, MenuItem, MenuList,
} from '@mui/material';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';

import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { AppRoute } from '../../constants/routes';
import ItemFormDialog from './item/ItemFormDialog';
import ImportModal from './ImportModal';
import { ReviewsContext, withReviewsContext } from '../controllers/reviews/ReviewsContext';
import { Permission } from '../../types/authorization';
import Can from '../containers/Can';

interface OwnProps extends TranslationContext, ReviewsContext {}

const UserMenu: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t, getRemainingReviews,
    } = props;

    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [showCreateItem, setShowCreateItem] = useState<boolean>(false);
    const [showImportModal, setShowImportModal] = useState<boolean>(false);
    const [totalRemainingReviews, setTotalRemainingReviews] = useState(0);

    const open = Boolean(anchorEl);

    useEffect(() => {
        getRemainingReviewsNumber();
    }, []);

    const getRemainingReviewsNumber = async () => {
        const data = await getRemainingReviews();

        if (data) {
            setTotalRemainingReviews(data.totalReviewsRequired);
        }
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        getRemainingReviewsNumber();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleItemClick = (route: string) => {
        handleClose();
        navigate(route);
    };

    return (
        <>
            {showCreateItem && <ItemFormDialog onClose={() => setShowCreateItem(false)} />}
            {showImportModal && <ImportModal onClose={() => setShowImportModal(false)} />}
            <IconButton
                data-testid="userMenu-button"
                aria-label="menu"
                size="medium"
                onClick={handleClick}
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
            >
                <MenuIcon fontSize="inherit" />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className="user-menu"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                elevation={1}
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
            >
                <MenuList dense>
                    <Can
                        actions={[Permission.CREATE_WORK]}
                        yes={() => (
                            <MenuItem data-testid="new-work-option" onClick={() => handleItemClick(AppRoute.WorkNew)}>
                                {t('userMenu.newWork')}
                            </MenuItem>
                        )}
                    />
                    <Can
                        actions={[Permission.CREATE_ITEM]}
                        yes={() => (
                            <MenuItem
                                data-testid="new-item-option"
                                onClick={() => {
                                    handleClose();
                                    setShowCreateItem(true);
                                }}
                            >
                                {t('userMenu.newItem')}
                            </MenuItem>
                        )}
                    />
                    <Can
                        actions={[Permission.USE_IMPORT]}
                        yes={() => (
                            <MenuItem
                                data-testid="import-option"
                                onClick={() => {
                                    handleClose();
                                    setShowImportModal(true);
                                }}
                            >
                                {t('userMenu.import')}
                            </MenuItem>
                        )}
                    />
                    <Can
                        actions={[Permission.LIST_USERS]}
                        yes={() => (
                            <MenuItem data-testid="users-option" onClick={() => handleItemClick(AppRoute.Users)}>
                                {t('userMenu.users')}
                            </MenuItem>
                        )}
                    />
                    <MenuItem data-testid="agents-option" onClick={() => handleItemClick(AppRoute.Agents)}>
                        {t('userMenu.agents')}
                    </MenuItem>
                    <MenuItem data-testid="archives-option" onClick={() => handleItemClick(AppRoute.Archives)}>
                        {t('userMenu.archives')}
                    </MenuItem>
                    <Can
                        actions={[Permission.LIST_VOCABULARY]}
                        yes={() => (
                            <MenuItem
                                data-testid="vocabulary-option"
                                onClick={() => handleItemClick(AppRoute.Vocabulary)}
                            >
                                {t('userMenu.vocabulary')}
                            </MenuItem>
                        )}
                    />
                    <Can
                        actions={[Permission.LIST_DATA_VALIDATION]}
                        yes={() => (
                            <MenuItem data-testid="data-option" onClick={() => handleItemClick(AppRoute.Data)}>
                                {t('userMenu.dataValidation')}
                                {(totalRemainingReviews > 0) && (
                                    <span className="new-data">{`(${totalRemainingReviews} ${t('userMenu.new')})`}</span>
                                )}
                            </MenuItem>
                        )}
                    />
                </MenuList>
            </Menu>
        </>
    );
};

export default withTranslationContext((withReviewsContext(UserMenu)));
