/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, ReactNode } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
 
import { SearchParamsContextProvider } from './SearchParamsContext';
import {
    resetSearchParamsActionCreator,
    setSimpleSearchParamsActionCreator,
    setCurrentPageActionCreator,
    setIsAdvancedSearchActionCreator,
    setAdvancedSearchParamsActionCreator,
    setSimpleSearchInputDisplayActionCreator,
} from '../../../actions/searchParams';
import { AdvancedSearchParams } from '../../../types/search';
import { AppState } from '../../../types/store';
import { EmptyObject } from '../../../types/misc';

interface StateProps {
    simpleSearchParams: string;
    advancedSearchParams: AdvancedSearchParams | null;
    isAdvancedSearch: boolean;
    currentPage: number;
    simpleSearchInputDisplay: string;
}

interface OwnProps {
    children: ReactNode;
}

interface DispatchProps {
    dispatchSetSimpleSearchParams:(simpleSearchParams: string) => void;
    dispatchSetAdvancedSearchParams: (advancedSearchParams: AdvancedSearchParams | null) => void;
    dispatchSetIsAdvancedSearch: (isAdvancedSearch: boolean) => void;
    dispatchSetCurrentPage: (currentPage: number) => void;
    dispatchSetSimpleSearchInputDisplay: (value: string) => void;
    dispatchResetSearchParams: () => void;
}

type Props = StateProps & OwnProps & DispatchProps;
 
export class SearchParamsController extends Component<Props> {
    setSimpleSearchParams = (simpleSearchParams: string): void => {
        const { dispatchSetSimpleSearchParams } = this.props;
        dispatchSetSimpleSearchParams(simpleSearchParams);
    };

    setAdvancedSearchParams = (advancedSearchParams: AdvancedSearchParams): void => {
        const { dispatchSetAdvancedSearchParams } = this.props;
        dispatchSetAdvancedSearchParams(advancedSearchParams);
    };

    setIsAdvancedSearch = (isAdvancedSearch: boolean): void => {
        const { dispatchSetIsAdvancedSearch } = this.props;
        dispatchSetIsAdvancedSearch(isAdvancedSearch);
    };

    setCurrentPage = (currentPage: number): void => {
        const { dispatchSetCurrentPage } = this.props;
        dispatchSetCurrentPage(currentPage);
    };

    setSimpleSearchInputDisplay = (value: string): void => {
        const { dispatchSetSimpleSearchInputDisplay } = this.props;
        dispatchSetSimpleSearchInputDisplay(value);
    };

    resetSearchParams = (): void => {
        const { dispatchResetSearchParams } = this.props;
        dispatchResetSearchParams();
    };

    render(): ReactNode {
        const {
            children,
            simpleSearchParams,
            advancedSearchParams,
            isAdvancedSearch,
            currentPage,
            simpleSearchInputDisplay,
        } = this.props;

        return (
            <SearchParamsContextProvider value={{
                simpleSearchParams,
                advancedSearchParams,
                isAdvancedSearch,
                currentPage,
                simpleSearchInputDisplay,
                setSimpleSearchParams: this.setSimpleSearchParams,
                setAdvancedSearchParams: this.setAdvancedSearchParams,
                setIsAdvancedSearch: this.setIsAdvancedSearch,
                setCurrentPage: this.setCurrentPage,
                setSimpleSearchInputDisplay: this.setSimpleSearchInputDisplay,
                resetSearchParams: this.resetSearchParams,
            }}
            >
                {children}
            </SearchParamsContextProvider>
        );
    }
}
 
const mapStateToProps = (state: AppState): StateProps => {
    return {
        simpleSearchParams: state.search.simpleSearchParams,
        advancedSearchParams: state.search.advancedSearchParams,
        isAdvancedSearch: state.search.isAdvancedSearch,
        currentPage: state.search.currentPage,
        simpleSearchInputDisplay: state.search.simpleSearchInputDisplay,
    };
};

export const mapDispatchToProps = (dispatch: ThunkDispatch<EmptyObject, EmptyObject, AnyAction>): DispatchProps => ({
    dispatchSetSimpleSearchParams: (simpleSearchParams: string) => dispatch(setSimpleSearchParamsActionCreator(simpleSearchParams)),
    dispatchSetAdvancedSearchParams: (advancedSearchParams: AdvancedSearchParams | null) => dispatch(setAdvancedSearchParamsActionCreator(advancedSearchParams)),
    dispatchSetIsAdvancedSearch: (isAdvancedSearch: boolean) => dispatch(setIsAdvancedSearchActionCreator(isAdvancedSearch)),
    dispatchSetCurrentPage: (currentPage: number) => dispatch(setCurrentPageActionCreator(currentPage)),
    dispatchSetSimpleSearchInputDisplay: (value: string) => dispatch(setSimpleSearchInputDisplayActionCreator(value)),
    dispatchResetSearchParams: () => dispatch(resetSearchParamsActionCreator()),
});

export const ConnectedSearchParamsController = connect(mapStateToProps, mapDispatchToProps)(SearchParamsController);
