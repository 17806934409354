/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { useEffect, useState, useRef } from 'react';
import {
    Button,
    Checkbox,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    TextField,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { isNull, omitBy } from 'lodash';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';

import { AccessibleType, WorkIdType } from '../../types/works';
import { withWorksContext, WorksContext } from '../controllers/works/WorksContext';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { AdvancedSearchOptions, AdvancedSearchParamName, AdvancedSearchParams } from '../../types/search';
import { ItemIdType } from '../../types/items';
import MuiDatePicker from './MuiDatePicker';
import { AuthorizationContext, withAuthorizationContext } from '../controllers/authorization/AuthorizationContext';
import Can from '../containers/Can';
import { Permission } from '../../types/authorization';
import { MIN_WORK_YEAR } from '../../constants/misc';

interface OwnProps extends TranslationContext, WorksContext, AuthorizationContext {
    onClose(): void;
    updateSearchParamsWithAdvancedParams(data: AdvancedSearchParams): void;
    handleSearch(advancedParam?: AdvancedSearchParams): void;
    initialParams: AdvancedSearchParams | null;
    selectOptions: AdvancedSearchOptions;
}

const AdvancedSearch: React.FunctionComponent<OwnProps> = (props): React.ReactElement | null => {
    const {
        t,
        onClose,
        handleSearch,
        initialParams,
        selectOptions,
        updateSearchParamsWithAdvancedParams,
    } = props;

    const { enqueueSnackbar } = useSnackbar();

    const searchDefaultParams: AdvancedSearchParams = {
        title: '',
        min_year: null,
        max_year: null,
        series: '',
        sound_production: '',
        fiaf_work_id: null,
        countries: [],
        local_work_id: '',
        archive_name: [],
        archive_cities: [],
        archive_codes: [],
        archive_item_reference: '',
        archive_item_db_id: '',
        accessible: '',
        specific_media_types: [],
        carriers: [],
        elements: [],
        languages: [],
        general_color_characteristics: [],
        bases: [],
        general_media_types: [],
        agent_name: '',
        agent_roles: [],
        page: null,
        created_by_users: [],
        created_by_min_date: '',
        created_by_max_date: '',
        edited_by_users: [],
        edited_by_min_date: '',
        edited_by_max_date: '',
        third_party_name: '',
        fiaf_item_id: '',
        third_party_id_value: '',
    };

    const [advancedSearchParams, setAdvancedSearchParams] = useState<AdvancedSearchParams>(searchDefaultParams);
    const [workIdSearchType, setWorkIdSearchType] = useState<string>('');
    const [workIdSearchValue, setWorkIdSearchValue] = useState<string>('');
    const [itemIdSearchType, setItemIdSearchType] = useState<string>('');
    const [itemIdSearchValue, setItemIdSearchValue] = useState<string>('');
    const advancedSearchRef = useRef<HTMLHeadingElement>(null);

    const currentYear = (new Date()).getFullYear();

    const handleMultipleSelectChange = (event: SelectChangeEvent<string[] | string>) => {
        const {
            target: { value, name },
        } = event;

        setAdvancedSearchParams({
            ...advancedSearchParams,
            [name]: typeof value === 'string' ? value.split(',') : value,
        });
    };

    const onWorkIdSelect = (event: SelectChangeEvent) => {
        const {
            target: { value },
        } = event;
        setWorkIdSearchType(value);
        setWorkIdSearchValue('');
    };

    const onWorkTypeId = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {
            target: { value },
        } = event;

        setWorkIdSearchValue(value);

        if (workIdSearchType === WorkIdType.FiafWorkId) {
            setAdvancedSearchParams({
                ...advancedSearchParams,
                fiaf_work_id: value,
                local_work_id: '',
                third_party_name: '',
                third_party_id_value: '',
            });
        } else if (workIdSearchType === WorkIdType.LocalWorkId) {
            setAdvancedSearchParams({
                ...advancedSearchParams,
                fiaf_work_id: '',
                local_work_id: value,
                third_party_name: '',
                third_party_id_value: '',
            });
        } else {
            setAdvancedSearchParams({
                ...advancedSearchParams,
                fiaf_work_id: '',
                local_work_id: '',
                third_party_name: workIdSearchType,
                third_party_id_value: value,
            });
        }
    };

    const onItemIdSelect = (event: SelectChangeEvent) => {
        const {
            target: { value },
        } = event;
        setItemIdSearchType(value);
        setItemIdSearchValue('');
    };

    const onItemTypeId = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {
            target: { value },
        } = event;

        setItemIdSearchValue(value);

        if (itemIdSearchType === ItemIdType.FiafItemId) {
            setAdvancedSearchParams({
                ...advancedSearchParams,
                fiaf_item_id: value,
                archive_item_reference: '',
                archive_item_db_id: '',
            });
        } else if (itemIdSearchType === ItemIdType.ArchiveItemReference) {
            setAdvancedSearchParams({
                ...advancedSearchParams,
                fiaf_item_id: '',
                archive_item_reference: value,
                archive_item_db_id: '',
            });
        } else {
            setAdvancedSearchParams({
                ...advancedSearchParams,
                fiaf_item_id: '',
                archive_item_reference: '',
                archive_item_db_id: value,
            });
        }
    };

    const handleSingleSelect = (event: SelectChangeEvent) => {
        const {
            target: { value, name },
        } = event;

        setAdvancedSearchParams({
            ...advancedSearchParams,
            [name]: value,
        });
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {
            target: { value, name },
        } = event;

        setAdvancedSearchParams({
            ...advancedSearchParams,
            [name]: value,
        });
    };

    const onDatePicker = (name: string, value: string | null) => {
        if (value && value?.toString() !== t('errors.invalidDate')) {
            setAdvancedSearchParams({
                ...advancedSearchParams,
                [name]: format(new Date(value), 'yyyy-MM-dd'),
            });
        }
    };

    const handleOnSubmit = async () => {
        if (advancedSearchParams.min_year && advancedSearchParams.max_year) {
            if (advancedSearchParams.min_year > advancedSearchParams.max_year) {
                enqueueSnackbar(t('advancedSearch.fromSmallerToYear'), { variant: 'error' });
                return;
            }
        }
        if (advancedSearchParams.min_year && advancedSearchParams.min_year < MIN_WORK_YEAR) {
            enqueueSnackbar(t('advancedSearch.minimumYearToSearch'), { variant: 'error' });
            return;
        }
        if (advancedSearchParams.max_year && advancedSearchParams.max_year < MIN_WORK_YEAR) {
            enqueueSnackbar(t('advancedSearch.minimumYearToSearch'), { variant: 'error' });
            return;
        }

        const filteredParams: AdvancedSearchParams = omitBy(advancedSearchParams, (v) => isNull(v) || v === '');
        onClose();
        handleSearch(filteredParams);
        updateSearchParamsWithAdvancedParams(filteredParams);
    };

    useEffect(() => {
        if (initialParams) {
            setAdvancedSearchParams({ ...searchDefaultParams, ...initialParams });
            if (initialParams.fiaf_work_id) {
                setWorkIdSearchType(WorkIdType.FiafWorkId);
                setWorkIdSearchValue(initialParams.fiaf_work_id);
            } else if (initialParams.local_work_id) {
                setWorkIdSearchType(WorkIdType.LocalWorkId);
                setWorkIdSearchValue(initialParams.local_work_id);
            } else if (initialParams.third_party_id_value) {
                setWorkIdSearchType(initialParams.third_party_id_value);
                setWorkIdSearchValue(initialParams.third_party_id_value);
            }

            if (initialParams.fiaf_item_id) {
                setItemIdSearchType(ItemIdType.FiafItemId);
                setItemIdSearchValue(initialParams.fiaf_item_id);
            } else if (initialParams.archive_item_reference) {
                setItemIdSearchType(ItemIdType.ArchiveItemReference);
                setItemIdSearchValue(initialParams.archive_item_reference);
            } else if (initialParams.archive_item_db_id) {
                setItemIdSearchType(ItemIdType.ArchiveItemDBId);
                setItemIdSearchValue(initialParams.archive_item_db_id);
            }
        }
    }, []);

    const enterKeyListener = (event: KeyboardEvent) => {
        if (event.code === 'Enter' || event.code === 'NumpadEnter') {
            handleOnSubmit();
        }
    };

    useEffect(() => {
        const element = advancedSearchRef.current;

        element?.addEventListener('keydown', enterKeyListener);
        return () => {
            element?.removeEventListener('keydown', enterKeyListener);
        };
    }, [advancedSearchRef, advancedSearchParams]);
    
    const countryList = selectOptions?.countries?.data ? [...selectOptions?.countries?.data].sort((a, b) => a.name.localeCompare(b.name)) : [];
    const archiveList = selectOptions?.archives?.data ? [...selectOptions?.archives?.data].sort((a, b) => a.name.localeCompare(b.name)) : [];

    return (
        <div ref={advancedSearchRef} className="advanced-search" data-testid="div-advanced-search">
            <div className="advanced-search__top">
                <h2>{t('advancedSearch.title')}</h2>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                >
                    <Close color="primary" />
                </IconButton>
            </div>
            <div className="advanced-search__form main-form">
                <h3>{t('advancedSearch.work')}</h3>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel>{t('advancedSearch.titleLabel')}</InputLabel>
                            <TextField
                                fullWidth
                                inputProps={{ 'data-testid': 'advanced-search-title-input' }}
                                value={advancedSearchParams.title || ''}
                                name={AdvancedSearchParamName.Title}
                                onChange={handleInputChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel>{t('advancedSearch.yearLabel')}</InputLabel>
                            <TextField
                                fullWidth
                                value={advancedSearchParams.min_year || ''}
                                name={AdvancedSearchParamName.MinYear}
                                type="number"
                                inputProps={{
                                    min: MIN_WORK_YEAR,
                                    max: currentYear,
                                    'data-testid': 'advanced-search-min-year-input',
                                }}
                                onChange={handleInputChange}
                                InputProps={{
                                    startAdornment: t('advancedSearch.from'),
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel>&nbsp;</InputLabel>
                            <TextField
                                fullWidth
                                value={advancedSearchParams.max_year || ''}
                                name={AdvancedSearchParamName.MaxYear}
                                type="number"
                                inputProps={{
                                    min: MIN_WORK_YEAR,
                                    max: currentYear,
                                    'data-testid': 'advanced-search-max-year-input',
                                }}
                                onChange={handleInputChange}
                                InputProps={{
                                    startAdornment: t('advancedSearch.to'),
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel>{t('advancedSearch.seriesLabel')}</InputLabel>
                            <TextField
                                fullWidth
                                value={advancedSearchParams.series || ''}
                                name={AdvancedSearchParamName.Series}
                                inputProps={{ 'data-testid': 'advanced-search-series-input' }}
                                onChange={handleInputChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel>{t('advancedSearch.audioLabel')}</InputLabel>
                            <Select
                                fullWidth
                                MenuProps={{ classes: { paper: 'select' } }}
                                value={advancedSearchParams.sound_production || ''}
                                name={AdvancedSearchParamName.SoundProduction}
                                data-testid="advanced-search-audio-input"
                                onChange={handleSingleSelect}
                            >
                                <MenuItem dense value="">
                                    <em>{t('advancedSearch.none')}</em>
                                </MenuItem>
                                {selectOptions.soundProductions?.data.map((sound) => (
                                    <MenuItem dense key={sound.name} value={sound.name}>{sound.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel>{t('advancedSearch.idTypeLabel')}</InputLabel>
                            <Select
                                fullWidth
                                MenuProps={{ classes: { paper: 'select' } }}
                                data-testid="advanced-search-work-id-type-input"
                                name={AdvancedSearchParamName.WorkIdType}
                                value={workIdSearchType}
                                onChange={onWorkIdSelect}
                            >
                                <MenuItem dense key={WorkIdType.FiafWorkId} value={WorkIdType.FiafWorkId}>
                                    {t('advancedSearch.fiafWorkId')}
                                </MenuItem>
                                <MenuItem dense key={WorkIdType.LocalWorkId} value={WorkIdType.LocalWorkId}>
                                    {t('advancedSearch.localWorkId')}
                                </MenuItem>
                                {selectOptions.thirdPartyNames?.data.map((sound) => (
                                    <MenuItem dense key={sound.name} value={sound.name}>{sound.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel>{t('advancedSearch.idLabel')}</InputLabel>
                            <TextField
                                fullWidth
                                inputProps={{ 'data-testid': 'advanced-search-work-id-value-input' }}
                                disabled={!workIdSearchType}
                                value={workIdSearchValue}
                                onChange={onWorkTypeId}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel>{t('advancedSearch.countryLabel')}</InputLabel>
                            <Select
                                fullWidth
                                multiple
                                displayEmpty
                                MenuProps={{ classes: { paper: 'select' } }}
                                value={advancedSearchParams.countries}
                                name={AdvancedSearchParamName.Countries}
                                data-testid="advanced-search-country-input"
                                onChange={handleMultipleSelectChange}
                                input={<OutlinedInput label="Tag" />}
                                renderValue={(selected) => selected.join(', ')}
                            >
                                {countryList.map((country) => (
                                    <MenuItem
                                        dense
                                        data-testid="advanced-search-country-input-item"
                                        key={country.name}
                                        value={country.ISO_code}
                                    >
                                        <Checkbox
                                            checked={advancedSearchParams.countries && advancedSearchParams.countries.includes(country.ISO_code)}
                                        />
                                        <ListItemText primary={country.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Can
                    actions={[Permission.SEARCH_BY_CREATION]}
                    yes={() => (
                        <>
                            <h3>{t('advancedSearch.workChangesByUsers')}</h3>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel>{t('advancedSearch.createdBy')}</InputLabel>
                                        <Select
                                            fullWidth
                                            multiple
                                            displayEmpty
                                            MenuProps={{ classes: { paper: 'select' } }}
                                            data-testid="advanced-search-created-by-users"
                                            name={AdvancedSearchParamName.CreatedByUsers}
                                            value={advancedSearchParams.created_by_users || []}
                                            onChange={handleMultipleSelectChange}
                                            input={<OutlinedInput label="Tag" />}
                                            renderValue={(selected) => selectOptions.users?.filter((u) => selected.includes(String(u.id))).map((u) => `${u.first_name} ${u.last_name}`).join(', ')
                                                || []
                                            }
                                        >
                                            {selectOptions.users?.map((user) => (
                                                <MenuItem
                                                    dense
                                                    data-testid="advanced-search-created-by-user-item"
                                                    key={user.id}
                                                    value={String(user.id)}
                                                >
                                                    <Checkbox
                                                        checked={advancedSearchParams.created_by_users && advancedSearchParams.created_by_users.includes(String(user.id))}
                                                    />
                                                    <ListItemText primary={`${user.first_name} ${user.last_name}`} />
                                                </MenuItem>
                                            )) || []}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth>
                                        <InputLabel>{t('advancedSearch.from')}</InputLabel>

                                        <MuiDatePicker
                                            name={AdvancedSearchParamName.CreatedByMinDate}
                                            value={advancedSearchParams.created_by_min_date || ''}
                                            onChange={(name, value) => onDatePicker(name, value)}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth>
                                        <InputLabel>{t('advancedSearch.to')}</InputLabel>

                                        <MuiDatePicker
                                            name={AdvancedSearchParamName.CreatedByMaxDate}
                                            value={advancedSearchParams.created_by_max_date || ''}
                                            onChange={(name, value) => onDatePicker(name, value)}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel>{t('advancedSearch.editedBy')}</InputLabel>
                                        <Select
                                            fullWidth
                                            multiple
                                            displayEmpty
                                            MenuProps={{ classes: { paper: 'select' } }}
                                            data-testid="advanced-search-edited-by-users"
                                            name={AdvancedSearchParamName.EditedByUsers}
                                            value={advancedSearchParams.edited_by_users || []}
                                            onChange={handleMultipleSelectChange}
                                            input={<OutlinedInput label="Tag" />}
                                            renderValue={(selected) => selectOptions.users?.filter((u) => selected.includes(String(u.id))).map((u) => `${u.first_name} ${u.last_name}`).join(', ')
                                                || []
                                            }
                                        >
                                            {selectOptions.users?.map((user) => (
                                                <MenuItem
                                                    dense
                                                    data-testid="advanced-search-edited-by-user-item"
                                                    key={user.id}
                                                    value={String(user.id)}
                                                >
                                                    <Checkbox
                                                        checked={advancedSearchParams.edited_by_users && advancedSearchParams.edited_by_users.includes(String(user.id))}
                                                    />
                                                    <ListItemText primary={`${user.first_name} ${user.last_name}`} />
                                                </MenuItem>
                                            )) || []}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth>
                                        <InputLabel>{t('advancedSearch.from')}</InputLabel>

                                        <MuiDatePicker
                                            name={AdvancedSearchParamName.EditedByMinDate}
                                            value={advancedSearchParams.edited_by_min_date || ''}
                                            onChange={(name, value) => onDatePicker(name, value)}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth>
                                        <InputLabel>{t('advancedSearch.to')}</InputLabel>

                                        <MuiDatePicker
                                            name={AdvancedSearchParamName.EditedByMaxDate}
                                            value={advancedSearchParams.edited_by_max_date || ''}
                                            onChange={(name, value) => onDatePicker(name, value)}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </>
                    )}
                />
                <h3>{t('advancedSearch.agent')}</h3>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel>{t('advancedSearch.nameAlternateNameLabel')}</InputLabel>
                            <TextField
                                fullWidth
                                inputProps={{ 'data-testid': 'advanced-search-name-input' }}
                                name={AdvancedSearchParamName.AgentName}
                                value={advancedSearchParams.agent_name || ''}
                                onChange={handleInputChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel>{t('advancedSearch.roleLabel')}</InputLabel>
                            <Select
                                fullWidth
                                multiple
                                MenuProps={{ classes: { paper: 'select' } }}
                                data-testid="advanced-search-agent-role-input"
                                name={AdvancedSearchParamName.AgentRoles}
                                value={advancedSearchParams.agent_roles}
                                onChange={handleMultipleSelectChange}
                                input={<OutlinedInput label="Tag" />}
                                renderValue={(selected) => selected.join(', ')}
                            >
                                {selectOptions.agentRoles?.data.map((role) => (
                                    <MenuItem
                                        dense
                                        data-testid="advanced-search-agent-role-input-item"
                                        key={role.name}
                                        value={role.name}
                                    >
                                        <Checkbox
                                            checked={advancedSearchParams.agent_roles && advancedSearchParams.agent_roles?.includes(role.name)}
                                        />
                                        <ListItemText primary={role.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <h3>{t('advancedSearch.archive')}</h3>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <FormControl fullWidth>
                            <InputLabel>{t('advancedSearch.nameLabel')}</InputLabel>
                            <Select
                                fullWidth
                                multiple
                                displayEmpty
                                MenuProps={{ classes: { paper: 'select' } }}
                                value={advancedSearchParams.archive_codes}
                                name={AdvancedSearchParamName.ArchiveCodes}
                                data-testid="advanced-search-archive-name-input"
                                onChange={handleMultipleSelectChange}
                                input={<OutlinedInput label="Tag" />}
                                renderValue={(selected) => selected.join(', ')}
                            >
                                {archiveList.map((archive) => (
                                    <MenuItem
                                        dense
                                        data-testid="advanced-search-archive-name-input-item"
                                        key={archive.name}
                                        value={archive.code}
                                    >
                                        <Checkbox
                                            checked={advancedSearchParams.archive_codes && advancedSearchParams.archive_codes.includes(archive.code)}
                                        />
                                        <ListItemText primary={archive.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <InputLabel>{t('advancedSearch.cityLabel')}</InputLabel>
                            <Select
                                fullWidth
                                multiple
                                MenuProps={{ classes: { paper: 'select' } }}
                                value={advancedSearchParams.archive_cities}
                                name={AdvancedSearchParamName.ArchiveCities}
                                data-testid="advanced-search-city-input"
                                onChange={handleMultipleSelectChange}
                                input={<OutlinedInput label="Tag" />}
                                renderValue={(selected) => selected.join(', ')}
                            >
                                {selectOptions.archiveCities?.map((archive) => (
                                    <MenuItem
                                        dense
                                        data-testid="advanced-search-city-input-item"
                                        key={archive}
                                        value={archive}
                                    >
                                        <Checkbox
                                            checked={advancedSearchParams.archive_cities && advancedSearchParams.archive_cities.includes(archive)}
                                        />
                                        <ListItemText primary={archive} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <h3>{t('advancedSearch.item')}</h3>
                <Grid container spacing={2}>
                    <Grid item xs={5}>
                        <FormControl fullWidth>
                            <InputLabel>{t('advancedSearch.idTypeLabel')}</InputLabel>
                            <Select
                                fullWidth
                                MenuProps={{ classes: { paper: 'select' } }}
                                data-testid="advanced-search-item-id-type-input"
                                value={itemIdSearchType}
                                name={AdvancedSearchParamName.ItemIdType}
                                onChange={onItemIdSelect}
                            >
                                <MenuItem dense key={ItemIdType.FiafItemId} value={ItemIdType.FiafItemId}>
                                    {t('advancedSearch.fiafItemId')}
                                </MenuItem>
                                <MenuItem
                                    dense
                                    key={ItemIdType.ArchiveItemReference}
                                    value={ItemIdType.ArchiveItemReference}
                                >
                                    {t('advancedSearch.archiveItemReference')}
                                </MenuItem>
                                <MenuItem dense key={ItemIdType.ArchiveItemDBId} value={ItemIdType.ArchiveItemDBId}>
                                    {t('advancedSearch.dbItemId')}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl fullWidth>
                            <InputLabel>{t('advancedSearch.idLabel')}</InputLabel>
                            <TextField
                                fullWidth
                                inputProps={{ 'data-testid': 'advanced-search-item-id-value-input' }}
                                disabled={!itemIdSearchType}
                                value={itemIdSearchValue}
                                onChange={onItemTypeId}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={5}>
                        <FormControl fullWidth>
                            <InputLabel>{t('advancedSearch.accessibilityLabel')}</InputLabel>
                            <Select
                                fullWidth
                                MenuProps={{ classes: { paper: 'select' } }}
                                value={advancedSearchParams.accessible}
                                name={AdvancedSearchParamName.Accessible}
                                data-testid="advanced-search-accessibility-input"
                                onChange={handleSingleSelect}
                            >
                                <MenuItem dense value="">
                                    <em>{t('advancedSearch.none')}</em>
                                </MenuItem>
                                <MenuItem
                                    dense
                                    data-testid="advanced-search-accessibility-input-item"
                                    value={AccessibleType.Accessible}
                                >
                                    {t('advancedSearch.accessible')}
                                </MenuItem>
                                <MenuItem dense value={AccessibleType.NonAccessible}>
                                    {t('advancedSearch.nonAccessible')}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <InputLabel>{t('advancedSearch.generalMediaTypeLabel')}</InputLabel>
                            <Select
                                fullWidth
                                multiple
                                MenuProps={{ classes: { paper: 'select' } }}
                                data-testid="advanced-search-general-media-type-input"
                                name={AdvancedSearchParamName.GeneralMediaTypes}
                                value={advancedSearchParams.general_media_types}
                                onChange={handleMultipleSelectChange}
                                input={<OutlinedInput label="Tag" />}
                                renderValue={(selected) => selected.join(', ')}
                            >
                                {selectOptions.generalMediaTypes?.data.map((type) => (
                                    <MenuItem
                                        dense
                                        data-testid="advanced-search-general-media-type-input-item"
                                        key={type.name}
                                        value={type.name}
                                    >
                                        <Checkbox
                                            checked={advancedSearchParams.general_media_types && advancedSearchParams.general_media_types?.includes(type.name)}
                                        />
                                        <ListItemText primary={type.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <InputLabel>{t('advancedSearch.specificMediaTypeLabel')}</InputLabel>
                            <Select
                                fullWidth
                                multiple
                                MenuProps={{ classes: { paper: 'select' } }}
                                data-testid="advanced-search-specific-media-type-input"
                                name={AdvancedSearchParamName.SpecificMediaTypes}
                                value={advancedSearchParams.specific_media_types}
                                onChange={handleMultipleSelectChange}
                                input={<OutlinedInput label="Tag" />}
                                renderValue={(selected) => selected.join(', ')}
                            >
                                {selectOptions.specificMediaTypes?.data.map((type) => (
                                    <MenuItem
                                        dense
                                        data-testid="advanced-search-specific-media-type-input-item"
                                        key={type.name}
                                        value={type.name}
                                    >
                                        <Checkbox
                                            checked={advancedSearchParams.specific_media_types && advancedSearchParams.specific_media_types?.includes(type.name)}
                                        />
                                        <ListItemText primary={type.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <InputLabel>{t('advancedSearch.carrierLabel')}</InputLabel>
                            <Select
                                fullWidth
                                multiple
                                MenuProps={{ classes: { paper: 'select' } }}
                                value={advancedSearchParams.carriers}
                                name={AdvancedSearchParamName.Carriers}
                                data-testid="advanced-search-carrier-input"
                                onChange={handleMultipleSelectChange}
                                input={<OutlinedInput label="Tag" />}
                                renderValue={(selected) => selected.join(', ')}
                            >
                                {selectOptions?.carriers?.data.map((carrier) => (
                                    <MenuItem
                                        dense
                                        data-testid="advanced-search-carrier-input-item"
                                        key={carrier.name}
                                        value={carrier.name}
                                    >
                                        <Checkbox
                                            checked={advancedSearchParams.carriers && advancedSearchParams.carriers?.includes(carrier.name)}
                                        />
                                        <ListItemText primary={carrier.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <InputLabel>{t('advancedSearch.elementLabel')}</InputLabel>
                            <Select
                                fullWidth
                                multiple
                                MenuProps={{ classes: { paper: 'select' } }}
                                value={advancedSearchParams.elements}
                                name={AdvancedSearchParamName.Elements}
                                data-testid="advanced-search-element-input"
                                onChange={handleMultipleSelectChange}
                                input={<OutlinedInput label="Tag" />}
                                renderValue={(selected) => selected.join(', ')}
                            >
                                {selectOptions.itemElements?.data.map((element) => (
                                    <MenuItem
                                        dense
                                        data-testid="advanced-search-element-input-item"
                                        key={element.name}
                                        value={element.name}
                                    >
                                        <Checkbox
                                            checked={advancedSearchParams.elements && advancedSearchParams.elements?.includes(element.name)}
                                        />
                                        <ListItemText primary={element.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <InputLabel>{t('advancedSearch.audioLabel')}</InputLabel>
                            <Select
                                fullWidth
                                MenuProps={{ classes: { paper: 'select' } }}
                                value={advancedSearchParams.sound_production}
                                name={AdvancedSearchParamName.SoundProduction}
                                data-testid="advanced-search-sound-input"
                                onChange={handleSingleSelect}
                            >
                                <MenuItem dense value="">
                                    <em>{t('advancedSearch.none')}</em>
                                </MenuItem>
                                {selectOptions.soundProductions?.data.map((sound) => (
                                    <MenuItem
                                        dense
                                        data-testid="advanced-search-sound-input-item"
                                        key={sound.name}
                                        value={sound.name}
                                    >
                                        {sound.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <InputLabel>{t('advancedSearch.languageLabel')}</InputLabel>
                            <Select
                                fullWidth
                                multiple
                                MenuProps={{ classes: { paper: 'select' } }}
                                value={advancedSearchParams.languages}
                                name={AdvancedSearchParamName.Languages}
                                data-testid="advanced-search-language-input"
                                onChange={handleMultipleSelectChange}
                                input={<OutlinedInput label="Tag" />}
                                renderValue={(selected) => selected.join(', ')}
                            >
                                {selectOptions?.languages?.data.map((language) => (
                                    <MenuItem
                                        dense
                                        data-testid="advanced-search-language-input-item"
                                        key={language.name}
                                        value={language.code}
                                    >
                                        <Checkbox
                                            checked={advancedSearchParams.languages && advancedSearchParams.languages?.includes(language.code)}
                                        />
                                        <ListItemText primary={language.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel>{t('advancedSearch.colorLabel')}</InputLabel>
                            <Select
                                fullWidth
                                multiple
                                MenuProps={{ classes: { paper: 'select' } }}
                                value={advancedSearchParams.general_color_characteristics}
                                name={AdvancedSearchParamName.GeneralColorCharacteristics}
                                data-testid="advanced-search-color-input"
                                onChange={handleMultipleSelectChange}
                                input={<OutlinedInput label="Tag" />}
                                renderValue={(selected) => selected.join(', ')}
                            >
                                {selectOptions.generalColorCharacteristics?.data.map((color) => (
                                    <MenuItem
                                        dense
                                        data-testid="advanced-search-color-input-item"
                                        key={color.name}
                                        value={color.name}
                                    >
                                        <Checkbox
                                            checked={advancedSearchParams.general_color_characteristics && advancedSearchParams.general_color_characteristics?.includes(color.name)}
                                        />
                                        <ListItemText primary={color.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel>{t('advancedSearch.baseLabel')}</InputLabel>
                            <Select
                                fullWidth
                                multiple
                                MenuProps={{ classes: { paper: 'select' } }}
                                value={advancedSearchParams.bases}
                                name={AdvancedSearchParamName.Bases}
                                data-testid="advanced-search-base-input"
                                onChange={handleMultipleSelectChange}
                                input={<OutlinedInput label="Tag" />}
                                renderValue={(selected) => selected.join(', ')}
                            >
                                {selectOptions.itemBases?.data.map((base) => (
                                    <MenuItem
                                        dense
                                        data-testid="advanced-search-base-input-item"
                                        key={base.name}
                                        value={base.name}
                                    >
                                        <Checkbox
                                            checked={advancedSearchParams.bases && advancedSearchParams.bases?.includes(base.name)}
                                        />
                                        <ListItemText primary={base.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </div>
            <div className="advanced-search__bottom">
                <Button
                    data-testid="advanced-search-submit-button"
                    onClick={handleOnSubmit}
                >
                    {t('advancedSearch.search')}
                </Button>
            </div>
        </div>
    );
};

export default withTranslationContext(
    withAuthorizationContext(
        withWorksContext(
            AdvancedSearch,
        ),
    ),
);
