/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { AppLanguage } from '../types/preferences';

export enum PreferencesAction {
    LanguageSet = 'LANGUAGE_SET'
}

export interface LanguageSetActionType {
    type: typeof PreferencesAction.LanguageSet;
    payload: AppLanguage;
}

export type PreferencesActionTypes = LanguageSetActionType
