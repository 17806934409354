/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';

const IconCircleWork: FunctionComponent = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
            <g transform="translate(6771 -16951)">
                <rect width="60" height="60" rx="30" transform="translate(-6771 16951)" fill="#231f20" className="icon-bg" />
                <path d="M-6730.5 16990h-2.578a12.257 12.257 0 0 0 2.988-4.019 11.631 11.631 0 0 0 1.09-4.981 11.772 11.772 0 0 0-.949-4.664 11.742 11.742 0 0 0-6.387-6.387 11.932 11.932 0 0 0-9.328 0 11.742 11.742 0 0 0-6.387 6.387 11.932 11.932 0 0 0 0 9.328 11.742 11.742 0 0 0 6.387 6.387 11.772 11.772 0 0 0 4.664.949h10.5a1.521 1.521 0 0 0 1.5-1.512 1.416 1.416 0 0 0-.445-1.055 1.461 1.461 0 0 0-1.055-.433Zm-18-6a2.892 2.892 0 0 1-2.121-.879 3 3 0 0 1 0-4.243 2.889 2.889 0 0 1 2.121-.878 2.889 2.889 0 0 1 2.121.879 3 3 0 0 1 0 4.243 2.892 2.892 0 0 1-2.121.878Zm7.5 7.5a3 3 0 0 1-2.121-5.121 3 3 0 0 1 4.243 0 3 3 0 0 1-2.122 5.121Zm-1.5-10.5a1.451 1.451 0 0 1 .433-1.067 1.432 1.432 0 0 1 1.055-.433 1.479 1.479 0 0 1 1.067.433 1.5 1.5 0 0 1 0 2.133 1.479 1.479 0 0 1-1.067.433 1.432 1.432 0 0 1-1.055-.433 1.451 1.451 0 0 1-.433-1.066Zm1.5-4.5a2.892 2.892 0 0 1-2.121-.879 2.887 2.887 0 0 1-.879-2.121 2.894 2.894 0 0 1 .879-2.121 3 3 0 0 1 4.243 0 2.894 2.894 0 0 1 .878 2.121 2.887 2.887 0 0 1-.879 2.121 2.892 2.892 0 0 1-2.121.879Zm4.5 4.5a3 3 0 0 1 5.121-2.121 3 3 0 0 1 0 4.243 3 3 0 0 1-5.121-2.122Z" fill="#707070" className="icon-icon" />
            </g>
        </svg>
    );
};

export default IconCircleWork;
