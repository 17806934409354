/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';

import { ConnectedControlledVocabulariesController } from './ControlledVocabulariesController';
import {
    ControlledVocabulary, CountryCV, LanguageCV, SimpleCV,
} from '../../../types/controlled_vocabularies';
import { ControlledVocabularyName } from '../../../services/controlled_vocabularies';
import { GenericFunction } from '../../../types/misc';

export interface ControlledVocabulariesContext {
    getCV<T>(cvName: ControlledVocabularyName): Promise<ControlledVocabulary<T> | undefined>;
    createCVValue(cvName: ControlledVocabularyName, data: SimpleCV | CountryCV | LanguageCV, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void>;
    deleteCVValue(cvName: ControlledVocabularyName, cvValue: string, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void>;
}

export const controlledVocabulariesDefaultValue = {
    getCV: async <T extends unknown>(): Promise<ControlledVocabulary<T> | undefined> => undefined,
    createCVValue: async (): Promise<void> => {},
    deleteCVValue: async (): Promise<void> => {},
};

const ControlledVocabulariesContextInstance = createContext<ControlledVocabulariesContext | null>(controlledVocabulariesDefaultValue);

export const ControlledVocabulariesContextProvider = ControlledVocabulariesContextInstance.Provider;
export const ControlledVocabulariesContextConsumer = ControlledVocabulariesContextInstance.Consumer;

export const withControlledVocabulariesContext = <P extends unknown>(Component: ComponentType<P>): FC<Omit<P, keyof ControlledVocabulariesContext>> => (props) => (
    <ConnectedControlledVocabulariesController>
        <ControlledVocabulariesContextConsumer>
            {(ctx) => <Component {...props as P} {...ctx} />}
        </ControlledVocabulariesContextConsumer>
    </ConnectedControlledVocabulariesController>
);
