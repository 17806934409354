/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';
import { Button, TableCell, TableRow } from '@mui/material';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import {
    ReviewAgentStatus,
    ReviewMetadataChangeDiffStatus,
    ReviewMetadataChangeItem,
    WorkReviewAgent,
} from '../../../types/reviews';
import ReviewActionButtons from './ReviewActionButtons';
import { getReviewValueCompareClassName, getReviewValueRefClassName } from '../../../utils/review';

interface OwnProps extends TranslationContext {
    agentChange: ReviewMetadataChangeItem<WorkReviewAgent>;
    onAccept(): void;
    onReject(): void;
    onOpenDialog(idx?: number): void;
}

const ReviewAgentsTableRow: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t, agentChange, onAccept, onReject, onOpenDialog,
    } = props;

    const {
        valueCompare, valueRef, reviewOperation, diffStatus,
    } = agentChange;

    if (diffStatus === ReviewMetadataChangeDiffStatus.Deleted) return null;

    const getReviewAgentName = (agent: Partial<WorkReviewAgent>) => {
        let name = `${agent.agent_first_name} ${agent.agent_last_name}`;
        if (agent.agent_name_inverted) name = `${agent.agent_last_name}, ${agent.agent_first_name}`;

        if (agent.agent_extra_name_info) {
            name += ` - ${agent.agent_extra_name_info}`;
        }

        return name.trim();
    };

    const renderFindMatch = () => {
        if (diffStatus === ReviewMetadataChangeDiffStatus.Unchanged) return null;

        return (
            <Button
                data-testid="agent-match-modal-btn"
                color="info"
                variant="contained"
                disableElevation
                className="btn-find-match"
                onClick={() => onOpenDialog()}
            >
                {t('workReview.findMatch')}
            </Button>
        );
    };

    const renderAgentName = (value: Partial<WorkReviewAgent>) => {
        return (
            <div className="review-agent-name">
                {getReviewAgentName(value)}
                {value.agent_status === ReviewAgentStatus.ReviewRequired && (
                    <div className="review-agent-name__tag">
                        {t('workReview.new')}
                    </div>
                )}
            </div>
        );
    };

    return (
        <React.Fragment>
            {valueRef && (
                <TableRow className={getReviewValueRefClassName(diffStatus, reviewOperation)}>
                    <TableCell>
                        {renderFindMatch()}
                    </TableCell>
                    <TableCell>{renderAgentName(valueRef)}</TableCell>
                    <TableCell>{valueRef.agent_role}</TableCell>
                    <TableCell align="right" data-testid="agents-cell">
                        <ReviewActionButtons
                            reviewOperation={reviewOperation}
                            changeDiffStatus={diffStatus}
                            onAccept={onAccept}
                            onReject={onReject}
                        />
                    </TableCell>
                </TableRow>
            )}
            {diffStatus !== ReviewMetadataChangeDiffStatus.Unchanged && valueCompare && (
                <TableRow className={getReviewValueCompareClassName(diffStatus, reviewOperation)}>
                    <TableCell>
                        {!valueRef && renderFindMatch()}
                    </TableCell>
                    <TableCell>{renderAgentName(valueCompare)}</TableCell>
                    <TableCell>{valueCompare.agent_role}</TableCell>
                    <TableCell align="right" data-testid="agents-cell">
                        {!valueRef && (
                            <ReviewActionButtons
                                reviewOperation={reviewOperation}
                                changeDiffStatus={diffStatus}
                                onAccept={onAccept}
                                onReject={onReject}
                            />
                        )}
                    </TableCell>
                </TableRow>
            )}
        </React.Fragment>
    );
};

export default withTranslationContext(ReviewAgentsTableRow);
