/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';
import { TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
 
interface OwnProps {
    name: string;
    onChange(name: string, value: string | null): void;
    value: string;
}

const MuiDatePicker: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        name, value, onChange,
    } = props;

    return (
        <div className="datepicker">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                    value={value && value?.toString() !== 'Invalid Date' ? new Date(value.replace(/-/g, '/')).toISOString() : null}
                    inputFormat="yyyy/MM/dd"
                    components={{
                        OpenPickerIcon: CalendarTodayIcon,
                    }}
                    views={['year', 'month', 'day']}
                    disableFuture
                    onChange={(selectedDate) => onChange(name, selectedDate)}
                    renderInput={(params) => <TextField {...params} helperText={null} data-testid={`advanced-search-${name}-input`} />}
                />
            </LocalizationProvider>
        </div>
    );
};
 
export default MuiDatePicker;
