/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useEffect, useState } from 'react';
import {
    Container,
    IconButton,
    Pagination,
    TextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';

import { CancelRounded } from '@mui/icons-material';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { AgentsContext, withAgentsContext } from '../controllers/agents/AgentsContext';
import { ListResponseMeta, ListSearchParams } from '../../types/misc';
import { Agent } from '../../types/agents';
import Loader from '../elements/Loader';
import AgentFormDialog from '../elements/agent/AgentFormDialog';
import IconCircleAgent from '../assets/IconCircleAgent';
import { getAgentNameForAgentScreen } from '../../utils/agents';
import Can from '../containers/Can';
import { Permission } from '../../types/authorization';
import ListNoDataMessage from '../elements/ListNoDataMessage';

interface OwnProps extends TranslationContext, AgentsContext {}

type Props = OwnProps;

const AgentsScreen: FunctionComponent<Props> = (props: Props) => {
    const agentSearchDefaultParams: ListSearchParams = {
        search: '',
        page: -1,
    };

    const { t, getAgents } = props;
    const [agentsList, setAgentsList] = useState<Array<Agent>>([]);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [selectedAgent, setSelectedAgent] = useState<Agent | null>(null);
    const [paginationParams, setPaginationParams] = useState<ListResponseMeta | null>(null);
    const [searchParams, setSearchParams] = useState<ListSearchParams>(agentSearchDefaultParams);
    const [valueSearched, setValueSearched] = useState<string>('');
    const [showCreate, setShowCreate] = useState<boolean>(false);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target: { value } } = event;
        setSearchParams({ search: value });
    };

    const handleSearch = async () => {
        setIsFetching(true);

        const params = (searchParams.search === '') ? agentSearchDefaultParams : { ...searchParams, page: null };
        const search = (searchParams.search === '') ? undefined : searchParams;

        setSearchParams(params);
        const agentsData = await getAgents(search);
        if (agentsData) {
            setAgentsList(agentsData.data);
            setPaginationParams(agentsData.meta);
            setCurrentPage(1);
        }

        setValueSearched(searchParams.search);

        setIsFetching(false);
    };

    const handlePagination = async (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
    };

    const fetchOnPageChange = async () => {
        setIsFetching(true);

        const agentsData = await getAgents({ ...searchParams, page: currentPage });
        if (agentsData) {
            setAgentsList(agentsData.data);
            setPaginationParams(agentsData.meta);
        }

        setIsFetching(false);
    };

    const handleCloseDialog = (updateList = false) => {
        if (updateList) fetchOnPageChange();
        setShowCreate(false);
        setSelectedAgent(null);
    };

    const handleAgentSelected = (agent: Agent) => {
        setSelectedAgent(agent);
    };

    useEffect(() => {
        const prepare = async () => {
            const agentsData = await getAgents();

            if (agentsData) {
                setAgentsList(agentsData.data);
                setPaginationParams(agentsData.meta);
            }

            setIsFetching(false);
        };

        prepare();
    }, []);

    useEffect(() => {
        fetchOnPageChange();
    }, [currentPage]);

    return (
        <Container maxWidth="md">
            {showCreate && <AgentFormDialog onClose={handleCloseDialog} />}
            <div className="management-screen agents-screen">
                {isFetching && <Loader />}
                <div className="management-screen__top">
                    <div className="management-screen__top__left">
                        <div className="management-screen__top__left__image">
                            <IconCircleAgent />
                        </div>
                        <div className="management-screen__top__left__title">
                            <h3>{t('agentsScreen.title')}</h3>
                        </div>
                    </div>
                    <div className="management-screen__top__right">
                        <div className="management-screen__top__right__search-bar-wrapper">
                            <div className="management-screen__top__right__search-bar-wrapper__search-bar">
                                <TextField
                                    fullWidth
                                    data-testid="agents-search-bar"
                                    variant="standard"
                                    placeholder={t('agentsScreen.searchPlaceholder')}
                                    value={searchParams.search}
                                    onChange={handleInputChange}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleSearch();
                                        }
                                    }}
                                    InputProps={{
                                        disableUnderline: true,
                                        startAdornment: <SearchIcon className="search-icon" />,
                                        endAdornment: (
                                            <>
                                                <IconButton data-testid="clear-search-icon" onClick={() => setSearchParams({ search: '' })}>
                                                    <CancelRounded />
                                                </IconButton>
                                                <IconButton
                                                    className="search-button"
                                                    onClick={handleSearch}
                                                >
                                                    <SearchIcon />
                                                </IconButton>
                                            </>
                                        ),
                                    }}
                                />
                            </div>
                        </div>
                        <Can
                            actions={[Permission.CREATE_AGENT]}
                            yes={() => (
                                <IconButton data-testid="new-agent-btn" className="add-button" onClick={() => setShowCreate(true)}>
                                    <AddIcon />
                                </IconButton>
                            )}
                        />
                    </div>
                </div>
                <div className="management-screen__bottom">
                    <div className="management-screen__bottom__list">
                        {agentsList.map((agent) => {
                            return (
                                <div
                                    data-testid="agents-list-item"
                                    className="management-screen__bottom__list__item"
                                    key={agent.id}
                                    onClick={() => handleAgentSelected(agent)}
                                >
                                    <div className="management-screen__bottom__list__item__image">
                                        <IconCircleAgent />
                                    </div>
                                    <div className="management-screen__bottom__list__item__description">
                                        <h3>{getAgentNameForAgentScreen(agent)}</h3>
                                        <p>{agent.agent_roles.map((ar) => ar.agent_role).join(', ')}</p>
                                    </div>
                                </div>
                            );
                        })}
                        {agentsList.length === 0 && (
                            <div className="management-screen__bottom__list__no-data">
                                <ListNoDataMessage searchValue={valueSearched} />
                            </div>
                        )}
                        {selectedAgent && <AgentFormDialog onClose={handleCloseDialog} agentId={selectedAgent.id} />}
                    </div>
                    <div className="management-screen__bottom__pagination">
                        {paginationParams && (
                            <Pagination
                                count={paginationParams.last_page}
                                page={paginationParams.current_page}
                                onChange={handlePagination}
                            />
                        )}
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default withTranslationContext(withAgentsContext(AgentsScreen));
