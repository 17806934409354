/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import axios, { AxiosError } from 'axios';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    remainingReviewsURL,
    reviewApproveNewWorkUrl,
    reviewDeclineNewWorkUrl,
    reviewMetadataChangesUrl, reviewSelectWorkUrl,
    reviewsUrl,
    reviewUrl, reviewWorkSuggestionsUrl, saveReviewMetadataChangesUrl,
} from '../../../services/reviews';
import {
    RemainingReviewsApiResponse,
    Review, ReviewMetadataChange, ReviewsApiResponse, ReviewsSearchParams, ReviewWorkSuggestions,
} from '../../../types/reviews';
import { ReviewsContextProvider } from './ReviewsContext';
import { GenericFunction } from '../../../types/misc';
import { KeyedObject } from '../../../types/general';

interface OwnProps {
    children: React.ReactNode;
}

type Props = OwnProps;

export class ReviewsController extends Component<Props> {
    getReviews = async (filters?: ReviewsSearchParams): Promise<ReviewsApiResponse | null> => {
        try {
            const { data } = await axios.get(reviewsUrl(filters));
            return data;
        } catch {
            return null;
        }
    };

    getReview = async (id: number): Promise<Review | null> => {
        try {
            const { data } = await axios.get(reviewUrl(id));
            return data.data;
        } catch {
            return null;
        }
    }

    getReviewMetadataChanges = async (id: number, filters?: KeyedObject): Promise<ReviewMetadataChange | null> => {
        try {
            const { data } = await axios.get(reviewMetadataChangesUrl(id, filters));
            return data;
        } catch {
            return null;
        }
    };

    getReviewWorkSuggestions = async (id: number): Promise<ReviewWorkSuggestions | null> => {
        try {
            const { data } = await axios.get(reviewWorkSuggestionsUrl(id));
            return data;
        } catch {
            return null;
        }
    };

    approveNewWorkFromReview = async (reviewId: number, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void> => {
        try {
            await axios.patch(reviewApproveNewWorkUrl(reviewId));
            onSuccess();
        } catch (error) {
            const err = error as AxiosError;
            onFailure(err.response?.data);
        }
    };

    declineNewWorkFromReview = async (reviewId: number, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void> => {
        try {
            await axios.patch(reviewDeclineNewWorkUrl(reviewId));
            onSuccess();
        } catch (error) {
            const err = error as AxiosError;
            onFailure(err.response?.data);
        }
    };

    setReviewSelectedWork = async (reviewId: number, workId: number, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void> => {
        try {
            await axios.patch(reviewSelectWorkUrl(reviewId), {
                fiaf_work_id: workId,
            });
            onSuccess();
        } catch {
            onFailure();
        }
    }

    sendMetadataChanges = async (reviewId: number, metadataChanges: ReviewMetadataChange, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void> => {
        try {
            await axios.patch(saveReviewMetadataChangesUrl(reviewId), metadataChanges);
            onSuccess();
        } catch (error) {
            const err = error as AxiosError;
            onFailure(err.response?.data);
        }
    }

    getRemainingReviews = async (): Promise<RemainingReviewsApiResponse | null> => {
        try {
            const { data } = await axios.get(remainingReviewsURL());
            return data;
        } catch {
            return null;
        }
    };

    render(): React.ReactNode {
        const { children } = this.props;
        return (
            <ReviewsContextProvider value={{
                getReview: this.getReview,
                getReviews: this.getReviews,
                getReviewMetadataChanges: this.getReviewMetadataChanges,
                approveNewWorkFromReview: this.approveNewWorkFromReview,
                declineNewWorkFromReview: this.declineNewWorkFromReview,
                getReviewWorkSuggestions: this.getReviewWorkSuggestions,
                setReviewSelectedWork: this.setReviewSelectedWork,
                sendMetadataChanges: this.sendMetadataChanges,
                getRemainingReviews: this.getRemainingReviews,
            }}
            >
                {children}
            </ReviewsContextProvider>
        );
    }
}

export const ConnectedReviewsController = connect()(ReviewsController);
