/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { LoginResponsePayload } from '../types/authentication';
import { User } from '../types/users';

export enum AuthenticationAction {
    LOGIN_REQUEST = 'LOGIN_REQUEST',
    LOGIN_SUCCESS = 'LOGIN_SUCCESS',
    LOGOUT_REQUEST = 'LOGOUT_REQUEST',
    SET_AUTHENTICATED_USER = 'SET_AUTHENTICATED_USER',
}

// Login
export interface LoginRequestActionType {
    type: typeof AuthenticationAction.LOGIN_REQUEST;
}

export interface LoginSuccessActionType {
    type: typeof AuthenticationAction.LOGIN_SUCCESS;
    payload: LoginResponsePayload;
}

export interface LogoutRequestActionType {
    type: typeof AuthenticationAction.LOGOUT_REQUEST;
}

export interface SetAuthenticatedUserActionType {
    type: typeof AuthenticationAction.SET_AUTHENTICATED_USER;
    payload: User;
}

export type AuthenticationActionTypes = LoginSuccessActionType
    | LoginRequestActionType
    | LogoutRequestActionType
    | SetAuthenticatedUserActionType;
