/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { ComponentType, createContext, FC } from 'react';

import {
    RemainingReviewsApiResponse,
    Review, ReviewMetadataChange, ReviewsApiResponse, ReviewsSearchParams, ReviewWorkSuggestions,
} from '../../../types/reviews';
import { ConnectedReviewsController } from './ReviewsController';
import { GenericFunction } from '../../../types/misc';
import { KeyedObject } from '../../../types/general';

export interface ReviewsContext {
    getReview(id: number): Promise<Review | null>;
    getReviews(filters?: ReviewsSearchParams): Promise<ReviewsApiResponse | null>;
    getReviewMetadataChanges(id: number, filters?: KeyedObject): Promise<ReviewMetadataChange | null>;
    approveNewWorkFromReview(reviewId: number, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void>;
    declineNewWorkFromReview(reviewId: number, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void>;
    getReviewWorkSuggestions(reviewId: number): Promise<ReviewWorkSuggestions | null>;
    setReviewSelectedWork(reviewId: number, workId: number, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void>;
    sendMetadataChanges(reviewId: number, metadataChanges: ReviewMetadataChange, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void>;
    getRemainingReviews(): Promise<RemainingReviewsApiResponse | null>;
}

export const reviewsContextDefaultValue = {
    getReview: async (): Promise<Review | null> => null,
    getReviews: async (): Promise<ReviewsApiResponse | null> => null,
    getReviewMetadataChanges: async (): Promise<ReviewMetadataChange | null> => null,
    approveNewWorkFromReview: async (): Promise<void> => {},
    declineNewWorkFromReview: async (): Promise<void> => {},
    getReviewWorkSuggestions: async (): Promise<ReviewWorkSuggestions | null> => null,
    setReviewSelectedWork: async (): Promise<void> => {},
    sendMetadataChanges: async (): Promise<void> => {},
    getRemainingReviews: async (): Promise<RemainingReviewsApiResponse | null> => null,
};

const reviewsContextInstance = createContext<ReviewsContext | null>(reviewsContextDefaultValue);

export const ReviewsContextProvider = reviewsContextInstance.Provider;
export const ReviewsContextConsumer = reviewsContextInstance.Consumer;

export const withReviewsContext = <P extends unknown>(Component: ComponentType<P>): FC<Omit<P, keyof ReviewsContext>> => (props) => (
    <ConnectedReviewsController>
        <ReviewsContextConsumer>
            {(ctx) => <Component {...props as P} {...ctx} />}
        </ReviewsContextConsumer>
    </ConnectedReviewsController>
);
