/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Container } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FiafTreasuresLogo from '../../assets/image/fiaf-treasures-logo.png';
import IconCircleUser from '../assets/IconCircleUser';
import { TOKEN_SEARCH_PARAM_KEY, USER_EMAIL_SEARCH_PARAM_KEY, USER_ID_SEARCH_PARAM_KEY } from '../../constants/misc';
import { AppRoute } from '../../constants/routes';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { UsersContext, withUsersContext } from '../controllers/users/UsersContext';
import { ConfirmEmailChangePayload } from '../../types/users';
import { ApiError } from '../../types/errors';
import Loader from './Loader';

interface OwnProps extends TranslationContext, UsersContext {}

const ConfirmEmailChangeScreen: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const { t, confirmEmailChange } = props;

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const token = searchParams.get(TOKEN_SEARCH_PARAM_KEY);
    const userEmail = searchParams.get(USER_EMAIL_SEARCH_PARAM_KEY);
    const userId = searchParams.get(USER_ID_SEARCH_PARAM_KEY);
    const [isFetching, setIsFetching] = useState(true);
    const [confirmedSuccessfully, setConfirmedSuccessfully] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (token && userEmail && userId) {
            const payload: ConfirmEmailChangePayload = {
                token,
                email: userEmail,
                user_id: Number(userId),
            };
            confirmEmailChange(payload, onSuccess, onFailure);
        }
    }, [token, userEmail, userId]);

    const onFailure = (error?: ApiError) => {
        setIsFetching(false);
        if (error && error.message) {
            setErrorMessage(error.message);
        } else {
            setErrorMessage(t('general.requestGeneralError'));
        }
    };

    const onSuccess = () => {
        setIsFetching(false);
        setConfirmedSuccessfully(true);
    };

    return (
        <Container maxWidth="md">
            {isFetching && <Loader />}
            <div className="entry-screen">
                <div className="entry-screen__top">
                    <div className="entry-screen__top__logo-wrapper">
                        <img
                            src={FiafTreasuresLogo}
                            alt={t('general.fiafAndTreasuresLogo')}
                        />
                    </div>
                    <div className="entry-screen__top__avatar">
                        <IconCircleUser />
                    </div>
                    {confirmedSuccessfully ? (
                        <React.Fragment>
                            <div className="entry-screen__top__message-wrapper entry-screen__top__message-wrapper--confirmation">
                                <h5>{t('confirmEmailChange.title')}</h5>
                                <p>{t('confirmEmailChange.message', { email: `${userEmail || ''}` })}</p>
                            </div>
                            <div className="entry-screen__top__button">
                                <Button onClick={() => navigate(AppRoute.Login)} type="submit" data-testid="email-change-to-login-button">
                                    {t('confirmEmailChange.goToLogin')}
                                </Button>
                            </div>
                        </React.Fragment>
                    ) : (
                        <p className="entry-screen__top__error-message">
                            {errorMessage}
                        </p>
                    )}
                </div>
            </div>
        </Container>
    );
};

export default withTranslationContext(withUsersContext(ConfirmEmailChangeScreen));
