/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import axios, { AxiosError } from 'axios';
import { AuthenticationAction, AuthenticationActionTypes } from './authentication_types';
import {
    LoginRequestPayload,
    LoginResponsePayload,
} from '../types/authentication';
import {
    loginURL, logoutURL,
} from '../services/authentication';
import { EmptyObject, GenericFunction } from '../types/misc';
import { userMeUrl } from '../services/users';
import { User, UserType } from '../types/users';
import { groupedPermissions } from '../constants/permissions';
import { Permission } from '../types/authorization';
import { setPermissionsActionCreator } from './authorization';

export const loginRequestActionCreator = (): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.LOGIN_REQUEST,
    };
};

export const logoutRequestActionCreator = (): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.LOGOUT_REQUEST,
    };
};

export const loginSuccessActionCreator = (payload: LoginResponsePayload): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.LOGIN_SUCCESS,
        payload,
    };
};

export const setAuthenticatedUserActionCreator = (payload: User): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.SET_AUTHENTICATED_USER,
        payload,
    };
};

export const requestLogin = (payload: LoginRequestPayload, onSuccess: () => void, onFailure?: GenericFunction) => {
    return async (dispatch: ThunkDispatch<EmptyObject, EmptyObject, AnyAction>): Promise<void> => {
        dispatch(loginRequestActionCreator());

        try {
            const { data } = await axios.post(loginURL(), payload);

            const authorizationToken: string = data.access_token;
            const refreshToken: string = data.refresh_token;

            const { data: { data: userData } } = await axios.get(userMeUrl(), {
                headers: {
                    Authorization: `Bearer ${authorizationToken}`,
                },
            });

            let permissions: Array<Permission> = [];

            switch (userData.user_type) {
                case UserType.Contributor:
                    permissions = groupedPermissions.Contributor;
                    break;
                case UserType.User:
                    permissions = groupedPermissions.User;
                    break;
                case UserType.Editor:
                    permissions = groupedPermissions.Editor;
                    break;
                default:
            }

            dispatch(setPermissionsActionCreator(permissions));

            dispatch(loginSuccessActionCreator({
                token: authorizationToken,
                refresh_token: refreshToken,
                authenticatedUser: {
                    id: userData.id,
                    name: userData.name,
                    first_name: userData.first_name,
                    last_name: userData.last_name,
                    email: userData.email,
                    user_type: userData.user_type,
                    archive_code: userData.archive_code,
                    archive: userData.archive,
                    status: userData.status,
                    new_email_requested: userData.new_email_requested,
                },
            }));
            onSuccess();
        } catch (error) {
            const err = error as AxiosError;
            if (onFailure) onFailure(err.response?.data);
        }
    };
};

export const requestLogout = (onFailure?: GenericFunction) => {
    return async (dispatch: ThunkDispatch<EmptyObject, EmptyObject, AnyAction>): Promise<void> => {
        try {
            await axios.post(logoutURL());
            dispatch(logoutRequestActionCreator());
        } catch (error) {
            const err = error as AxiosError;
            if (onFailure) onFailure(err.response?.data);
        }
    };
};
