/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, ReactElement, useState } from 'react';
import {
    Button,
    Container,
    FormControl,
    InputLabel,
    TextField,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';

import FiafTreasuresLogo from '../../assets/image/fiaf-treasures-logo.png';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { LoginRequestPayload } from '../../types/authentication';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import { AppRoute } from '../../constants/routes';
import { ApiError } from '../../types/errors';
import Loader from '../elements/Loader';
import PasswordField from '../elements/PasswordField';

enum FieldName {
    Email = 'email',
    Password = 'password',
}

type Props = TranslationContext & AuthenticationContext;

const LoginScreen: FunctionComponent<Props> = (props): ReactElement | null => {
    const loginDefaultValue: LoginRequestPayload = {
        email: '',
        password: '',
    };

    const { t, submitLogin } = props;

    const [isFetching, setIsFetching] = useState(false);
    const [loginData, setLoginData] = useState(loginDefaultValue);

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setLoginData({
            ...loginData,
            [name]: value,
        });
    };

    const onFailure = (error: ApiError) => {
        setIsFetching(false);
        if (error.message) enqueueSnackbar(error.message, { variant: 'error' });
    };

    const onLoginSuccess = () => {
        navigate(AppRoute.Index);
    };

    const onForgotPassword = () => {
        navigate(AppRoute.ForgotPassword);
    };

    const handleSubmit = () => {
        setIsFetching(true);
        submitLogin(loginData, onLoginSuccess, onFailure);
    };

    return (
        <Container maxWidth="md">
            {isFetching && <Loader />}
            <div className="entry-screen">
                <div className="entry-screen__top">
                    <div className="entry-screen__top__logo-wrapper">
                        <img
                            src={FiafTreasuresLogo}
                            alt={t('general.fiafAndTreasuresLogo')}
                        />
                    </div>
                    <div className="entry-screen__top__message-wrapper">
                        <h3>{t('login.welcomeBack')}</h3>
                        <p>{t('login.pleaseLoginToYourAccount')}</p>
                    </div>
                    <div className="entry-screen__top__input-wrapper">
                        <FormControl fullWidth>
                            <InputLabel>{t('login.email')}</InputLabel>
                            <TextField
                                fullWidth
                                name={FieldName.Email}
                                data-testid="login-input-email"
                                placeholder={t('login.emailAddress')}
                                value={loginData.email}
                                onChange={handleInputChange}
                            />
                        </FormControl>
                        <PasswordField
                            label={t('login.password')}
                            placeholder={t('login.password')}
                            value={loginData.password}
                            onChange={handleInputChange}
                            name={FieldName.Password}
                        />
                    </div>
                    <div className="entry-screen__top__button">
                        <Button onClick={handleSubmit} type="submit" data-testid="login-submit-button">
                            {t('login.loginToFiaf')}
                        </Button>
                    </div>
                    <div className="entry-screen__top__forgot-button">
                        <Button onClick={onForgotPassword} data-testid="login-forgot-button">
                            {t('login.forgotPassword')}
                        </Button>
                    </div>
                </div>
                <div className="entry-screen__bottom">
                    <p>{t('login.noAccount')}</p>
                    <div className="entry-screen__bottom__request-access">
                        <a href="mailto:treasures@fiafnet.org" data-testid="request-access-mailto">
                            {t('login.requestAccess')}
                        </a>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default withTranslationContext(withAuthenticationContext(LoginScreen));
