/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { LinksApiResponse, MetaApiResponse } from './api';
import { User } from './users';
import {
    Work,
    WorkCountry, WorkDate, WorkThirdPartyIDs, WorkTitle,
} from './works';

export enum ReviewStatusParams {
    All = 'all',
    Required = 'review-required',
    NoRequired = 'no-review-required',
    Complete = 'review-complete',
    CompleteRevised = 'review-complete-revised',
    CompleteApproved = 'review-complete-approved',
    CompleteDeclined = 'review-complete-declined',
    RequiredOrCompleted = 'review-required-or-completed',
}

export enum ReviewStatusReturns {
    Required = 'review required',
    NoRequired = 'no review required',
    CompleteRevised = 'review complete - revised',
    CompleteApproved = 'review complete - approved',
    CompleteDeclined = 'review complete - declined',
}

export interface ReviewsSearchParams {
    status?: ReviewStatusParams;
    page?: number | null;
}

export interface Review {
    id: number;
    import_id: number;
    archive_code: string;
    status: string;
    created_by_user: number;
    userCreated: User;
    created_at: string;
    fiaf_work_id: number;
    fiaf_work_operation: string;
    fiaf_work_id_exact: boolean;
    fiaf_work_id_review_completed: boolean;
    archive_work_id: number;
    archive_work_operation: string;
    local_work_id: number;
    fiaf_work_preferred_title: string;
    archive_work_preferred_title: string,
    preferred_work_title_original: string,
}

export enum ReviewAgentStatus {
    Confirmed = 'confirmed',
    ReviewRequired = 'review required',
}

export interface WorkReviewAgent {
    agent_company: boolean;
    agent_extra_name_info: string;
    agent_first_name: string;
    agent_last_name: string;
    agent_id: number;
    agent_name_inverted: boolean;
    agent_role: string;
    agent_status: ReviewAgentStatus;
    agent_id_switch: number | null;
}

export interface ReviewsApiResponse {
    data: Review[];
    links: LinksApiResponse;
    meta: MetaApiResponse;
}

export enum ReviewMetadataChangeDiffStatus {
    Unchanged = 'unchanged',
    Changed = 'changed',
    New = 'new',
    Deleted = 'deleted',
}

export enum ReviewOperation {
    Accept = 'accept',
    Reject = 'reject',
    ReviseSwitch = 'revise_switch',
}

export interface ReviewMetadataChangeItem<T> {
    diffStatus: ReviewMetadataChangeDiffStatus;
    idCompare: number;
    idRef: number;
    reviewOperation: ReviewOperation | null;
    valueCompare: Partial<T> | null;
    valueRef: Partial<T> | null;
}

export interface ReviewMetadataChange {
    agents: ReviewMetadataChangeItem<WorkReviewAgent>[];
    countries: ReviewMetadataChangeItem<WorkCountry>[];
    content_description: ReviewMetadataChangeItem<string>;
    dates: ReviewMetadataChangeItem<WorkDate>[];
    notes: ReviewMetadataChangeItem<string>;
    preferred_work_title: ReviewMetadataChangeItem<string>;
    series: ReviewMetadataChangeItem<string>[];
    sound_production: ReviewMetadataChangeItem<string>;
    thirdPartyWorkIds: ReviewMetadataChangeItem<WorkThirdPartyIDs>[];
    titles: ReviewMetadataChangeItem<WorkTitle>[];
}

export interface ReviewWorkSuggestions {
    current_selection_fiaf_work: Work;
    alternative_titles: Work[];
    preferred_work_title_equal: Work[];
    preferred_work_title_similar: Work[];
    reference: Work;
    series: Work[];
    third_party_work_id: Work[];
}

export interface RemainingReviewsApiResponse {
    totalReviewsRequired: number;
}
