/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useState } from 'react';
import {
    Button, Grid, InputLabel, Table, TableBody, TableCell, TableHead, TableRow,
} from '@mui/material';
import update from 'immutability-helper';

import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { ReviewsContext, withReviewsContext } from '../../controllers/reviews/ReviewsContext';
import {
    Review,
    ReviewMetadataChange,
    ReviewMetadataChangeDiffStatus,
    ReviewMetadataChangeItem,
    ReviewOperation,
} from '../../../types/reviews';
import ReviewActionButtons from './ReviewActionButtons';
import ReviewDateTableRow from './ReviewDateTableRow';
import ReviewThirdPartyIdTableRow from './ReviewThirdPartyIdTableRow';
import ReviewTitleTableRow from './ReviewTitleTableRow';
import ReviewSerieTableRow from './ReviewSerieTableRow';
import ReviewCountriesTable from './ReviewCountriesTable';
import ReviewAgentsTableRow from './ReviewAgentsTableRow';
import { ArchiveWork } from '../../../types/archive_works';
import WorkFormItems from '../work/WorkFormItems';
import { getReviewValueCompareClassName, getReviewValueRefClassName } from '../../../utils/review';
import { Agent } from '../../../types/agents';
import AgentMatchesDialog from '../agent/AgentMatchesDialog';

interface OwnProps extends TranslationContext, ReviewsContext {
    review: Review;
    metadataChanges: ReviewMetadataChange | null;
    workArchives: Array<ArchiveWork>;
    setMetadataChanges(changes: ReviewMetadataChange): void;
}

const ReviewWorkChanges: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t, review, metadataChanges, setMetadataChanges, workArchives,
    } = props;

    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [agentIndexToUpdate, setAgentIndexToUpdate] = useState<number | null>(null);

    const onAcceptAllChanges = () => {
        if (metadataChanges) {
            const agentsRejected = metadataChanges.agents.map((a) => ({ ...a, reviewOperation: ReviewOperation.Accept }));
            const countriesRejected = metadataChanges.countries.map((c) => ({ ...c, reviewOperation: ReviewOperation.Accept }));
            const datesRejected = metadataChanges.dates.map((d) => ({ ...d, reviewOperation: ReviewOperation.Accept }));
            const seriesRejected = metadataChanges.series.map((s) => ({ ...s, reviewOperation: ReviewOperation.Accept }));
            const thirdPartyWorkIdsRejected = metadataChanges.thirdPartyWorkIds.map((p) => ({ ...p, reviewOperation: ReviewOperation.Accept }));
            const titlesRejected = metadataChanges.titles.map((i) => ({ ...i, reviewOperation: ReviewOperation.Accept }));

            setMetadataChanges({
                ...metadataChanges,
                agents: [...agentsRejected],
                countries: [...countriesRejected],
                dates: [...datesRejected],
                series: [...seriesRejected],
                thirdPartyWorkIds: [...thirdPartyWorkIdsRejected],
                titles: [...titlesRejected],
                content_description: getSingleChangeItemOperationChanged(metadataChanges.content_description),
                notes: getSingleChangeItemOperationChanged(metadataChanges.notes),
                preferred_work_title: getSingleChangeItemOperationChanged(metadataChanges.preferred_work_title),
                sound_production: getSingleChangeItemOperationChanged(metadataChanges.sound_production),
            });
        }
    };

    const onRejectAllChanges = () => {
        if (metadataChanges) {
            const agentsRejected = metadataChanges.agents.map((a) => ({ ...a, reviewOperation: ReviewOperation.Reject }));
            const countriesRejected = metadataChanges.countries.map((c) => ({ ...c, reviewOperation: ReviewOperation.Reject }));
            const datesRejected = metadataChanges.dates.map((d) => ({ ...d, reviewOperation: ReviewOperation.Reject }));
            const seriesRejected = metadataChanges.series.map((s) => ({ ...s, reviewOperation: ReviewOperation.Reject }));
            const thirdPartyWorkIdsRejected = metadataChanges.thirdPartyWorkIds.map((p) => ({ ...p, reviewOperation: ReviewOperation.Reject }));
            const titlesRejected = metadataChanges.titles.map((i) => ({ ...i, reviewOperation: ReviewOperation.Reject }));

            setMetadataChanges({
                ...metadataChanges,
                agents: [...agentsRejected],
                countries: [...countriesRejected],
                dates: [...datesRejected],
                series: [...seriesRejected],
                thirdPartyWorkIds: [...thirdPartyWorkIdsRejected],
                titles: [...titlesRejected],
                content_description: getSingleChangeItemOperationChanged(metadataChanges.content_description, true),
                notes: getSingleChangeItemOperationChanged(metadataChanges.notes, true),
                preferred_work_title: getSingleChangeItemOperationChanged(metadataChanges.preferred_work_title, true),
                sound_production: getSingleChangeItemOperationChanged(metadataChanges.sound_production, true),
            });
        }
    };

    const getSingleChangeItemOperationChanged = (changeItem: ReviewMetadataChangeItem<string>, reject = false) => {
        return {
            ...changeItem,
            reviewOperation: reject ? ReviewOperation.Reject : ReviewOperation.Accept,
        };
    };

    const setAcceptOperationArrayItem = (key: string, accept: boolean, idx: number) => {
        if (metadataChanges) {
            setMetadataChanges(update(metadataChanges, {
                [key]: {
                    [idx]: {
                        $merge: {
                            reviewOperation: accept ? ReviewOperation.Accept : ReviewOperation.Reject,
                        },
                    },
                },
            }));
        }
    };

    const setSingleItemAcceptOperation = (key: string, accept: boolean) => {
        if (metadataChanges) {
            setMetadataChanges(update(metadataChanges, {
                [key]: {
                    $merge: {
                        reviewOperation: accept ? ReviewOperation.Accept : ReviewOperation.Reject,
                    },
                },
            }));
        }
    };

    const onAgentMatch = (data: Agent, idx: number) => {
        const oldData = metadataChanges?.agents[idx];
        if (oldData) {
            setMetadataChanges(update(metadataChanges, {
                agents: {
                    [idx]: {
                        $merge: {
                            reviewOperation: ReviewOperation.ReviseSwitch,
                            valueCompare: {
                                agent_role: oldData.valueCompare?.agent_role,
                                agent_id: oldData.valueCompare?.agent_id,
                                agent_id_switch: data.id,
                                agent_status: oldData.valueCompare?.agent_status,
                                agent_first_name: data.first_name,
                                agent_last_name: data.last_name,
                                agent_extra_name_info: data.extra_name_info,
                                agent_name_inverted: data.name_inverted,
                                agent_company: data.company,
                            },
                        },
                    },
                },
            }));
        }
    };
    
    const onRenderMatchesDialog = (idx: number) => {
        setAgentIndexToUpdate(idx);
        setIsDialogOpen((prevState) => !prevState);
    };

    const renderSimpleChangeItem = (changeItem: ReviewMetadataChangeItem<string>, key: string) => {
        return (
            <React.Fragment key={changeItem.idRef}>
                {changeItem.valueRef && (
                    <TableRow className={getReviewValueRefClassName(changeItem.diffStatus, changeItem.reviewOperation)}>
                        <TableCell>{changeItem.valueRef}</TableCell>
                        <TableCell align="right">
                            <ReviewActionButtons
                                reviewOperation={changeItem.reviewOperation}
                                changeDiffStatus={changeItem.diffStatus}
                                onAccept={() => setSingleItemAcceptOperation(key, true)}
                                onReject={() => setSingleItemAcceptOperation(key, false)}
                            />
                        </TableCell>
                    </TableRow>
                )}
                {changeItem.diffStatus !== ReviewMetadataChangeDiffStatus.Unchanged && changeItem.valueCompare && (
                    <TableRow className={getReviewValueCompareClassName(changeItem.diffStatus, changeItem.reviewOperation)}>
                        <TableCell>{changeItem.valueCompare}</TableCell>
                        <TableCell align="right">
                            {!changeItem.valueRef && (
                                <ReviewActionButtons
                                    reviewOperation={changeItem.reviewOperation}
                                    changeDiffStatus={changeItem.diffStatus}
                                    onAccept={() => setSingleItemAcceptOperation(key, true)}
                                    onReject={() => setSingleItemAcceptOperation(key, false)}
                                />
                            )}
                        </TableCell>
                    </TableRow>
                )}
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <div className="work-form__review-top">
                <div>
                    <h3>{t('workReview.changesMade')}</h3>
                    <p>{t('workReview.changesByUser', { userName: `${review.userCreated.first_name || ''} ${review.userCreated.last_name || ''}` })}</p>
                </div>
                <div>
                    <Button
                        variant="contained"
                        type="button"
                        color="error"
                        onClick={onRejectAllChanges}
                        disableElevation
                    >
                        {t('workReview.rejectAllChanges')}
                    </Button>
                    <Button
                        variant="contained"
                        type="button"
                        color="success"
                        onClick={onAcceptAllChanges}
                        disableElevation
                    >
                        {t('workReview.acceptAllChanges')}
                    </Button>
                </div>
            </div>
            {metadataChanges && (
                <div className="work-form__inner work-form__inner--review">
                    <div>
                        {metadataChanges.thirdPartyWorkIds.length > 0 && (
                            <div className="work-form__inner__section">
                                <h3>{t('work.form.identifiers')}</h3>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t('work.form.id')}</TableCell>
                                            <TableCell>{t('work.form.issuer')}</TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {metadataChanges.thirdPartyWorkIds.map((changeData, idx) => (
                                            <ReviewThirdPartyIdTableRow
                                                key={changeData.idCompare || changeData.idRef}
                                                thirdPartyChange={changeData}
                                                onAccept={() => setAcceptOperationArrayItem('thirdPartyWorkIds', true, idx)}
                                                onReject={() => setAcceptOperationArrayItem('thirdPartyWorkIds', false, idx)}
                                            />
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                        )}
                        <div className="work-form__inner__section">
                            <h3>{t('work.form.titles')}</h3>
                            {metadataChanges.preferred_work_title && (
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t('work.form.preferredTitle')}</TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {renderSimpleChangeItem(metadataChanges.preferred_work_title, 'preferred_work_title')}
                                    </TableBody>
                                </Table>
                            )}
                            {metadataChanges.titles.length > 0 && (
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t('work.form.otherTitles')}</TableCell>
                                            <TableCell>{t('work.form.type')}</TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {metadataChanges.titles.map((change, idx) => (
                                            <ReviewTitleTableRow
                                                key={change.idCompare || change.idRef}
                                                titleChange={change}
                                                onAccept={() => setAcceptOperationArrayItem('titles', true, idx)}
                                                onReject={() => setAcceptOperationArrayItem('titles', false, idx)}
                                            />
                                        ))}
                                    </TableBody>
                                </Table>
                            )}
                            {metadataChanges.series.length > 0 && (
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t('work.form.series')}</TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {metadataChanges.series.map((change, idx) => (
                                            <ReviewSerieTableRow
                                                key={change.idCompare || change.idRef}
                                                serieChange={change}
                                                onAccept={() => setAcceptOperationArrayItem('series', true, idx)}
                                                onReject={() => setAcceptOperationArrayItem('series', false, idx)}
                                            />
                                        ))}
                                    </TableBody>
                                </Table>
                            )}
                        </div>
                        <div className="work-form__inner__section">
                            <h3>{t('work.form.production')}</h3>
                            {metadataChanges.countries && (
                                <ReviewCountriesTable
                                    countriesChanges={metadataChanges.countries}
                                    onAcceptChange={(idx) => setAcceptOperationArrayItem('countries', true, idx)}
                                    onRejectChange={(idx) => setAcceptOperationArrayItem('countries', false, idx)}
                                />
                            )}
                            {metadataChanges.sound_production && (
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t('work.form.soundSilent')}</TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {renderSimpleChangeItem(metadataChanges.sound_production, 'sound_production')}
                                    </TableBody>
                                </Table>
                            )}
                            {metadataChanges.dates.length > 0 && (
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t('work.form.from')}</TableCell>
                                            <TableCell>{t('work.form.until')}</TableCell>
                                            <TableCell>{t('work.form.type')}</TableCell>
                                            <TableCell>{t('work.form.accuracy')}</TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {metadataChanges.dates.map((changeData, idx) => (
                                            <ReviewDateTableRow
                                                key={changeData.idCompare || changeData.idRef}
                                                dateChange={changeData}
                                                onAccept={() => setAcceptOperationArrayItem('dates', true, idx)}
                                                onReject={() => setAcceptOperationArrayItem('dates', false, idx)}
                                            />
                                        ))}
                                    </TableBody>
                                </Table>
                            )}
                            {metadataChanges.content_description && metadataChanges.content_description.valueCompare && (
                                <div className="single-item-review margin">
                                    <InputLabel>{t('work.form.description')}</InputLabel>
                                    <div className="single-item-review__inner">
                                        <div className="single-item-review__inner__text">
                                            {metadataChanges.content_description.valueRef && metadataChanges.content_description.diffStatus === ReviewMetadataChangeDiffStatus.Changed && (
                                                <p
                                                    data-testid="review-work-description"
                                                    className={metadataChanges.content_description.reviewOperation === ReviewOperation.Reject ? 'old ' : 'old txt-line-through'}
                                                >
                                                    {metadataChanges.content_description.valueRef}
                                                </p>
                                            )}
                                            <p className={metadataChanges.content_description.reviewOperation === ReviewOperation.Reject ? 'txt-line-through' : ''}>{metadataChanges.content_description.valueCompare}</p>
                                        </div>
                                        <ReviewActionButtons
                                            reviewOperation={metadataChanges.content_description.reviewOperation}
                                            changeDiffStatus={metadataChanges.content_description.diffStatus}
                                            onAccept={() => setSingleItemAcceptOperation('content_description', true)}
                                            onReject={() => setSingleItemAcceptOperation('content_description', false)}
                                        />
                                    </div>
                                </div>
                            )}
                            {metadataChanges.notes && metadataChanges.notes.valueCompare && (
                                <div className="single-item-review margin">
                                    <InputLabel>{t('work.form.notes')}</InputLabel>
                                    <div className="single-item-review__inner">
                                        <div className="single-item-review__inner__text">
                                            {metadataChanges.notes.valueRef && metadataChanges.notes.diffStatus === ReviewMetadataChangeDiffStatus.Changed && (
                                                <p
                                                    className={metadataChanges.notes.reviewOperation === ReviewOperation.Reject ? 'old txt-line-through' : 'old'}
                                                >
                                                    {metadataChanges.notes.valueRef}
                                                </p>
                                            )}
                                            <p>{metadataChanges.notes.valueCompare}</p>
                                        </div>
                                        <ReviewActionButtons
                                            reviewOperation={metadataChanges.notes.reviewOperation}
                                            changeDiffStatus={metadataChanges.notes.diffStatus}
                                            onAccept={() => setSingleItemAcceptOperation('notes', true)}
                                            onReject={() => setSingleItemAcceptOperation('notes', false)}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        {metadataChanges.agents.length > 0 && (
                            <div className="work-form__inner__section">
                                <h3>{t('work.form.agents')}</h3>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell />
                                            <TableCell>{t('work.form.name')}</TableCell>
                                            <TableCell>{t('work.form.role')}</TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {metadataChanges.agents.map((change, idx) => (
                                            <ReviewAgentsTableRow
                                                key={change.idRef || change.idCompare}
                                                agentChange={change}
                                                onAccept={() => setAcceptOperationArrayItem('agents', true, idx)}
                                                onReject={() => setAcceptOperationArrayItem('agents', false, idx)}
                                                onOpenDialog={() => onRenderMatchesDialog(idx)}
                                            />
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                        )}
                        {workArchives.length > 0 && (
                            <div className="work-form__inner__section">
                                <h3>{t('work.form.items')}</h3>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <WorkFormItems
                                            archiveWorks={workArchives}
                                            importId={review.import_id}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {isDialogOpen && agentIndexToUpdate !== null && (
                <AgentMatchesDialog
                    onClose={() => {
                        setAgentIndexToUpdate(null);
                        setIsDialogOpen(false);
                    }}
                    onFindMatch={(data) => onAgentMatch(data, agentIndexToUpdate)}
                />
            )}
        </React.Fragment>
    );
};
export default withTranslationContext(withReviewsContext(ReviewWorkChanges));
