/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useState } from 'react';
import {
    Button,
    Container,
} from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { TOKEN_SEARCH_PARAM_KEY, USER_EMAIL_SEARCH_PARAM_KEY, USER_ID_SEARCH_PARAM_KEY } from '../../constants/misc';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { UsersContext, withUsersContext } from '../controllers/users/UsersContext';
import FiafTreasuresLogo from '../../assets/image/fiaf-treasures-logo.png';
import IconCircleUser from '../assets/IconCircleUser';
import { UserSetPasswordPayload, SetPasswordFields } from '../../types/users';
import { ApiError } from '../../types/errors';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import { AppRoute } from '../../constants/routes';
import Loader from '../elements/Loader';
import PasswordField from '../elements/PasswordField';

enum FieldName {
    PasswordConfirmation = 'password_confirmation',
    Password = 'password',
}

interface OwnProps extends TranslationContext, UsersContext, AuthenticationContext {}

const SetPasswordScreen: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t, userAcceptInvitation, submitLogin, submitResetPassword,
    } = props;

    const location = useLocation();
    const isAcceptInvitationRoute = location.pathname === AppRoute.AcceptInvite;
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const token = searchParams.get(TOKEN_SEARCH_PARAM_KEY);
    const userEmail = searchParams.get(USER_EMAIL_SEARCH_PARAM_KEY);
    const userId = searchParams.get(USER_ID_SEARCH_PARAM_KEY);
    const { enqueueSnackbar } = useSnackbar();

    const [fields, setFields] = useState<SetPasswordFields>({
        password: '',
        password_confirmation: '',
    });
    const [isFetching, setIsFetching] = useState(false);

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setFields({
            ...fields,
            [name]: value,
        });
    };
    
    const onSuccess = () => {
        submitLogin({
            email: userEmail || '',
            password: fields[FieldName.Password],
        }, () => navigate(AppRoute.Index), onFailure);
    };
    
    const onFailure = (error?: ApiError) => {
        setIsFetching(false);
        if (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
        } else {
            enqueueSnackbar(t('general.requestGeneralError'), { variant: 'error' });
        }
    };

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        if (fields[FieldName.Password] !== fields[FieldName.PasswordConfirmation]) {
            enqueueSnackbar(t('errors.passwordAndConfirmationDoNotMatch'), { variant: 'error' });
            return;
        }

        setIsFetching(true);

        const payload: UserSetPasswordPayload = {
            email: userEmail || '',
            password: fields[FieldName.Password],
            password_confirmation: fields[FieldName.PasswordConfirmation],
            token: token || '',
        };

        if (isAcceptInvitationRoute && userId) {
            userAcceptInvitation(Number(userId), payload, onSuccess, onFailure);
        } else {
            submitResetPassword(payload, onSuccess, onFailure);
        }
    };

    return (
        <Container maxWidth="md">
            {isFetching && <Loader />}
            <div className="entry-screen">
                <form className="entry-screen__top" onSubmit={onSubmit}>
                    <div className="entry-screen__top__logo-wrapper">
                        <img
                            src={FiafTreasuresLogo}
                            alt={t('general.fiafAndTreasuresLogo')}
                        />
                    </div>
                    <div className="entry-screen__top__avatar">
                        <IconCircleUser />
                    </div>
                    <div className="entry-screen__top__input-wrapper">
                        <PasswordField
                            onChange={onInputChange}
                            name={FieldName.Password}
                            value={fields[FieldName.Password]}
                            placeholder={t('setPassword.setupPassword')}
                            label={t('setPassword.setupPassword')}
                        />
                        <PasswordField
                            onChange={onInputChange}
                            name={FieldName.PasswordConfirmation}
                            value={fields[FieldName.PasswordConfirmation]}
                            placeholder={t('setPassword.repeatPassword')}
                            label={t('setPassword.repeatPassword')}
                        />
                    </div>
                    <div className="entry-screen__top__button">
                        <Button
                            type="submit"
                            data-testid="login-submit-button"
                            disabled={!token || !userEmail || (isAcceptInvitationRoute && !userId)}
                        >
                            {t('setPassword.savePassword')}
                        </Button>
                    </div>
                </form>
            </div>
        </Container>
    );
};

export default withTranslationContext(withUsersContext(withAuthenticationContext(SetPasswordScreen)));
