/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import {
    Store,
    compose,
    createStore,
    applyMiddleware,
} from 'redux';
import ReduxThunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistCombineReducers, Persistor } from 'redux-persist';

import preferences from './reducers/preferences';

import authentication from './reducers/authentication';
import authorization from './reducers/authorization';
import search from './reducers/searchParams';

const isDevVersion: boolean = process.env.NODE_ENV === 'development';
const middlewares = [ReduxThunk];
const volatileReducers: string[] = ['preferences'];

const config = {
    key: 'root',
    storage,
    debug: isDevVersion,
    blacklist: volatileReducers,
};

const reducers = persistCombineReducers(config, {
    preferences,
    authentication,
    authorization,
    search,
});

export const configureStore = () : { persistor: Persistor, store: Store } => {
    const store = isDevVersion ? createStore(reducers, composeWithDevTools(applyMiddleware(...middlewares))) : createStore(reducers, compose(applyMiddleware(...middlewares)));

    const persistor = persistStore(store, null);

    return { persistor, store };
};
