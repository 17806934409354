/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';
import { ConnectedTranslationController } from './TranslationController';
import { AppLanguage } from '../../../types/preferences';

export interface TranslationContext {
    language: AppLanguage;
    languages: AppLanguage[];
    t(literal: string, params?: Record<string, string>): string;
}

export const translationContextDefaultValue = {
    language: AppLanguage.EN,
    languages: [AppLanguage.EN],
    t: (): string => '',
};

const translationContextInstance = createContext<TranslationContext | null>(translationContextDefaultValue);

export const TranslationContextProvider = translationContextInstance.Provider;
export const TranslationContextConsumer = translationContextInstance.Consumer;

export const withTranslationContext = <P extends unknown>(Component: ComponentType<P>): FC<Omit<P, keyof TranslationContext>> => (props) => (
    <ConnectedTranslationController>
        <TranslationContextConsumer>
            {(ctx) => <Component {...props as P} {...ctx} />}
        </TranslationContextConsumer>
    </ConnectedTranslationController>
);
