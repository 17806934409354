/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, ReactElement, useState } from 'react';
import {
    Button,
    Container,
    FormControl,
    InputLabel,
    TextField,
} from '@mui/material';
import { useNavigate } from 'react-router';
import FiafTreasuresLogo from '../../assets/image/fiaf-treasures-logo.png';
 
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { RecoverPasswordPayload } from '../../types/authentication';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import { AppRoute } from '../../constants/routes';
import IconCircleUser from '../assets/IconCircleUser';
 
type Props = TranslationContext & AuthenticationContext;
 
const ForgotPasswordScreen: FunctionComponent<Props> = (props): ReactElement | null => {
    const inputDefaultValue: RecoverPasswordPayload = {
        email: '',
    };
    const { t, submitRecoverPassword } = props;
    const [userEmail, setUserEmail] = useState<RecoverPasswordPayload>(inputDefaultValue);
    const [emailSent, setEmailSent] = useState(false);
 
    const navigate = useNavigate();

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setUserEmail({
            ...userEmail,
            email: value,
        });
    };

    const onSubmit = async () => {
        if (userEmail) {
            submitRecoverPassword(userEmail);
            setEmailSent(true);
        }
    };

    const navigateToLogin = () => {
        navigate(AppRoute.Login);
    };

    const renderPasswordRequest = () => {
        if (!emailSent) {
            return (
                <>
                    <div className="entry-screen__top__message-wrapper">
                        <h3>{t('forgotPassword.title')}</h3>
                        <p>{t('forgotPassword.pleaseTypeYourEmail')}</p>
                        <p>{t('forgotPassword.whereYouCanSet')}</p>
                    </div>
                    <div className="entry-screen__top__input-wrapper">
                        <FormControl fullWidth>
                            <InputLabel>{t('forgotPassword.email')}</InputLabel>
                            <TextField
                                fullWidth
                                data-testid="forgot-input-email"
                                placeholder={t('forgotPassword.emailAddress')}
                                value={userEmail.email}
                                onChange={onInputChange}
                            />
                        </FormControl>
                    </div>
                    <div className="entry-screen__top__button">
                        <Button onClick={onSubmit} type="submit" data-testid="forgot-submit-button">
                            {t('forgotPassword.sendRecoveryLink')}
                        </Button>
                    </div>
                    <div className="entry-screen__top__mailto">
                        <a href="mailto:treasures@fiafnet.org" data-testid="forgot-mailto">
                            {t('forgotPassword.didntGetTheEmail')}
                        </a>
                    </div>
                </>
            );
        }
        return (
            <>
                <div className="entry-screen__top__avatar">
                    <IconCircleUser />
                </div>
                <div className="entry-screen__top__message-wrapper entry-screen__top__message-wrapper--confirmation">
                    <h5>{t('forgotPassword.emailToResetPassword')}</h5>
                    <p>{t('forgotPassword.pleaseCheckInbox', { email: `${userEmail.email}` })}</p>
                </div>
                <div className="entry-screen__top__button">
                    <Button onClick={navigateToLogin} type="submit" data-testid="forgot-to-login-button">
                        {t('forgotPassword.goToLogin')}
                    </Button>
                </div>
                <div className="entry-screen__top__mailto">
                    <a href="mailto:treasures@fiafnet.org" data-testid="forgot-mailto">
                        {t('forgotPassword.didntGetTheEmail')}
                    </a>
                </div>
            </>
        );
    };
 
    return (
        <Container maxWidth="md">
            <div className="entry-screen">
                <div className="entry-screen__top">
                    <div className="entry-screen__top__logo-wrapper">
                        <img
                            src={FiafTreasuresLogo}
                            alt={t('general.fiafAndTreasuresLogo')}
                        />
                    </div>
                    {renderPasswordRequest()}
                </div>
            </div>
        </Container>
    );
};
 
export default withTranslationContext(withAuthenticationContext(ForgotPasswordScreen));
