/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useEffect, useState } from 'react';
import {
    IconButton, MenuItem, Select, SelectChangeEvent, Table, TableBody, TableCell, TableHead, TableRow,
} from '@mui/material';
import {
    AddRounded, CheckRounded, CloseRounded,
} from '@mui/icons-material';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import {
    ControlledVocabulariesContext,
    withControlledVocabulariesContext,
} from '../../controllers/controlled_vocabularies/ControlledVocabulariesContext';
import { ItemLanguage } from '../../../types/items';
import { getRandomInt } from '../../../utils/misc';
import { LanguageCV, LanguageUsageCV } from '../../../types/controlled_vocabularies';
import { ControlledVocabularyName } from '../../../services/controlled_vocabularies';
import Loader from '../Loader';

interface OwnProps extends TranslationContext, ControlledVocabulariesContext {
    languagesList: Array<Partial<ItemLanguage>>;
    onAdd(language: Partial<ItemLanguage>): void;
    onRemove(id?: number): void;
}

enum FieldsName {
    Language = 'language',
    LanguageUsage = 'language_usage',
}

const ItemFormLanguagesTable: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t, getCV, languagesList, onAdd, onRemove,
    } = props;

    const [languagesOptions, setLanguagesOptions] = useState<Array<LanguageCV>>([]);
    const [languagesUsageOptions, setLanguagesUsageOptions] = useState<Array<LanguageUsageCV>>([]);
    const [newLanguage, setNewLanguage] = useState<Partial<ItemLanguage> | null>(null);
    const [isFetching, setIsFetching] = useState(true);

    useEffect(() => {
        fetchOptions();
    }, []);

    useEffect(() => {
        if (languagesList.length === 0) onAddNewLanguage();
    }, [languagesList]);

    const onAddNewLanguage = () => {
        setNewLanguage({
            id: getRandomInt(-10000, 0),
            language: '',
            language_usage: '',
        });
    };

    const onAccept = () => {
        if (newLanguage) onAdd(newLanguage);
        setNewLanguage(null);
    };

    const onSelectChange = (event: SelectChangeEvent) => {
        const {
            target: { value, name },
        } = event;

        if (newLanguage) {
            setNewLanguage({
                ...newLanguage,
                [name]: value,
            });
        }
    };

    const fetchOptions = () => {
        Promise.all([
            getCV<LanguageCV>(ControlledVocabularyName.Languages),
            getCV<LanguageUsageCV>(ControlledVocabularyName.LanguageUsages),
        ]).then((results) => {
            if (results[0]) setLanguagesOptions(results[0].data);
            if (results[1]) setLanguagesUsageOptions(results[1].data);
        }).finally(() => setIsFetching(false));
    };

    if (isFetching) {
        return <Loader />;
    }
    return (
        <Table size="small" data-testid="item-languages-table">
            <TableHead>
                <TableRow>
                    <TableCell>{t('itemFormDialog.languagesLabel')}</TableCell>
                    <TableCell>{t('itemFormDialog.usageLabel')}</TableCell>
                    <TableCell className="add-btn-cell">
                        <IconButton data-testid="add-new-language-btn" className="add-small-btn" onClick={onAddNewLanguage}>
                            <AddRounded />
                        </IconButton>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {languagesList.map((language) => {
                    const languageCV = languagesOptions.find((l) => l.code === language.language);
                    return (
                        <TableRow key={language.id}>
                            <TableCell data-testid="language-list-name">{languageCV?.name || language.language}</TableCell>
                            <TableCell data-testid="language-usage-list-name">{language.language_usage}</TableCell>
                            <TableCell align="right">
                                <IconButton
                                    size="small"
                                    data-testid="new-language-delete-btn"
                                    onClick={() => onRemove(language.id)}
                                    className="action-btn"
                                >
                                    <CloseRounded />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    );
                })}
                {newLanguage && (
                    <TableRow>
                        <TableCell>
                            <Select
                                fullWidth
                                MenuProps={{ classes: { paper: 'select' } }}
                                data-testid="new-language-select"
                                className="simple-select dense-select"
                                name={FieldsName.Language}
                                value={newLanguage[FieldsName.Language]}
                                onChange={onSelectChange}
                                displayEmpty
                                variant="standard"
                                renderValue={
                                    newLanguage[FieldsName.Language] !== '' ? undefined : () => <div className="placeholder">{t('general.select')}</div>
                                }
                            >
                                {languagesOptions.map((value) => (
                                    <MenuItem dense key={value.name} value={value.code}>{value.name}</MenuItem>
                                ))}
                            </Select>
                        </TableCell>
                        <TableCell>
                            <Select
                                fullWidth
                                MenuProps={{ classes: { paper: 'select' } }}
                                data-testid="new-language-usage-select"
                                className="simple-select dense-select"
                                name={FieldsName.LanguageUsage}
                                value={newLanguage[FieldsName.LanguageUsage]}
                                onChange={onSelectChange}
                                displayEmpty
                                variant="standard"
                                renderValue={
                                    newLanguage[FieldsName.LanguageUsage] !== '' ? undefined : () => <div className="placeholder">{t('general.select')}</div>
                                }
                            >
                                {languagesUsageOptions.map((value) => (
                                    <MenuItem dense key={value.name} value={value.name}>{value.name}</MenuItem>
                                ))}
                            </Select>
                        </TableCell>
                        <TableCell align="right">
                            <IconButton
                                size="small"
                                data-testid="new-language-confirm-btn"
                                onClick={onAccept}
                                className="action-btn"
                                disabled={!newLanguage.language || !newLanguage.language_usage}
                            >
                                <CheckRounded />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
};

export default withTranslationContext(withControlledVocabulariesContext(ItemFormLanguagesTable));
