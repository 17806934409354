/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { KeyedObject } from '../types/general';
import { API_URL } from '../settings';
import { objectToParams } from '../utils/misc';

export const importsUrl = (filters?: KeyedObject): string => {
    return `${API_URL}/imports${objectToParams(filters)}`;
};

export const importTemplateUrl = (): string => {
    return `${API_URL}/imports/template?template_format=csv&includes_cv=false&includes_example=false`;
};
