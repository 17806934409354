/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';
import { KeyedObject } from '../types/general';
import { objectToParams } from '../utils/misc';

export const reviewsUrl = (filters?: KeyedObject): string => {
    const params = objectToParams(filters);
    return `${API_URL}/work_reviews${params}`;
};

export const reviewUrl = (id: string | number): string => {
    return `${API_URL}/work_reviews/review/${id}`;
};

export const reviewMetadataChangesUrl = (id: string | number, filters?: KeyedObject): string => {
    const params = objectToParams(filters);
    return `${API_URL}/work_reviews/review/${id}/metadata/changes${params}`;
};

export const reviewApproveNewWorkUrl = (id: number): string => {
    return `${API_URL}/work_reviews/review/${id}/approved`;
};

export const reviewDeclineNewWorkUrl = (id: number): string => {
    return `${API_URL}/work_reviews/review/${id}/declined`;
};

export const reviewWorkSuggestionsUrl = (id: number): string => {
    return `${API_URL}/work_reviews/review/${id}/suggestions`;
};

export const reviewSelectWorkUrl = (id: number): string => {
    return `${API_URL}/work_reviews/review/${id}/selected_work`;
};

export const saveReviewMetadataChangesUrl = (id: number): string => {
    return `${API_URL}/work_reviews/review/${id}/metadata`;
};

export const remainingReviewsURL = (): string => {
    return `${API_URL}/work_reviews/total_required`;
};
