/**
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

import { AnyAction } from 'redux';
import { REHYDRATE } from 'redux-persist';
import { AuthenticationState } from '../types/store';
import { AuthenticationAction } from '../actions/authentication_types';

export const authenticationInitialState: AuthenticationState = {
    token: null,
    isAuthenticated: false,
    isFetching: false,
    authenticatedUser: null,
};

function authentication(state = authenticationInitialState, action: AnyAction): AuthenticationState {
    switch (action.type) {
        case REHYDRATE:
            if (action.payload && action.payload.authentication) {
                return {
                    ...action.payload.authentication,
                    isFetching: authenticationInitialState.isFetching,
                };
            }
            return state;
        case AuthenticationAction.LOGIN_REQUEST:
            return {
                ...state,
                isAuthenticated: false,
                isFetching: true,
            };
        case AuthenticationAction.LOGIN_SUCCESS:
            return {
                ...state,
                ...action.payload,
                isAuthenticated: true,
                isFetching: false,
            };
        case AuthenticationAction.SET_AUTHENTICATED_USER:
            return {
                ...state,
                authenticatedUser: action.payload,
            };
        case AuthenticationAction.LOGOUT_REQUEST:
            return authenticationInitialState;
        default:
            return state;
    }
}

export default authentication;
