/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';
import FiafLogo from '../../assets/image/fiaf-logo.png';
import CcaaaLogo from '../../assets/image/ccaaa-logo.png';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';

const BottomBar: FunctionComponent<TranslationContext> = (props: TranslationContext) => {
    const { t } = props;

    return (
        <div className="bottom-bar">
            <div className="bottom-bar__message-wrapper">
                <div className="bottom-bar__message-wrapper__logo-wrapper">
                    <img src={FiafLogo} alt={t('footer.fiafLogo')} />
                </div>
                <p>{t('footer.copyrightYear')}</p>
                <p>{t('footer.fiafFullNameEN')}</p>
            </div>
            <div className="bottom-bar__membership-wrapper">
                <p>{t('footer.memberOf')}</p>
                <div className="bottom-bar__membership-wrapper__logo-wrapper">
                    <img src={CcaaaLogo} alt={t('footer.ccaaaLogo')} />
                </div>
            </div>
        </div>
    );
};

export default withTranslationContext(BottomBar);
