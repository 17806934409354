/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useState } from 'react';
import { ArchiveWork } from '../../../types/archive_works';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import WorkArchivesListItem from './WorkArchivesListItem';
import ItemDetailsDialog from '../item/ItemDetailsDialog';

interface OwnProps extends TranslationContext {
    archiveWorks: Array<ArchiveWork>;
    importId?: number;
}

const WorkFormItems: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const { archiveWorks, importId } = props;

    const [expanded, setExpanded] = useState<Set<string>>(new Set<string>());
    const [selectedItemId, setSelectedItemId] = useState<number | null>(null);

    const handleExpand = (panel: string, isExpanded: boolean) => {
        if (isExpanded) {
            setExpanded(new Set(expanded).add(panel));
        } else {
            const newExpanded = new Set(expanded);
            newExpanded.delete(panel);
            setExpanded(newExpanded);
        }
    };

    return (
        <div className="work-form__inner__section__items work-items-list">
            {selectedItemId && (
                <ItemDetailsDialog
                    itemId={selectedItemId}
                    onClose={() => setSelectedItemId(null)}
                />
            )}
            {archiveWorks && archiveWorks.map((archiveWork) => (
                <WorkArchivesListItem
                    key={archiveWork.archive_work_id}
                    archiveWork={archiveWork}
                    archiveWorkItems={archiveWork.items}
                    expanded={expanded}
                    onExpanded={handleExpand}
                    onSelectItem={() => {}}
                    importId={importId}
                />
            ))}
        </div>
    );
};

WorkFormItems.defaultProps = {
    importId: undefined,
};

export default withTranslationContext(WorkFormItems);
