/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useState } from 'react';
import {
    Button,
    Switch,
    FormGroup,
    FormControlLabel,
} from '@mui/material';

import { Work } from '../../../types/works';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { Item } from '../../../types/items';
import ItemDetailsDialog from '../item/ItemDetailsDialog';
import WorkArchivesListItem from './WorkArchivesListItem';

interface OwnProps extends TranslationContext {
    work: Work;
    onRefresh(): void;
}

type Props = OwnProps;

const WorkScreenContentRight: FunctionComponent<Props> = (props: Props) => {
    const { t, work, onRefresh } = props;

    const [expanded, setExpanded] = useState<Set<string>>(new Set<string>());
    const [accessibleOnly, setAccessibleOnly] = useState<boolean>(false);
    const [selectedItemId, setSelectedItemId] = useState<number | null>(null);

    const mediaItemCount = work.items?.length || 0;

    const handleAccessibleOnly = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAccessibleOnly(event.target.checked);
    };

    const handleExpand = (panel: string, isExpanded: boolean) => {
        if (isExpanded) {
            setExpanded(new Set(expanded).add(panel));
        } else {
            const newExpanded = new Set(expanded);
            newExpanded.delete(panel);
            setExpanded(newExpanded);
        }
    };

    const handleExpandAll = () => {
        const all = (expanded.size === work.archiveWorks?.length);
        if (all) {
            setExpanded(new Set());
        } else {
            const codes = work.archiveWorks ? work.archiveWorks?.map((aw) => aw.archive_code) : [];
            setExpanded(new Set(codes));
        }
    };

    const handleItemClose = () => {
        setSelectedItemId(null);
        onRefresh();
    };

    return (
        <div className="work-screen__bottom__right work-items-list">
            {selectedItemId && (
                <ItemDetailsDialog
                    itemId={selectedItemId}
                    onClose={handleItemClose}
                />
            )}
            <div className="list-top">
                <div className="list-top__title">
                    {t('work.archives.top.totalItems', { count: `${mediaItemCount}` })}
                </div>
                {(mediaItemCount > 0) && (
                    <>
                        <FormGroup>
                            <FormControlLabel
                                data-testid="work-accessible-only-switch"
                                control={(
                                    <Switch
                                        checked={accessibleOnly}
                                        onChange={handleAccessibleOnly}
                                        size="small"
                                        color="success"
                                    />
                                )}
                                label={t('work.archives.top.showAccessibleOnly')}
                                className="switch-input"
                            />
                        </FormGroup>
                        <Button
                            data-testid="work-expand-all-button"
                            variant="text"
                            color="secondary"
                            onClick={handleExpandAll}
                        >
                            {(expanded.size === work.archiveWorks?.length) ? t('work.archives.top.collapseAll') : t('work.archives.top.expandAll')}
                        </Button>
                    </>
                )}
            </div>
            {work.archiveWorks && work.archiveWorks.map((archiveWork) => {
                const archiveWorkItems = (accessibleOnly) ? archiveWork.items.filter((item: Item) => item.accessible) : archiveWork.items;
                if (archiveWorkItems.length === 0) return null;

                return (
                    <WorkArchivesListItem
                        key={archiveWork.archive_work_id}
                        archiveWork={archiveWork}
                        archiveWorkItems={archiveWorkItems}
                        expanded={expanded}
                        onExpanded={handleExpand}
                        onSelectItem={(id) => setSelectedItemId(id)}
                    />
                );
            })}
        </div>
    );
};

export default withTranslationContext(WorkScreenContentRight);
