/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { Permission } from '../types/authorization';
import { AuthorizationAction, AuthorizationActionTypes } from './authorization_types';

export const setPermissionsActionCreator = (payload: Array<Permission>): AuthorizationActionTypes => {
    return {
        type: AuthorizationAction.SetPermissions,
        payload,
    };
};
