/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useState } from 'react';
import {
    FormControl, IconButton, InputLabel, TextField,
} from '@mui/material';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';

interface OwnProps {
    onChange(event: React.ChangeEvent<HTMLInputElement>): void;
    name: string;
    value: string;
    label?: string;
    placeholder?: string;
}

const PasswordField: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        label, name, value, onChange, placeholder,
    } = props;

    const [passwordVisible, setPasswordVisible] = useState(false);

    return (
        <FormControl fullWidth>
            {label && <InputLabel>{label}</InputLabel>}
            <TextField
                data-testid={`password-${name}`}
                type={passwordVisible ? 'text' : 'password'}
                fullWidth
                name={name}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                InputProps={{
                    endAdornment: (
                        <IconButton
                            className="icon-eye"
                            data-testid="password-visibility"
                            onClick={() => setPasswordVisible(!passwordVisible)}
                        >
                            {passwordVisible ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                        </IconButton>
                    ),
                }}
            />
        </FormControl>
    );
};

PasswordField.defaultProps = {
    label: '',
    placeholder: '',
};

export default PasswordField;
