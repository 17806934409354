/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useEffect, useState } from 'react';
import {
    Dialog, IconButton, Pagination, TextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { Close } from '@mui/icons-material';

import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { WorksContext, withWorksContext } from '../../controllers/works/WorksContext';
import { ListResponseMeta, ListSearchParams } from '../../../types/misc';
import { Work } from '../../../types/works';
import WorksList from '../WorksList';
import Loader from '../Loader';

interface OwnProps extends TranslationContext, WorksContext {
    onNewSuggestion(data: Work): void;
    onClose(): void;
}

type Props = OwnProps;

const WorkMatchesDialog: FunctionComponent<Props> = (props: Props) => {
    const {
        t, getWorks, onClose, onNewSuggestion,
    } = props;

    const workSearchDefaultParams: ListSearchParams = {
        search: '',
        page: 1,
    };

    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [searchParams, setSearchParams] = useState<ListSearchParams>(workSearchDefaultParams);
    const [worksList, setWorksList] = useState<Array<Work>>([]);
    const [paginationParams, setPaginationParams] = useState<ListResponseMeta | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);

    const onSearch = async () => {
        const params = (searchParams.search === '') ? workSearchDefaultParams : { ...searchParams, page: 1 };
        const search = (searchParams.search === '') ? undefined : searchParams;

        setSearchParams(params);
        const worksData = await getWorks(search);

        if (worksData) {
            setWorksList(worksData.data);
            setPaginationParams(worksData.meta);
            setCurrentPage(1);
        }
    };

    const fetchOnPageChange = async () => {
        const workData = await getWorks({ ...searchParams, page: currentPage });
        if (workData) {
            setWorksList(workData.data);
            setPaginationParams(workData.meta);
        }
    };

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target: { value } } = event;
        setSearchParams({ search: value });
    };

    const onPagination = async (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
    };

    const onNewMatchSelect = (data: Work) => {
        onNewSuggestion(data);
        onClose();
    };

    useEffect(() => {
        fetchOnPageChange();
    }, [currentPage]);

    useEffect(() => {
        const prepare = async () => {
            const workData = await getWorks(workSearchDefaultParams);
            if (workData) {
                setWorksList(workData.data);
                setPaginationParams(workData.meta);
            }
            setIsFetching(false);
        };

        prepare();
    }, []);

    if (isFetching) {
        return <Loader />;
    }
    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open
            className="matches-dialog"
            data-testid="work-match-dialog"
        >
            <div className="matches-dialog__top">
                <div className="matches-dialog__top__left">
                    <div>
                        <h2>{t('workReview.findMatchingWork')}</h2>
                    </div>
                </div>
                <IconButton
                    data-testid="work-match-close-button"
                    aria-label="close"
                    onClick={onClose}
                >
                    <Close color="primary" />
                </IconButton>
            </div>
            <div className="matches-dialog__search-area">
                <div className="matches-dialog__search-area__search-bar-wrapper">
                    <div className="matches-dialog__search-area__search-bar-wrapper__search-bar">
                        <TextField
                            fullWidth
                            data-testid="work-match-search-bar"
                            variant="standard"
                            placeholder={t('workReview.workSearchPlaceholder')}
                            value={searchParams.search}
                            onChange={onInputChange}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    onSearch();
                                }
                            }}
                            InputProps={{
                                disableUnderline: true,
                                startAdornment: <SearchIcon className="matches-dialog__search-area__search-bar-wrapper__search-icon" />,
                                endAdornment: (
                                    <>
                                        <IconButton onClick={() => setSearchParams({ search: '' })}>
                                            <CancelRoundedIcon />
                                        </IconButton>
                                        <IconButton
                                            className="matches-dialog__search-area__search-bar-wrapper__search-button"
                                            onClick={onSearch}
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </>
                                ),
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="matches-dialog__content">
                <div className="search-screen__works-container__works-list">
                    <WorksList works={worksList} isClickable={false} onWorkSelected={onNewMatchSelect} />
                </div>
                <div className="matches-dialog__content__pagination">
                    {paginationParams && worksList.length > 0 && (
                        <Pagination
                            count={paginationParams.last_page}
                            page={paginationParams.current_page}
                            onChange={onPagination}
                        />
                    )}
                </div>
            </div>
        </Dialog>
    );
};

export default withTranslationContext(withWorksContext(WorkMatchesDialog));
