/*
*
* @Copyright 2022 VOID SOFTWARE, S.A.
*
*/

import React, { ComponentType, createContext, FC } from 'react';

import { ConnectedSearchParamsController } from './SearchParamsController';
import { AdvancedSearchParams } from '../../../types/search';
 
export interface SearchParamsContext {
    simpleSearchParams: string;
    advancedSearchParams: AdvancedSearchParams | null;
    isAdvancedSearch: boolean;
    currentPage: number;
    simpleSearchInputDisplay: string;
    setSimpleSearchParams(simpleSearchParams: string): void;
    setAdvancedSearchParams(advancedSearchParams: AdvancedSearchParams | null): void;
    setIsAdvancedSearch(isAdvancedSearch: boolean): void;
    setCurrentPage(currentPage: number): void;
    setSimpleSearchInputDisplay(value: string): void;
    resetSearchParams(): void;
}
 
export const searchParamsContextDefaultValue = {
    simpleSearchParams: '',
    advancedSearchParams: null,
    isAdvancedSearch: false,
    currentPage: 1,
    simpleSearchInputDisplay: '',
    setSimpleSearchParams: (): void => {},
    setAdvancedSearchParams: (): void => {},
    setIsAdvancedSearch: (): void => {},
    setCurrentPage: (): void => {},
    setSimpleSearchInputDisplay: (): void => {},
    resetSearchParams: (): void => {},
};
 
const searchParamsContextInstance = createContext<SearchParamsContext | null>(searchParamsContextDefaultValue);
 
export const SearchParamsContextProvider = searchParamsContextInstance.Provider;
export const SearchParamsContextConsumer = searchParamsContextInstance.Consumer;
 
export const withSearchParamsContext = <P extends unknown>(Component: ComponentType<P>): FC<Omit<P, keyof SearchParamsContext>> => (props) => (
    <ConnectedSearchParamsController>
        <SearchParamsContextConsumer>
            {(ctx) => <Component {...props as P} {...ctx} />}
        </SearchParamsContextConsumer>
    </ConnectedSearchParamsController>
);
