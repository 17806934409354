/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, {
    FunctionComponent,
    ReactElement,
    useEffect,
    useState,
} from 'react';
import {
    TextField,
    InputAdornment,
    Pagination,
    Container,
    IconButton,
    Typography,
    Button,
    Select,
    FormControl,
    MenuItem,
    ListSubheader,
    SelectChangeEvent,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import { useLocation } from 'react-router';
import SearchIcon from '@mui/icons-material/Search';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { withWorksContext, WorksContext } from '../controllers/works/WorksContext';
import { SearchParamsContext, withSearchParamsContext } from '../controllers/searchParams/SearchParamsContext';
import { Work } from '../../types/works';
import WorksList from '../elements/WorksList';
import AdvancedSearch from '../elements/AdvancedSearch';
import {
    AdvancedSearchOptions,
    AdvancedSearchParams,
    ExportFileFormat,
    SearchSortDirectionOptions,
    SearchSortOrderOptions,
    SearchSortParams,
    SearchSortValues,
} from '../../types/search';
import Loader from '../elements/Loader';
import { MetaApiResponse } from '../../types/api';
import {
    AgentRoleCV,
    CarrierCV,
    CountryCV,
    GeneralColorCharacteristicCV,
    GeneralMediaTypeCV,
    ItemBaseCV,
    ItemElementCV,
    LanguageCV,
    SoundProductionCV,
    SpecificMediaTypeCV,
    ThirdPartyNameCV,
} from '../../types/controlled_vocabularies';
import { ControlledVocabularyName } from '../../services/controlled_vocabularies';
import {
    ControlledVocabulariesContext,
    withControlledVocabulariesContext,
} from '../controllers/controlled_vocabularies/ControlledVocabulariesContext';
import { ArchivesContext, withArchivesContext } from '../controllers/archives/ArchivesContext';
import { UsersContext, withUsersContext } from '../controllers/users/UsersContext';
import { ApiError } from '../../types/errors';
import ExportFileMenu from '../elements/ExportFileMenu';
import ListNoDataMessage from '../elements/ListNoDataMessage';
import { downloadFile } from '../../utils/misc';

type Props =
    TranslationContext
    & WorksContext
    & ControlledVocabulariesContext
    & ArchivesContext
    & UsersContext
    & SearchParamsContext;

const SearchScreen: FunctionComponent<Props> = (props): ReactElement | null => {
    const {
        t,
        getCV,
        getWorks,
        getUsers,
        getArchives,
        getArchivesCities,
        getWorksFile,
        setSimpleSearchInputDisplay,
        setAdvancedSearchParams,
        setIsAdvancedSearch,
        setCurrentPage,
        advancedSearchParams,
        isAdvancedSearch,
        currentPage,
        simpleSearchInputDisplay,
    } = props;

    const orderByParamsInitialValue: SearchSortParams = {
        order_by: SearchSortOrderOptions.Title,
        order_direction: SearchSortDirectionOptions.Ascending,
    };

    const location = useLocation();
    const [works, setWorks] = useState<Array<Work>>([]);
    const [paginationParams, setPaginationParams] = useState<MetaApiResponse | null>(null);
    const [advancedSearchOptions, setAdvancedSearchOptions] = useState<AdvancedSearchOptions | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [simpleSearchExportParam, setSimpleSearchExportParam] = useState<string>('');
    const [valueSearched, setValueSearched] = useState<string>('');
    const [searchTotalResults, setSearchTotalResults] = useState<number | null>(null);
    const [searchParamsToOrderBy, setSearchParamsToOrderBy] = useState<SearchSortParams>(orderByParamsInitialValue);
    const [searchSortInputValue, setSearchSortInputValue] = useState<SearchSortValues>(SearchSortValues.TitleAsc);

    const { enqueueSnackbar } = useSnackbar();

    const onRequestFailureMessage = (error?: ApiError) => {
        if (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
        } else {
            enqueueSnackbar(t('general.requestGeneralError'), { variant: 'error' });
        }
    };
    
    const fetchOnPageChange = async () => {
        if (advancedSearchParams) {
            setIsFetching(true);
            const data = await getWorks({ ...advancedSearchParams, page: currentPage, ...searchParamsToOrderBy });
            if (data) {
                setWorks(data.data);
                setPaginationParams(data.meta);
            }
            setIsFetching(false);
        } else if (simpleSearchInputDisplay) {
            setIsFetching(true);
            const data = await getWorks({ search: simpleSearchInputDisplay, page: currentPage, ...searchParamsToOrderBy });
            if (data) {
                setWorks(data.data);
                setPaginationParams(data.meta);
            }
            setIsFetching(false);
        }
    };

    async function fetchAdvancedSearchOptions() {
        setIsFetching(true);
        try {
            await Promise.all([
                getCV<CountryCV>(ControlledVocabularyName.Countries),
                getCV<SoundProductionCV>(ControlledVocabularyName.SoundProductions),
                getArchives({ page: -1 }),
                getCV<GeneralMediaTypeCV>(ControlledVocabularyName.GeneralMediaTypes),
                getCV<SpecificMediaTypeCV>(ControlledVocabularyName.SpecificMediaTypes),
                getCV<CarrierCV>(ControlledVocabularyName.Carriers),
                getCV<ItemElementCV>(ControlledVocabularyName.ItemElements),
                getCV<LanguageCV>(ControlledVocabularyName.Languages),
                getCV<GeneralColorCharacteristicCV>(ControlledVocabularyName.GeneralColorCharacteristics),
                getCV<ItemBaseCV>(ControlledVocabularyName.ItemBases),
                getArchivesCities(),
                getCV<AgentRoleCV>(ControlledVocabularyName.AgentRoles),
                getUsers({ page: -1 }),
                getCV<ThirdPartyNameCV>(ControlledVocabularyName.ThirdPartyNames),
            ]).then((results) => {
                setAdvancedSearchOptions({
                    countries: results[0],
                    soundProductions: results[1],
                    archives: results[2],
                    generalMediaTypes: results[3],
                    specificMediaTypes: results[4],
                    carriers: results[5],
                    itemElements: results[6],
                    languages: results[7],
                    generalColorCharacteristics: results[8],
                    itemBases: results[9],
                    archiveCities: results[10],
                    agentRoles: results[11],
                    users: results[12] ? results[12].data : [],
                    thirdPartyNames: results[13],
                });
            });
            setIsFetching(false);
        } catch (error) {
            const err = error as AxiosError;
            onRequestFailureMessage(err.response?.data);
        }
    }

    const handleSearch = async (advancedParams?: AdvancedSearchParams) => {
        setIsFetching(true);
        if (advancedParams) {
            setIsAdvancedSearch(false);
            setCurrentPage(1);
            setSimpleSearchExportParam(simpleSearchInputDisplay);
            setValueSearched('');
            const data = await getWorks(
                {
                    ...advancedParams,
                    page: currentPage,
                    ...searchParamsToOrderBy,
                },
            );
            if (data) {
                setWorks(data.data);
                setPaginationParams(data.meta);
                setSearchTotalResults(data.meta.total);
                setAdvancedSearchParams(advancedParams);
            }
            setIsFetching(false);
        } else {
            setIsAdvancedSearch(false);
            setSimpleSearchInputDisplay(simpleSearchInputDisplay);
            setSimpleSearchExportParam(simpleSearchInputDisplay);
            setValueSearched(simpleSearchInputDisplay);
            setAdvancedSearchParams(null);
            const data = await getWorks({
                search: simpleSearchInputDisplay,
                page: currentPage,
                ...searchParamsToOrderBy,
            });
            if (data) {
                setWorks(data.data);
                setPaginationParams(data.meta);
                setSearchTotalResults(data.meta.total);
            }
            setIsFetching(false);
        }
    };

    const handleSearchAction = () => {
        setCurrentPage(1);
        handleSearch();
    };

    const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.currentTarget.blur();
        handleSearchAction();
    };

    const handleAdvancedSearchButton = async () => {
        if (advancedSearchOptions === null) await fetchAdvancedSearchOptions();
        setIsAdvancedSearch(!isAdvancedSearch);
    };

    const handlePagination = async (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
    };

    const changeAdvancedSearchRender = () => {
        setIsAdvancedSearch(!isAdvancedSearch);
    };

    const updateSearchParamsWithAdvancedParams = (data: AdvancedSearchParams) => {
        setAdvancedSearchParams(data);
    };

    const onSortTypeSelect = (event: SelectChangeEvent) => {
        const {
            target: { value },
        } = event;
        setSearchSortInputValue(value as SearchSortValues);
        switch (value) {
            case SearchSortValues.TitleDesc:
                setSearchParamsToOrderBy({
                    order_by: SearchSortOrderOptions.Title,
                    order_direction: SearchSortDirectionOptions.Descending,
                });
                break;
            case SearchSortValues.DateAsc:
                setSearchParamsToOrderBy({
                    order_by: SearchSortOrderOptions.Date,
                    order_direction: SearchSortDirectionOptions.Ascending,
                });
                break;
            case SearchSortValues.DateDesc:
                setSearchParamsToOrderBy({
                    order_by: SearchSortOrderOptions.Date,
                    order_direction: SearchSortDirectionOptions.Descending,
                });
                break;
            default:
                setSearchParamsToOrderBy({
                    order_by: SearchSortOrderOptions.Title,
                    order_direction: SearchSortDirectionOptions.Ascending,
                });
        }
    };

    const renderList = (data: MetaApiResponse) => {
        if (works.length > 0 && data) {
            return (
                <>
                    <div className="search-screen__works-container__works-list">
                        <WorksList works={works} />
                    </div>
                    <div className="search-screen__works-container__pagination">
                        <Pagination
                            data-testid="search-pagination-item"
                            count={data.last_page}
                            page={data.current_page}
                            onChange={handlePagination}
                        />
                    </div>
                </>
            );
        }
        return (
            <div className="management-screen__bottom__list__no-data">
                <ListNoDataMessage searchValue={valueSearched} />
            </div>
        );
    };

    const renderAdvancedSearchButton = () => {
        if (!isAdvancedSearch) {
            return (
                <div className="search-screen__options">
                    <Button
                        data-testid="advanced-search-render-btn"
                        color="secondary"
                        size="small"
                        onClick={handleAdvancedSearchButton}
                    >
                        {t('search.advancedSearch')}
                    </Button>

                    {works.length !== 0 && (
                        <div className="search-screen__options__sort-wrapper">
                            <Typography>{t('sortSelect.sort')}</Typography>
                            <FormControl>
                                <Select
                                    MenuProps={{ classes: { paper: 'select' } }}
                                    value={searchSortInputValue}
                                    onChange={onSortTypeSelect}
                                    data-testid="sort-type-input"
                                >
                                    <ListSubheader className="red-item">{t('sortSelect.alphabetically')}</ListSubheader>
                                    <MenuItem value={SearchSortValues.TitleAsc}>{t('sortSelect.az')}</MenuItem>
                                    <MenuItem value={SearchSortValues.TitleDesc}>{t('sortSelect.za')}</MenuItem>
                                    <ListSubheader className="red-item">{t('sortSelect.releaseDate')}</ListSubheader>
                                    <MenuItem value={SearchSortValues.DateAsc}>{t('sortSelect.oldNew')}</MenuItem>
                                    <MenuItem value={SearchSortValues.DateDesc}>{t('sortSelect.newOld')}</MenuItem>
                                </Select>
                            </FormControl>
                            <ExportFileMenu onExport={onExport} />
                        </div>
                    )}
                </div>
            );
        }
    };

    const onExport = async (type: ExportFileFormat) => {
        setIsFetching(true);
        let fileResponse;
        try {
            if (advancedSearchParams) {
                fileResponse = await getWorksFile({ ...advancedSearchParams, export_format: type });
            } else {
                fileResponse = await getWorksFile({ search: simpleSearchExportParam, export_format: type });
            }
            if (fileResponse) {
                downloadFile(fileResponse);
            }
        } catch (error) {
            const err = error as AxiosError;
            onRequestFailureMessage(err.response?.data);
        } finally { setIsFetching(false); }
    };

    const onInputChange = (value: string) => {
        setSimpleSearchInputDisplay(value);
    };

    useEffect(() => {
        fetchOnPageChange();
    }, [currentPage]);

    useEffect(() => {
        if (advancedSearchParams) {
            handleSearch(advancedSearchParams);
        } else if (simpleSearchInputDisplay) {
            handleSearch();
        }
    }, [searchSortInputValue]);

    useEffect(() => {
        setWorks([]);
        setPaginationParams(null);
        setSearchTotalResults(null);
    }, [location.key]);

    return (
        <Container maxWidth="sm">
            <div className="search-screen" data-testid="search-screen">
                {isFetching && <Loader />}
                {!isAdvancedSearch && (
                    <form data-testid="work-form-searchbar" onSubmit={(e) => handleOnSubmit(e)}>
                        <TextField
                            placeholder={t('search.searchPlaceholder')}
                            className="search-screen__generic-search"
                            data-testid="work-suggestion-searchBar"
                            value={simpleSearchInputDisplay}
                            fullWidth
                            onChange={(e) => onInputChange(e.target.value)}
                            InputProps={{
                                startAdornment: <SearchIcon />,
                                endAdornment: (
                                    <>
                                        {searchTotalResults && (
                                            <InputAdornment position="end">
                                                <Typography variant="caption">
                                                    {String(searchTotalResults)} {t('search.results')}
                                                </Typography>
                                            </InputAdornment>
                                        )}
                                        <IconButton
                                            data-testid="clear-search-icon"
                                            onClick={() => {
                                                setSearchTotalResults(null);
                                                setSimpleSearchInputDisplay('');
                                            }}
                                        >
                                            <CancelRoundedIcon />
                                        </IconButton>
                                        <IconButton
                                            onClick={handleSearchAction}
                                            className="search-results-icon"
                                            data-testid="autocomplete-search-btn"
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </>
                                ),
                            }}
                        />
                    </form>
                )}
                {renderAdvancedSearchButton()}
                {(!isAdvancedSearch && paginationParams) && (
                    <div className="search-screen__works-container">
                        {renderList(paginationParams)}
                    </div>
                )}
                {(isAdvancedSearch && advancedSearchOptions) && (
                    <div className="search-screen__advanced-search">
                        <AdvancedSearch
                            selectOptions={advancedSearchOptions}
                            initialParams={advancedSearchParams}
                            handleSearch={handleSearch}
                            updateSearchParamsWithAdvancedParams={updateSearchParamsWithAdvancedParams}
                            onClose={changeAdvancedSearchRender}
                        />
                    </div>
                )}
            </div>
        </Container>
    );
};

export default withTranslationContext(
    withWorksContext(
        withControlledVocabulariesContext(
            withArchivesContext(
                withUsersContext(
                    withSearchParamsContext(
                        SearchScreen,
                    ),
                ),
            ),
        ),
    ),
);
