/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';

const IconExportFile: FunctionComponent = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38">
            <g transform="translate(-1286 -227.168)">
                <rect width="38" height="38" rx="6" transform="translate(1286 227.168)" />
                <g fill="#5a5a5a" fillRule="evenodd">
                    <path d="M1297.9 249.718h1.775v3.55h10.65v-3.55h1.775v3.55a1.78 1.78 0 0 1-1.775 1.775h-10.65a1.817 1.817 0 0 1-1.775-1.775Z" />
                    <path d="M1304.115 247.205v-9.915h1.775v9.915l2.877-2.876 1.256 1.255-5.021 5.021-5.021-5.021 1.255-1.255Z" />
                </g>
            </g>
        </svg>
    );
};

export default IconExportFile;
