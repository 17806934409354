/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import {
    Close,
    DeleteOutline,
    EditOutlined,
} from '@mui/icons-material';

import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import IconCircleAgent from '../../assets/IconCircleAgent';
import { GenericFunction } from '../../../types/misc';
import {
    Agent,
    AgentAlternateName,
    AgentFormFields,
    AgentPayload,
    AgentWorksInvolved,
} from '../../../types/agents';
import { AgentsContext, withAgentsContext } from '../../controllers/agents/AgentsContext';
import { getRandomInt } from '../../../utils/misc';
import Loader from '../Loader';
import { ApiError } from '../../../types/errors';
import Confirm from '../Confirm';
import ChipsInput from '../ChipsInput';
import { AuthorizationContext, withAuthorizationContext } from '../../controllers/authorization/AuthorizationContext';
import { Permission } from '../../../types/authorization';

enum FieldName {
    FirstName = 'first_name',
    LastName = 'last_name',
    ExtraNameInfo = 'extra_name_info',
    NameInverted = 'name_inverted',
}

const initialFieldValues: AgentFormFields = {
    first_name: '',
    last_name: '',
    extra_name_info: '',
    name_inverted: false,
};

interface OwnProps extends TranslationContext, AgentsContext, AuthorizationContext {
    agentId?: number;
    onClose: GenericFunction;
}

type Props = OwnProps;

const AgentFormDialog: FunctionComponent<Props> = (props: Props) => {
    const {
        t,
        agentId,
        onClose,
        getAgent,
        createAgent,
        editAgent,
        deleteAgent,
        checkPermission,
    } = props;

    const isEdit = !!agentId;

    const { enqueueSnackbar } = useSnackbar();
    
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [isViewingDetails, setIsViewingDetails] = useState<boolean>(true);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [agent, setAgent] = useState<Agent | null>(null);
    const [fieldValues, setFieldValues] = useState<AgentFormFields>(initialFieldValues);
    const [alternateNames, setAlternateNames] = useState<Array<Partial<AgentAlternateName>>>([]);
    const [isIndividualAgent, setIsIndividualAgent] = useState<boolean>(true);
    const [agentWorksInvolved, setAgentWorksInvolved] = useState<Array<AgentWorksInvolved>>([]);

    useEffect(() => {
        prepare();
    }, []);

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setFieldValues({
            ...fieldValues,
            [name]: value,
        });
    };

    const onAddNewAlternativeName = (newName: string) => {
        setAlternateNames([
            ...alternateNames,
            {
                id: getRandomInt(-10000, 0),
                alternate_name: newName,
            },
        ]);
    };

    const onSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {
            target: { checked, name },
        } = event;

        setFieldValues({
            ...fieldValues,
            [name]: checked,
        });
    };

    const onCreateSuccess = () => {
        enqueueSnackbar(t('agentFormDialog.successfullyCreated'), { variant: 'success' });
        onClose(true);
    };

    const onEditSuccess = (data: Agent) => {
        enqueueSnackbar(t('agentFormDialog.successfullyEdited'), { variant: 'success' });
        onClose(data);
    };

    const onDeleteSuccess = () => {
        enqueueSnackbar(t('agentDeleteConfirm.deleteSuccess'), { variant: 'success' });
        onClose(true);
    };

    const onFailure = (error?: ApiError) => {
        setIsFetching(false);
        if (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
        } else {
            enqueueSnackbar(t('general.requestGeneralError'), { variant: 'error' });
        }
    };

    const onFormSubmit = () => {
        const agentPayload: AgentPayload = {
            first_name: fieldValues[FieldName.FirstName],
            last_name: fieldValues[FieldName.LastName],
            extra_name_info: fieldValues[FieldName.ExtraNameInfo],
            name_inverted: fieldValues[FieldName.NameInverted],
            company: !isIndividualAgent,
            alternate_names: alternateNames,
        };

        setIsFetching(true);
        if (isEdit && agent) {
            editAgent(agent.id, agentPayload, onEditSuccess, onFailure);
        } else {
            createAgent(agentPayload, onCreateSuccess, onFailure);
        }
    };

    const onDelete = () => {
        if (agent) {
            setIsFetching(true);

            deleteAgent(agent.id, onDeleteSuccess, onFailure);
        }
    };

    const toggleIsIndividualAgent = () => {
        setFieldValues({
            ...fieldValues,
            [FieldName.LastName]: agent && !isIndividualAgent ? agent.last_name : '',
        });

        setIsIndividualAgent(!isIndividualAgent);
    };

    const prepare = async () => {
        if (agentId) {
            setIsFetching(true);

            const agentData = await getAgent(agentId);

            if (agentData) {
                setFieldValues({
                    [FieldName.FirstName]: agentData.first_name,
                    [FieldName.LastName]: agentData.last_name,
                    [FieldName.ExtraNameInfo]: agentData.extra_name_info,
                    [FieldName.NameInverted]: agentData.name_inverted,
                });
                setAlternateNames(agentData.alternate_names);
                setIsIndividualAgent(!agentData.company);
                setAgentWorksInvolved(agentData.works_involved || []);
                setAgent(agentData);
            }

            setIsFetching(false);
        } else {
            setIsViewingDetails(false);
        }
    };

    if (isFetching) {
        return <Loader />;
    }
    
    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open
            onClose={onClose}
            className="main-dialog archive-dialog"
            data-testid="agent-form-dialog"
        >
            {agent && (
                <Confirm
                    show={showConfirm}
                    title={t('agentDeleteConfirm.title')}
                    message={[t('agentDeleteConfirm.message1'), t('agentDeleteConfirm.message2')]}
                    okButton={t('agentDeleteConfirm.okButton')}
                    onClose={() => setShowConfirm(false)}
                    onConfirm={onDelete}
                >
                    <div className="delete-confirm delete-item">
                        <IconCircleAgent />
                        <p>{agent.name}</p>
                    </div>
                </Confirm>
            )}
            <div className="main-dialog__top">
                <div className="main-dialog__top__left">
                    {isViewingDetails ? (
                        <div>
                            <h2 className="secondary">{t('agentFormDialog.agent')}</h2>
                            <h3>{agent?.id}</h3>
                        </div>
                    ) : (
                        <div>
                            <h2 className="secondary">{isEdit ? t('agentFormDialog.editTitle') : t('agentFormDialog.createTitle')}</h2>
                            <h3>{agent?.id}</h3>
                        </div>
                    )}
                </div>
                <div className="main-dialog__top__right">
                    {!isViewingDetails && (
                        <Button
                            color="primary"
                            variant="contained"
                            data-testid="agent-save-changes-button"
                            disableElevation
                            onClick={onFormSubmit}
                        >
                            {isEdit ? t('agentFormDialog.saveChanges') : t('agentFormDialog.createAgent')}
                        </Button>
                    )}
                    {isViewingDetails && isEdit && checkPermission([Permission.UPDATE_AGENT]) && (
                        <Tooltip title={t('agentFormDialog.editTitle')}>
                            <IconButton
                                className="square-button"
                                data-testid="agent-edit-button"
                                onClick={() => setIsViewingDetails(false)}
                            >
                                <EditOutlined />
                            </IconButton>
                        </Tooltip>
                    )}
                    {isEdit && checkPermission([Permission.DELETE_AGENT]) && (
                        <Tooltip title={t('agentFormDialog.deleteTooltip')}>
                            <IconButton
                                className="square-button"
                                data-testid="agent-delete-button"
                                onClick={() => setShowConfirm(true)}
                            >
                                <DeleteOutline />
                            </IconButton>
                        </Tooltip>
                    )}
                    <IconButton
                        data-testid="agent-dialog-close-button"
                        aria-label="close"
                        onClick={() => onClose(false)}
                    >
                        <Close color="primary" />
                    </IconButton>
                </div>
            </div>
            <div className="main-dialog__content main-form">
                <Grid container spacing={2}>
                    <Grid item xs={12} className="centered">
                        <IconCircleAgent />
                    </Grid>
                    <Grid item xs={12} className="agent-type">
                        <Button
                            variant="text"
                            className={isIndividualAgent ? 'selected' : ''}
                            onClick={toggleIsIndividualAgent}
                            disabled={isViewingDetails}
                            data-testid="agent-dialog-individual-button"
                        >
                            {t('agentFormDialog.individual')}
                        </Button>
                        <Button
                            variant="text"
                            className={isIndividualAgent ? '' : 'selected'}
                            onClick={toggleIsIndividualAgent}
                            disabled={isViewingDetails}
                            data-testid="agent-dialog-institution-button"
                        >
                            {t('agentFormDialog.institution')}
                        </Button>
                    </Grid>
                </Grid>
                <h3>{t('agentFormDialog.preferredName')}</h3>
                <Grid container spacing={2}>
                    <Grid item xs={isIndividualAgent ? 6 : 12}>
                        <FormControl fullWidth>
                            <InputLabel>{isIndividualAgent ? t('agentFormDialog.firstName') : t('agentFormDialog.name')}</InputLabel>
                            <TextField
                                fullWidth
                                inputProps={{ 'data-testid': 'agent-first-name-input' }}
                                name={FieldName.FirstName}
                                value={fieldValues[FieldName.FirstName]}
                                onChange={onInputChange}
                                disabled={isViewingDetails}
                            />
                        </FormControl>
                    </Grid>
                    {isIndividualAgent && (
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel>{t('agentFormDialog.lastName')}</InputLabel>
                                <TextField
                                    fullWidth
                                    inputProps={{ 'data-testid': 'agent-last-name-input' }}
                                    name={FieldName.LastName}
                                    value={fieldValues[FieldName.LastName]}
                                    onChange={onInputChange}
                                    disabled={isViewingDetails}
                                />
                            </FormControl>
                        </Grid>
                    )}
                    {isIndividualAgent && (
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={(
                                    <Switch
                                        name={FieldName.NameInverted}
                                        checked={fieldValues[FieldName.NameInverted]}
                                        data-testid="agent-name-inverted-switch"
                                        onChange={onSwitchChange}
                                        size="small"
                                        color="success"
                                        disabled={isViewingDetails}
                                    />
                                )}
                                label={t('agentFormDialog.nameInverted')}
                                className="switch-input"
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <InputLabel>{t('agentFormDialog.extraNameInfo')}</InputLabel>
                        <TextField
                            fullWidth
                            inputProps={{ 'data-testid': 'agent-extra-name-info-input', maxLength: 10 }}
                            name={FieldName.ExtraNameInfo}
                            value={fieldValues[FieldName.ExtraNameInfo]}
                            onChange={onInputChange}
                            disabled={isViewingDetails}
                        />
                    </Grid>
                </Grid>
                <h3>{t('agentFormDialog.alternateNames')}</h3>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ChipsInput
                            data-testid="agent-alternate-name-chip-input"
                            onAdd={onAddNewAlternativeName}
                            onRemove={(name) => setAlternateNames([...alternateNames.filter((n) => n.alternate_name !== name)])}
                            values={alternateNames.map((name) => ({
                                value: name.alternate_name || '',
                            }))}
                            placeholder={t('agentFormDialog.addNewAlternativeName')}
                            disabled={isViewingDetails}
                            hideInput={isViewingDetails}
                        />
                    </Grid>
                </Grid>
                {agentWorksInvolved.length > 0 && (
                    <>
                        <h3>{t('agentFormDialog.works')}</h3>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Table size="small" data-testid="agent-works-table" className={isViewingDetails ? 'read-only' : ''}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t('agentFormDialog.name')}</TableCell>
                                            <TableCell>{t('agentFormDialog.role')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {agentWorksInvolved.map((work) => (
                                            <TableRow key={work.fiaf_work_id + work.agent_role}>
                                                <TableCell>{work.preferred_title}</TableCell>
                                                <TableCell>{work.agent_role}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </>
                )}
            </div>
        </Dialog>
    );
};

AgentFormDialog.defaultProps = {
    agentId: undefined,
};

export default withTranslationContext(withAuthorizationContext(withAgentsContext(AgentFormDialog)));
