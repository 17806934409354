/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';
import { KeyedObject } from '../types/general';
import { objectToParams } from '../utils/misc';

export const worksUrl = (filters?: KeyedObject): string => {
    const params = objectToParams(filters);
    return `${API_URL}/works${params}`;
};

export const workUrl = (id: number): string => {
    return `${API_URL}/works/${id}`;
};

export const workSuggestionsUrl = (filters?: KeyedObject): string => {
    const params = objectToParams(filters);
    return `${API_URL}/works/suggestions${params}`;
};
