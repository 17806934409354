/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';

export const loginURL = (): string => {
    return `${API_URL}/auth/login`;
};

export const logoutURL = (): string => {
    return `${API_URL}/auth/logout`;
};

export const recoverPasswordURL = (): string => {
    return `${API_URL}/auth/forgot_password`;
};

export const resetPasswordURL = (): string => {
    return `${API_URL}/auth/reset_password`;
};
