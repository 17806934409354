/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';

const IconCircleItem: FunctionComponent = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
            <g transform="translate(6856 -16951)">
                <rect width="60" height="60" rx="30" transform="translate(-6856 16951)" fill="#959595" className="icon-bg" />
                <path d="M-6838 16970.5a1.5 1.5 0 0 1 1.5-1.5h21a1.5 1.5 0 0 1 1.5 1.5v21a1.5 1.5 0 0 1-1.5 1.5h-21a1.5 1.5 0 0 1-1.5-1.5Zm6 0h12v9h-12Zm12 12h-12v9h12Zm-16.5-12h3v3h-3Zm3 4.5h-3v3h3Zm-3 4.5h3v3h-3Zm3 4.5h-3v3h3Zm-3 4.5h3v3h-3Zm21-18h-3v3h3Zm-3 4.5h3v3h-3Zm3 4.5h-3v3h3Zm-3 4.5h3v3h-3Zm3 4.5h-3v3h3Z" fill="#f1f2f2" fillRule="evenodd" className="icon-icon" />
            </g>
        </svg>
    );
};

export default IconCircleItem;
