/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, ReactElement } from 'react';
import { Route, Routes } from 'react-router';

import { AppRoute } from '../../constants/routes';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import TopBar from '../elements/TopBar';
import SearchScreen from './SearchScreen';
import WorkScreen from './WorkScreen';
import ArchivesScreen from './ArchivesScreen';
import AgentsScreen from './AgentsScreen';
import WorkFormScreen from './WorkFormScreen';
import ControlledVocabularyScreen from './ControlledVocabularyScreen';
import UsersScreen from './UsersScreen';
import DataListScreen from './DataListScreen';
import WorkReviewWrapperScreen from './WorkReviewWrapperScreen';

type Props = AuthenticationContext;

const ScreenBundler: FunctionComponent<Props> = (): ReactElement | null => {
    return (
        <div data-testid="screen-bundler">
            <header className="header">
                <TopBar />
            </header>
            <div className="screen-wrapper">
                <Routes>
                    <Route
                        path={AppRoute.Index}
                        element={<SearchScreen />}
                    />
                    <Route
                        path={AppRoute.WorkDetails}
                        element={<WorkScreen />}
                    />
                    <Route
                        path={AppRoute.WorkNew}
                        element={<WorkFormScreen />}
                    />
                    <Route
                        path={AppRoute.WorkEdit}
                        element={<WorkFormScreen />}
                    />
                    <Route
                        path={AppRoute.Archives}
                        element={<ArchivesScreen />}
                    />
                    <Route
                        path={AppRoute.Agents}
                        element={<AgentsScreen />}
                    />
                    <Route
                        path={AppRoute.Vocabulary}
                        element={<ControlledVocabularyScreen />}
                    />
                    <Route
                        path={AppRoute.Users}
                        element={<UsersScreen />}
                    />
                    <Route
                        path={AppRoute.WorkReview}
                        element={<WorkReviewWrapperScreen />}
                    />
                    <Route
                        path={AppRoute.WorkReviewChanges}
                        element={<WorkReviewWrapperScreen />}
                    />
                    <Route
                        path={AppRoute.Data}
                        element={<DataListScreen />}
                    />
                    <Route
                        path="/*"
                        element={(
                            <div>Not found</div>
                        )}
                    />
                </Routes>
            </div>
        </div>
    );
};

export default withAuthenticationContext(ScreenBundler);
