/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useState } from 'react';
import {
    Chip, MenuItem, Select, SelectChangeEvent, TextField,
} from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { ChipsInputValue } from '../../types/misc';

import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';

interface OwnProps extends TranslationContext {
    onAdd(newValue: string): void;
    values: Array<ChipsInputValue>;
    placeholder?: string;
    options?: Array<string>;
    onRemove(value: string): void;
    disabled?: boolean;
    hideInput?: boolean;
}

const ChipsInput: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t, onAdd, values, placeholder, options, onRemove, disabled, hideInput,
    } = props;

    const noOptions = t('general.noOptions');
    const [inputValue, setInputValue] = useState('');

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {
            target: { value },
        } = event;

        setInputValue(value);
    };

    const onSelectChange = (event: SelectChangeEvent) => {
        const {
            target: { value },
        } = event;

        if (value !== noOptions) {
            setInputValue(value);

            if (options) onAddNewValue(value);
        }
    };

    const onAddNewValue = (newValue: string) => {
        if (newValue.trim() && !values.find((v) => v.value.toLowerCase() === newValue.toLowerCase())) {
            onAdd(newValue);
            setInputValue('');
        }
    };

    const renderInput = () => {
        if (hideInput) return null;

        if (options && options.length > 0) {
            return (
                <Select
                    fullWidth
                    MenuProps={{ classes: { paper: 'select' } }}
                    data-testid="chips-select"
                    name="chips-select"
                    value={inputValue}
                    onChange={onSelectChange}
                    renderValue={
                        inputValue !== '' ? undefined : () => <div className="placeholder">{placeholder}</div>
                    }
                    displayEmpty
                    variant="standard"
                    disabled={disabled}
                >
                    {selectableOptions.map((opt) => (
                        <MenuItem dense key={opt} value={opt}>{opt}</MenuItem>
                    ))}
                </Select>
            );
        }

        return (
            <TextField
                fullWidth
                inputProps={{ 'data-testid': 'chips-input', placeholder }}
                name="chips-input"
                variant="standard"
                value={inputValue}
                onChange={onInputChange}
                disabled={disabled}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        onAddNewValue(inputValue);
                    }
                }}
            />
        );
    };

    let selectableOptions: Array<string> = [];
    if (options) selectableOptions = options.filter((o) => !values.find((v) => v.value === o));
    if (selectableOptions.length < 1) selectableOptions = [noOptions];

    return (
        <div className={`chips-input ${disabled ? 'disabled' : ''}`}>
            {renderInput()}
            <div className="chips-input__chips">
                <div className="chips-input__chips__inner">
                    {values.map((value) => (
                        <Chip
                            size="small"
                            key={value.value}
                            label={value.value}
                            deleteIcon={value.removeDisabled || disabled ? undefined : <CloseRounded data-testid="remove-chip" fontSize="small" />}
                            onDelete={value.removeDisabled || disabled ? undefined : () => onRemove(value.value)}
                            color={value.removeDisabled || disabled ? 'secondary' : 'default'}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

ChipsInput.defaultProps = {
    placeholder: '',
    options: [],
    disabled: false,
    hideInput: false,
};

const areEqual = (prevProps: Readonly<React.PropsWithChildren<OwnProps>>, nextProps: Readonly<React.PropsWithChildren<OwnProps>>) => {
    return !(prevProps.values.length !== nextProps.values.length
        || prevProps.options?.length !== nextProps.options?.length
        || prevProps.disabled !== nextProps.disabled
        || prevProps.hideInput !== nextProps.hideInput
    );
};

export default withTranslationContext(React.memo(ChipsInput, areEqual));
