/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { ReviewMetadataChangeDiffStatus, ReviewMetadataChangeItem } from '../../../types/reviews';
import { WorkThirdPartyIDs } from '../../../types/works';
import ReviewActionButtons from './ReviewActionButtons';
import { getReviewValueCompareClassName, getReviewValueRefClassName } from '../../../utils/review';

interface OwnProps {
    thirdPartyChange: ReviewMetadataChangeItem<WorkThirdPartyIDs>;
    onAccept(): void;
    onReject(): void;
}

const ReviewThirdPartyIdTableRow: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        thirdPartyChange, onReject, onAccept,
    } = props;
    const {
        valueCompare, valueRef, reviewOperation, diffStatus,
    } = thirdPartyChange;

    if (diffStatus === ReviewMetadataChangeDiffStatus.Deleted) return null;

    return (
        <React.Fragment>
            {valueRef && (
                <TableRow className={getReviewValueRefClassName(diffStatus, reviewOperation)}>
                    <TableCell>
                        <div className="review-status-cell">
                            {valueRef.id_value}
                        </div>
                    </TableCell>
                    <TableCell>
                        {valueRef.third_party_name}
                    </TableCell>
                    <TableCell align="right" data-testid="third-party-cell">
                        <ReviewActionButtons
                            reviewOperation={reviewOperation}
                            changeDiffStatus={diffStatus}
                            onAccept={onAccept}
                            onReject={onReject}
                        />
                    </TableCell>
                </TableRow>
            )}
            {diffStatus !== ReviewMetadataChangeDiffStatus.Unchanged && valueCompare && (
                <TableRow className={getReviewValueCompareClassName(diffStatus, reviewOperation)}>
                    <TableCell>
                        {valueCompare.id_value}
                    </TableCell>
                    <TableCell>
                        {valueCompare.third_party_name}
                    </TableCell>
                    <TableCell align="right" data-testid="third-party-cell">
                        {!valueRef && (
                            <ReviewActionButtons
                                reviewOperation={reviewOperation}
                                changeDiffStatus={diffStatus}
                                onAccept={onAccept}
                                onReject={onReject}
                            />
                        )}
                    </TableCell>
                </TableRow>
            )}
        </React.Fragment>
    );
};

export default ReviewThirdPartyIdTableRow;
