/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useEffect, useState } from 'react';
import {
    IconButton,
    MenuItem,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import { AddRounded, CheckRounded, CloseRounded } from '@mui/icons-material';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import {
    ControlledVocabulariesContext,
    withControlledVocabulariesContext,
} from '../../controllers/controlled_vocabularies/ControlledVocabulariesContext';
import { WorkTitle } from '../../../types/works';
import { TitleTypeCV } from '../../../types/controlled_vocabularies';
import { ControlledVocabularyName } from '../../../services/controlled_vocabularies';
import { getRandomInt } from '../../../utils/misc';
import Loader from '../Loader';

enum WorkTitleFieldsName {
    Value = 'value',
    TitleType = 'title_type',
}

interface OwnProps extends TranslationContext, ControlledVocabulariesContext {
    titles: Array<WorkTitle>;
    onAdd?(title: WorkTitle): void;
    onRemove?(id: number): void;
}

const WorkFormTitlesTable: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t, getCV, titles, onAdd, onRemove,
    } = props;

    const [titleTypes, setTitleTypes] = useState<Array<TitleTypeCV>>([]);
    const [newTitle, setNewTitle] = useState<WorkTitle | null>(null);
    const [isFetching, setIsFetching] = useState(true);

    useEffect(() => {
        const requestTitleTypes = async () => {
            const results = await getCV<TitleTypeCV>(ControlledVocabularyName.TitleTypes);
            if (results) {
                setTitleTypes(results.data);
            }
            setIsFetching(false);
        };

        requestTitleTypes();
    }, []);

    useEffect(() => {
        if (titles.length === 0) {
            setNewTitleData();
        } else {
            setNewTitle(null);
        }
    }, [titles]);

    const onAddNewTitle = () => {
        setNewTitleData();
    };

    const onAccept = () => {
        if (newTitle) onAdd?.(newTitle);
        setNewTitle(null);
    };

    const setNewTitleData = () => {
        setNewTitle({
            id: getRandomInt(-100000, 0),
            title_type: '',
            value: '',
        });
    };

    const onSelect = (event: SelectChangeEvent) => {
        const {
            target: { value },
        } = event;

        if (newTitle) {
            setNewTitle({
                ...newTitle,
                title_type: value,
            });
        }
    };

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {
            target: { value },
        } = event;

        if (newTitle) {
            setNewTitle({
                ...newTitle,
                value,
            });
        }
    };

    if (isFetching) {
        return <Loader />;
    }

    if (titles.length === 0 && !onAdd) {
        return null;
    }

    return (
        <Table size="small" data-testid="other-titles-table">
            <TableHead>
                <TableRow>
                    <TableCell>{t('work.form.otherTitles')}</TableCell>
                    <TableCell>{t('work.form.type')}</TableCell>
                    {onAdd && (
                        <TableCell className="add-btn-cell">
                            <IconButton data-testid="add-new-title-btn" className="add-small-btn" onClick={onAddNewTitle}>
                                <AddRounded />
                            </IconButton>
                        </TableCell>
                    )}
                </TableRow>
            </TableHead>
            <TableBody>
                {titles.map((title) => (
                    <TableRow key={title.id}>
                        <TableCell data-testid="existing-title-name">{title.value}</TableCell>
                        <TableCell data-testid="existing-title-type">{title.title_type}</TableCell>
                        {onRemove && (
                            <TableCell align="right">
                                <IconButton
                                    size="small"
                                    data-testid="other-title-delete-btn"
                                    onClick={() => onRemove(title.id)}
                                    className="action-btn"
                                >
                                    <CloseRounded />
                                </IconButton>
                            </TableCell>
                        )}
                    </TableRow>
                ))}
                {newTitle && (
                    <TableRow>
                        <TableCell className="input-cell">
                            <TextField
                                fullWidth
                                variant="standard"
                                inputProps={{ 'data-testid': 'other-title-input', placeholder: t('work.form.titleValue') }}
                                name={WorkTitleFieldsName.Value}
                                value={newTitle[WorkTitleFieldsName.Value]}
                                onChange={onInputChange}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' && newTitle[WorkTitleFieldsName.Value] && newTitle[WorkTitleFieldsName.TitleType]) {
                                        onAccept();
                                    }
                                }}
                            />
                        </TableCell>
                        <TableCell>
                            <Select
                                fullWidth
                                MenuProps={{ classes: { paper: 'select' } }}
                                data-testid="other-title-type"
                                className="simple-select dense-select"
                                name={WorkTitleFieldsName.TitleType}
                                value={newTitle[WorkTitleFieldsName.TitleType]}
                                onChange={onSelect}
                                displayEmpty
                            >
                                <MenuItem dense value="">
                                    <em>{t('general.select')}</em>
                                </MenuItem>
                                {titleTypes.map((value) => (
                                    <MenuItem data-testid="other-title-type-item" dense key={value.name} value={value.name}>{value.name}</MenuItem>
                                ))}
                            </Select>
                        </TableCell>
                        <TableCell align="right">
                            <IconButton
                                size="small"
                                data-testid="other-title-confirm-btn"
                                onClick={onAccept}
                                className="action-btn"
                                disabled={!newTitle[WorkTitleFieldsName.Value] || !newTitle[WorkTitleFieldsName.TitleType]}
                            >
                                <CheckRounded />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
};

WorkFormTitlesTable.defaultProps = {
    onAdd: undefined,
    onRemove: undefined,
};

export default withTranslationContext(withControlledVocabulariesContext(WorkFormTitlesTable));
