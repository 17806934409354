/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { AdvancedSearchParams } from '../types/search';

export enum SearchParamsAction {
    SET_SIMPLE_SEARCH_PARAMS = 'SET_SIMPLE_SEARCH_PARAMS',
    SET_ADVANCED_SEARCH_PARAMS = 'SET_ADVANCED_SEARCH_PARAMS',
    SET_IS_ADVANCED_SEARCH = 'SET_IS_ADVANCED_SEARCH',
    SET_CURRENT_PAGE = 'SET_CURRENT_PAGE',
    SET_SIMPLE_SEARCH_INPUT_DISPLAY = 'SET_SIMPLE_SEARCH_INPUT_DISPLAY',
    RESET_SEARCH_PARAMS = 'RESET_SEARCH_PARAMS',
}

export interface SetSimpleSearchParamsActionType {
    type: typeof SearchParamsAction.SET_SIMPLE_SEARCH_PARAMS;
    payload: string;
}

export interface SetAdvancedSearchParamsActionType {
    type: typeof SearchParamsAction.SET_ADVANCED_SEARCH_PARAMS;
    payload: AdvancedSearchParams | null;
}

export interface SetIsAdvancedSearchActionType {
    type: typeof SearchParamsAction.SET_IS_ADVANCED_SEARCH;
    payload: boolean;
}

export interface SetCurrentPageActionType {
    type: typeof SearchParamsAction.SET_CURRENT_PAGE;
    payload: number;
}

export interface SetSimpleSearchInputDisplayActionType {
    type: typeof SearchParamsAction.SET_SIMPLE_SEARCH_INPUT_DISPLAY;
    payload: string;
}

export interface ResetSearchParamsActionType {
    type: typeof SearchParamsAction.RESET_SEARCH_PARAMS;
}

export type SearchParamsActionTypes = SetSimpleSearchParamsActionType
    | SetAdvancedSearchParamsActionType
    | SetIsAdvancedSearchActionType
    | SetCurrentPageActionType
    | SetSimpleSearchInputDisplayActionType
    | ResetSearchParamsActionType;
