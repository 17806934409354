/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useState } from 'react';

import { Work } from '../../../types/works';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { getWorkAgent, getWorkAlternativeTitles } from '../../../utils/work';
import { AgentType } from '../../../types/agents';
import { getAgentName } from '../../../utils/agents';

interface ListNameObject {
    title: string;
    subtitle?: string;
    probable?: boolean;
}

interface OwnProps extends TranslationContext {
    work: Work;
}

type Props = OwnProps;

const WorkScreenContentLeft: FunctionComponent<Props> = (props: Props) => {
    const { t, work } = props;

    const [expanded, setExpanded] = useState<Array<string>>([]);

    const handleExpand = (title: string) => {
        if (!expanded.includes(title)) setExpanded([...expanded, title]);
    };

    const renderNamesList = (title: string, names: Array<ListNameObject>) => {
        if (names.length === 0) return null;

        const visible = (names.length > 5) ? names.slice(0, 5) : names;
        const more = (names.length > 5) ? names.slice(5, names.length) : [];
        const showAll = expanded.includes(title);

        return (
            <div className="item">
                <h4>{title}</h4>
                <ul>
                    {visible.map((name) => (
                        <li key={name.title}>
                            {name.title} <span>{name.subtitle || ''}</span> <span className="probable">{name.probable ? t('work.lists.probable') : ''}</span>
                        </li>
                    ))}
                    {(more.length > 0 && !showAll) && (
                        <li
                            data-testid="more-button"
                            className="more"
                            onClick={() => handleExpand(title)}
                        >
                            {t('work.lists.more', { count: `${more.length}` })}
                        </li>
                    )}
                    {(more.length > 0 && showAll) && more.map((name) => (
                        <li key={name.title}>
                            {name.title} <span>{name.subtitle || ''}</span>
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    const thirdPartyWorkIds = work.thirdPartyWorkIds || [];
    const alternativeTitles = getWorkAlternativeTitles(work);
    const series = work.series || [];
    const countries = work.countries || [];
    const dates = work.dates || [];
    const directors = getWorkAgent(work, AgentType.Director);
    const producers = getWorkAgent(work, AgentType.Producer);
    const productionCompanies = getWorkAgent(work, AgentType.ProductionCompany);
    const editors = getWorkAgent(work, AgentType.Editor);
    const screenwriters = getWorkAgent(work, AgentType.Screenwriter);
    const cinematographers = getWorkAgent(work, AgentType.Photography);
    const cast = getWorkAgent(work, AgentType.Cast);
    const agents = getWorkAgent(work);
    const hasData = (thirdPartyWorkIds.length > 0 || alternativeTitles.length > 0 || series.length > 0 || countries.length > 0 || dates.length > 0 || (work.agents && work.agents.length > 0));

    if (!hasData) {
        return (
            <div className="work-screen__bottom__left">
                <div className="no-results">{t('work.lists.noResults')}</div>
            </div>
        );
    }

    return (
        <div className="work-screen__bottom__left">
            {renderNamesList(
                t('work.lists.ids'),
                thirdPartyWorkIds.map((i) => {
                    return { title: i.id_value, subtitle: i.third_party_name };
                }),
            )}
            {renderNamesList(
                t('work.lists.alternativeTitles'),
                alternativeTitles.map((tt) => {
                    return { title: tt.value };
                }),
            )}
            {renderNamesList(
                t('work.lists.series'),
                series.map((s) => {
                    return { title: s.title };
                }),
            )}
            {renderNamesList(
                t('work.lists.countries'),
                countries.map((c) => {
                    return { title: c.name, probable: c.country_probable };
                }),
            )}
            {renderNamesList(
                t('work.lists.dates'),
                dates.map((d) => {
                    return { title: d.date_string, subtitle: d.date_type, probable: !d.date_accuracy };
                }),
            )}
            {renderNamesList(
                t('work.lists.directedBy'),
                directors.map((d) => {
                    return { title: getAgentName(d) };
                }),
            )}
            {renderNamesList(
                t('work.lists.producedBy'),
                producers.map((p) => {
                    return { title: getAgentName(p) };
                }),
            )}
            {renderNamesList(
                t('work.lists.productionCompany'),
                productionCompanies.map((pc) => {
                    return { title: getAgentName(pc) };
                }),
            )}
            {renderNamesList(
                t('work.lists.editedBy'),
                editors.map((e) => {
                    return { title: getAgentName(e) };
                }),
            )}
            {renderNamesList(
                t('work.lists.writtenBy'),
                screenwriters.map((s) => {
                    return { title: getAgentName(s) };
                }),
            )}
            {renderNamesList(
                t('work.lists.cinematography'),
                cinematographers.map((c) => {
                    return { title: getAgentName(c) };
                }),
            )}
            {renderNamesList(
                t('work.lists.cast'),
                cast.map((c) => {
                    return { title: getAgentName(c) };
                }),
            )}
            {renderNamesList(
                t('work.lists.additionalCredits'),
                agents.map((a) => {
                    return { title: getAgentName(a), subtitle: a.agent_role };
                }),
            )}
            {work.content_description && (
                <div className="txt-container">
                    <h4>{t('work.lists.description')}</h4>
                    <p>{work.content_description}</p>
                </div>
            )}
            {work.notes && (
                <div className="txt-container">
                    <h4>{t('work.lists.notes')}</h4>
                    <p>{work.notes}</p>
                </div>
            )}
        </div>
    );
};

export default withTranslationContext(WorkScreenContentLeft);
