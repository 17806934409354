/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

export interface ImportResponseData {
    id: number;
    total_items_imported: number;
    total_items_imported_insert: number;
    total_items_imported_update: number;
    total_items_to_import: number;
    url_validation_messages_excel: string | null;
    url_validation_messages_text: string | null;
    total_validation_error_messages: number;
    total_works_imported_insert: number;
    total_works_imported_update: number;
    total_works_to_review: number;
    total_works_imported: number;
    status?: ImportResponseDataStatus;
}

export enum ImportResponseDataStatus {
    InterruptedWithErrors = 'interrupted_with_errors'
}
