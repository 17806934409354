/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

export enum AgentType {
    Cast = 'Cast',
    Director = 'Director',
    Photography = 'Photography',
    Producer = 'Producer',
    ProductionCompany = 'Production Company',
    Screenwriter = 'Screenwriter',
    Editor = 'Editor',
    Credit = 'Credit',
}

export interface AgentAlternateName {
    id: number;
    agent_id: number;
    alternate_name: string;
}

export interface AgentRole {
    agent_role: string;
    total: number;
}

export interface AgentWorksInvolved {
    fiaf_work_id: number;
    preferred_title: string;
    agent_role: string;
}

export interface Agent {
    id: number;
    name: string;
    company: boolean;
    first_name: string;
    last_name: string;
    extra_name_info: string;
    name_inverted: boolean;
    alternate_names: Array<AgentAlternateName>;
    agent_roles: Array<AgentRole>;
    works_involved: Array<AgentWorksInvolved>;
}

export interface AgentFormFields {
    first_name: string;
    last_name: string;
    extra_name_info: string;
    name_inverted: boolean;
}

export interface AgentPayload {
    first_name: string;
    last_name: string;
    extra_name_info: string;
    name_inverted: boolean;
    company: boolean;
    alternate_names: Array<Partial<AgentAlternateName>>;
}
