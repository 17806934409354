/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

export enum PermissionOperator {
    OR = 'OR',
    AND = 'AND',
}

export enum Permission {
    // general
    VIEW_ADVANCED_SEARCH = 'VIEW_ADVANCED_SEARCH',
    USE_IMPORT = 'USE_IMPORT',
    MANAGE_ALL_ARCHIVES = 'MANAGE_ALL_ARCHIVES',
    // search
    SEARCH_BY_CREATION = 'SEARCH_BY_CREATION',
    // work
    CREATE_WORK = 'CREATE_WORK',
    UPDATE_WORK = 'UPDATE_WORK',
    DELETE_WORK = 'DELETE_WORK',
    // item
    CREATE_ITEM = 'CREATE_ITEM',
    UPDATE_ITEM = 'UPDATE_ITEM',
    DELETE_ITEM = 'DELETE_ITEM',
    // users
    LIST_USERS = 'LIST_USERS',
    CREATE_USER = 'CREATE_USER',
    UPDATE_USER = 'UPDATE_USER',
    DELETE_USER = 'DELETE_USER',
    // agents
    LIST_AGENTS = 'LIST_AGENTS',
    CREATE_AGENT = 'CREATE_AGENT',
    UPDATE_AGENT = 'UPDATE_AGENT',
    DELETE_AGENT = 'DELETE_AGENT',
    // archives
    LIST_ARCHIVES = 'LIST_ARCHIVES',
    CREATE_ARCHIVE = 'CREATE_ARCHIVE',
    UPDATE_ARCHIVE = 'UPDATE_ARCHIVE',
    DELETE_ARCHIVE = 'DELETE_ARCHIVE',
    // controlled vocabulary
    LIST_VOCABULARY = 'LIST_VOCABULARY',
    CREATE_VOCABULARY = 'CREATE_VOCABULARY',
    UPDATE_VOCABULARY = 'UPDATE_VOCABULARY',
    DELETE_VOCABULARY = 'DELETE_VOCABULARY',
    // data validation
    LIST_DATA_VALIDATION = 'LIST_DATA_VALIDATION'
}
