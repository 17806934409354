/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { ComponentType, createContext, FC } from 'react';

import { KeyedObject } from '../../../types/general';
import { GenericFunction } from '../../../types/misc';
import {
    Work,
    WorkApiResponse,
    WorkPayload,
    WorkSuggestionApiResponse,
} from '../../../types/works';
import { ConnectedWorksController } from './WorksController';

export interface WorksContext {
    getWork(id: number): Promise<Work | null>;
    deleteWork(id: number, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void>;
    getWorks(filters?: KeyedObject): Promise<WorkApiResponse | null>;
    getWorksSuggestions(filters?: KeyedObject): Promise<WorkSuggestionApiResponse | null>;
    createWork(payload: WorkPayload, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void>;
    updateWork(id: number, payload: WorkPayload, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void>;
    getWorksFile(filters?: KeyedObject): Promise<{ file: Blob, fileName: string } | null>;
}

export const worksContextDefaultValue = {
    getWork: async (): Promise<Work | null> => null,
    deleteWork: async (): Promise<void> => {},
    getWorks: async (): Promise<WorkApiResponse | null> => null,
    getWorksSuggestions: async (): Promise<WorkSuggestionApiResponse | null> => null,
    createWork: async (): Promise<void> => {},
    updateWork: async (): Promise<void> => {},
    getWorksFile: async (): Promise<{ file: Blob, fileName: string }| null> => null,
};

const workContextInstance = createContext<WorksContext | null>(worksContextDefaultValue);

export const WorksContextProvider = workContextInstance.Provider;
export const WorksContextConsumer = workContextInstance.Consumer;

export const withWorksContext = <P extends unknown>(Component: ComponentType<P>): FC<Omit<P, keyof WorksContext>> => (props) => (
    <ConnectedWorksController>
        <WorksContextConsumer>
            {(ctx) => <Component {...props as P} {...ctx} />}
        </WorksContextConsumer>
    </ConnectedWorksController>
);
