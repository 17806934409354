/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useState } from 'react';
import { AddRounded, CheckRounded, CloseRounded } from '@mui/icons-material';
import {
    IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField,
} from '@mui/material';

import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { LanguageCV } from '../../../types/controlled_vocabularies';

interface OwnProps extends TranslationContext {
    languagesList: Array<LanguageCV>;
    onRemove(languageIsoCode: string): void;
    onAdd(language: LanguageCV): void;
    viewOnly?: boolean;
}

enum FieldName {
    Name = 'name',
    Code = 'code',
}

const CVFormLanguagesTable: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t, languagesList, onRemove, onAdd, viewOnly,
    } = props;

    const [newLanguage, setNewLanguage] = useState<LanguageCV | null>(null);

    const onAddNewLanguage = () => {
        setNewLanguage({
            code: '',
            name: '',
        });
    };

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {
            target: { value, name },
        } = event;

        if (newLanguage) {
            setNewLanguage({
                ...newLanguage,
                [name]: value,
            });
        }
    };

    const onAccept = () => {
        if (newLanguage) onAdd(newLanguage);
        setNewLanguage(null);
    };

    return (
        <Table size="small" data-testid="cv-languages-table">
            <TableHead>
                <TableRow>
                    <TableCell>{t('controlledVocabularyForm.languages.language')}</TableCell>
                    <TableCell>{t('controlledVocabularyForm.languages.isoCode')}</TableCell>
                    {!viewOnly && (
                        <TableCell className="add-btn-cell">
                            <IconButton data-testid="add-cv-language-btn" className="add-small-btn" onClick={onAddNewLanguage}>
                                <AddRounded />
                            </IconButton>
                        </TableCell>
                    )}
                </TableRow>
            </TableHead>
            <TableBody>
                {languagesList.map((language) => (
                    <TableRow key={language.code}>
                        <TableCell>{language.name}</TableCell>
                        <TableCell>{language.code}</TableCell>
                        {!viewOnly && (
                            <TableCell align="right">
                                <IconButton
                                    size="small"
                                    data-testid="cv-language-delete-btn"
                                    onClick={() => onRemove(language.code)}
                                    className="action-btn"
                                >
                                    <CloseRounded />
                                </IconButton>
                            </TableCell>
                        )}
                    </TableRow>
                ))}
                {newLanguage && (
                    <TableRow>
                        <TableCell>
                            <TextField
                                fullWidth
                                variant="standard"
                                inputProps={{
                                    'data-testid': 'cv-language-name-input',
                                    placeholder: t('controlledVocabularyForm.languages.language'),
                                }}
                                name={FieldName.Name}
                                value={newLanguage[FieldName.Name]}
                                onChange={onInputChange}
                            />
                        </TableCell>
                        <TableCell>
                            <TextField
                                fullWidth
                                variant="standard"
                                inputProps={{
                                    'data-testid': 'cv-language-code-input',
                                    placeholder: t('controlledVocabularyForm.languages.code'),
                                }}
                                name={FieldName.Code}
                                value={newLanguage[FieldName.Code]}
                                onChange={onInputChange}
                            />
                        </TableCell>
                        <TableCell align="right">
                            <IconButton
                                size="small"
                                data-testid="cv-language-confirm-btn"
                                onClick={onAccept}
                                className="action-btn"
                                disabled={!newLanguage.name || !newLanguage.code}
                            >
                                <CheckRounded />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
};

CVFormLanguagesTable.defaultProps = {
    viewOnly: false,
};

export default withTranslationContext(CVFormLanguagesTable);
