/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, ReactNode } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import axios, { AxiosError } from 'axios';

import { AppState } from '../../../types/store';
import { LoginRequestPayload, RecoverPasswordPayload, RecoverPasswordResponsePayload } from '../../../types/authentication';
import { requestLogin, requestLogout, setAuthenticatedUserActionCreator } from '../../../actions/authentication';
import { AuthenticationContextProvider } from './AuthenticationContext';
import { EmptyObject, GenericFunction } from '../../../types/misc';
import { recoverPasswordURL, resetPasswordURL } from '../../../services/authentication';
import { User, UserSetPasswordPayload } from '../../../types/users';

interface StateProps {
    token: string | null;
    isAuthenticated: boolean;
    authenticatedUser: User | null;
}

interface OwnProps {
    children: ReactNode;
}

interface DispatchProps {
    dispatchRequestLogin(payload: LoginRequestPayload, onSuccess: () => void, onFailure?: GenericFunction): void;
    dispatchRequestLogout: (onFailure?: GenericFunction) => void;
    dispatchSetAuthenticatedUser: (user: User) => void;
}

type Props = StateProps & OwnProps & DispatchProps;

export class AuthenticationController extends Component<Props> {
    submitLogin = (payload: LoginRequestPayload, onSuccess: () => void, onFailure?: () => void): void => {
        const { dispatchRequestLogin } = this.props;

        dispatchRequestLogin(payload, onSuccess, onFailure);
    };

    submitRecoverPassword = async (payload: RecoverPasswordPayload): Promise<RecoverPasswordResponsePayload | null> => {
        try {
            const { data } = await axios.post(recoverPasswordURL(), payload);
            return data;
        } catch {
            return null;
        }
    };

    submitLogout = (onFailure?: GenericFunction): void => {
        const {
            dispatchRequestLogout,
        } = this.props;

        dispatchRequestLogout(onFailure);
    };

    setAuthenticatedUser = (user: User): void => {
        const { dispatchSetAuthenticatedUser } = this.props;
        dispatchSetAuthenticatedUser(user);
    };

    submitResetPassword = async (payload: UserSetPasswordPayload, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void> => {
        try {
            await axios.post(resetPasswordURL(), payload);
            onSuccess();
        } catch (error) {
            const err = error as AxiosError;
            onFailure(err.response?.data);
        }
    };

    render(): ReactNode {
        const {
            children,
            token,
            isAuthenticated,
            authenticatedUser,
        } = this.props;

        return (
            <AuthenticationContextProvider
                value={{
                    token,
                    isAuthenticated,
                    authenticatedUser,
                    submitLogout: this.submitLogout,
                    submitLogin: this.submitLogin,
                    submitRecoverPassword: this.submitRecoverPassword,
                    setAuthenticatedUser: this.setAuthenticatedUser,
                    submitResetPassword: this.submitResetPassword,
                }}
            >
                {children}
            </AuthenticationContextProvider>
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => {
    return {
        token: state.authentication.token,
        isAuthenticated: state.authentication.isAuthenticated,
        authenticatedUser: state.authentication.authenticatedUser,
    };
};

export const mapDispatchToProps = (dispatch: ThunkDispatch<EmptyObject, EmptyObject, AnyAction>): DispatchProps => ({
    dispatchRequestLogin: (
        payload: LoginRequestPayload,
        onSuccess: () => void,
        onFailure?: GenericFunction,
    ) => dispatch(requestLogin(payload, onSuccess, onFailure)),
    dispatchRequestLogout: (onFailure?: GenericFunction) => dispatch(requestLogout(onFailure)),
    dispatchSetAuthenticatedUser: (user: User) => dispatch(setAuthenticatedUserActionCreator(user)),
});

export const ConnectedAuthenticationController = connect(mapStateToProps, mapDispatchToProps)(AuthenticationController);
