/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { AppRoute } from '../constants/routes';
import { ExportFile } from '../types/misc';

export const arrayToParams = (paramName: string, arrayValues: unknown[], useQ = true): string => {
    let params = '';

    arrayValues.forEach((value) => {
        const includeAnd = params.length > 0 ? '&' : '';
        params += `${includeAnd}${paramName}[]=${value}`;
    });

    if (useQ) {
        if (params && params.length > 0) {
            params = `?${params}`;
        }
    }

    return params;
};

export const objectToParams = (obj?: Record<string, string | number | undefined | unknown[]>, useQ = true): string => {
    let params = '';

    if (obj === undefined) return params;

    const constructQueryParam = (key: string, value?: string | number | unknown[]) => {
        if (!value) {
            return '';
        }
        if (typeof value === 'string') {
            return `${key}=${encodeURIComponent(value)}`;
        }
        return `${key}=${value}`;
    };

    Object.keys(obj).forEach((key) => {
        if (obj[key] !== undefined) {
            const val = (Array.isArray(obj[key])) ? obj[key] : `${obj[key]}`;
            if (params.length > 0) {
                if (Array.isArray(val)) {
                    params = `${params}&${arrayToParams(key, val, false)}`;
                } else {
                    params = `${params}&${constructQueryParam(key, val)}`;
                }
            } else {
                if (Array.isArray(val)) {
                    params = `${arrayToParams(key, val, false)}`;
                } else {
                    params = constructQueryParam(key, obj[key]);
                }
            }
        }
    });

    if (useQ) {
        if (params && params.length > 0) {
            params = `?${params}`;
        }
    }

    return params;
};

export const getRandomInt = (min: number, max: number): number => {
    return Math.floor(Math.random() * (max - min)) + min;
};

export const buildRoute = (route: AppRoute, params: Record<string, string | number>): string => {
    let finalRoute: string = route;

    Object.keys(params).forEach((key) => {
        finalRoute = finalRoute.replace(`:${key}`, `${params[key]}`);
    });

    return finalRoute;
};

export const downloadFile = (file: ExportFile): void => {
    const url = URL.createObjectURL(file.file);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', file.fileName);
    document.body.appendChild(link);
    link.click();
    if (link.parentNode) {
        link.parentNode.removeChild(link);
    }
    URL.revokeObjectURL(url);
};
