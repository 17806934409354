/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { ArchiveWork } from './archive_works';
import { ControlledVocabularyList } from './controlled_vocabularies';
import { ListResponse } from './misc';
import { Archive } from './archives';

export enum ItemIdType {
    ArchiveItemDBId = 'archive_item_db_id',
    ArchiveItemReference = 'archive_item_reference',
    FiafItemId = 'fiaf_item_id',
}

export enum ItemSound {
    HasSound = 'hasSound',
    NoSound = 'noSound',
}

export enum ItemGeneralMediaType {
    Unspecified = 'Unspecified',
}

export enum ItemSpecificMediaType {
    Unspecified = 'Unspecified',
}

export enum ItemCarrierType {
    Unspecified = 'Unspecified',
}

export enum ItemElementType {
    Unspecified = 'Unspecified',
}

export interface ItemColor {
    id: number;
    fiaf_item_id: number;
    general_color_characteristic: string;
    specific_color_type: string;
}

export interface ItemBase {
    id: number;
    fiaf_item_id: number;
    base: string;
}

export interface ItemLanguage {
    id: number;
    fiaf_item_id: number;
    language: string;
    language_usage: string;
}

export interface Item {
    accessible: boolean;
    archive_item_db_id: string | null;
    archive_item_ref: string | null;
    archive_work?: ArchiveWork;
    archive_work_id: number;
    aspect_ratio?: string;
    bases: Array<ItemBase>;
    broadcast_standard: string | null;
    carrier: string;
    codec: string | null;
    colors: Array<ItemColor>;
    condition?: string | null;
    duration?: string | null;
    element: string;
    fiaf_item_id: number;
    general_media_type: string;
    has_sound: boolean | null;
    languages: Array<ItemLanguage>;
    length_measurement?: string;
    length_value?: string;
    notes?: string | null;
    specific_media_type: string;
    created_by_import_operation?: number;
}

export interface ItemFormOptions extends ControlledVocabularyList {
    archives?: ListResponse<Archive> | null;
}

export interface ItemFormFields {
    archive_item_ref: string;
    archive_item_db_id: string;
    accessible: boolean;
    general_media_type: string;
    specific_media_type: string;
    carrier: string;
    codec: string
    element: string;
    duration: string;
    length_value: string;
    length_measurement: string;
    has_sound: string;
    aspect_ratio: string;
    broadcast_standard: string;
    condition: string;
    notes: string;
}

export interface ItemPayload {
    general_media_type: string;
    specific_media_type: string;
    accessible: boolean;
    carrier: string;
    element: string;
    duration: number;
    length_value: number | null;
    archive_item_ref?: string;
    archive_item_db_id?: string;
    codec?: string
    length_measurement?: string;
    has_sound?: null;
    aspect_ratio?: string;
    broadcast_standard?: string;
    condition?: string;
    notes?: string;
    colors: Array<Partial<ItemColor>>;
    bases: Array<Partial<ItemBase>>;
    languages: Array<Partial<ItemLanguage>>;
    archive_work_id?: string;
    archive_code?: string;
    fiaf_work_id?: number;
}
