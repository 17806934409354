/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useEffect, useState } from 'react';
import {
    Container, IconButton, Pagination, TextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { UsersContext, withUsersContext } from '../controllers/users/UsersContext';
import { ListResponseMeta, ListSearchParams } from '../../types/misc';
import Loader from '../elements/Loader';
import IconCircleAgent from '../assets/IconCircleAgent';
import IconCircleUser from '../assets/IconCircleUser';
import { User, UserStatus } from '../../types/users';
import UserFormDialog from '../elements/user/UserFormDialog';
import ListNoDataMessage from '../elements/ListNoDataMessage';

interface OwnProps extends TranslationContext, UsersContext {}

const UsersScreen: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const { t, getUsers } = props;

    const usersSearchDefaultParams: ListSearchParams = {
        search: '',
        page: 1,
    };

    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [userSelectedId, setUserSelectedId] = useState<number>();
    const [showForm, setShowForm] = useState<boolean>(false);
    const [paginationParams, setPaginationParams] = useState<ListResponseMeta | null>(null);
    const [searchParams, setSearchParams] = useState<ListSearchParams>(usersSearchDefaultParams);
    const [valueSearched, setValueSearched] = useState<string>('');
    const [usersList, setUsersList] = useState<Array<User>>([]);

    useEffect(() => {
        getUsersList();
    }, [searchParams]);

    const onUserClick = (userId: number) => {
        setShowForm(true);
        setUserSelectedId(userId);
    };

    const onSearch = async () => {
        setIsFetching(true);

        const params = searchParams.search === '' ? usersSearchDefaultParams : { ...searchParams, page: undefined };

        setSearchParams(params);
    };

    const onPagination = async (event: React.ChangeEvent<unknown>, value: number) => {
        setSearchParams({ ...searchParams, page: value });
    };

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target: { value } } = event;
        setSearchParams({ search: value });
    };

    const getUsersList = async () => {
        setIsFetching(true);

        const params = searchParams.search === '' ? { ...searchParams, search: undefined } : { ...searchParams };
        const usersData = await getUsers(params);

        if (usersData) {
            setUsersList(usersData.data);
            setPaginationParams(usersData.meta);
        }

        setValueSearched(searchParams.search);

        setIsFetching(false);
    };

    const onCloseForm = (updateList: boolean) => {
        setShowForm(false);
        setUserSelectedId(undefined);
        if (updateList) getUsersList();
    };

    return (
        <Container maxWidth="md">
            {isFetching && <Loader />}
            {showForm && <UserFormDialog userId={userSelectedId} onClose={onCloseForm} />}
            <div className="management-screen">
                <div className="management-screen__top">
                    <div className="management-screen__top__left">
                        <div className="management-screen__top__left__image">
                            <IconCircleUser />
                        </div>
                        <div className="management-screen__top__left__title">
                            <h3>{t('usersScreen.title')}</h3>
                            <p>{t('usersScreen.subtitle')}</p>
                        </div>
                    </div>
                    <div className="management-screen__top__right">
                        <div className="management-screen__top__right__search-bar-wrapper">
                            <div className="management-screen__top__right__search-bar-wrapper__search-bar">
                                <TextField
                                    fullWidth
                                    data-testid="users-search-bar"
                                    variant="standard"
                                    placeholder={t('usersScreen.searchPlaceholder')}
                                    value={searchParams.search}
                                    onChange={onInputChange}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            onSearch();
                                        }
                                    }}
                                    InputProps={{
                                        disableUnderline: true,
                                        startAdornment: <SearchIcon className="search-icon" />,
                                        endAdornment: (
                                            <>
                                                <IconButton data-testid="clear-search-icon" onClick={() => setSearchParams({ search: '' })}>
                                                    <CancelRoundedIcon />
                                                </IconButton>
                                                <IconButton
                                                    className="search-button"
                                                    onClick={onSearch}
                                                >
                                                    <SearchIcon />
                                                </IconButton>
                                            </>
                                        ),
                                    }}
                                />
                            </div>
                        </div>
                        <IconButton data-testid="new-user-btn" className="add-button" onClick={() => setShowForm(true)}>
                            <AddIcon />
                        </IconButton>
                    </div>
                </div>
                <div className="management-screen__bottom">
                    <div className="management-screen__bottom__list">
                        {usersList.map((user) => {
                            return (
                                <div
                                    data-testid="users-list-item"
                                    className={`management-screen__bottom__list__item ${user.status === UserStatus.InviteSent ? 'invite-sent' : ''}`}
                                    key={user.id}
                                    onClick={() => onUserClick(user.id)}
                                >
                                    <div className="management-screen__bottom__list__item__image">
                                        <IconCircleAgent />
                                    </div>
                                    <div className="management-screen__bottom__list__item__description">
                                        <div className="management-screen__bottom__list__item__description__name">
                                            <h3>{`${user.first_name} ${user.last_name}`}</h3>
                                            {user.status === UserStatus.InviteSent && (
                                                <div className="management-screen__bottom__list__item__description__name__invite-sent">
                                                    {t('usersScreen.inviteSent')}
                                                </div>
                                            )}
                                        </div>
                                        <p>
                                            {user.archive_code && <span className="bold">{`${user.archive_code}, `}</span>}
                                            <span className="capitalize">{user.user_type}</span>
                                        </p>
                                    </div>
                                </div>
                            );
                        })}
                        {usersList.length === 0 && (
                            <div className="management-screen__bottom__list__no-data">
                                <ListNoDataMessage searchValue={valueSearched} />
                            </div>
                        )}
                    </div>
                    <div className="management-screen__bottom__pagination">
                        {paginationParams && (
                            <Pagination
                                count={paginationParams.last_page}
                                page={paginationParams.current_page}
                                onChange={onPagination}
                            />
                        )}
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default withTranslationContext(withUsersContext(UsersScreen));
