/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';

import { KeyedObject } from '../../../types/general';
import { GenericFunction, ListResponse } from '../../../types/misc';
import {
    ConfirmEmailChangePayload, ChangePasswordPayload, User, UserPayload, UserSetPasswordPayload,
} from '../../../types/users';
import { ConnectedUsersController } from './UsersController';

export interface UsersContext {
    getUsers(filters?: KeyedObject): Promise<ListResponse<User> | null>;
    getUser(id: number): Promise<User | null>;
    createUser(payload: UserPayload, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void>;
    editUser(userId: number, payload: UserPayload, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void>;
    deleteUser(userId: number, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void>;
    userAcceptInvitation(userId: number, payload: UserSetPasswordPayload, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void>;
    changeUserPassword(userId: number, payload: ChangePasswordPayload, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void>;
    changeUserEmail(userId: number, newEmail: string, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void>;
    confirmEmailChange(payload: ConfirmEmailChangePayload, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void>;
}

export const usersContextDefaultValue = {
    getUsers: async (): Promise<ListResponse<User> | null> => null,
    getUser: async (): Promise<User | null> => null,
    createUser: async (): Promise<void> => {},
    editUser: async (): Promise<void> => {},
    deleteUser: async (): Promise<void> => {},
    userAcceptInvitation: async (): Promise<void> => {},
    changeUserPassword: async (): Promise<void> => {},
    changeUserEmail: async (): Promise<void> => {},
    confirmEmailChange: async (): Promise<void> => {},
};

const UsersContextInstance = createContext<UsersContext | null>(usersContextDefaultValue);

export const UsersContextProvider = UsersContextInstance.Provider;
export const UsersContextConsumer = UsersContextInstance.Consumer;

export const withUsersContext = <P extends unknown>(Component: ComponentType<P>): FC<Omit<P, keyof UsersContext>> => (props) => (
    <ConnectedUsersController>
        <UsersContextConsumer>
            {(ctx) => <Component {...props as P} {...ctx} />}
        </UsersContextConsumer>
    </ConnectedUsersController>
);
