/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import axios from 'axios';
import type { Store } from 'redux';
import { logoutRequestActionCreator } from './actions/authentication';
 
const setupRequestInterceptor = (store: Store) => {
    axios.interceptors.request.use(
        (reqConfig) => {
            const { authentication } = store.getState();
            const { token } = authentication;
 
            const newConfig = reqConfig;
 
            if (token && newConfig.url && !newConfig.url.includes('/login') && !newConfig.url.endsWith('/me') && newConfig.headers) {
                newConfig.headers.Authorization = `Bearer ${token}`;
            }
 
            return newConfig;
        },
        (err) => Promise.reject(err),
    );
};
 
const setupResponseInterceptor = (store: Store) => {
    axios.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        if (!error || !error.response || !error.response.config) return Promise.reject(error);
 
        if (error.response.status === 401) {
            store.dispatch(logoutRequestActionCreator());
        }
 
        return Promise.reject(error);
    });
};
 
export const setupAPI = async (store: Store): Promise<void> => {
    axios.create({ withCredentials: true });
    setupRequestInterceptor(store);
    setupResponseInterceptor(store);
};
