/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import {
    IconButton, Menu, MenuItem, MenuList,
} from '@mui/material';
import React, { FunctionComponent, useState } from 'react';

import { ExportFileFormat } from '../../types/search';
import IconExportFile from '../assets/IconExportFile';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';

interface OwnProps extends TranslationContext {
    onExport(type: ExportFileFormat): void;
}

const ExportFileMenu: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const { t, onExport } = props;

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const open = Boolean(anchorEl);

    const onItemClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const onClose = () => {
        setAnchorEl(null);
    };

    const onExportClick = (type: ExportFileFormat) => {
        onExport(type);
    };

    return (
        <div className="search-screen__options__export">
            <IconButton
                data-testid="export-file-button"
                aria-label="menu"
                size="medium"
                onClick={onItemClick}
                aria-controls={open ? 'export-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
            >
                <IconExportFile />
            </IconButton>
            <Menu
                id="export-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={onClose}
                className="user-menu"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                elevation={1}
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
            >
                <MenuList dense>
                    <MenuItem className="title left">{t('general.export')}</MenuItem>
                    <MenuItem
                        data-testid="export-button-csv"
                        onClick={() => {
                            onExportClick(ExportFileFormat.Csv);
                            onClose();
                        }}
                    >
                        {t('exportMenu.csvFormat')}
                    </MenuItem>
                    <MenuItem
                        data-testid="export-button-json"
                        onClick={() => {
                            onExportClick(ExportFileFormat.Json);
                            onClose();
                        }}
                    >
                        {t('exportMenu.jsonFormat')}
                    </MenuItem>
                </MenuList>
            </Menu>
        </div>
    );
};

export default withTranslationContext(ExportFileMenu);
