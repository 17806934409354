/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import React, {
    FunctionComponent, ReactElement, useEffect, useState,
} from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { SnackbarProvider } from 'notistack';

import { configureStore } from './store';
import AppRouter from './components/AppRouter';
import { EmptyObject } from './types/misc';
import { setupAPI } from './api';

const { persistor, store } = configureStore();

type Props = EmptyObject;

const App: FunctionComponent<Props> = (): ReactElement | null => {
    const [isPrepared, setIsPrepared] = useState<boolean>(false);

    useEffect(() => {
        const prepare = async () => {
            await setupAPI(store);
            setIsPrepared(true);
        };
        prepare();
    }, []);

    if (!isPrepared) {
        return null;
    }

    return (
        <div className="app-container">
            <PersistGate persistor={persistor}>
                <Provider store={store}>
                    <SnackbarProvider maxSnack={3}>
                        <BrowserRouter>
                            <AppRouter />
                        </BrowserRouter>
                    </SnackbarProvider>
                </Provider>
            </PersistGate>
        </div>
    );
};

export default App;
