/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';

export enum ControlledVocabularyName {
    AgentRoles = 'agent_roles',
    AspectRatios = 'aspect_ratios',
    BroadcastStandards = 'broadcast_standards',
    Carriers = 'carriers',
    Codecs = 'codecs',
    Countries = 'countries',
    DateTypes = 'date_types',
    GeneralColorCharacteristics = 'general_color_characteristics',
    GeneralMediaTypes = 'general_media_types',
    ItemBases = 'item_bases',
    ItemElements = 'item_elements',
    Languages = 'languages',
    LanguageUsages = 'language_usages',
    LengthMeasurements = 'length_measurements',
    SoundProductions = 'sound_productions',
    SpecificColorTypes = 'specific_color_types',
    SpecificMediaTypes = 'specific_media_types',
    ThirdPartyNames = 'third_party_names',
    TitleTypes = 'title_types',
}

export const cvURL = (cvName: ControlledVocabularyName, cvValue?: string): string => {
    let baseUrl = `${API_URL}/cv/${encodeURIComponent(cvName)}`;
    if (cvValue) {
        baseUrl += `/${encodeURIComponent(cvValue)}`;
    }
    return baseUrl;
};

export const thirdPartyNamesUrl = (): string => {
    return `${API_URL}/cv/third_party_names`;
};

export const titleTypesUrl = (): string => {
    return `${API_URL}/cv/title_types`;
};
