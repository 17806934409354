/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import axios, { AxiosError } from 'axios';
import { connect } from 'react-redux';
import React, { Component, ReactNode } from 'react';

import { ControlledVocabulariesContextProvider } from './ControlledVocabulariesContext';
import {
    cvURL,
    ControlledVocabularyName,
} from '../../../services/controlled_vocabularies';
import {
    ControlledVocabulary, CountryCV, LanguageCV, SimpleCV,
} from '../../../types/controlled_vocabularies';
import { GenericFunction } from '../../../types/misc';

interface OwnProps {
    children: ReactNode;
}

type Props = OwnProps;

export class ControlledVocabulariesController extends Component<Props> {
    getCV = async <T extends unknown>(cvName: ControlledVocabularyName): Promise<ControlledVocabulary<T> | undefined> => {
        try {
            const { data } = await axios.get(cvURL(cvName));
            return data;
        } catch {
            return undefined;
        }
    };

    createCVValue = async (cvName: ControlledVocabularyName, data: SimpleCV | CountryCV | LanguageCV, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void > => {
        try {
            await axios.post(cvURL(cvName), data);
            onSuccess();
        } catch (error) {
            const err = error as AxiosError;
            if (onFailure) onFailure(err.response?.data);
        }
    }

    deleteCVValue = async (cvName: ControlledVocabularyName, cvValue: string, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void > => {
        try {
            await axios.delete(cvURL(cvName, cvValue));
            onSuccess();
        } catch (error) {
            const err = error as AxiosError;
            if (onFailure) onFailure(err.response?.data);
        }
    }

    render(): ReactNode {
        const {
            children,
        } = this.props;

        return (
            <ControlledVocabulariesContextProvider
                value={{
                    getCV: this.getCV,
                    createCVValue: this.createCVValue,
                    deleteCVValue: this.deleteCVValue,
                }}
            >
                {children}
            </ControlledVocabulariesContextProvider>
        );
    }
}

export const ConnectedControlledVocabulariesController = connect()(ControlledVocabulariesController);
