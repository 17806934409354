/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useEffect, useState } from 'react';
import {
    Chip,
    Dialog,
    IconButton,
    Tooltip,
} from '@mui/material';
import { Close, DeleteOutline, EditOutlined } from '@mui/icons-material';

import { useSnackbar } from 'notistack';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { ItemsContext, withItemsContext } from '../../controllers/items/ItemsContext';
import { Item } from '../../../types/items';
import Loader from '../Loader';
import { Work } from '../../../types/works';
import iconMediaType from '../../../assets/image/icon-mediatype.svg';
import iconCarrier from '../../../assets/image/icon-carrier.svg';
import iconElement from '../../../assets/image/icon-element.svg';
import iconBase from '../../../assets/image/icon-base.svg';
import iconColor from '../../../assets/image/icon-color.svg';
import iconLanguage from '../../../assets/image/icon-language.svg';
import iconDuration from '../../../assets/image/icon-duration.svg';
import iconAspectRatio from '../../../assets/image/icon-aspectratio.svg';
import iconLength from '../../../assets/image/icon-length.svg';
import iconCodec from '../../../assets/image/icon-codec.svg';
import iconBroadcast from '../../../assets/image/icon-broadcast.svg';
import { GenericFunction } from '../../../types/misc';
import Confirm from '../Confirm';
import { withWorksContext, WorksContext } from '../../controllers/works/WorksContext';
import ItemFormDialog from './ItemFormDialog';
import IconCircleItem from '../../assets/IconCircleItem';
import { ApiError } from '../../../types/errors';
import { AuthenticationContext, withAuthenticationContext } from '../../controllers/authentication/AuthenticationContext';
import { AuthorizationContext, withAuthorizationContext } from '../../controllers/authorization/AuthorizationContext';
import { Permission } from '../../../types/authorization';
import Can from '../../containers/Can';

interface OwnProps extends TranslationContext, ItemsContext, WorksContext, AuthenticationContext, AuthorizationContext {
    itemId: number;
    onClose: GenericFunction;
}

type Props = OwnProps;

const ItemDetailsDialog: FunctionComponent<Props> = (props: Props) => {
    const {
        t,
        itemId,
        onClose,
        getItem,
        getWork,
        deleteItem,
        authenticatedUser,
        checkPermission,
    } = props;

    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [showEdit, setShowEdit] = useState<boolean>(false);
    const [item, setItem] = useState<Item | null>(null);
    const [work, setWork] = useState<Work | null>(null);

    const { enqueueSnackbar } = useSnackbar();

    const onDeleteSuccessMessage = () => {
        enqueueSnackbar(t('itemFormDialog.successfullyDeleted'), { variant: 'success' });
    };

    const onDeleteFailureMessage = (error?: ApiError) => {
        if (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
        } else {
            enqueueSnackbar(t('general.requestGeneralError'), { variant: 'error' });
        }
    };

    const handleDelete = () => {
        setShowConfirm(false);

        deleteItem(
            itemId,
            () => {
                onDeleteSuccessMessage();
                onClose();
            },
            onDeleteFailureMessage,
        );
    };

    const renderEmptyValue = () => {
        return <div className="value empty">---</div>;
    };

    const prepare = async () => {
        setIsFetching(true);
        const itemData = await getItem(Number(itemId));
        if (itemData) {
            setItem(itemData);

            const workData = await getWork(Number(itemData.archive_work?.fiaf_work_id));
            if (workData) setWork(workData);
        }
        setIsFetching(false);
    };

    const handleCloseDialog = () => {
        setShowEdit(false);
        prepare();
    };

    useEffect(() => {
        prepare();
    }, []);

    if (isFetching) {
        return <Loader />;
    }
    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open
            onClose={onClose}
            className="main-dialog"
        >
            <Confirm
                show={showConfirm}
                title={t('itemDeleteConfirm.title')}
                message={[t('itemDeleteConfirm.message1'), t('itemDeleteConfirm.message2')]}
                okButton={t('itemDeleteConfirm.okButton')}
                onClose={() => setShowConfirm(false)}
                onConfirm={handleDelete}
            >
                <div className="delete-confirm">
                    <div className="icon">
                        <IconCircleItem />
                    </div>
                    <p>{item?.specific_media_type}</p>
                    <p>{item?.general_media_type}</p>
                </div>
            </Confirm>
            {showEdit && (
                <ItemFormDialog
                    workId={work?.fiaf_work_id}
                    itemId={item?.fiaf_item_id}
                    onClose={() => {
                        handleCloseDialog();
                    }}
                />
            )}
            {(item && work) && (
                <>
                    <div className="main-dialog__top">
                        <div className="main-dialog__top__left">
                            <IconCircleItem />
                            <h2>{work.preferred_title.toLowerCase()}</h2>
                        </div>
                        <div className="main-dialog__top__right">
                            <Chip
                                label={item.accessible ? t('itemDetailsDialog.accessible') : t('itemDetailsDialog.notAccessible')}
                                color={item.accessible ? 'success' : 'default'}
                            />
                            {(checkPermission([Permission.MANAGE_ALL_ARCHIVES]) || authenticatedUser?.archive_code === item.archive_work?.archive_code)
                            && (
                                <>
                                    <Can
                                        actions={[Permission.UPDATE_ITEM]}
                                        yes={() => (
                                            <Tooltip title={t('itemDetailsDialog.editTooltip')}>
                                                <IconButton
                                                    className="square-button"
                                                    data-testid="item-edit-button"
                                                    onClick={() => setShowEdit(true)}
                                                >
                                                    <EditOutlined />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    />
                                    <Can
                                        actions={[Permission.DELETE_ITEM]}
                                        yes={() => (
                                            <Tooltip title={t('itemDetailsDialog.deleteTooltip')}>
                                                <IconButton
                                                    className="square-button"
                                                    data-testid="item-delete-button"
                                                    onClick={() => setShowConfirm(true)}
                                                >
                                                    <DeleteOutline />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    />
                                </>
                            )}
                            <IconButton
                                aria-label="close"
                                onClick={onClose}
                                data-testid="item-close-button"
                            >
                                <Close color="primary" />
                            </IconButton>
                        </div>
                    </div>
                    <div className="main-dialog__content">
                        <div className="item-details">
                            <div className="item-details__top">
                                <div>
                                    <div className="label">
                                        {t('itemDetailsDialog.ids')}
                                    </div>
                                    <div className="value">
                                        {t('itemDetailsDialog.fiafID')}<Chip label={item.fiaf_item_id} size="small" /> {item.archive_item_db_id && <>{t('itemDetailsDialog.archiveDBID')} <Chip label={item.archive_item_db_id} size="small" /></>}
                                    </div>
                                </div>
                                <div>
                                    <div className="label">{t('itemDetailsDialog.archiveReference')}</div>
                                    {item.archive_item_ref ? <div className="value">{item.archive_item_ref}</div> : renderEmptyValue()}
                                </div>
                            </div>
                            <div className="item-details__middle">
                                <div className="item-details__middle__left">
                                    <div className="prop">
                                        <img src={iconMediaType} alt={t('itemDetailsDialog.mediaType')} />
                                        <div className="label">{t('itemDetailsDialog.mediaType')}</div>
                                        {item.general_media_type ? <div className="value">{item.general_media_type}</div> : renderEmptyValue()}
                                        {item.specific_media_type ? <div className="value">{item.specific_media_type}</div> : renderEmptyValue()}
                                    </div>
                                    <div className="prop">
                                        <img src={iconCarrier} alt={t('itemDetailsDialog.carrier')} />
                                        <div className="label">{t('itemDetailsDialog.carrier')}</div>
                                        {item.carrier ? <div className="value">{item.carrier}</div> : renderEmptyValue()}
                                    </div>
                                    <div className="prop">
                                        <img src={iconElement} alt={t('itemDetailsDialog.element')} />
                                        <div className="label">{t('itemDetailsDialog.element')}</div>
                                        {item.element ? <div className="value">{item.element}</div> : renderEmptyValue()}
                                    </div>
                                </div>
                                <div className="item-details__middle__right">
                                    <div className="item-details__middle__right__row">
                                        <div className="prop">
                                            <img src={iconBase} alt={t('itemDetailsDialog.base')} />
                                            <div className="label">{t('itemDetailsDialog.base')}</div>
                                            {item.bases.length > 0 ? (
                                                <div className="value">
                                                    {item.bases.map((b, i) => {
                                                        return `${(i > 0) ? ', ' : ''}${b.base}`;
                                                    })}
                                                </div>
                                            ) : renderEmptyValue()}
                                        </div>
                                        <div className="prop">
                                            <img src={iconLanguage} alt={t('itemDetailsDialog.language')} />
                                            <div className="label">{t('itemDetailsDialog.language')}</div>
                                            {item.languages.length > 0 ? (
                                                <div className="value uppercased">
                                                    {item.languages.map((l, i) => {
                                                        return `${(i > 0) ? ', ' : ''}${l.language}`;
                                                    })}
                                                </div>
                                            ) : renderEmptyValue()}
                                        </div>
                                        <div className="prop">
                                            <img src={iconLength} alt={t('itemDetailsDialog.sizeLength')} />
                                            <div className="label">{t('itemDetailsDialog.sizeLength')}</div>
                                            {(item.length_value || item.length_measurement) ? <div className="value">{item.length_value} {item.length_measurement}</div> : renderEmptyValue()}
                                        </div>
                                    </div>
                                    <div className="item-details__middle__right__row">
                                        <div className="prop">
                                            <img src={iconColor} alt={t('itemDetailsDialog.color')} />
                                            <div className="label">{t('itemDetailsDialog.color')}</div>
                                            {item.colors.length ? (
                                                <div className="value">
                                                    {item.colors.map((c, i) => {
                                                        return `${(i > 0) ? ', ' : ''}${c.general_color_characteristic}`;
                                                    })}
                                                </div>
                                            ) : renderEmptyValue()}
                                        </div>
                                        <div className="prop">
                                            <img src={iconDuration} alt={t('itemDetailsDialog.duration')} />
                                            <div className="label">{t('itemDetailsDialog.duration')}</div>
                                            {item.duration ? <div className="value">{item.duration}</div> : renderEmptyValue()}
                                        </div>
                                        <div className="prop">
                                            <img src={iconCodec} alt={t('itemDetailsDialog.codec')} />
                                            <div className="label">{t('itemDetailsDialog.codec')}</div>
                                            {item.codec ? <div className="value">{item.codec}</div> : renderEmptyValue()}
                                        </div>
                                    </div>
                                    <div className="item-details__middle__right__row">
                                        <div className="prop">
                                            <img src={iconAspectRatio} alt={t('itemDetailsDialog.aspectRatio')} />
                                            <div className="label">{t('itemDetailsDialog.aspectRatio')}</div>
                                            {item.aspect_ratio ? <div className="value">{item.aspect_ratio}</div> : renderEmptyValue()}
                                        </div>
                                        <div className="prop">
                                            <img src={iconBroadcast} alt={t('itemDetailsDialog.broadcastStandard')} />
                                            <div className="label">{t('itemDetailsDialog.broadcastStandard')}</div>
                                            {item.broadcast_standard ? <div className="value">{item.broadcast_standard}</div> : renderEmptyValue()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item-details__bottom">
                                {item.condition && (
                                    <>
                                        <div className="label">{t('itemDetailsDialog.condition')}</div>
                                        <div className="value">{item.condition}</div>
                                    </>
                                )}
                                {item.notes && (
                                    <>
                                        <div className="label">{t('itemDetailsDialog.notesAdditional')}</div>
                                        <div className="value">{item.notes}</div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Dialog>
    );
};

export default withTranslationContext(
    withItemsContext(
        withWorksContext(
            withAuthenticationContext(
                withAuthorizationContext(
                    ItemDetailsDialog,
                ),
            ),
        ),
    ),
);
