/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';
import ImportController from './ImportController';
import { GenericFunction } from '../../../types/misc';

export interface ImportContext {
    sendImportFile(formData: FormData, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void>;
    downloadFileAPI(typeUrl: string, onFailure: GenericFunction): Promise<{ file: Blob, fileName: string } | null>;
}

export const importContextDefaultValue: ImportContext = {
    sendImportFile: async (): Promise<void> => {},
    downloadFileAPI: async (): Promise<{ file: Blob, fileName: string } | null> => null,
};

const ImportContextInstance = createContext<ImportContext | null>(importContextDefaultValue);

export const ImportContextProvider = ImportContextInstance.Provider;
export const ImportContextConsumer = ImportContextInstance.Consumer;

export const withImportContext = <P extends unknown>(Component: ComponentType<P>): FC<Omit<P, keyof ImportContext>> => (props) => (
    <ImportController>
        <ImportContextConsumer>
            {(ctx) => <Component {...props as P} {...ctx} />}
        </ImportContextConsumer>
    </ImportController>
);
