/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { ControlledVocabularyList } from './controlled_vocabularies';
import { ListResponse } from './misc';
import { Archive } from './archives';
import { User } from './users';

export interface AdvancedSearchOptions extends ControlledVocabularyList {
    archives?: ListResponse<Archive> | null;
    archiveCities?: Array<string> | null;
    users?: Array<User>;
}

export interface AdvancedSearchParams {
    countries?: Array<string>;
    min_year?: number | null;
    max_year?: number | null;
    fiaf_work_id?: string | null;
    title?: string;
    series?: string;
    agent_roles?: Array<string>;
    sound_production?: string;
    archive_codes?: Array<string>;
    archive_cities?: Array<string>;
    local_work_id?: string;
    archive_name?: Array<string>;
    archive_item_reference?: string;
    archive_item_db_id?: string;
    accessible?: string;
    general_media_types?: Array<string>;
    specific_media_types?: Array<string>;
    carriers?: Array<string>;
    elements?: Array<string>;
    languages?: Array<string>;
    general_color_characteristics?: Array<string>;
    bases?: Array<string>;
    page?: number | null;
    agent_name?: string;
    created_by_users?: Array<string>;
    created_by_min_date?: string;
    created_by_max_date?: string;
    edited_by_users?: Array<string>;
    edited_by_min_date?: string;
    edited_by_max_date?: string;
    workIdType?: string;
    itemIdType?: string;
    export_format?: string;
    third_party_name?: string;
    fiaf_item_id?: string;
    third_party_id_value?: string;
    exact_title?: string;
    exact_alternative_title?: string;
}

export enum AdvancedSearchParamName {
    Countries = 'countries',
    MinYear = 'min_year',
    MaxYear = 'max_year',
    FiafWorkId = 'fiaf_work_id',
    Title = 'title',
    Series = 'series',
    AgentRoles = 'agent_roles',
    SoundProduction = 'sound_production',
    ArchiveName = 'archive_name',
    ArchiveCodes = 'archive_codes',
    ArchiveCities = 'archive_cities',
    LocalWorkId = 'local_work_id',
    ArchiveItemReference = 'archive_item_reference',
    ArchiveItemDBId = 'archive_item_db_id',
    Accessible = 'accessible',
    GeneralMediaTypes = 'general_media_types',
    SpecificMediaTypes = 'specific_media_types',
    Carriers = 'carriers',
    Elements = 'elements',
    Languages = 'languages',
    GeneralColorCharacteristics = 'general_color_characteristics',
    Bases = 'bases',
    Page = 'page',
    AgentName = 'agent_name',
    ItemIdValue = 'item_id_value',
    WorkIdValue = 'work_id_value',
    CreatedByUsers = 'created_by_users',
    CreatedByMinDate = 'created_by_min_date',
    CreatedByMaxDate = 'created_by_max_date',
    EditedByUsers = 'edited_by_users',
    EditedByMinDate = 'edited_by_min_date',
    EditedByMaxDate = 'edited_by_max_date',
    WorkIdType = 'work_id_type',
    ItemIdType = 'item_id_type',
}

export enum ExportFileFormat {
    Csv = 'csv',
    Json = 'json_file'
}

export enum SearchSortOrderOptions {
    Title = 'preferred_title',
    Date = 'release_date',
}

export enum SearchSortDirectionOptions {
    Ascending = 'asc',
    Descending = 'desc'
}

export interface SearchSortParams {
    order_by: SearchSortOrderOptions
    order_direction: SearchSortDirectionOptions
}

export enum SearchSortValues {
    TitleAsc = 'TitleAsc',
    TitleDesc = 'TitleDesc',
    DateAsc = 'DateAsc',
    DateDesc = 'DateDesc',
}
