/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';

const IconCircleArchive: FunctionComponent = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
            <g>
                <g transform="translate(-432.5 -123.525)">
                    <rect width="60" height="60" rx="30" transform="translate(432.5 123.525)" fill="#707070" className="icon-bg" />
                </g>
                <g fill="#fff" className="icon-icon">
                    <path d="M29.549 18 18 23.25v2.732h23.919v-2.746Zm10.775 6.476H19.595v-.28l9.98-4.536 10.752 4.55Z" />
                    <path d="M18 42h23.919v-4.893H18Zm1.595-3.388h20.729v1.882H19.595Z" />
                    <path d="M20.635 27.917h1.506v7.528h-1.506Z" />
                    <path d="M37.778 27.917h1.506v7.528h-1.506Z" />
                    <path d="M26.405 27.917h1.506v7.528h-1.506Z" />
                    <path d="M32.008 27.917h1.506v7.528h-1.506Z" />
                </g>
            </g>
        </svg>
    );
};

export default IconCircleArchive;
