/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useEffect, useState } from 'react';
import {
    IconButton,
    MenuItem,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';
import { AddRounded, CheckRounded, CloseRounded } from '@mui/icons-material';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import {
    ControlledVocabulariesContext,
    withControlledVocabulariesContext,
} from '../../controllers/controlled_vocabularies/ControlledVocabulariesContext';
import { GeneralColorCharacteristicCV, SpecificColorTypeCV } from '../../../types/controlled_vocabularies';
import { ControlledVocabularyName } from '../../../services/controlled_vocabularies';
import { ItemColor } from '../../../types/items';
import { getRandomInt } from '../../../utils/misc';
import Loader from '../Loader';

interface OwnProps extends TranslationContext, ControlledVocabulariesContext {
    colors: Array<Partial<ItemColor>>;
    onAdd(itemColor: Partial<ItemColor>): void;
    onRemove(id?: number): void;
}

enum FieldsName {
    GeneralColorCharacteristic = 'general_color_characteristic',
    SpecificColorType = 'specific_color_type',
}

const ItemFormColorsTable: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t, getCV, colors, onRemove, onAdd,
    } = props;

    const [colorCharacteristicsOptions, setColorCharacteristicsOptions] = useState<Array<GeneralColorCharacteristicCV>>([]);
    const [specificColorTypesOptions, setSpecificColorTypesOptions] = useState<Array<SpecificColorTypeCV>>([]);
    const [newColor, setNewColor] = useState<Partial<ItemColor> | null>(null);
    const [isFetching, setIsFetching] = useState(true);

    useEffect(() => {
        fetchOptions();
    }, []);

    useEffect(() => {
        if (colors.length === 0) onAddColor();
    }, [colors]);

    const onAddColor = () => {
        setNewColor({
            id: getRandomInt(-10000, 0),
            general_color_characteristic: '',
            specific_color_type: '',
        });
    };

    const onSelectChange = (event: SelectChangeEvent) => {
        const {
            target: { value, name },
        } = event;

        if (newColor) {
            setNewColor({
                ...newColor,
                [name]: value,
            });
        }
    };

    const onAccept = () => {
        if (newColor) onAdd(newColor);
        setNewColor(null);
    };

    const fetchOptions = async () => {
        Promise.all([
            getCV<GeneralColorCharacteristicCV>(ControlledVocabularyName.GeneralColorCharacteristics),
            getCV<SpecificColorTypeCV>(ControlledVocabularyName.SpecificColorTypes),
        ]).then((results) => {
            if (results[0]) setColorCharacteristicsOptions(results[0].data);
            if (results[1]) setSpecificColorTypesOptions(results[1].data);
        }).finally(() => setIsFetching(false));
    };

    if (isFetching) {
        return <Loader />;
    }
    return (
        <Table size="small" data-testid="item-colors-table">
            <TableHead>
                <TableRow>
                    <TableCell>{t('itemFormDialog.colorsLabel')}</TableCell>
                    <TableCell>{t('itemFormDialog.specificLabel')}</TableCell>
                    <TableCell className="add-btn-cell">
                        <IconButton data-testid="add-new-color-btn" className="add-small-btn" onClick={onAddColor}>
                            <AddRounded />
                        </IconButton>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {colors.map((color) => (
                    <TableRow key={color.id}>
                        <TableCell data-testid="item-general-color-list-name">{color.general_color_characteristic}</TableCell>
                        <TableCell data-testid="item-specific-color-list-name">{color.specific_color_type}</TableCell>
                        <TableCell align="right">
                            <IconButton
                                size="small"
                                data-testid="new-color-delete-btn"
                                onClick={() => onRemove(color.id)}
                                className="action-btn"
                            >
                                <CloseRounded />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
                {newColor && (
                    <TableRow>
                        <TableCell className="item-list-select">
                            <Select
                                fullWidth
                                MenuProps={{ classes: { paper: 'select' } }}
                                data-testid="new-color-general-type"
                                className="simple-select dense-select"
                                name={FieldsName.GeneralColorCharacteristic}
                                value={newColor[FieldsName.GeneralColorCharacteristic]}
                                onChange={onSelectChange}
                                displayEmpty
                                variant="standard"
                                renderValue={
                                            newColor[FieldsName.GeneralColorCharacteristic] !== '' ? undefined : () => <div className="placeholder">{t('general.select')}</div>
                                        }
                            >
                                {colorCharacteristicsOptions.map((value) => (
                                    <MenuItem dense key={value.name} value={value.name}>{value.name}</MenuItem>
                                ))}
                            </Select>
                        </TableCell>
                        <TableCell className="item-list-select">
                            <Select
                                fullWidth
                                MenuProps={{ classes: { paper: 'select' } }}
                                data-testid="new-color-specific-type"
                                className="simple-select dense-select"
                                name={FieldsName.SpecificColorType}
                                value={newColor[FieldsName.SpecificColorType]}
                                onChange={onSelectChange}
                                displayEmpty
                                variant="standard"
                                renderValue={
                                    newColor[FieldsName.SpecificColorType] !== '' ? undefined : () => <div className="placeholder">{t('general.select')}</div>
                                }
                            >
                                {specificColorTypesOptions.map((value) => (
                                    <MenuItem dense key={value.name} value={value.name}>{value.name}</MenuItem>
                                ))}
                            </Select>
                        </TableCell>
                        <TableCell align="right">
                            <IconButton
                                size="small"
                                data-testid="new-color-confirm-btn"
                                onClick={onAccept}
                                className="action-btn"
                                disabled={!newColor.general_color_characteristic}
                            >
                                <CheckRounded />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
};

export default withTranslationContext(withControlledVocabulariesContext(ItemFormColorsTable));
