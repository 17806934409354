/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { KeyedObject } from '../types/general';
import { API_URL } from '../settings';
import { objectToParams } from '../utils/misc';

export const userMeUrl = (): string => {
    return `${API_URL}/users/me`;
};

export const usersUrl = (filters?: KeyedObject): string => {
    return `${API_URL}/users${objectToParams(filters)}`;
};

export const userUrl = (userId: number): string => {
    return `${API_URL}/users/${userId}`;
};

export const userAcceptInvitationUrl = (userId: number | string): string => {
    return `${API_URL}/users/${userId}/accept_invitation`;
};

export const userPasswordChangeUrl = (userId: number | string): string => {
    return `${API_URL}/users/${userId}/password`;
};

export const userChangeEmailUrl = (userId: number | string): string => {
    return `${API_URL}/users/${userId}/email`;
};

export const userConfirmEmailChangeUrl = (userId: number | string): string => {
    return `${API_URL}/users/${userId}/confirm_mail_change`;
};
