/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';

const IconCircleAgent: FunctionComponent = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
            <g transform="translate(7026 -16951)">
                <rect width="60" height="60" rx="30" transform="translate(-7026 16951)" fill="#707070" className="icon-bg" />
                <g transform="translate(-7013 16970)" fill="#f1f2f2" className="icon-icon">
                    <path d="M7.124 5.312a2.053 2.053 0 1 0-1.649-2.389 2.052 2.052 0 0 0 1.649 2.389Z" />
                    <path d="M28.272 3.117a1.481 1.481 0 0 1 2.841.147 2.053 2.053 0 0 0-3.9-1.1 1.486 1.486 0 0 1 1.059.953Z" />
                    <path d="M27.994 7.18a1.937 1.937 0 0 0 .151-.139h-.155c0 .047.002.091.004.139Z" />
                    <path d="M30.76 19.752a13.584 13.584 0 0 1-.4-6.391 1.737 1.737 0 0 0 1.229-1.434 10.081 10.081 0 0 0-.277-5.085c-.006-.021-.014-.04-.021-.061h-.962a.909.909 0 0 1-.094.248 5.33 5.33 0 0 1-.362.544 4.011 4.011 0 0 1-.97.948 2.3 2.3 0 0 1-.623.292 10.617 10.617 0 0 1 .038 3.195 4.385 4.385 0 0 0-.016.179 15.013 15.013 0 0 0 .264 8.176 1.142 1.142 0 0 0 2.2-.61Z" />
                    <path d="M29.719 7.727a5.058 5.058 0 0 0 .348-.523.729.729 0 0 0 .065-.159h-1.738l-.038.042a1.942 1.942 0 0 1-.374.332.466.466 0 0 1-.228.091.132.132 0 0 1-.065-.017.671.671 0 0 1-.2-.183 1.629 1.629 0 0 1-.1-.132 4.356 4.356 0 0 1-.466-1.044.73.73 0 0 0-1.388.452 5.454 5.454 0 0 0 .705 1.494c.061.085.123.166.188.24a2.037 2.037 0 0 0 .612.481 1.6 1.6 0 0 0 .709.169 1.9 1.9 0 0 0 1.04-.337 3.862 3.862 0 0 0 .93-.906Z" />
                    <path d="M25.357 6.695a.913.913 0 1 1 1.735-.565 5.067 5.067 0 0 0 .318.784h4.221a.365.365 0 0 0 .365-.365V3.811a.365.365 0 0 0-.365-.365h-.485v.08a1.482 1.482 0 0 1-2.882.486 1.482 1.482 0 0 1-2.882-.486v-.08h-.407a.365.365 0 0 0-.365.365v.326l-2.105-.276v2.646l2.105-.276v.322a.365.365 0 0 0 .365.365h.448a1.256 1.256 0 0 0-.066-.223Z" />
                    <path d="M25.573 3.603a1.3 1.3 0 1 0 1.3-1.3 1.3 1.3 0 0 0-1.3 1.3Zm1.3-.73a.73.73 0 1 1-.73.73.73.73 0 0 1 .731-.735Z" />
                    <path d="M28.371 3.603a1.3 1.3 0 1 0 1.3-1.3 1.3 1.3 0 0 0-1.3 1.3Zm1.3-.73a.73.73 0 1 1-.73.73.73.73 0 0 1 .73-.735Z" />
                    <path d="M.113 19.602a.137.137 0 0 0 .023.272H.16a6.619 6.619 0 0 0 5.04-4.078 16 16 0 0 1-.611 3.956 1.142 1.142 0 1 0 2.2.61 15.934 15.934 0 0 0 .468-7.334h-.048a.907.907 0 0 1-.517-.161 5.742 5.742 0 0 1-1.863-1.993 4.324 4.324 0 0 1-.513-2.04 4.911 4.911 0 0 1 .7-2.489l.009-.015v-.006a.912.912 0 0 1 1.524 1l-.006.011c-.009.015-.024.04-.042.074a3.134 3.134 0 0 0-.369 1.425 2.468 2.468 0 0 0 .3 1.18 3.206 3.206 0 0 0 .493.677 14.835 14.835 0 0 1 .148-1.914l.026-.191a3.766 3.766 0 0 0 1.625.366h.006a4.609 4.609 0 0 0 1.807-.4l-.073.228-.082.255.076.029a4.873 4.873 0 0 1-2.448 2.622.917.917 0 0 1 .091.26 5.063 5.063 0 0 0 2.615-2.784l.15.057.352-.8.129-.274c.157-.094.317-.2.477-.31A.73.73 0 0 0 12 6.818a.739.739 0 0 0-.046-.059l.079-.179.227-.514a.759.759 0 0 0 .407-.431.671.671 0 1 0-1.251-.475.758.758 0 0 0 .018.592l-.187.585-.065.2a.729.729 0 0 0-.2.1 3.979 3.979 0 0 1-2.259.856 2.36 2.36 0 0 1-1.381-.448c.008-.073.012-.146.013-.219a1.565 1.565 0 0 0-1.022-1.661 2.089 2.089 0 0 0-2.29 1.68 10.081 10.081 0 0 0-.277 5.085 1.737 1.737 0 0 0 1.229 1.434 10.3 10.3 0 0 1 .205 1.781c-.832 1.9-2.041 3.926-5.087 4.457Z" />
                    <path d="M7.623 11.738a4.1 4.1 0 0 1-1.347-1.415 2.643 2.643 0 0 1-.319-1.265 3.223 3.223 0 0 1 .232-1.17 3.18 3.18 0 0 1 .158-.342l.047-.083.01-.016a.73.73 0 0 0-1.22-.8 4.678 4.678 0 0 0-.688 2.414 4.113 4.113 0 0 0 .491 1.953 5.558 5.558 0 0 0 1.806 1.929.73.73 0 1 0 .829-1.2Z" />
                    <path d="M21.491 13.011h.043a.73.73 0 0 0 .728-.687c.226-3.855-.257-6.37-1.433-7.476a1.974 1.974 0 0 0-1.162-.563.706.706 0 0 0-.152-.017h-.605c-.11.711-.536 3.2-1.158 3.825-.622-.622-1.047-3.115-1.158-3.825h-.605a.707.707 0 0 0-.152.017 1.975 1.975 0 0 0-1.161.563c-1.176 1.105-1.659 3.621-1.433 7.476a.73.73 0 0 0 .728.687h.043a.73.73 0 0 0 .686-.771c-.188-3.206.176-4.915.584-5.764v13.616a1.128 1.128 0 1 0 2.256 0v-7.963h.423v7.963a1.128 1.128 0 0 0 2.256 0V6.482c.381.778.78 2.4.584 5.758a.73.73 0 0 0 .688.771Z" />
                    <circle cx="2.053" cy="2.053" r="2.053" transform="translate(15.707)" />
                    <path d="M17.681 5.022v-.091l-.636-.365v.821Z" />
                    <path d="M17.851 4.931v.091l.639.365v-.821Z" />
                    <circle cx=".182" cy=".182" r=".182" transform="translate(17.582 5.508)" />
                    <circle cx=".182" cy=".182" r=".182" transform="translate(17.582 6.135)" />
                    <circle cx=".182" cy=".182" r=".182" transform="translate(17.582 6.851)" />
                </g>
            </g>
        </svg>
    );
};

export default IconCircleAgent;
