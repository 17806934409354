/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Chip, Container } from '@mui/material';
import { useSnackbar } from 'notistack';
import {
    matchRoutes, useLocation, useParams, useNavigate,
} from 'react-router-dom';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { ReviewsContext, withReviewsContext } from '../controllers/reviews/ReviewsContext';
import {
    Review, ReviewMetadataChange, ReviewStatusReturns, ReviewWorkSuggestions,
} from '../../types/reviews';
import Loader from '../elements/Loader';
import IconCircleWork from '../assets/IconCircleWork';
import ReviewNewWorkData from '../elements/review/ReviewNewWorkData';
import { AppRoute } from '../../constants/routes';
import ReviewWorkChanges from '../elements/review/ReviewWorkChanges';
import { buildRoute } from '../../utils/misc';
import { withWorksContext, WorksContext } from '../controllers/works/WorksContext';
import { Work } from '../../types/works';
import { ApiError } from '../../types/errors';

interface OwnProps extends TranslationContext, ReviewsContext, WorksContext {}

const WorkReviewWrapperScreen: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t, getReview, getReviewMetadataChanges, approveNewWorkFromReview, declineNewWorkFromReview, getReviewWorkSuggestions,
        sendMetadataChanges, getWork,
    } = props;

    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const matchData = matchRoutes([{ path: AppRoute.WorkReviewChanges }], location);

    const isMetadataChangesReviewScreen = !!(matchData && matchData[0] && matchData[0].route && matchData[0].route.path === AppRoute.WorkReviewChanges);

    const [isFetching, setIsFetching] = useState(false);
    const [review, setReview] = useState<Review | null>(null);
    const [metadataChanges, setMetadataChanges] = useState<ReviewMetadataChange | null>(null);
    const [workSuggestions, setWorkSuggestions] = useState<ReviewWorkSuggestions | null>(null);
    const [originalWork, setOriginalWork] = useState<Work | null>(null);

    useEffect(() => {
        getReviewData();
    }, [id, location]);

    const onApproveNewWork = () => {
        if (!review) return;

        setIsFetching(true);

        approveNewWorkFromReview(review?.id, onApproveNewWorkSuccess, onApproveNewWorkFailure);
    };

    const onApproveNewWorkSuccess = () => {
        enqueueSnackbar(t('workReview.newWorkApproveSuccess'), { variant: 'success' });
        navigate(AppRoute.Data);
    };

    const onApproveNewWorkFailure = (error?: ApiError) => {
        setIsFetching(false);
        if (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
        } else {
            enqueueSnackbar(t('general.requestGeneralError'), { variant: 'error' });
        }
    };

    const onDeclineNewWork = () => {
        if (!review) return;

        setIsFetching(true);

        declineNewWorkFromReview(review?.id, onDeclineNewWorkSuccess, onDeclineNewWorkFailure);
    };

    const onDeclineNewWorkSuccess = () => {
        enqueueSnackbar(t('workReview.newWorkDeclineSuccess'), { variant: 'success' });
        navigate(AppRoute.Data);
    };

    const onApproveChanges = () => {
        if (!review || !metadataChanges) return;
        setIsFetching(true);
        sendMetadataChanges(review.id, metadataChanges, onApproveChangesSuccess, onApproveChangesFailure);
    };

    const onApproveChangesSuccess = () => {
        setIsFetching(false);
        enqueueSnackbar(t('workReview.workReviewChangesSuccess'), { variant: 'success' });
        navigate(AppRoute.Data);
    };

    const onApproveChangesFailure = (error?: ApiError) => {
        setIsFetching(false);
        // The error message returned by the API is not user-friendly,
        // because of it, we are looking for the fields required message pattern to show a more readable message.
        if (error?.message?.includes('field is required')) {
            enqueueSnackbar(t('general.fieldIsRequiredError'), { variant: 'error' });
        } else {
            enqueueSnackbar(t('general.requestGeneralError'), { variant: 'error' });
        }
    };

    const onDeclineNewWorkFailure = (error?: ApiError) => {
        setIsFetching(false);
        if (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
        } else {
            enqueueSnackbar(t('general.requestGeneralError'), { variant: 'error' });
        }
    };

    const getReviewData = async () => {
        setIsFetching(true);

        const reviewData = await getReview(Number(id));

        setReview(reviewData);

        if (reviewData) {
            if (!isMetadataChangesReviewScreen && reviewData.status === ReviewStatusReturns.Required && reviewData.fiaf_work_id_exact) {
                navigate(buildRoute(AppRoute.WorkReviewChanges, { id: reviewData.id }));
            } else {
                if (reviewData.status === ReviewStatusReturns.Required && !reviewData.fiaf_work_id_exact) {
                    getWorkSuggestions(reviewData.id);
                }
                getMetadataChanges();
            }

            if (reviewData.fiaf_work_id) getOriginalWork(reviewData.fiaf_work_id);
        } else {
            setIsFetching(false);
        }
    };

    const getOriginalWork = async (workId: number) => {
        const data = await getWork(workId);
        setOriginalWork(data);
    };

    const getWorkSuggestions = async (reviewId: number) => {
        const suggestions = await getReviewWorkSuggestions(reviewId);

        setWorkSuggestions(suggestions);
    };

    const getMetadataChanges = async () => {
        const metadataChangedData = await getReviewMetadataChanges(Number(id), { ignore_deleted: true });
        setMetadataChanges(metadataChangedData);

        setIsFetching(false);
    };

    const renderReviewNewWorkDataButtons = () => {
        return (
            <div className="work-screen-top__actions">
                <Button
                    color="error"
                    variant="contained"
                    disableElevation
                    onClick={onDeclineNewWork}
                >
                    {t('workReview.decline')}
                </Button>
                <Button
                    color="success"
                    variant="contained"
                    disableElevation
                    onClick={onApproveNewWork}
                >
                    {t('workReview.save')}
                </Button>
            </div>
        );
    };

    const renderReviewChangesButtons = () => {
        return (
            <div className="work-screen-top__actions">
                <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={onApproveChanges}
                >
                    {t('workReview.save')}
                </Button>
            </div>
        );
    };

    if (isFetching) {
        return <Loader />;
    }
    return (
        <Container maxWidth="md">
            <div className="work-form">
                <div className="work-screen-top">
                    <div className="work-screen-top__img-container">
                        <IconCircleWork />
                    </div>
                    {review && (
                    <div className="work-screen-top__info">
                        <div className="work-screen-top__info__tags">
                            <Chip label={review.fiaf_work_id} size="small" />
                        </div>
                        <h1>{review.fiaf_work_preferred_title}</h1>
                    </div>
                    )}
                    {isMetadataChangesReviewScreen ? renderReviewChangesButtons() : renderReviewNewWorkDataButtons()}
                </div>
                {review && (
                    isMetadataChangesReviewScreen ? (
                        <ReviewWorkChanges
                            review={review}
                            metadataChanges={metadataChanges}
                            workArchives={originalWork?.archiveWorks || []}
                            setMetadataChanges={(changesUpdated) => setMetadataChanges(changesUpdated)}
                        />
                    ) : (
                        <ReviewNewWorkData
                            review={review}
                            metadataChanges={metadataChanges}
                            suggestions={workSuggestions}
                            workArchives={originalWork?.archiveWorks || []}
                        />
                    )
                )}
            </div>
        </Container>
    );
};

export default withTranslationContext(withReviewsContext(withWorksContext(WorkReviewWrapperScreen)));
