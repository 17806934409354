/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';

const IconCircleUser: FunctionComponent = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
            <g transform="translate(7196 -16951)">
                <rect width="60" height="60" rx="30" transform="translate(-7196 16951)" fill="#cfcfcf" className="icon-bg" />
                <path d="M-7159.209 16986.167c-3.147-1.147-4.153-2.114-4.153-4.186 0-1.243 1.031-2.049 1.383-3.115a25.3 25.3 0 0 0 .724-3.245 20.255 20.255 0 0 0 .328-2.251c.112-1.219-.704-4.37-5.073-4.37s-5.187 3.151-5.072 4.371a20.413 20.413 0 0 0 .328 2.251 25.183 25.183 0 0 0 .721 3.245c.351 1.066 1.384 1.871 1.384 3.115 0 2.072-1.006 3.039-4.153 4.186s-5.208 2.281-5.208 3.082v3.75h24v-3.75c0-.799-2.051-1.932-5.209-3.083Z" fill="#797979" className="icon-icon" />
            </g>
        </svg>
    );
};

export default IconCircleUser;
