/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { AnyAction } from 'redux';
import { REHYDRATE } from 'redux-persist';

import { PreferencesState } from '../types/store';
import { PreferencesAction } from '../actions/preferences_types';
import { AppLanguage } from '../types/preferences';

export const preferencesInitialState: PreferencesState = {
    translations: null,
    language: AppLanguage.EN,
    languages: [AppLanguage.EN],
};

const preferencesReducer = (state = preferencesInitialState, action: AnyAction): PreferencesState => {
    switch (action.type) {
        case REHYDRATE:
            if (action.payload && action.payload.preferences) {
                return {
                    ...action.payload.preferences,
                    translationsRequest: {
                        isFetching: false,
                        errors: null,
                    },
                };
            }
            return state;
        case PreferencesAction.LanguageSet:
            return {
                ...state,
                language: action.payload,
            };
        default:
            return state;
    }
};

export default preferencesReducer;
