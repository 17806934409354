/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useState } from 'react';
import {
    Button, CircularProgress, Dialog, IconButton,
} from '@mui/material';
import {
    Check, Close, DeleteOutlined, ErrorOutlined,
} from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Dropzone from 'react-dropzone';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { ImportContext, withImportContext } from '../controllers/import/ImportContext';
import { ImportResponseData, ImportResponseDataStatus } from '../../types/import';
import { FIAF_TREASURES_EMAIL } from '../../constants/misc';
import { ApiError } from '../../types/errors';
import { downloadFile } from '../../utils/misc';
import { importTemplateUrl } from '../../services/import';

enum DataImportType {
    Item = 'item',
    Work = 'work',
}

interface OwnProps extends TranslationContext, ImportContext {
    onClose(): void;
}

const ImportModal: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t, onClose, sendImportFile, downloadFileAPI,
    } = props;

    const { enqueueSnackbar } = useSnackbar();

    const [file, setFile] = useState<File | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [wasSuccessful, setWasSuccessful] = useState(false);
    const [importResponseData, setImportResponseData] = useState<ImportResponseData | null>(null);

    const onAcceptFile = (acceptedFiles: File[]) => {
        if (acceptedFiles.length === 1) {
            setFile(acceptedFiles[0]);
        }
    };

    const onUploadSuccess = (responseData: ImportResponseData) => {
        setIsFetching(false);
        setWasSuccessful(true);
        setImportResponseData(responseData);
    };

    const onUploadFailure = (responseData: ImportResponseData) => {
        enqueueSnackbar(t('general.requestGeneralError'), { variant: 'error' });
        setIsFetching(false);
        setImportResponseData(responseData);
    };

    const onUpload = () => {
        const formData = new FormData();
        if (file) {
            setIsFetching(true);
            formData.append('import_file', file);

            sendImportFile(formData, onUploadSuccess, onUploadFailure);
        }
    };

    const renderExampleFileDownload = () => {
        return (
            <div className="import-example-link">
                <p>{t('importDialog.downloadOur')}&nbsp;</p>
                <button
                    onClick={() => onDownloadFile(importTemplateUrl())}
                    type="button"
                >
                    {t('importDialog.sampleCSVFile')}
                </button>
            </div>
        );
    };
    const renderActionBtn = () => {
        if (importResponseData?.status === ImportResponseDataStatus.InterruptedWithErrors) {
            return (
                <div className="import-form-content__button">
                    <Button
                        onClick={onClose}
                        variant="contained"
                        data-testid="import-upload-button"
                    >
                        {t('general.close')}
                    </Button>
                </div>
            );
        }

        return (
            <div className="import-form-content__button">
                <Button
                    onClick={wasSuccessful ? onClose : onUpload}
                    variant="contained"
                    disabled={file === null}
                    data-testid="import-upload-button"
                >
                    {wasSuccessful ? t('importDialog.done') : t('importDialog.upload')}
                </Button>
            </div>
        );
    };

    const totalImportedResultsToRender = (type: DataImportType) => {
        if (importResponseData) {
            const {
                total_items_to_import, total_items_imported_insert, total_items_imported_update,
                total_works_imported, total_works_imported_insert, total_works_imported_update,
            } = importResponseData;

            let result = 0;
            if (type === DataImportType.Item) {
                if (total_items_to_import
                    && total_items_imported_insert >= 0
                    && total_items_imported_update >= 0) {
                    result = total_items_to_import
                        - total_items_imported_insert
                        - total_items_imported_update;
                    if (result > 0) {
                        return (
                            <p>
                                {t('importDialog.itemAlreadyExisted', { total: String(result) })}
                            </p>
                        );
                    }
                }
            } else {
                if (total_works_imported
                    && total_works_imported_insert >= 0
                    && total_works_imported_update >= 0) {
                    result = total_works_imported
                        - total_works_imported_insert
                        - total_works_imported_update;
                    if (result > 0) {
                        return (
                            <p>
                                {t('importDialog.worksAlreadyExisted', { total: String(result) })}
                            </p>
                        );
                    }
                }
            }
        }
    };

    const renderSuccessInfo = () => {
        return (
            <>
                <div className="import-form-content__response-info__details success">
                    <div className="import-form-content__response-info__details__top">
                        <div className="import-form-content__response-info__details__top__icon-wrapper">
                            <Check color="success" />
                            <h6>
                                <span className="txt-bold">
                                    {t('importDialog.numberOfRecords', { total: String(importResponseData?.total_items_to_import) })}
                                </span>
                                {t('importDialog.importedSuccessfully')}
                            </h6>
                        </div>
                    </div>
                </div>
                {importResponseData && importResponseData.total_items_to_import > 0
                    && (
                        <div className="import-form-content__response-info__details info">
                            <div className="import-form-content__response-info__details__top">
                                <div className="import-form-content__response-info__details__top__icon-wrapper">
                                    <InfoOutlinedIcon />
                                    <h6>
                                        <span className="txt-bold">
                                            {t('importDialog.numberOfItems', { total: String(importResponseData.total_items_to_import) })}
                                        </span>
                                        {t('importDialog.submittedToImport')}
                                    </h6>
                                </div>
                                {importResponseData.total_items_imported_insert > 0
                                    && (
                                        <p>
                                            {t('importDialog.itemsCreatedSuccessfully', { total: String(importResponseData.total_items_imported_insert) })}
                                        </p>
                                    )}
                                {importResponseData.total_items_imported_update > 0
                                    && (
                                        <p>
                                            {t('importDialog.itemsUpdatedSuccessfully', { total: String(importResponseData.total_items_imported_update) })}
                                        </p>
                                    )}
                                {totalImportedResultsToRender(DataImportType.Item)}
                            </div>
                        </div>
                    )
                }
                {importResponseData && importResponseData.total_works_to_review > 0
                    && (
                        <div className="import-form-content__response-info__details info">
                            <div className="import-form-content__response-info__details__top">
                                <div className="import-form-content__response-info__details__top__icon-wrapper">
                                    <InfoOutlinedIcon />
                                    <h6>
                                        <span className="txt-bold">
                                            {t('importDialog.numberOfWorks', { total: String(importResponseData.total_works_imported) })}
                                        </span>
                                        {t('importDialog.submittedToImport')}
                                    </h6>
                                </div>
                                {importResponseData.total_works_imported_insert > 0
                                    && (
                                        <p>
                                            {t('importDialog.newWorksDetected', { total: String(importResponseData.total_works_imported_insert) })}
                                        </p>
                                    )}
                                {importResponseData.total_works_imported_update > 0
                                    && (
                                        <p>
                                            {t('importDialog.worksChanged', { total: String(importResponseData.total_works_imported_update) })}
                                        </p>
                                    )}
                                {totalImportedResultsToRender(DataImportType.Work)}
                            </div>
                        </div>
                    )
                }
            </>
        );
    };

    const onFailure = (error?: ApiError) => {
        if (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
        } else {
            enqueueSnackbar(t('general.requestGeneralError'), { variant: 'error' });
        }
    };

    const onDownloadFile = async (downloadUrl: string | null) => {
        if (typeof downloadUrl === 'string') {
            setIsFetching(true);
            try {
                const fileResponse = await downloadFileAPI(downloadUrl, onFailure);
                if (fileResponse) {
                    downloadFile(fileResponse);
                }
            } catch (error) {
                const err = error as AxiosError;
                onFailure(err.response?.data);
            }
            setIsFetching(false);
        }
    };

    const renderErrorInfo = () => {
        if (importResponseData?.total_validation_error_messages) {
            return (
                <div className="import-form-content__response-info__details">
                    <div className="import-form-content__response-info__details__top">
                        <ErrorOutlined color="error" />
                        <p>
                            <span className="txt-bold">
                                {t('importDialog.numberOfRecords', {
                                    totalImported: String(importResponseData?.total_items_imported),
                                    total: String(importResponseData?.total_items_to_import),
                                })}
                            </span>
                            {t('importDialog.notImported')}
                        </p>
                    </div>
                    <div className="import-form-content__response-info__details__error-msg">
                        <p>{t('importDialog.errorMsg')}</p>
                        <div>
                            <p>{t('importDialog.downloadThis')}&nbsp;</p>
                            {importResponseData.url_validation_messages_text
                                && (
                                    <button
                                        type="button"
                                        onClick={() => onDownloadFile(importResponseData.url_validation_messages_text)}
                                    >{t('importDialog.txtFile')}
                                    </button>
                                )
                            }
                            {importResponseData?.url_validation_messages_text && importResponseData?.url_validation_messages_excel && (
                                <p>&nbsp;{t('importDialog.orThis')}&nbsp;</p>
                            )}
                            {importResponseData.url_validation_messages_excel
                                && (
                                    <button
                                        type="button"
                                        onClick={() => onDownloadFile(importResponseData.url_validation_messages_excel)}
                                    >{t('importDialog.xlsReport')}
                                    </button>
                                )
                            }
                            <p>{`. ${t('importDialog.correctAllErrorsAndUpload')}`}</p>
                        </div>
                        <div>
                            <p>{t('importDialog.errorsHelpMsg')}&nbsp;</p>
                            <a className="email-link" href={`mailto:${FIAF_TREASURES_EMAIL}`}>{FIAF_TREASURES_EMAIL}</a>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="import-form-content__response-info__details">
                <div className="import-form-content__response-info__details__top">
                    <ErrorOutlined color="error" />
                    <p>
                        <span className="txt-bold">
                            {t('importDialog.ops')}&nbsp;
                        </span>
                        {t('importDialog.couldNotReadFile')}
                    </p>
                </div>
                <div className="import-form-content__response-info__details__error-msg">
                    <p className="grey">{t('importDialog.couldNotReadFile')}</p>
                    {renderExampleFileDownload()}
                </div>
            </div>
        );
    };

    const renderContentWhenHasFile = () => {
        if (!file) return null;

        if (isFetching) {
            return (
                <div className="import-form-content">
                    <div className="import-form-content__loading">
                        <CircularProgress size={40} thickness={4} />
                        <h4>{t('importDialog.yourFileIsBeingAnalysed')}</h4>
                        <p>{t('importDialog.analysingInformation')}</p>
                    </div>
                </div>
            );
        }

        return (
            <div className="import-form-content">
                <div className="import-form-content__file-data">
                    <div>
                        <h4>{file.name}</h4>
                        <p>{Number(file.size / 1024).toFixed(2)}kb</p>
                    </div>
                    {!importResponseData && (
                        <IconButton data-testid="import-delete-button" onClick={() => setFile(null)}>
                            <DeleteOutlined />
                        </IconButton>
                    )}
                </div>
                {importResponseData && (
                    <div className="import-form-content__response-info">
                        <h1>{t('importDialog.summary')}</h1>
                        {wasSuccessful ? renderSuccessInfo() : renderErrorInfo()}
                    </div>
                )}
                {renderActionBtn()}
            </div>
        );
    };

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open
            onClose={onClose}
            className="main-dialog"
        >
            <div className="main-dialog__top">
                <div className="main-dialog__top__left">
                    <div>
                        <h2 className="txt-bold secondary">{t('importDialog.title')}</h2>
                        <h2 className="secondary">{t('importDialog.uploadFileOfYourData')}</h2>
                    </div>
                </div>
                <div className="main-dialog__top__right">
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        data-testid="import-close-button"
                    >
                        <Close color="primary" />
                    </IconButton>
                </div>
            </div>
            {file === null ? (
                <div className="import-form-content">
                    <h3>{t('importDialog.fileUpload')}</h3>
                    <Dropzone
                        noClick
                        multiple={false}
                        maxSize={2000000}
                        onDrop={onAcceptFile}
                        accept={{
                            'application/octet-stream': ['.csv', '.xlsx', '.xls', '.ods'],
                        }}
                    >
                        {({ open, getRootProps, getInputProps }) => (
                            <section className="dropzone-wrapper">
                                <div {...getRootProps({ className: 'dropzone' })}>
                                    <input {...getInputProps()} data-testid="import-drop-input" />
                                    <span>
                                        <p>{t('importDialog.dragAndDropHere')}&nbsp;</p>
                                        <button
                                            onClick={() => open()}
                                            type="button"
                                        >
                                            {t('importDialog.orSelectFiles')}
                                        </button>
                                    </span>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                    <p className="import-form-content__info">{t('importDialog.fileFormatAndSize')}</p>
                    <h3>{t('importDialog.notSureWhatToInclude')}</h3>
                    {renderExampleFileDownload()}
                    {renderActionBtn()}
                </div>
            ) : renderContentWhenHasFile()}
        </Dialog>
    );
};

export default withTranslationContext(withImportContext(ImportModal));
