/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { connect } from 'react-redux';
import React, { Component, ReactNode } from 'react';
import axios, { AxiosError } from 'axios';

import { UsersContextProvider } from './UsersContext';
import { KeyedObject } from '../../../types/general';
import { GenericFunction, ListResponse } from '../../../types/misc';
import {
    ChangePasswordPayload, ConfirmEmailChangePayload, User, UserPayload, UserSetPasswordPayload,
} from '../../../types/users';
import {
    userAcceptInvitationUrl,
    userChangeEmailUrl,
    userConfirmEmailChangeUrl,
    usersUrl,
    userUrl,
    userPasswordChangeUrl,
} from '../../../services/users';

interface OwnProps {
    children: ReactNode;
}

type Props = OwnProps;

export class UsersController extends Component<Props> {
    getUsers = async (filters?: KeyedObject): Promise<ListResponse<User> | null> => {
        try {
            const { data } = await axios.get(usersUrl(filters));
            return data;
        } catch {
            return null;
        }
    };

    getUser = async (userId: number): Promise<User | null> => {
        try {
            const { data } = await axios.get(userUrl(userId));
            return data.data;
        } catch {
            return null;
        }
    };

    createUser = async (payload: UserPayload, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void> => {
        try {
            await axios.post(usersUrl(), payload);
            onSuccess();
        } catch (error) {
            const err = error as AxiosError;
            onFailure(err.response?.data);
        }
    };

    editUser = async (userId: number, payload: UserPayload, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void> => {
        try {
            const { data } = await axios.put(userUrl(userId), payload);
            onSuccess(data.data);
        } catch (error) {
            const err = error as AxiosError;
            onFailure(err.response?.data);
        }
    };

    deleteUser = async (userId: number, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void> => {
        try {
            await axios.delete(userUrl(userId));
            onSuccess();
        } catch (error) {
            const err = error as AxiosError;
            onFailure(err.response?.data);
        }
    };

    userAcceptInvitation = async (userId: number, payload: UserSetPasswordPayload, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void> => {
        try {
            await axios.post(userAcceptInvitationUrl(userId), payload);
            onSuccess();
        } catch (error) {
            const err = error as AxiosError;
            onFailure(err.response?.data);
        }
    };

    changeUserEmail = async (userId: number, newEmail: string, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void> => {
        try {
            await axios.patch(userChangeEmailUrl(userId), { email: newEmail });
            onSuccess();
        } catch (error) {
            const err = error as AxiosError;
            onFailure(err.response?.data);
        }
    };

    confirmEmailChange = async (payload: ConfirmEmailChangePayload, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void> => {
        try {
            await axios.post(userConfirmEmailChangeUrl(payload.user_id), payload);
            onSuccess();
        } catch (error) {
            const err = error as AxiosError;
            onFailure(err.response?.data);
        }
    };

    changeUserPassword = async (userId: number, payload: ChangePasswordPayload, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void> => {
        try {
            await axios.patch(userPasswordChangeUrl(userId), payload);
            onSuccess();
        } catch (error) {
            const err = error as AxiosError;
            onFailure(err.response?.data);
        }
    };

    render(): ReactNode {
        const {
            children,
        } = this.props;

        return (
            <UsersContextProvider
                value={{
                    getUsers: this.getUsers,
                    getUser: this.getUser,
                    createUser: this.createUser,
                    editUser: this.editUser,
                    deleteUser: this.deleteUser,
                    userAcceptInvitation: this.userAcceptInvitation,
                    changeUserEmail: this.changeUserEmail,
                    confirmEmailChange: this.confirmEmailChange,
                    changeUserPassword: this.changeUserPassword,
                }}
            >
                {children}
            </UsersContextProvider>
        );
    }
}

export const ConnectedUsersController = connect()(UsersController);
