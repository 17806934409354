/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';
import { CircularProgress } from '@mui/material';

const Loader: FunctionComponent = () => {
    return (
        <div className="loader" data-testid="loader">
            <div className="loader__wrapper">
                <CircularProgress size={40} thickness={4} />
            </div>
        </div>
    );
};

export default Loader;
