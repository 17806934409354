/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

export enum SoundProduction {
    Silent = 'Silent',
    Sound = 'Sound',
}
