/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';

const IconCirclePen: FunctionComponent = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
            <rect fill="#707070" rx="30" height="60" width="60" />
            <path d="M7032.719-16932.743a1.427 1.427 0 0 1-.557.345l-4.281 1.427a1.427 1.427 0 0 1-1.807-1.809l1.427-4.281a1.427 1.427 0 0 1 .345-.558l11.123-11.118a2.854 2.854 0 0 1 4.036 0l.837.837a2.854 2.854 0 0 1 0 4.036l-11.122 11.121h-.001zm-3.867-3.863 11.125-11.122a1.427 1.427 0 0 1 2.018 0l.837.837a1.427 1.427 0 0 1 0 2.018l-11.122 11.121-4.282 1.427 1.424-4.281z" />
            <path d="M7038.637-16948.651a.714.714 0 0 0-1.01 0l-4.7 4.7a.714.714 0 1 1-1.01-1.01l4.7-4.7a2.141 2.141 0 0 1 3.028 0l.418.417a.714.714 0 0 1-1.002 1.012l-.418-.418-.006-.001zM7043.545-16950.582a1.427 1.427 0 0 1 2.018 0l.043.043a1.427 1.427 0 0 1 .043 1.974l-1.808 1.969-2.141-2.141 1.845-1.845z" />
            <g fill="#f1f2f2">
                <path d="M26.707 38.205a1.427 1.427 0 0 1-.557.345l-4.28 1.427a1.427 1.427 0 0 1-1.808-1.809l1.427-4.28a1.427 1.427 0 0 1 .345-.559l11.123-11.118a2.854 2.854 0 0 1 4.036 0l.837.837a2.854 2.854 0 0 1 0 4.036L26.708 38.205zm-3.867-3.863L33.965 23.22a1.427 1.427 0 0 1 2.018 0l.837.837a1.427 1.427 0 0 1 0 2.018L25.698 37.196l-4.282 1.427 1.424-4.28z" fillRule="evenodd" />
                <path d="M32.625 22.297a.714.714 0 0 0-1.01 0l-4.7 4.7a.714.714 0 1 1-1.01-1.01l4.7-4.7a2.141 2.141 0 0 1 3.028 0l.418.417a.714.714 0 0 1-1.002 1.012l-.418-.418h-.006z" fillRule="evenodd" />
                <path d="M37.533 20.366a1.427 1.427 0 0 1 2.018 0l.043.043a1.427 1.427 0 0 1 .043 1.974l-1.808 1.97-2.14-2.142 1.844-1.845z" />
            </g>
        </svg>
    );
};

export default IconCirclePen;
