/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';

import {
    LoginRequestPayload,
    RecoverPasswordPayload,
    RecoverPasswordResponsePayload,
} from '../../../types/authentication';
import { GenericFunction } from '../../../types/misc';
import { ConnectedAuthenticationController } from './AuthenticationController';
import { UserSetPasswordPayload, User } from '../../../types/users';

export interface AuthenticationContext {
    token: string | null;
    isAuthenticated: boolean;
    authenticatedUser: User | null;
    submitLogout(onFailure?: GenericFunction): void;
    submitLogin(payload: LoginRequestPayload, onSuccess: GenericFunction, onFailure?: GenericFunction): void;
    submitRecoverPassword(payload: RecoverPasswordPayload): Promise<RecoverPasswordResponsePayload | null>;
    setAuthenticatedUser(user: User): void;
    submitResetPassword(payload: UserSetPasswordPayload, onSuccess: GenericFunction, onFailure: GenericFunction): Promise<void>;
}

export const authenticationContextDefaultValue = {
    token: null,
    isAuthenticated: false,
    authenticatedUser: null,
    submitLogout: (): void => {},
    submitLogin: (): void => {},
    submitRecoverPassword: async (): Promise<RecoverPasswordResponsePayload | null> => null,
    setAuthenticatedUser: (): void => {},
    submitResetPassword: async (): Promise<void> => {},
};

const AuthenticationContextCreated = createContext<AuthenticationContext | null>(authenticationContextDefaultValue);

export const AuthenticationContextProvider = AuthenticationContextCreated.Provider;
export const AuthenticationContextConsumer = AuthenticationContextCreated.Consumer;

export const withAuthenticationContext = <P extends unknown>(Component: ComponentType<P>): FC<Omit<P, keyof AuthenticationContext>> => (props) => (
    <ConnectedAuthenticationController>
        <AuthenticationContextConsumer>
            {(ctx) => <Component {...props as P} {...ctx} />}
        </AuthenticationContextConsumer>
    </ConnectedAuthenticationController>
);
